define("plantworks/framework/base-model-multiple-attribute-sets", ["exports", "plantworks/framework/base-model", "ember-concurrency"], function (_exports, _baseModel, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'prefuncs': Ember.computed('attributeSets.@each.{evaluationOrder,attributeSetName,preFuncs}', function () {
      return this.get('_filterFuncs').perform('pre');
    }),
    'dataPoints': Ember.computed('attributeSets.@each.{evaluationOrder,attributeSetName,dataPoints}', function () {
      return this.get('_reorderProps').perform();
    }),
    'observerfuncs': Ember.computed('attributeSets.@each.{evaluationOrder,attributeSetName,observerFuncs}', function () {
      return this.get('_filterFuncs').perform('observer');
    }),
    'postfuncs': Ember.computed('attributeSets.@each.{evaluationOrder,attributeSetName,postFuncs}', function () {
      return this.get('_filterFuncs').perform('post');
    }),
    '_orderAttributeSets': (0, _emberConcurrency.task)(function* () {
      let machineAttrSets = yield this.get('attributeSets');

      for (let idx = 0; idx < machineAttrSets.length; idx++) {
        const thisAttrSet = machineAttrSets.objectAt(idx);
        yield thisAttrSet.get('tenantAttributeSet');
      }

      machineAttrSets = machineAttrSets.toArray();
      machineAttrSets.sort((left, right) => {
        if (left.get('evaluationOrder') !== right.get('evaluationOrder')) return left.get('evaluationOrder') - right.get('evaluationOrder');
        if (left.get('attributeSetName') < right.get('attributeSetName')) return -1;
        if (left.get('attributeSetName') > right.get('attributeSetName')) return 1;
        return 0;
      });
      return machineAttrSets;
    }).enqueue(),
    '_filterFuncs': (0, _emberConcurrency.task)(function* (funcType) {
      let attrSets = yield this.get('_orderAttributeSets').perform();
      const allFuncs = {};

      for (let idx = 0; idx < attrSets.length; idx++) {
        const attrSet = attrSets[idx];
        const attrSetFuncs = yield attrSet.get(`${funcType}Funcs`);

        for (let jdx = 0; jdx < attrSetFuncs.length; jdx++) {
          const attrSetFunc = attrSetFuncs[jdx];
          const attrSetFuncObsTags = yield attrSetFunc.get('observedTags');
          allFuncs[`${attrSetFunc.get('name')}-${attrSetFuncObsTags}`] = attrSetFunc;
        }
      }

      const filteredFuncs = Object.keys(allFuncs).map(key => {
        return allFuncs[key];
      });

      if (funcType !== 'observer') {
        filteredFuncs.sort((left, right) => {
          if (left.get('executionOrder') !== right.get('executionOrder')) return left.get('executionOrder') - right.get('executionOrder');
          if (left.get('name') < right.get('name')) return -1;
          if (left.get('name') > right.get('name')) return 1;
          return 0;
        });
      }

      return Ember.ArrayProxy.create({
        'content': Ember.A(filteredFuncs)
      });
    }).enqueue(),
    '_reorderProps': (0, _emberConcurrency.task)(function* () {
      let attrSets = yield this.get('_orderAttributeSets').perform();
      const allProps = {};

      for (let idx = 0; idx < attrSets.length; idx++) {
        const attrSet = attrSets[idx];
        const attrSetProps = yield attrSet.get('dataPoints');

        for (let jdx = 0; jdx < attrSetProps.length; jdx++) {
          const attrSetProp = attrSetProps[jdx];
          allProps[attrSetProp.get('internalTag')] = attrSetProp;
        }
      }

      const filteredProps = Object.keys(allProps).map(key => {
        return allProps[key];
      });
      return Ember.ArrayProxy.create({
        'content': Ember.A(filteredProps)
      });
    }).enqueue()
  });

  _exports.default = _default;
});