define("plantworks/components/settings/account/basics/location-details", ["exports", "plantworks/framework/base-component", "ember-resize/mixins/resize-aware", "plantworks/config/environment", "ember-concurrency"], function (_exports, _baseComponent, _resizeAware, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_resizeAware.default, {
    'classNames': ['w-100', 'layout-row', 'layout-align-center-stretch'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    'editable': false,
    'resizeWidthSensitive': true,
    'resizeHeightSensitive': true,
    'staticUrl': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-basics-read');
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('settings-account-basics-update');
      this.set('editable', updatePerm);
    }),
    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('displayLocation').perform();
    }).drop().on('didInsertElement'),
    'onLocationsChange': Ember.observer('model.tenantLocations', 'model.tenantLocations.@each.{latitude,longitude}', function () {
      this.get('displayLocation').perform();
    }),

    debouncedDidResize(width, height) {
      if (!width || !height) return;
      this.get('displayLocation').perform();
    },

    'addLocation': (0, _emberConcurrency.task)(function* () {
      try {
        const self = this;
        const primaryTenantLocation = this.get('store').createRecord('settings/account/basics/tenant-location', {
          'tenant': this.get('model')
        });
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.locations.label_add_location'),
            'iconType': 'md',
            'icon': 'location-city'
          },
          'dialogClass': 'flex-75',
          'componentName': 'Common/LocationEditor',
          'componentState': {
            'model': primaryTenantLocation
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_add_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('saveLocation').perform(primaryTenantLocation);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': () => {
              self.get('_confirmedDeleteLocation').perform(primaryTenantLocation);
            }
          }
        };
        this.get('model.tenantLocations').addObject(primaryTenantLocation);
        yield this.invokeAction('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'editLocation': (0, _emberConcurrency.task)(function* (tenantLocation) {
      try {
        const self = this;
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.locations.label_add_location'),
            'iconType': 'md',
            'icon': 'location-city'
          },
          'dialogClass': 'flex-75',
          'componentName': 'Common/LocationEditor',
          'componentState': {
            'model': tenantLocation
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_save_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('saveLocation').perform(tenantLocation);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': () => {
              if (tenantLocation.get('isNew')) {
                tenantLocation.destroyRecord();
                return;
              }

              if (tenantLocation.rollback) {
                tenantLocation.rollback();
                return;
              }

              if (tenantLocation.content.rollback) tenantLocation.content.rollback();
            }
          }
        };
        yield this.invokeAction('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'displayLocation': (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1500);
      const staticLocationDisplayDiv = document.getElementById('settings-account-locations-static-location-display');
      if (!staticLocationDisplayDiv) return;
      const mapWidth = staticLocationDisplayDiv.clientWidth;
      const mapHeight = staticLocationDisplayDiv.clientHeight;
      const mapParameters = {
        'lat': 0,
        'lng': 0,
        'key': _environment.default['ember-google-maps']['key']
      };
      const locations = yield this.get('model.tenantLocations');
      locations.forEach(location => {
        mapParameters.lat = location.get('latitude');
        mapParameters.lng = location.get('longitude');
        location.set('staticUrl', `//maps.googleapis.com/maps/api/staticmap?center=${mapParameters.lat},${mapParameters.lng}&size=${mapWidth}x${mapHeight}&maptype=roadmap&markers=color:blue%7Clabel:S%7C${mapParameters.lat},${mapParameters.lng}&key=${mapParameters.key}`);
      });
    }).keepLatest(),
    'saveLocation': (0, _emberConcurrency.task)(function* (tenantLocation) {
      if (tenantLocation.save) yield tenantLocation.save();
      if (tenantLocation.content && tenantLocation.content.save) yield tenantLocation.content.save();
      yield this.get('model').reload();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveLocationSucceeded': Ember.on('saveLocation:succeeded', function (taskInstance) {
      const tenantLocation = taskInstance.args[0];
      if (tenantLocation && tenantLocation.reload) tenantLocation.reload();
      if (tenantLocation.content && tenantLocation.content.reload) tenantLocation.content.reload();
    }),
    'saveLocationErrored': Ember.on('saveLocation:errored', function (taskInstance, err) {
      const tenantLocation = taskInstance.args[0];
      if (tenantLocation.rollback) tenantLocation.rollback();
      if (tenantLocation.content && tenantLocation.content.rollback) tenantLocation.content.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteLocation': (0, _emberConcurrency.task)(function* (tenantLocation) {
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.locations.label_delete_location'),
          'iconType': 'md',
          'icon': 'location-city'
        },
        'content': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.locations.question_delete_location'),
        'confirmButton': {
          'text': this.intl.t('modal.default_delete_text'),
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteLocation').perform(tenantLocation);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteLocation': (0, _emberConcurrency.task)(function* (tenantLocation) {
      if (tenantLocation.content) {
        yield tenantLocation.content.destroyRecord();
      } else {
        yield tenantLocation.destroyRecord();
      }

      yield this.get('model').reload();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteLocationErrored': Ember.on('_confirmedDeleteLocation:errored', function (taskInstance, err) {
      this.get('model').rollback();
      const tenantLocation = taskInstance.args[0];
      if (tenantLocation.content) tenantLocation.content.rollback();else tenantLocation.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});