define("plantworks/components/pug/user-manager/feature-access", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    attributeBindings: ['style'],
    style: 'min-width: 900px; padding: 0px 20px;',
    'subComponents': null,
    'selectedComponent': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        this._super(...arguments);

        this.set('subComponents', [{
          'iconType': 'mdi',
          'icon': 'pac-man',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_hdashboards'),
          'component': 'Pug/UserManager/UserHdashboardsAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'seat-legroom-extra',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_lines'),
          'component': 'Pug/UserManager/UserLinesAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'seat-legroom-extra',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_machines'),
          'component': 'Pug/UserManager/UserMachinesAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'pac-man',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_panels'),
          'component': 'Pug/UserManager/UserPanelsAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'pac-man',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_reports'),
          'component': 'Pug/UserManager/UserReportsAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'seat-legroom-extra',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_stations'),
          'component': 'Pug/UserManager/UserStationsAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'file-upload',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_emd'),
          'component': 'Pug/UserManager/UserEmdsAccess'
        }, {
          'iconType': 'mdi',
          'icon': 'file-upload',
          'title': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_wof'),
          'component': 'Pug/UserManager/UserWofsAccess'
        }]);
        this.set('selectedComponent', this.get('subComponents')[0]);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      const userId = this.get('state.tenantUser').get('id');
      const panels = yield this.get('ajax').request(`/pug/user-manager/panels?user_id=${userId}`);
      panels.forEach(panel => {
        let i18n_path = [];

        for (let idx = 0; idx < panel.path.length; idx++) {
          let elem = panel.path[idx];

          if (elem.includes('board_feature')) {
            elem = elem.replace('board_feature', 'plant_works_webapp_server_server.board_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        panel.i18n_path = i18n_path.join(' > ');
        this.get('state.features.panels').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(panel)
        }));
      });
      const reports = yield this.get('ajax').request(`/pug/user-manager/reports?user_id=${userId}`);
      reports.forEach(report => {
        let i18n_path = [];

        for (let idx = 0; idx < report.path.length; idx++) {
          let elem = report.path[idx];

          if (elem.includes('report_feature')) {
            elem = elem.replace('report_feature', 'plant_works_webapp_server_server.report_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        report.i18n_path = i18n_path.join(' > ');
        this.get('state.features.reports').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(report)
        }));
      });
      const hdashboards = yield this.get('ajax').request(`/pug/user-manager/hdashboards?user_id=${userId}`);
      hdashboards.forEach(dashboard => {
        let i18n_path = [];

        for (let idx = 0; idx < dashboard.path.length; idx++) {
          let elem = dashboard.path[idx];

          if (elem.includes('historical_dashboard_feature')) {
            elem = elem.replace('historical_dashboard_feature', 'plant_works_webapp_server_server.historical_dashboard_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        dashboard.i18n_path = i18n_path.join(' > ');
        this.get('state.features.hdashboards').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(dashboard)
        }));
      });
      const stations = yield this.get('ajax').request(`/pug/user-manager/plant-unit-stations?user_id=${userId}`);
      stations.forEach(station => {
        let i18n_path = [];

        for (let idx = 0; idx < station.path.length; idx++) {
          let elem = station.path[idx];

          if (elem.includes('manufacturing_feature')) {
            elem = elem.replace('manufacturing_feature', 'plant_works_webapp_server_server.manufacturing_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        station.i18n_path = i18n_path.join(' > ');
        this.get('state.features.stations').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(station)
        }));
      });
      const lines = yield this.get('ajax').request(`/pug/user-manager/plant-unit-lines?user_id=${userId}`);
      lines.forEach(line => {
        let i18n_path = [];

        for (let idx = 0; idx < line.path.length; idx++) {
          let elem = line.path[idx];

          if (elem.includes('manufacturing_feature')) {
            elem = elem.replace('manufacturing_feature', 'plant_works_webapp_server_server.manufacturing_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        line.i18n_path = i18n_path.join(' > ');
        this.get('state.features.lines').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(line)
        }));
      });
      const machines = yield this.get('ajax').request(`/pug/user-manager/plant-unit-machines?user_id=${userId}`);
      machines.forEach(machine => {
        let i18n_path = [];

        for (let idx = 0; idx < machine.path.length; idx++) {
          let elem = machine.path[idx];

          if (elem.includes('manufacturing_feature')) {
            elem = elem.replace('manufacturing_feature', 'plant_works_webapp_server_server.manufacturing_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        machine.i18n_path = i18n_path.join(' > ');
        this.get('state.features.machines').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(machine)
        }));
      });
      const emds = yield this.get('ajax').request(`/pug/user-manager/plant-emd?user_id=${userId}`);
      emds.forEach(emd => {
        let i18n_path = [];

        for (let idx = 0; idx < emd.path.length; idx++) {
          let elem = emd.path[idx];

          if (elem.includes('emd_feature')) {
            elem = elem.replace('emd_feature', 'plant_works_webapp_server_server.emd_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        emd.i18n_path = i18n_path.join(' > ');
        this.get('state.features.emds').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(emd)
        }));
      });
      const wofs = yield this.get('ajax').request(`/pug/user-manager/plant-wof?user_id=${userId}`);
      wofs.forEach(wof => {
        let i18n_path = [];

        for (let idx = 0; idx < wof.path.length; idx++) {
          let elem = wof.path[idx];

          if (elem.includes('work_order_feature')) {
            elem = elem.replace('work_order_feature', 'plant_works_webapp_server_server.work_order_feature');
            i18n_path.push(this.intl.t(elem));
          } else {
            i18n_path.push(elem);
          }
        }

        wof.i18n_path = i18n_path.join(' > ');
        this.get('state.features.wofs').addObject(Ember.ObjectProxy.create({
          'content': Ember.Object.create(wof)
        }));
      });
    }).on('willInsertElement')
  });

  _exports.default = _default;
});