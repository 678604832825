define("plantworks/components/work-order/configure/property-metadata-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['layout-row', 'layout-align-start-center', 'py-4'],
    'metadata': null,
    'isUniqueId': false,
    'isNumber': false,
    'isNull': false,
    'calculateSetuptime': false,
    'onInit': (0, _emberConcurrency.task)(function* () {
      var entity = this.get('entity');
      const allMetadata = yield entity.get(`${this.get('metadataField')}`);
      let metadata = allMetadata.filterBy('parameterId', this.get('model.id')).objectAt(0);

      if (!metadata) {
        metadata = allMetadata.createFragment({
          'parameterId': this.get('model.id')
        });
      }

      const isNumber = this.get('model.datatype') === 'number';
      this.set('isNumber', isNumber);
      this.set('metadata', metadata);
    }).on('init').drop(),
    'onIsSelectionChanged': Ember.observer('metadata.isUniqueId', function () {
      if (!this.get('metadata')) return;
      if (!this.get('metadata.isUniqueId')) return;
      const allMetadata = this.get(`entity.${this.get('metadataField')}`);

      for (let idx = 0; idx < allMetadata.length; idx++) {
        const metadata = allMetadata.objectAt(idx);
        if (metadata.get('parameterId') === this.get('model.id')) continue;
        metadata.set('isUniqueId', false);
      }
    }),
    'onIsPrimaryOutputChanged': Ember.observer('metadata.isPrimaryOutput', function () {
      if (!this.get('metadata')) return;
      if (!this.get('metadata.isPrimaryOutput')) return;
      const allMetadata = this.get(`entity.${this.get('metadataField')}`);

      for (let idx = 0; idx < allMetadata.length; idx++) {
        const metadata = allMetadata.objectAt(idx);

        if (metadata.get('parameterId') === this.get('model.id')) {
          metadata.set('isSecondaryOutput', false);
          continue;
        }

        metadata.set('isPrimaryOutput', false);
      }
    }),
    'onIsSecondaryOutputChanged': Ember.observer('metadata.isSecondaryOutput', function () {
      if (!this.get('metadata')) return;
      if (!this.get('metadata.isSecondaryOutput')) return;
      const allMetadata = this.get(`entity.${this.get('metadataField')}`);

      for (let idx = 0; idx < allMetadata.length; idx++) {
        const metadata = allMetadata.objectAt(idx);

        if (metadata.get('parameterId') === this.get('model.id')) {
          metadata.set('isPrimaryOutput', false);
          continue;
        }

        metadata.set('isSecondaryOutput', false);
      }
    }),
    'onCalculateSetuptimeChanged': Ember.observer('metadata.calculateSetuptime', function () {
      if (!this.get('metadata')) return;
      if (!this.get('metadata.calculateSetuptime')) return;
      const allMetadata = this.get(`entity.${this.get('metadataField')}`);

      for (let idx = 0; idx < allMetadata.length; idx++) {
        const metadata = allMetadata.objectAt(idx);
        if (metadata.get('parameterId') === this.get('model.id')) continue;
        metadata.set('calculateSetuptime', false);
      }
    })
  });

  _exports.default = _default;
});