define("plantworks/components/common/plant-works-code-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'tagName': 'textarea',
    'codeMirror': Ember.inject.service('codeMirror'),
    '_codeMirror': null,
    'options': null,
    '_topPanels': null,
    '_bottomPanels': null,

    init() {
      this._super(...arguments);

      this.set('options', {
        'lineNumbers': true,
        'indentWithTabs': true,
        'readOnly': this.get('readOnly') ? 'nocursor' : false,
        'theme': 'elegant',
        'mode': this.get('language') ? this.get('language') : 'javascript',
        'viewportMargin': Infinity
      });
      this.set('_topPanels', []);
      this.set('_bottomPanels', []);
    },

    readOnlyChangeReactor: Ember.observer('readOnly', function () {
      this.set('options.readOnly', this.get('readOnly') ? 'nocursor' : false);
      this.updateCodeMirrorOptions();
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('_codeMirror', this.get('codeMirror').fromTextArea(this.get('elementId'), this.get('element')));
      this.setupCodeMirrorEventHandler('change', this, this.scheduleValueUpdatedAction);
      (this.get('topPanels') || []).forEach((topPanelCode, idx) => {
        const isLastPanel = idx === (this.get('topPanels') || []).length - 1;
        const longTopPanelCode = topPanelCode + '';
        const shortTopPanelCode = longTopPanelCode.split('\n').length > 1 ? longTopPanelCode.split('\n')[0] : '.....';
        const topPanelDiv = document.createElement('div');
        topPanelDiv.className = 'panel top bg-grey lighten-4 pt-2 pb-1 layout-row layout-align-end-start';

        if (!isLastPanel) {
          topPanelDiv.classList.add('br-0');
          topPanelDiv.classList.add('br-b-1');
          topPanelDiv.classList.add('br-blue-grey');
        }

        const topPanelText = topPanelDiv.appendChild(document.createElement("pre"));
        topPanelText.classList.add('mb-0');
        topPanelText.classList.add('flex');
        topPanelText.style.fontSize = '0.85rem';
        topPanelText.textContent = isLastPanel ? longTopPanelCode : shortTopPanelCode;

        if (!isLastPanel) {
          const toggleDisplay = topPanelDiv.appendChild(document.createElement("a"));
          toggleDisplay.setAttribute("title", "Expand");
          toggleDisplay.setAttribute("class", "remove-panel");
          toggleDisplay.classList.add('mr-4');
          toggleDisplay.style.fontSize = '1.75rem';
          toggleDisplay.style.cursor = 'pointer';
          toggleDisplay.textContent = "+";
          window.CodeMirror.on(toggleDisplay, "mousedown", function (e) {
            e.preventDefault();

            if (e.srcElement.textContent === '+') {
              e.srcElement.setAttribute('title', 'Collapse');
              e.srcElement.textContent = '-';
              topPanelText.textContent = longTopPanelCode;
              return;
            }

            if (e.srcElement.textContent === '-') {
              e.srcElement.setAttribute('title', 'Expand');
              e.srcElement.textContent = '+';
              topPanelText.textContent = shortTopPanelCode;
              return;
            }
          });
        }

        const _topPanel = this.get('_codeMirror').addPanel(topPanelDiv, {
          'position': idx ? 'after-top' : 'top',
          'stable': true
        });

        this.get('_topPanels').push(_topPanel);
      });
      (this.get('bottomPanels') || []).forEach(bottomPanelCode => {
        const bottomPanelDiv = document.createElement('div');
        bottomPanelDiv.className = 'panel bottom bg-grey lighten-4 pt-2 pb-1 layout-row layout-align-start-center';
        const bottomPanelText = bottomPanelDiv.appendChild(document.createElement("pre"));
        bottomPanelText.classList.add('mb-0');
        bottomPanelText.classList.add('flex');
        bottomPanelText.style.fontSize = '0.85rem';
        bottomPanelText.textContent = bottomPanelCode;

        const _bottomPanel = this.get('_codeMirror').addPanel(bottomPanelDiv, {
          'position': 'bottom',
          'stable': true
        });

        this.get('_bottomPanels').push(_bottomPanel);
      });
    },

    didRender() {
      this._super(...arguments);

      this.updateCodeMirrorOptions();
      this.updateCodeMirrorValue();
    },

    willDestroyElement() {
      this._super(...arguments); // Remove the panels, if any


      this.get('_bottomPanels').forEach(_bottomPanel => {
        _bottomPanel.clear();
      });
      this.get('_topPanels').forEach(_topPanel => {
        _topPanel.clear();
      }); // Remove the editor and restore the original textarea.

      this.get('_codeMirror').toTextArea();
      this.get('codeMirror').unregisterInstance(this.get('elementId'));
      this.set('_codeMirror', null);
    },

    'isVisibleDidChange': Ember.observer('isVisible', function () {
      if (this._wasVisible === this.get('isVisible')) {
        return;
      }

      Ember.run.scheduleOnce('render', this, this.toggleVisibility);
    }),

    scheduleValueUpdatedAction(codeMirror, changeObj) {
      Ember.run.once(this, this.sendValueUpdatedAction, codeMirror.getValue(), codeMirror, changeObj);
    },

    setupCodeMirrorEventHandler(event, target, method) {
      const callback = Ember.run.bind(target, method);
      this.get('_codeMirror').on(event, callback);
      this.one('willDestroyElement', this, function () {
        this.get('_codeMirror').off(event, callback);
      });
    },

    sendValueUpdatedAction(...args) {
      this.invokeAction('valueUpdated', ...args);
    },

    toggleVisibility() {
      const isVisible = this.get('isVisible');

      if (this._wasVisible === isVisible) {
        return;
      }

      this._wasVisible = isVisible;

      if (isVisible) {
        // Force a refresh when becoming visible, since CodeMirror won't render
        // itself onto a hidden element.
        this.get('_codeMirror').refresh();
      }
    },

    updateCodeMirrorOption(option, value) {
      if (this.get('_codeMirror').getOption(option) !== value) {
        this.get('_codeMirror').setOption(option, value);
      }
    },

    updateCodeMirrorOptions() {
      const options = this.get('options');

      if (options) {
        Object.keys(options).forEach(function (option) {
          this.updateCodeMirrorOption(option, options[option]);
        }, this);
      }
    },

    updateCodeMirrorValue() {
      const value = this.get('value');

      if (value !== this.get('_codeMirror').getValue()) {
        this.get('_codeMirror').setValue(value || '');
      }
    }

  });

  _exports.default = _default;
});