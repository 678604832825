define("plantworks/controllers/application", ["exports", "jquery", "plantworks/framework/base-controller", "plantworks/config/environment", "ember-concurrency"], function (_exports, _jquery, _baseController, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'media': Ember.inject.service('media'),
    'notification': Ember.inject.service('integrated-notification'),
    'realtimeData': Ember.inject.service('realtime-data'),
    'resize': Ember.inject.service('resize'),
    'modalData': null,
    'showDialog': false,
    'displayToolbar': false,
    'mainTitle': '',
    'displayCurrentYear': false,
    'startYear': _environment.default.plantworks.startYear,
    'currentYear': _environment.default.plantworks.startYear,
    'domain': _environment.default.plantworks.domain,
    'isCorber': _environment.default.IS_CORBER,
    'realtimeConnectivityLost': Ember.computed('intl.locale', function () {
      return this.intl.t('general.connectivity_lost');
    }),
    'realtimeConnectivityLostWillReconnect': Ember.computed('intl.locale', function () {
      return this.intl.t('general.connectivity_lost_with_reconnect');
    }),
    'multipleModalError': Ember.computed('intl.locale', function () {
      return this.intl.t('modal.multiple_error');
    }),
    'defaultModalTitle': Ember.computed('intl.locale', function () {
      return this.intl.t('modal.default_title');
    }),
    'defaultModalContent': Ember.computed('intl.locale', function () {
      return this.intl.t('modal.default_content');
    }),
    'defaultModalOkText': Ember.computed('intl.locale', function () {
      return this.intl.t('modal.default_ok_text');
    }),
    'defaultModalCancelText': Ember.computed('intl.locale', function () {
      return this.intl.t('modal.default_cancel_text');
    }),

    init() {
      this._super(...arguments);

      this.set('mainTitle', document.title);
      const currentYear = new Date().getFullYear();
      this.set('currentYear', currentYear);
      this.set('displayCurrentYear', currentYear > this.get('startYear'));
      this.get('realtimeData').on('websocket-data::display-status-message', this, this.onDisplayWebsocketStatusMessage);
      this.get('realtimeData').on('websocket-close', this, this.onWebsocketClose);
      this.get('realtimeData').on('websocket-disconnection', this, this.onWebsocketDisconnect);
      this.get('currentUser').on('userDataUpdated', this, '_setResizeAware');
      this.get('currentUser').on('userDataUpdated', this, '_setDisplayToolbar');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, '_setResizeAware');
      this.get('currentUser').off('userDataUpdated', this, '_setDisplayToolbar');
      this.get('realtimeData').off('websocket-disconnection', this, this.onWebsocketDisconnect);
      this.get('realtimeData').off('websocket-close', this, this.onWebsocketClose);
      this.get('realtimeData').off('websocket-data::display-status-message', this, this.onDisplayWebsocketStatusMessage);

      this._super(...arguments);
    },

    debouncedDidResize() {
      this._setMainHeight();
    },

    onDisplayWebsocketStatusMessage(data) {
      const notification = this.get('notification');
      notification.display(data);
    },

    onWebsocketClose() {
      const notification = this.get('notification');
      notification.display(this.get('realtimeConnectivityLostWillReconnect'));
    },

    onWebsocketDisconnect() {
      const notification = this.get('notification');
      notification.display(this.get('realtimeConnectivityLost'));
    },

    displayModal(data) {
      if (this.get('showDialog')) {
        this.get('notification').display({
          'type': 'error',
          'error': new Error(this.get('multipleModalError'))
        });
        return;
      }

      const defaultData = {
        'title': {
          'text': this.get('defaultModalText'),
          'iconType': 'md',
          'icon': 'turned-in'
        },
        'content': this.get('defaultModalContent'),
        'dialogClass': '',
        'confirmButton': {
          'text': this.get('defaultModalOkText'),
          'iconType': 'md',
          'icon': 'check',
          'disabled': false,
          'primary': true,
          'raised': true,
          'callback': null
        },
        'cancelButton': {
          'text': this.get('defaultModalCancelText'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': null
        },
        'actions': {}
      };
      const modalData = Object.assign({}, defaultData, data);
      this.set('modalData', modalData);
      this.set('showDialog', true);
    },

    closeDialog(proceed) {
      if (proceed && this.get('modalData.confirmButton.callback')) {
        this.get('modalData.confirmButton.callback')();
      }

      if (!proceed && this.get('modalData.cancelButton.callback')) {
        this.get('modalData.cancelButton.callback')();
      }

      this.set('showDialog', false);
      this.set('modalData', null);
    },

    _setResizeAware() {
      if (window.plantworksUserId) this.get('resize').on('debouncedDidResize', this, '_setMainHeight');else this.get('resize').off('debouncedDidResize', this, '_setMainHeight');
      this.get('_setMainHeightTask').perform();
      ;
    },

    _setDisplayToolbar() {
      this.set('displayToolbar', !(this.get('currentUser.userData.user_id') === "ffffffff-ffff-4fff-ffff-ffffffffffff"));
    },

    '_setMainHeightTask': (0, _emberConcurrency.task)(function* () {
      while ((0, _jquery.default)('body').length == 0 || (0, _jquery.default)('body main').length == 0) yield (0, _emberConcurrency.timeout)(1000);

      this._setMainHeight();
    }).enqueue(),

    _setMainHeight() {
      if (window.plantworksUserId && (this.get('media.isMd') || this.get('media.isLg') || this.get('media.isXl'))) {
        (0, _jquery.default)('body').addClass('overflow-none');
        (0, _jquery.default)('body').removeClass('overflow-auto');
        (0, _jquery.default)('body main').parent().addClass('flex');
        (0, _jquery.default)('body main').parent().addClass('layout-column');
        (0, _jquery.default)('body main').parent().addClass('layout-align-start-stretch');
        (0, _jquery.default)('body main').addClass('overflow-auto');
      } else {
        (0, _jquery.default)('body main').removeClass('overflow-auto');
        (0, _jquery.default)('body main').parent().removeClass('layout-align-start-stretch');
        (0, _jquery.default)('body main').parent().removeClass('layout-column');
        (0, _jquery.default)('body main').parent().removeClass('flex');
        (0, _jquery.default)('body').removeClass('overflow-none');
        (0, _jquery.default)('body').addClass('overflow-auto');
      }
    },

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'actions': {
      'controller-action': function (action, data) {
        if (this.get('showDialog') && this.get('modalData') && this.get('modalData.actions')) {
          const modalActions = this.get('modalData')['actions'][action];

          if (modalActions) {
            modalActions(data);
            return;
          }
        }

        if (this[action] && typeof this[action] === 'function') {
          this[action](data);
          return;
        }

        this.get('notification').display(`TODO: Handle ${action} action with data: `, data);
      }
    }
  });

  _exports.default = _default;
});