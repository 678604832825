define("plantworks/components/historical-dashboard/devenv/historical-dashboard-subcomponent-list", ["exports", "plantworks/framework/base-component", "ember-concurrency", "ember-cli-uuid"], function (_exports, _baseComponent, _emberConcurrency, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'selectedSubcomponent': null,
    'addHistoricalDashboardSubcomponent': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      const tenant = yield this.get('model.tenant');
      const newSubcomponent = yield this.get('store').createRecord('historical-dashboard/historical-dashboard-subcomponent', {
        'name': `subcomponent_${new Date().valueOf()}`,
        'tenant': tenant,
        'tenantHistoricalDashboard': this.get('model'),
        'componentId': (0, _emberCliUuid.uuid)()
      });
      this.get('model.subcomponents').addObject(newSubcomponent);
    }),
    'selectSubcomponent': (0, _emberConcurrency.task)(function* (subcomponent) {
      yield this.set('selectedSubcomponent', subcomponent);
    }).keepLatest(),
    'selectMainSubcomponent': (0, _emberConcurrency.task)(function* () {
      yield this.set('selectedSubcomponent', 'main');
    }).keepLatest(),
    'deleteSubcomponent': (0, _emberConcurrency.task)(function* (subcomponent) {
      const componentId = subcomponent.get('componentId');
      yield subcomponent.destroyRecord();
      let allSubcomponents = yield this.get('model.subcomponents');
      allSubcomponents = allSubcomponents.filterBy('componentId', componentId);

      for (let idx = 0; idx < allSubcomponents.length; idx++) {
        const historicalDashboardSubcomponent = allSubcomponents.objectAt(idx);
        yield historicalDashboardSubcomponent.unloadRecord();
      }
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteSubcomponentSucceeded': Ember.on('deleteSubcomponent:succeeded', function (taskInstance) {
      const subcomponent = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.succesful_historical_dashboard_subcomponent_delete', {
          'htmlSafe': true,
          'displayName': subcomponent.get('name')
        })
      });
    }),
    'deleteSubcomponentErrored': Ember.on('deleteSubcomponent:errored', function (taskInstance, err) {
      const subcomponent = taskInstance.args[0];
      subcomponent.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'saveSubcomponent': (0, _emberConcurrency.task)(function* (subcomponent) {
      yield subcomponent.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveSubcomponentSucceeded': Ember.on('saveSubcomponent:succeeded', function (taskInstance) {
      const subcomponent = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.succesful_historical_dashboard_subcomponent_save', {
          'htmlSafe': true,
          'displayName': subcomponent.get('name')
        })
      });
    }),
    'saveSubcomponentErrored': Ember.on('saveSubcomponent:errored', function (taskInstance, err) {
      const subcomponent = taskInstance.args[0];
      subcomponent.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelSubcomponent': (0, _emberConcurrency.task)(function* (subcomponent) {
      yield subcomponent.rollback();
    }),
    actions: {
      'resetSelectedSubcomponent': function () {
        this.set('selectedSubcomponent', null);
      }
    }
  });

  _exports.default = _default;
});