define("plantworks/templates/components/manufacturing/configure/plant-unit-line-editor/output-property-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mpDbDXeb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isNumber\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_primary_output\"],null],[24,[\"metadata\",\"isPrimaryOutput\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isPrimaryOutput\"]]],null]],null]]]],[0,\"\\n\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_secondary_output\"],null],[24,[\"metadata\",\"isSecondaryOutput\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isSecondaryOutput\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isBoolean\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_speed_deviation_parameter\"],null],[28,\"not\",[[24,[\"isBoolean\"]]],null],[24,[\"metadata\",\"isSpeedDeviationFlag\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isSpeedDeviationFlag\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/configure/plant-unit-line-editor/output-property-selector.hbs"
    }
  });

  _exports.default = _default;
});