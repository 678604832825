define("plantworks/components/event-alert/configure/event-alert-editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'currentTab': 'users',
    'eventAlertResponseTypes': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'event-alert-read');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const eventAlertResponseTypes = yield this.get('ajax').request('/masterdata/event-alert-response-formatter-types', {
          'method': 'GET'
        });
        this.setProperties({
          'eventAlertResponseTypes': eventAlertResponseTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('event-alert-create');
      const updatePerm = this.get('currentUser').hasPermission('event-alert-update');
      const deletePerm = this.get('currentUser').hasPermission('event-alert-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'cancelEventAlert': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').rollback();
    }).enqueue(),
    'saveEventAlert': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveEventAlertSucceeded': Ember.on('saveEventAlert:succeeded', function () {
      const unit = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_event_alert_save', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    'saveEventAlertErrored': Ember.on('saveEventAlert:errored', function (taskInstance, err) {
      const unit = this.get('model');
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteEventAlert': (0, _emberConcurrency.task)(function* () {
      const eventAlertName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.event_alert_feature.label_delete_event_alert'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.event_alert_feature.delete_event_alert_message', {
          'htmlSafe': true,
          'displayName': eventAlertName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteEventAlert').perform(eventAlertName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteEventAlert': (0, _emberConcurrency.task)(function* (eventAlertName, folderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteEventAlertSucceeded': Ember.on('_confirmedDeleteEventAlert:succeeded', function (taskInstance) {
      const eventAlertName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_event_alert_delete', {
          'htmlSafe': true,
          'displayName': eventAlertName
        })
      });
    }),
    '_confirmedDeleteEventAlertErrored': Ember.on('_confirmedDeleteEventAlert:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});