define("plantworks/templates/components/board/configure/display-period-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HLlTsTT7",
    "block": "{\"symbols\":[\"Row\",\"displayPeriod\",\"@Body\"],\"statements\":[[6,[23,3,[\"Row\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[24,[\"InputDataPoint\",\"name\"]],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[24,[\"InputDataPoint\",\"internalTag\"]],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[\"@class\"],[\"px-2\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"plant-works-select\",[],[[\"@hideAllMessages\",\"@class\",\"@options\",\"@selected\",\"@onChange\"],[true,\"w-100 m-0\",[22,\"dataDisplayChoices\"],[24,[\"metadata\",\"dataDisplayPeriod\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"dataDisplayPeriod\"]]],null]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"t\",[[28,\"concat\",[\"database_types.\",[23,2,[]]],null]],null],false],[0,\"\\n\\t\\t\"]],\"parameters\":[2]}],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[\"@class\"],[\"text-center\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"plant-works-checkbox\",[],[[\"@value\",\"@onChange\"],[[24,[\"metadata\",\"isGlobal\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isGlobal\"]]],null]],null]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/board/configure/display-period-editor.hbs"
    }
  });

  _exports.default = _default;
});