define("plantworks/models/manufacturing/plant-unit-schedule", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'description': _emberData.default.attr('string'),
    'startDate': _emberData.default.attr('date'),
    'endDate': _emberData.default.attr('date'),
    'type': _emberData.default.attr('string'),
    'startTime': _emberData.default.attr('string'),
    'endTime': _emberData.default.attr('string'),
    'isFirstShift': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'i18n_type': Ember.computed('type', function () {
      return this.intl.t(`plant_works_webapp_server_server.manufacturing_feature.label_${this.get('type')}`);
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true
    }),
    'tenantPlantUnit': _emberData.default.belongsTo('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'schedules'
    }),
    'extensions': _emberData.default.hasMany('manufacturing/plant-unit-schedule-extension', {
      'async': true,
      'inverse': 'shift'
    }),
    'path': Ember.computed('name', 'parent.path', function () {
      return this.get('_computePath').perform();
    }),
    'pastStartDate': Ember.computed('startDate', function () {
      return window.moment(this.get('startDate')).isSameOrAfter(window.moment().format(), 'day');
    }),
    'pastEndDate': Ember.computed('endDate', function () {
      return window.moment(this.get('endDate')).isSameOrAfter(window.moment().format(), 'day');
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const parent = yield this.get('parent');

      if (!(parent && parent.get('id'))) {
        const plant = yield this.get('plant');
        return `${plant.get('name')} > ${this.get('name')}`;
      }

      const parentPath = this.get('parent.path');
      return `${parentPath} > ${this.get('name')}`;
    }).keepLatest()
  });

  _exports.default = _default;
});