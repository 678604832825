define("plantworks/components/pug/user-manager/add-existing-accounts", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'selectedUser': null,
    'searchUserByEmail': (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(750);
      return this.get('ajax').request(`/pug/user-manager/searchUsers?email=${term}`);
    }),
    'onSelectedUserChanged': Ember.observer('selectedUser', function () {
      this.get('_addSelectedUser').perform();
    }),
    '_addSelectedUser': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedUser')) return;
      const isDuplicate = this.get('state.model').filterBy('user_id', this.get('selectedUser.id')).get('length');

      if (isDuplicate) {
        this.set('selectedUser', null);
        return;
      }

      let userModel = this.get('store').peekRecord('pug/user-manager/user', this.get('selectedUser.id'));
      if (!userModel) userModel = yield this.get('store').findRecord('pug/user-manager/user', this.get('selectedUser.id'));
      this.get('state.model').addObject(userModel);
      this.set('selectedUser', null);
    }).enqueue(),
    'deleteUser': (0, _emberConcurrency.task)(function* (user) {
      const model = yield this.get('state.model');
      model.removeObject(user);
    }).enqueue()
  });

  _exports.default = _default;
});