define("plantworks/controllers/pug/user-manager", ["exports", "plantworks/framework/base-controller", "ember-concurrency"], function (_exports, _baseController, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'canViewGroupAdministrator': false,
    'canViewUserAdministrator': false,
    'editable': false,
    'hasSubModulePermissions': Ember.computed.or('canViewGroupAdministrator', 'canViewUserAdministrator'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'user-manager-read');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    'onPermissionChanges': Ember.on('init', Ember.observer('permissions', function () {
      this.onUserDataUpdated();
    })),

    onUserDataUpdated() {
      const currentUser = this.get('currentUser');
      const updatePerm = currentUser.hasPermission('user-manager-update');
      this.set('editable', updatePerm);
      this.set('canViewGroupAdministrator', currentUser.hasPermission('group-manager-read'));
      this.set('canViewUserAdministrator', currentUser.hasPermission('user-manager-read'));
    },

    'createUser': (0, _emberConcurrency.task)(function* () {
      try {
        const self = this;
        const tenant = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
        const user = this.get('store').createRecord('pug/user-manager/user', {
          'firstName': this.intl.t('general.label_new'),
          'lastName': this.intl.t('general.label_user'),
          'email': `${this.intl.t('general.label_new')}.${this.intl.t('general.label_user')}@${tenant.get('subDomain')}.com`.toLocaleLowerCase()
        });
        const tenantUser = this.get('store').createRecord('pug/user-manager/tenant-user', {
          'tenant': tenant,
          'user': user
        });
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.create_user'),
            'iconType': 'mdi',
            'icon': 'account'
          },
          'componentName': 'Pug/UserManager/CreateNewAccount',
          'componentState': {
            'tenantUser': tenantUser,
            'model': user
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_save_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              if (!user.saveDisabled) {
                tenantUser.deleteRecord();
                user.deleteRecord();
                this.get('notification').display({
                  'type': 'error',
                  'error': 'Invalid Username!'
                });
              } else self.get('doCreateAccount').perform(user, tenantUser);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': () => {
              tenantUser.deleteRecord();
              user.deleteRecord();
            }
          }
        };
        yield this.send('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'doCreateAccount': (0, _emberConcurrency.task)(function* (user, tenantUser) {
      yield user.save();
      yield tenantUser.save();
      const defaultGroup = this.get('store').peekAll('pug/group-manager/tenant-group').filterBy('defaultForNewUser', true).objectAt(0);
      if (defaultGroup) this.get('store').unloadRecord(defaultGroup);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'doCreateAccountSucceeded': Ember.on('doCreateAccount:succeeded', function (taskInstance) {
      const user = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': `${user.get('fullName')} <${user.get('email')}> ${this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.succesful_create')}`
      });
    }),
    'doCreateAccountErrored': Ember.on('doCreateAccount:errored', function (taskInstance, err) {
      const user = taskInstance.args[0];
      const tenantUser = taskInstance.args[1];
      tenantUser.destroyRecord();
      user.destroyRecord();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addUser': (0, _emberConcurrency.task)(function* () {
      try {
        const self = this;
        const usersToBeAdded = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.add_existing_user'),
            'iconType': 'mdi',
            'icon': 'account'
          },
          'componentName': 'Pug/UserManager/AddExistingAccounts',
          'componentState': {
            'model': usersToBeAdded
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_add_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('doAddAccounts').perform(usersToBeAdded);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': null
          }
        };
        yield this.send('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'doAddAccounts': (0, _emberConcurrency.task)(function* (userList) {
      const tenant = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);

      for (let idx = 0; idx < userList.get('length'); idx++) {
        const user = userList.objectAt(idx);
        let tenantUser = this.get('store').peekAll('pug/user-manager/tenant-user').filterBy('user.id', user.get('id')).objectAt(0);
        if (tenantUser && !tenantUser.get('isNew')) continue;
        if (!tenantUser) tenantUser = this.get('store').createRecord('pug/user-manager/tenant-user', {
          'tenant': tenant,
          'user': user
        });
        yield tenantUser.save();
      }

      const defaultGroup = this.get('store').peekAll('pug/group-manager/tenant-group').filterBy('defaultForNewUser', true).objectAt(0);
      if (defaultGroup) this.get('store').unloadRecord(defaultGroup);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'doAddAccountsSucceeded': Ember.on('doAddAccounts:succeeded', function (taskInstance) {
      const userList = taskInstance.args[0];
      if (!userList.get('length')) return;
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.succesful_add', {
          'numAccounts': userList.get('length')
        })
      });
    }),
    'doAddAccountsErrored': Ember.on('doAddAccounts:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});