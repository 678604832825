define("plantworks/components/plant-works-table/edit-dialog", ["exports", "pworks-dsl/components/plant-works-table/edit-dialog"], function (_exports, _editDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _editDialog.default;
    }
  });
});