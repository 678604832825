define("plantworks/components/board/watch/realtime-custom-display", ["exports", "plantworks/framework/base-component", "moment", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _moment, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-unused-vars
  var _default = _baseComponent.default.extend({
    'media': Ember.inject.service('media'),
    'onInitTaskDone': false,
    'onBeforeRenderTaskDone': false,
    'onAfterRenderTaskDone': false,

    init() {
      this._super(...arguments);

      this._setupFrontEndHooks();
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      const attributeSetMetadata = this.get('model.attributeSetMetadata');
      const dataPointTimePeriods = {};
      attributeSetMetadata.forEach(dataPointMetadata => {
        if (dataPointMetadata.get('dataDisplayPeriod') === 'realtime') return;
        const dataPoint = this.get('arrayModel').filter(dataSetProperty => {
          return dataSetProperty.get('id') === dataPointMetadata.get('parameterId');
        }).objectAt(0);
        if (dataPoint) dataPointTimePeriods[dataPoint.get('internalTag')] = dataPointMetadata.get('dataDisplayPeriod');
      });

      if (Object.keys(dataPointTimePeriods).length) {
        const historicalData = yield this.get('ajax').post(`/board/historicalData/${this.get('model.id')}`, {
          'dataType': 'json',
          'data': dataPointTimePeriods
        });
        Object.keys(historicalData).forEach(timePeriodKey => {
          const thisHistoricData = historicalData[timePeriodKey];
          Object.keys(thisHistoricData).forEach(dataPoint => {
            const historicDataPoints = thisHistoricData[dataPoint];
            historicDataPoints.forEach(historicDataPoint => {
              historicDataPoint['timestamp'] = (0, _moment.default)(historicDataPoint['timestamp']).valueOf();
            });
          });
        });
        this.set('state.historicalData', historicalData);
      }

      this.set('onInitTaskDone', true);
    }).on('init').keepLatest(),
    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      while (!this.get('onInitTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('componentBeforeRenderTask').perform();
    }).on('willInsertElement').enqueue(),
    'onDidRender': (0, _emberConcurrency.task)(function* () {
      if (this.get('onAfterRenderTaskDone')) return;

      while (!this.get('onBeforeRenderTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('componentAfterRenderTask').perform();
      yield this.get('onDataFunc').perform();
      yield this.get('onPeriodicDataFunc').perform();
    }).on('didRender').enqueue(),

    _setupFrontEndHooks() {
      // Step 1: Sanity checks
      let componentBeforeRenderCode = this.get('processor.componentBeforeRenderCode');
      if (!componentBeforeRenderCode) componentBeforeRenderCode = '';else componentBeforeRenderCode = componentBeforeRenderCode.trim();
      let componentAfterRenderCode = this.get('processor.componentAfterRenderCode');
      if (!componentAfterRenderCode) componentAfterRenderCode = '';else componentAfterRenderCode = componentAfterRenderCode.trim();
      let componentOnDataCode = this.get('processor.componentOnDataCode');
      if (!componentOnDataCode) componentOnDataCode = '';else componentOnDataCode = componentOnDataCode.trim();
      let componentOnPeriodicDataCode = this.get('processor.componentOnPeriodicDataCode');
      if (!componentOnPeriodicDataCode) componentOnPeriodicDataCode = '';else componentOnPeriodicDataCode = componentOnPeriodicDataCode.trim();
      let componentBeforeDestroyCode = this.get('processor.componentBeforeDestroyCode');
      if (!componentBeforeDestroyCode) componentBeforeDestroyCode = '';else componentBeforeDestroyCode = componentBeforeDestroyCode.trim(); // Step 2: Set defaults

      if (componentBeforeRenderCode === '') componentBeforeRenderCode = `this.debug('Executing custom componentBeforeRenderCode');\nyield;`;
      if (componentAfterRenderCode === '') componentAfterRenderCode = `this.debug('Executing custom componentAfterRenderCode');\nyield;`;
      if (componentOnDataCode === '') componentOnDataCode = `this.debug('Executing custom componentOnDataCode');\nyield;`;
      if (componentOnPeriodicDataCode === '') componentOnPeriodicDataCode = `this.debug('Executing custom componentOnPeriodicDataCode');\nyield;`;
      if (componentBeforeDestroyCode === '') componentBeforeDestroyCode = `this.debug('Executing custom componentBeforeDestroyCode');\nyield;`; // Step 3: Create functions using eval

      const GeneratorFunction = Object.getPrototypeOf(function* () {}).constructor;
      const componentBeforeRenderFunc = new GeneratorFunction(`
try {
	${componentBeforeRenderCode}

	this.set('onBeforeRenderTaskDone', true);
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentAfterRenderFunc = GeneratorFunction(`
try {
	${componentAfterRenderCode}

	this.set('onAfterRenderTaskDone', true);
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentBeforeDestroyFunc = GeneratorFunction(`
try {
	${componentBeforeDestroyCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentOnDataFunc = GeneratorFunction(`
try {
	${componentOnDataCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentOnPeriodicDataFunc = GeneratorFunction(`
try {
	${componentOnPeriodicDataCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`); // Step 4: Set the new functions as properties of this object

      Ember.defineProperty(this, 'state', undefined, Ember.Object.create(this.get('processor.frontendComponentState') || {}));
      Ember.defineProperty(this, 'componentBeforeRenderTask', (0, _emberConcurrency.task)(componentBeforeRenderFunc).enqueue());
      Ember.defineProperty(this, 'componentAfterRenderTask', (0, _emberConcurrency.task)(componentAfterRenderFunc).enqueue());
      Ember.defineProperty(this, 'onWillDestroyElement', (0, _emberConcurrency.task)(componentBeforeDestroyFunc).on('willDestroyElement').enqueue());
      Ember.defineProperty(this, 'onDataFunc', (0, _emberConcurrency.task)(componentOnDataFunc).enqueue());
      Ember.defineProperty(this, 'onPeriodicDataFunc', (0, _emberConcurrency.task)(componentOnPeriodicDataFunc).enqueue()); // Repeating above steps for observers

      const Function = Object.getPrototypeOf(function () {}).constructor;
      const allObservers = this.get('processor.componentObservers');
      allObservers.forEach(componentObserver => {
        if (!componentObserver.get('name') || !componentObserver.get('observedProperty') || !componentObserver.get('functionCode')) return;
        const templateObservedProperty = componentObserver.get('observedProperty');
        let componentObserverCode = componentObserver.get('functionCode');
        componentObserverCode = `
try {
	${componentObserverCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
			`;
        const componentObserverFunction = new Function(componentObserverCode);
        Ember.addObserver(this, templateObservedProperty, this, componentObserverFunction);
      }); // Repeating above steps for tasks

      const allTasks = this.get('processor.componentTasks');
      allTasks.forEach(componentTask => {
        if (!componentTask.get('name') || !componentTask.get('functionCode')) return;
        let componentTaskCode = componentTask.get('functionCode');
        let componentTaskArgs = componentTask.get('arguments');
        if (componentTaskArgs) componentTaskArgs = componentTaskArgs.split(',').map(arg => {
          return arg.trim();
        });else componentTaskArgs = [];
        componentTaskCode = `
try {
	${componentTaskCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
			`;
        const componentTaskGeneratorFunction = new GeneratorFunction(...componentTaskArgs, componentTaskCode);
        Ember.defineProperty(this, componentTask.get('name'), (0, _emberConcurrency.task)(componentTaskGeneratorFunction).keepLatest());
      });
    },

    'onDataChanged': Ember.observer('arrayModel.@each.currentValue', function () {
      (0, _emberLifeline.debounceTask)(this, 'executeCustomOnDataFunction', 1000);
    }),
    'preOnDataFunc': (0, _emberConcurrency.task)(function* () {
      while (!this.get('onAfterRenderTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('onDataFunc').perform();
    }).enqueue(),

    executeCustomOnDataFunction() {
      const currentTime = (0, _moment.default)();
      currentTime.set({
        'seconds': 0,
        'milliseconds': 0
      });
      Ember.RSVP.resolve().then(() => {
        const historicalData = this.get('state.historicalData') || {};
        Object.keys(historicalData).forEach(timePeriod => {
          let minTime = currentTime.clone();

          switch (timePeriod) {
            case 'last_one_hour':
              minTime.subtract(1, 'hour');
              break;

            case 'last_two_hours':
              minTime.subtract(2, 'hour');
              break;

            case 'last_four_hours':
              minTime.subtract(4, 'hour');
              break;

            case 'last_eight_hours':
              minTime.subtract(8, 'hour');
              break;

            case 'last_twelve_hours':
              minTime.subtract(12, 'hour');
              break;

            case 'last_twenty_four_hours':
              minTime.subtract(24, 'hour');
              break;

            case 'current_shift':
              // TODO: Once Rahul Khunt's API come online
              break;

            case 'current_day':
              minTime.set({
                'hour': 0,
                'minute': 0
              });
              break;

            default:
              minTime.subtract(1, 'hour');
              break;
          }

          minTime = minTime.valueOf();
          const categoryData = historicalData[timePeriod];
          Object.keys(categoryData).forEach(tag => {
            if (!this.get(`objectModel.${tag}`)) return;
            let tagHistoricalData = categoryData[tag];
            tagHistoricalData.push({
              'timestamp': currentTime.valueOf(),
              'value': this.get(`objectModel.${tag}.currentValue`)
            });
            tagHistoricalData = tagHistoricalData.filter(tagData => {
              return tagData.timestamp >= minTime;
            });
            categoryData[tag] = tagHistoricalData;
          });
        });
        return this.get('preOnDataFunc').perform();
      }).catch(err => {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      });
    },

    'onPeriodicDataChanged': Ember.observer('periodicData', function () {
      (0, _emberLifeline.debounceTask)(this, 'executePeriodicOnDataFunc', 1000);
    }),

    executePeriodicOnDataFunc() {
      return this.get('preOnPeriodicDataFunc').perform();
    },

    'preOnPeriodicDataFunc': (0, _emberConcurrency.task)(function* () {
      while (!this.get('onAfterRenderTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('onPeriodicDataFunc').perform();
    }).enqueue()
  });

  _exports.default = _default;
});