define("plantworks/models/event-alert/fragments/escalation-level", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'ttl': _emberData.default.attr('number', {
      'defaultValue': 15
    }),
    // minutes
    'watchers': (0, _attributes.fragmentArray)('alert/fragments/escalation-level-watcher'),
    'enabled': _emberData.default.attr('boolean', {
      'defaultValue': true
    })
  });

  _exports.default = _default;
});