define("plantworks/routes/pug/user-manager", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      if (!window.plantworksTenantId) {
        this.get('store').unloadAll('pug/user-manager/tenant-user');
        this.get('store').unloadAll('pug/user-manager/user');
        this.get('store').unloadAll('pug/user-manager/user-contact');
        return;
      }

      return this.get('store').findAll('pug/user-manager/tenant-user');
    },

    onUserDataUpdated() {
      if (!window.plantworksTenantId) {
        this.get('store').unloadAll('pug/user-manager/tenant-user');
        this.get('store').unloadAll('pug/user-manager/user');
        this.get('store').unloadAll('pug/user-manager/user-contact');
      }

      const isActive = this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksTenantId) {
        this.transitionTo('index');
        return;
      }

      this.get('refreshTenantUserModel').perform();
    },

    'refreshTenantUserModel': (0, _emberConcurrency.task)(function* () {
      let tenantUserData = yield this.get('store').findAll('pug/user-manager/tenant-user');
      this.get('controller').set('model', tenantUserData);
    }).keepLatest()
  });

  _exports.default = _default;
});