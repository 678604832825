define("plantworks/components/manufacturing/configure/plant-unit-line-editor/line-block-constituent-viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency", "ember-cli-uuid"], function (_exports, _baseComponent, _emberConcurrency, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'tagName': '',
    'blockTypes': null,

    init() {
      this._super(...arguments);

      this.set('blockTypes', ['block_some', 'block_any']);
    },

    'createBlock': (0, _emberConcurrency.task)(function* () {
      const line = this.get('model');
      const tenant = yield line.get('tenant'); // eslint-disable-next-line no-unused-vars

      const observedEntities = yield line.get('inputConstituents');
      const newBlock = this.get('store').createRecord('manufacturing/plant-unit-line-constituent', {
        'tenant': tenant,
        'tenantPlantUnitLine': line,
        'constituentType': 'block_some',
        'step': '',
        'tenantPlantUnitLineConstituentId': (0, _emberCliUuid.uuid)()
      });
      newBlock.set('isEditing', true);
    }).drop(),
    'editBlock': (0, _emberConcurrency.task)(function* (block) {
      yield block.set('isEditing', true);
    }).enqueue(),
    'cancelBlockEdit': (0, _emberConcurrency.task)(function* (block) {
      block.set('isEditing', false);
      block.set('showConstituents', false);
      yield block.rollback();
    }).enqueue(),
    'saveBlock': (0, _emberConcurrency.task)(function* (block) {
      block.set('isEditing', false);
      yield block.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveBlockSucceeded': Ember.on('saveBlock:succeeded', function (taskInstance) {
      const block = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_block_save', {
          'htmlSafe': true,
          'displayName': block.get('constituentType')
        })
      });
    }),
    'saveBlockErrored': Ember.on('saveBlock:errored', function (taskInstance, err) {
      const block = taskInstance.args[0];
      block.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteBlock': (0, _emberConcurrency.task)(function* (block) {
      const formatName = yield block.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_block'),
          'iconType': 'mdi',
          'icon': 'file-upload'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_block_message', {
          'htmlSafe': true,
          'displayName': formatName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteBlock').perform(block);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteBlock': (0, _emberConcurrency.task)(function* (block) {
      yield block.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteBlockSucceeded': Ember.on('_confirmedDeleteBlock:succeeded', function (taskInstance) {
      const block = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_manufacturing_block_delete', {
          'htmlSafe': true,
          'displayName': block.get('name')
        })
      });
    }),
    '_confirmedDeleteBlockErrored': Ember.on('_confirmedDeleteBlock:errored', function (taskInstance, err) {
      const block = taskInstance.args[0];
      block.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'showBlockEntities': (0, _emberConcurrency.task)(function* (block) {
      yield block.set('showConstituents', true);
    }).enqueue()
  });

  _exports.default = _default;
});