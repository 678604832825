define("plantworks/components/manufacturing/devenv/plant-unit-machine-editor/backend-environment", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'selectedEvaluationStep': null,
    'evaluationSteps': null,
    'selectedOeeEvaluationStep': null,
    'oeeEvaluationSteps': null,
    'propertyMetadataEditor': 'Manufacturing/Devenv/PlantUnitMachineEditor/PropertyMetadataViewer',
    'propertyMetadataField': 'attributeSetMetadata',
    'rawDataTransformTop': null,
    'rawDataTransformBottom': null,
    'processedDataTransformTop': null,
    'processedDataTransformBottom': null,
    'preRealtimePushTransformTop': null,
    'preRealtimePushTransformBottom': null,
    'availabilityCalculationTop': null,
    'availabilityCalculationBottom': null,
    'performanceCalculationTop': null,
    'performanceCalculationBottom': null,
    'qualityCalculationTop': null,
    'qualityCalculationBottom': null,
    'currentProcessor': null,

    init() {
      this._super(...arguments);

      this.set('evaluationSteps', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_raw_data_transformer'
        }, {
          'name': 'plant_works_webapp_server_server.common.label_attribute_set'
        }, {
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_processed_data_transformer'
        }, {
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_oee_calculation'
        }, {
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_alert_definition'
        }, {
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_pre_realtime_transformer'
        }])
      }));
      this.set('oeeEvaluationSteps', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_oee_calculation_availability'
        }, {
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_oee_calculation_performance'
        }, {
          'name': 'plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_oee_calculation_quality'
        }])
      }));
      this.set('rawDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {string} [currentData] - Raw incoming data
  * @returns  {object} Current set of sensor data as key-value pairs.
  *
  * @summary  Takes the raw data incoming from the field, converts it into a dict, and returns the dict.
  */
`, `async _rawDataTransformer(currentData) {`])
      }));
      this.set('rawDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // return currentData;
}`])
      }));
      this.set('processedDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [evaluatedAttributes] - Processed data.
  * @returns  {undefined} Nothing.
  *
  * @summary  Post-processing of data that has already been evaluated. Done in-place.
  */
`, `async _processedDataTransformer(evaluatedAttributes) {`])
      }));
      this.set('processedDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    return null;
}`])
      }));
      this.set('preRealtimePushTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [evaluatedAttributes] - Processed data.
  * @returns  {object} Data formatted for frontend display.
  *
  * @summary  Format pre-evaluated data for display on the screen. Returns transformed data.
  */
`, `async _realtimePushTransform(evaluatedAttributes) {`])
      }));
      this.set('preRealtimePushTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // return evaluatedAttributes;
}`])
      }));
      this.set('availabilityCalculationTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [evaluatedAttributes] - Processed data.
  * @returns  {undefined} Nothing.
  *
  * @summary  Calculate availability based on incoming data. Done in-place.
  */
`, `async _calculateAvailability(evaluatedAttributes) {`])
      }));
      this.set('availabilityCalculationBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // evaluatedAttributes.attributes['AVAILABILITY'] = 1;`, `    return null;`, `}`])
      }));
      this.set('performanceCalculationTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [evaluatedAttributes] - Processed data.
  * @returns  {undefined} Nothing.
  *
  * @summary  Calculate performance based on incoming data. Done in-place.
  */
`, `async _calculatePerformance(evaluatedAttributes) {`])
      }));
      this.set('performanceCalculationBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // evaluatedAttributes.attributes['PERFORMANCE'] = 1;`, `    return null;`, `}`])
      }));
      this.set('qualityCalculationTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [evaluatedAttributes] - Processed data.
  * @returns  {undefined} Nothing.
  *
  * @summary  Calculate quality based on incoming data. Done in-place.
  */
`, `async _calculateQuality(evaluatedAttributes) {`])
      }));
      this.set('qualityCalculationBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // evaluatedAttributes.attributes['QUALITY'] = 1;`, `    return null;`, `}`])
      }));
      this.set('selectedEvaluationStep', this.get('evaluationSteps').objectAt(0));
      this.set('selectedOeeEvaluationStep', this.get('oeeEvaluationSteps').objectAt(0));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('_setupProcessor').perform();
      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'cancelPlantUnitMachineProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentProcessor')) return;
      yield this.get('currentProcessor').rollback();
    }).enqueue(),
    'savePlantUnitMachineProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentProcessor')) return;
      yield this.get('currentProcessor').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitMachineProcessorSucceeded': Ember.on('savePlantUnitMachineProcessor:succeeded', function () {
      const unitMachine = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_machine_save', {
          'htmlSafe': true,
          'displayName': unitMachine.get('name')
        })
      });
    }),
    'savePlantUnitMachineProcessorErrored': Ember.on('savePlantUnitMachineProcessor:errored', function (taskInstance, err) {
      const unitMachine = this.get('model');
      unitMachine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSavePlantUnitMachineProcessor': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentProcessor')) return;
      this.set('currentProcessor.publishStatus', publish);
      yield this.get('currentProcessor').save();
    }).keepLatest(),
    'publishAndSavePlantUnitMachineProcessorSucceeded': Ember.on('publishAndSavePlantUnitMachineProcessor:succeeded', function () {
      const unitMachine = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_machine_save', {
          'htmlSafe': true,
          'displayName': unitMachine.get('name')
        })
      });
    }),
    'publishAndSavePlantUnitMachineProcessorErrored': Ember.on('publishAndSavePlantUnitMachineProcessor:errored', function (taskInstance, err) {
      const unitMachine = this.get('model');
      unitMachine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'editPlantUnitMachineProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentProcessor')) return;
      if (!this.get('model')) return;
      const currentProcessor = this.get('currentProcessor'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const processors = yield this.get('model.processors');
      const newProcessor = this.get('store').createRecord(`manufacturing/plant-unit-machine-processor`, {
        'tenant': tenant,
        'tenantPlantUnitMachine': this.get('model')
      });
      newProcessor.set('publishStatus', false);
      newProcessor.set('rawDataTransformCode', currentProcessor.get('rawDataTransformCode'));
      newProcessor.set('processedDataTransformCode', currentProcessor.get('processedDataTransformCode'));
      newProcessor.set('preRealtimePushTransformCode', currentProcessor.get('preRealtimePushTransformCode'));
      newProcessor.set('availabilityCalculationCode', currentProcessor.get('availabilityCalculationCode'));
      newProcessor.set('performanceCalculationCode', currentProcessor.get('performanceCalculationCode'));
      newProcessor.set('qualityCalculationCode', currentProcessor.get('qualityCalculationCode'));
      newProcessor.set('isCustomOeeCalculation', currentProcessor.get('isCustomOeeCalculation'));
      newProcessor.set('customOeeCalculationCode', currentProcessor.get('customOeeCalculationCode'));
      processors.addObject(newProcessor);
      this.set('currentProcessor', newProcessor);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,name,processors.[]}', function () {
      this.get('_setupProcessor').perform();
    }),
    'onActiveTabChanged': Ember.observer('selectedEvaluationStep', function () {
      if (!this.get('selectedEvaluationStep')) return;
      const currTabIdx = this.get('evaluationSteps').indexOf(this.get('selectedEvaluationStep'));

      if (currTabIdx === 0 || currTabIdx === 2 || currTabIdx === 5) {
        (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
        return;
      }

      if (currTabIdx === 3) {
        this.set('selectedOeeEvaluationStep', this.get('oeeEvaluationSteps').objectAt(0));
        (0, _emberLifeline.runTask)(this, this._fillOeeCodeEditor, 150);
        return;
      }
    }),
    'onActiveOeeTabChanged': Ember.observer('selectedOeeEvaluationStep', function () {
      if (!this.get('selectedOeeEvaluationStep')) return;
      (0, _emberLifeline.runTask)(this, this._fillOeeCodeEditor, 150);
    }),
    '_setupProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      const processors = yield this.get('model.processors');
      let currentProcessor = null;
      if (!processors) return;

      if (processors.length) {
        currentProcessor = processors.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentProcessor = this.get('store').createRecord(`manufacturing/plant-unit-machine-processor`, {
          'tenant': tenant,
          'tenantPlantUnitMachine': this.get('model')
        });
        processors.addObject(currentProcessor);
      }

      this.set('currentProcessor', currentProcessor);
    }).keepLatest(),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.plant-unit-machine-backend-editor-subcomponent-container');
      const panelDivs = this.$('div.plant-unit-machine-backend-editor-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.plant-unit-machine-backend-editor-subcomponent-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    },
    '_fillOeeCodeEditor': function () {
      const containerDiv = this.$('div.plant-unit-machine-backend-editor-oee-subcomponent-container');
      const panelDivs = this.$('div.plant-unit-machine-backend-editor-oee-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.plant-unit-machine-backend-editor-oee-subcomponent-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});