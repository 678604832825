define("plantworks/utils/public-api", ["exports", "ember-google-maps/utils/public-api"], function (_exports, _publicApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _publicApi.default;
    }
  });
});