define("plantworks/components/operator-form/devenv/operator-form-frontend-environment", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDevMode': null,
    'allDevModes': null,

    init() {
      this._super(...arguments);

      this.set('allDevModes', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.operator_form_feature.label_input_component',
          'component': 'OperatorForm/Devenv/InputComponentEditor'
        }, {
          'name': 'plant_works_webapp_server_server.operator_form_feature.label_result_component',
          'component': 'OperatorForm/Devenv/ResultComponentEditor'
        }, {
          'name': 'general.label_frontend_dev_template_preview',
          'component': 'OperatorForm/Execute/RequestWrapper'
        }])
      }));
      this.set('currentDevMode', this.get('allDevModes').objectAt(0));
    }

  });

  _exports.default = _default;
});