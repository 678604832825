define("plantworks/components/report/devenv/request-formatter", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'formatterDataTransformTop': null,
    'formatterDataTransformBottom': null,
    'currentFormatter': null,

    init() {
      this._super(...arguments);

      let inputVariableName = 'inputData';
      if (this.get('type') === 'cron') inputVariableName = `inputData<{ currentTimestamp }>`;
      this.set('formatterDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`async _requestDataTransformer(${inputVariableName}) {`])
      }));
      this.set('formatterDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`//  return formattedData;
}`])
      }));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('_setupFormatter').perform();
    },

    'cancelRequestFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      yield this.get('currentFormatter').rollback();
    }).enqueue(),
    'saveRequestFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      yield this.get('currentFormatter').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveRequestFormatterSucceeded': Ember.on('saveRequestFormatter:succeeded', function () {
      const report = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_save', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    'saveRequestFormatterErrored': Ember.on('saveRequestFormatter:errored', function (taskInstance, err) {
      const requestFormatter = this.get('currentFormatter');
      requestFormatter.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveRequestFormatter': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentFormatter')) return;
      this.set('currentFormatter.publishStatus', publish);
      yield this.get('currentFormatter').save();

      if (this.get('type') === 'http') {
        this.get('store').unloadAll('dashboard/feature');
        this.get('store').findAll('dashboard/feature');
      }
    }).keepLatest(),
    'publishAndSaveRequestFormatterSucceeded': Ember.on('publishAndSaveRequestFormatter:succeeded', function () {
      const report = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_save', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    'publishAndSaveRequestFormatterErrored': Ember.on('publishAndSaveRequestFormatter:errored', function (taskInstance, err) {
      const report = this.get('model');
      report.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'editRequestFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      if (!this.get('model')) return;
      const currentFormatter = this.get('currentFormatter'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const formatters = yield this.get('model.requestFormatters');
      const newFormatter = this.get('store').createRecord(`report/report-request-formatter`, {
        'tenant': tenant,
        'tenantReport': this.get('model')
      });
      newFormatter.set('requestType', currentFormatter.get('requestType'));
      newFormatter.set('formatterCode', currentFormatter.get('formatterCode'));
      formatters.addObject(newFormatter);
      this.set('currentFormatter', newFormatter);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,requestFormatters.[]}', function () {
      this.get('_setupFormatter').perform();
    }),
    '_setupFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      let formatters = yield this.get('model.requestFormatters');
      if (!formatters) return;
      formatters = formatters.filterBy('requestType', this.get('type'));
      let currentFormatter = null;

      if (formatters.length) {
        currentFormatter = formatters.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentFormatter = this.get('store').createRecord(`report/report-request-formatter`, {
          'tenant': tenant,
          'tenantReport': this.get('model'),
          'requestType': this.get('type')
        });
        formatters.addObject(currentFormatter);
      }

      this.set('currentFormatter', currentFormatter);
    }).keepLatest()
  });

  _exports.default = _default;
});