define("plantworks/templates/manufacturing/watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z9g9oeQ7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"manufacturing/watch/station-display\",[],[[\"@class\",\"@model\",\"@currentWorkOrder\",\"@controller-action\"],[\"flex layout-column layout-align-start-stretch\",[22,\"model\"],[22,\"currentWorkOrder\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/manufacturing/watch.hbs"
    }
  });

  _exports.default = _default;
});