define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/add-plc", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'oemPlcList': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const oemPlcList = yield this.get('store').findAll('masterdata/plc-master', {
        'reload': true
      });
      this.set('oemPlcList', oemPlcList);
    }).on('init').drop(),
    'setMachinePlc': function (newPlcId) {
      const newPlc = this.get('store').peekRecord('masterdata/plc-master', newPlcId);
      this.set('state.model.plc', newPlc);
    }
  });

  _exports.default = _default;
});