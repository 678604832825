define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/property-metadata-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['layout-row', 'layout-align-start-center', 'py-4'],
    'metadata': null,
    'isTimestamp': false,
    'isBoolean': false,
    'isNumber': false,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const allMetadata = yield this.get(`entity.${this.get('metadataField')}`);
      let metadata = allMetadata.filterBy('parameterId', this.get('model.id')).objectAt(0);

      if (!metadata) {
        metadata = allMetadata.createFragment({
          'parameterId': this.get('model.id')
        });
      }

      const isTimestamp = this.get('model.datatype') === 'date'; // if(!isTimestamp) metadata.set('isTimestamp', isTimestamp);

      const isBoolean = this.get('model.datatype') === 'boolean'; // if(!isBoolean) metadata.set('isSpeedDeviationFlag', isBoolean);

      const isNumber = this.get('model.datatype') === 'number';
      this.set('isTimestamp', isTimestamp);
      this.set('isBoolean', isBoolean);
      this.set('isNumber', isNumber);
      this.set('metadata', metadata);
    }).on('init').drop(),
    'onIsTimestampChanged': Ember.observer('metadata.isTimestamp', function () {
      if (!this.get('metadata')) return;
      if (!this.get('metadata.isTimestamp')) return;
      const allMetadata = this.get(`entity.${this.get('metadataField')}`);

      for (let idx = 0; idx < allMetadata.length; idx++) {
        const metadata = allMetadata.objectAt(idx);
        if (metadata.get('parameterId') === this.get('model.id')) continue;
        metadata.set('isTimestamp', false);
      }
    })
  });

  _exports.default = _default;
});