define("plantworks/services/plant-works-toaster", ["exports", "pworks-dsl/services/plant-works-toaster"], function (_exports, _plantWorksToaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksToaster.default;
    }
  });
});