define("plantworks/components/emd/uploader/file-uploader", ["exports", "plantworks/framework/base-component", "@uppy/core", "@uppy/dashboard", "@uppy/xhr-upload", "ember-concurrency"], function (_exports, _baseComponent, _core, _dashboard, _xhrUpload, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'uppyInstance': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'emd-read');
    },

    didRender() {
      this._super(...arguments);

      if (this.get('uppyInstance') && this.get('uppyInstance').getPlugin('Dashboard') && this.get('uppyInstance').getPlugin('XHRUpload')) this.get('uppyInstance').getPlugin('XHRUpload').opts.endpoint = `/emd/uploadData/${this.get('model.id')}`;
    },

    didInsertElement() {
      this._super(...arguments);

      const uppy = (0, _core.default)({
        'restrictions': {
          'allowedFileTypes': ['.xlsx', '.xls']
        },
        'allowMultipleUploads': false,
        'autoProceed': true
      }).use(_dashboard.default, {
        'trigger': '#emd-uploader-file-upload-form',
        'inline': false,
        'showLinkToFileUploadResult': false,
        'disableThumbnailGenerator': true
      }).use(_xhrUpload.default, {
        'endpoint': `/emd/uploadData/${this.get('model.id')}`,
        'method': 'POST',
        'fieldName': 'emdFile',
        'timeout': 0
      });
      uppy.on('upload-success', this._handleFileResponse.bind(this));
      uppy.on('upload-error', (file, error, response) => {
        this.get('notification').display({
          'type': 'error',
          'error': error
        });
        uppy.reset();
      });
      this.set('uppyInstance', uppy);
    },

    '_handleFileResponse': function (e, response) {
      let parsedRows = [];
      let rejectFile = false;
      response.body.forEach(sheet => {
        sheet.forEach(row => {
          row['approved'] = !row['rejected'] && !row['deactivate'];
          row['showDiff'] = false;
          if (row['columnMismatch']) rejectFile = true;
          parsedRows.push(row);
        });
      });

      if (rejectFile) {
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('plant_works_webapp_server_server.emd_feature.error_emd_file_upload')
        });
      } else {
        this.invokeAction('controller-action', 'setParsedData', parsedRows);
      }

      if (this.get('uppyInstance')) {
        this.get('uppyInstance').getPlugin('Dashboard').closeModal();
        this.get('uppyInstance').reset();
      }

      return true;
    },
    'downloadEmdExcel': (0, _emberConcurrency.task)(function* () {
      const blob = yield this.makeRequest(this.get('model.id'));
      let buffer = yield blob.arrayBuffer();
      saveAs(new Blob([buffer]), `${this.get('model.name')}.xlsx`);
    }).keepLatest(),

    makeRequest(emdId) {
      return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/emd/retrieveEmdExcel/${emdId}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.responseType = 'blob';

        xhr.onload = function (e) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
        };

        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };

        xhr.send();
      });
    }

  });

  _exports.default = _default;
});