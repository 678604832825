define("plantworks/components/report/configure/report-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'report-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('report-create');
      const updatePerm = this.get('currentUser').hasPermission('report-update');
      const deletePerm = this.get('currentUser').hasPermission('report-delete');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
      yield;
    }).keepLatest(),
    'addReport': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newReport = this.get('store').createRecord('report/report', {
        'tenant': tenant,
        'folder': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.report_feature.new_report_name', {
        'now': window.moment().valueOf()
      });
      newReport.set('name', displayName);
      newReport.set('description', displayName);
      const siblingReports = yield this.get('model.reports');
      siblingReports.addObject(newReport);
      this.get('editReport').perform(newReport);
    }).enqueue(),
    'editReport': (0, _emberConcurrency.task)(function* (report) {
      yield report.set('isEditing', true);
    }).enqueue(),
    'cancelReport': (0, _emberConcurrency.task)(function* (report) {
      report.set('isEditing', false);
      yield report.rollback();
    }).enqueue(),
    'saveReport': (0, _emberConcurrency.task)(function* (report) {
      report.set('isEditing', false);
      yield report.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveReportSucceeded': Ember.on('saveReport:succeeded', function (taskInstance) {
      const report = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_save', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    'saveReportErrored': Ember.on('saveReport:errored', function (taskInstance, err) {
      const report = taskInstance.args[0];
      report.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteReport': (0, _emberConcurrency.task)(function* (report) {
      const reportName = yield report.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.report_feature.label_delete_report'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.report_feature.delete_report_message', {
          'htmlSafe': true,
          'displayName': reportName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteReport').perform(report);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteReport': (0, _emberConcurrency.task)(function* (report) {
      yield report.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteReportSucceeded': Ember.on('_confirmedDeleteReport:succeeded', function (taskInstance) {
      const report = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_delete', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    '_confirmedDeleteReportErrored': Ember.on('_confirmedDeleteReport:errored', function (taskInstance, err) {
      const report = taskInstance.args[0];
      report.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedReport': function (selectedReport) {
      if (selectedReport.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedReport.get('id'), false, false);
    }
  });

  _exports.default = _default;
});