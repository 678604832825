define("plantworks/models/board/attribute-set-function-observed-property", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'boardAttributeSetFunctionObservedProperties'
    }),
    'attributeSet': _emberData.default.belongsTo('board/attribute-set', {
      'async': true,
      'inverse': 'functionObservedProperties'
    }),
    'attributeSetProperty': _emberData.default.belongsTo('board/attribute-set-property', {
      'async': true,
      'inverse': 'observingFunctions'
    }),
    'attributeSetFunction': _emberData.default.belongsTo('board/attribute-set-function', {
      'async': true,
      'inverse': 'observedProperties'
    }),
    'linkedInternalTag': Ember.computed('attributeSetProperty.internalTag', function () {
      return this.get('_linkedInternalTag').perform();
    }),
    '_linkedInternalTag': (0, _emberConcurrency.task)(function* () {
      const prop = yield this.get('attributeSetProperty');
      return prop.get('internalTag');
    }).enqueue()
  });

  _exports.default = _default;
});