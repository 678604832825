define("plantworks/components/work-order/work-order-app/format-data-viewer", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'isUploadPerm': false,
    'isOperationPerm': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
      this.set('isUploadPerm', this.get('currentUser').hasPermission('work-order-upload'));
      this.set('isOperationPerm', this.get('currentUser').hasPermission('work-order-operation'));
    }

  });

  _exports.default = _default;
});