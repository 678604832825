define("plantworks/components/outgoing/realtime-standard-display", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch', 'bg-white'],
    'categorizedDataSetPropertyView': false,
    'selectedDataSetSourceType': 'static',

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});