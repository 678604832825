define("plantworks/models/settings/fragments/tenant-settings", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'disableWorkOrderOperationsCancelation': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'type': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'category': _emberData.default.attr('string', {
      'defaultValue': ''
    })
  });

  _exports.default = _default;
});