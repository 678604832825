define("plantworks/routes/settings/account/work-orders", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      if (!window.plantworksTenantId) {
        this.get('store').unloadAll('settings/account/basics/tenant');
        return;
      }

      const tenantData = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
      if (tenantData) return tenantData;
      return this.get('store').findRecord('settings/account/basics/tenant', window.plantworksTenantId);
    },

    onUserDataUpdated() {
      if (!window.plantworksTenantId) {
        this.get('store').unloadAll('settings/account/basics/tenant');
      }

      const isActive = this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksUserId) {
        this.transitionTo('index');
        return;
      }

      this.get('refreshTenantModel').perform();
    },

    'refreshTenantModel': (0, _emberConcurrency.task)(function* () {
      let tenantData = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
      if (!tenantData) tenantData = yield this.get('store').findRecord('settings/account/basics/tenant', window.plantworksTenantId);
      this.get('controller').set('model', tenantData);
    }).keepLatest()
  });

  _exports.default = _default;
});