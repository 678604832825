define("plantworks/components/session/log-out", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'router': Ember.inject.service('router'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
    },

    click() {
      this.get('_doLogout').perform();
    },

    '_doLogout': (0, _emberConcurrency.task)(function* () {
      const notification = this.get('notification');
      notification.display({
        'type': 'info',
        'message': this.intl.t('plant_works_webapp_server_server.session_component.logout_message')
      });

      try {
        const logoutResult = yield this.get('ajax').request('/session/logout', {
          'method': 'GET'
        });
        notification.display({
          'type': logoutResult.status < 400 ? 'success' : 'error',
          'message': logoutResult.info.message,
          'error': logoutResult.info.message
        });
        this.get('currentUser').one('userDataUpdated', () => {
          this.get('router').transitionTo('index');
        });
        if (window.AndroidInterface) window.AndroidInterface.setLogInStatus(false);
        window.PlantWorksApp.trigger('userChanged');
      } catch (err) {
        notification.display({
          'type': 'error',
          'error': err
        });
      }
    }).drop()
  });

  _exports.default = _default;
});