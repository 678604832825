define("plantworks/components/board/devenv/panel-subcomponent-editor", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'displayViews': null,
    'selectedDisplayView': null,
    'frontendHooks': null,
    'selectedFrontendHook': null,
    'customTemplateTop': null,
    'customTemplateBottom': null,
    'selectedNode': null,
    'currentTemplate': null,
    'currentTemplateId': null,

    init() {
      this._super(...arguments);

      this.set('displayViews', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_frontend_dev_component_code'
        }, {
          'name': 'general.label_frontend_dev_template_code'
        }, {
          'name': 'general.label_frontend_dev_template_preview'
        }])
      }));
      this.set('frontendHooks', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_frontend_dev_component_before_render'
        }, {
          'name': 'general.label_frontend_dev_component_after_render'
        }, {
          'name': 'general.label_frontend_dev_component_observers'
        }, {
          'name': 'general.label_frontend_dev_component_tasks'
        }, {
          'name': 'general.label_frontend_dev_component_before_destroy'
        }])
      }));
      this.set('customTemplateTop', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.set('customTemplateBottom', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.set('selectedDisplayView', this.get('displayViews').objectAt(0));
      this.set('selectedFrontendHook', this.get('frontendHooks').objectAt(0));
      this.get('_setupNodeAndTemplate').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'cancelPanelTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentTemplate')) return;
      yield this.get('currentTemplate').rollback();
    }).enqueue(),
    'savePanelTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentTemplate')) return;
      yield this.get('currentTemplate').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePanelTemplateSucceeded': Ember.on('savePanelTemplate:succeeded', function () {
      const panel = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.board_feature.succesful_panel_save', {
          'htmlSafe': true,
          'displayName': panel.get('name')
        })
      });
    }),
    'savePanelTemplateErrored': Ember.on('savePanelTemplate:errored', function (taskInstance, err) {
      const panelTemplate = this.get('currentTemplate');
      panelTemplate.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSavePanelTemplate': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentTemplate')) return;
      this.set('currentTemplate.publishStatus', publish);
      yield this.get('currentTemplate').save();
    }).keepLatest(),
    'editPanelTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentTemplate')) return;
      if (!this.get('model')) return;
      const currentTemplate = this.get('currentTemplate'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const templates = yield this.get('model.subcomponents');
      const newTemplate = this.get('store').createRecord(`board/panel-subcomponent`, {
        'tenant': tenant,
        'name': currentTemplate.get('name'),
        'componentId': this.get('selectedSubcomponent').get('componentId'),
        'tenantPanel': this.get('model')
      });
      newTemplate.set('publishStatus', false);
      newTemplate.set('componentState', currentTemplate.get('componentState'));
      newTemplate.set('componentBeforeRenderCode', currentTemplate.get('componentBeforeRenderCode'));
      newTemplate.set('componentAfterRenderCode', currentTemplate.get('componentAfterRenderCode'));
      newTemplate.set('componentOnDataCode', currentTemplate.get('componentOnDataCode'));
      newTemplate.set('componentBeforeDestroyCode', currentTemplate.get('componentBeforeDestroyCode'));
      newTemplate.set('template', currentTemplate.get('template'));
      currentTemplate.get('componentObservers').forEach(componentObserver => {
        newTemplate.get('componentObservers').createFragment({
          'name': componentObserver.get('name'),
          'observedProperty': componentObserver.get('observedProperty'),
          'functionCode': componentObserver.get('functionCode')
        });
      });
      currentTemplate.get('componentTasks').forEach(componentTask => {
        newTemplate.get('componentTasks').createFragment({
          'name': componentTask.get('name'),
          'arguments': componentTask.get('arguments'),
          'functionCode': componentTask.get('functionCode')
        });
      });
      templates.addObject(newTemplate);
      this.set('currentTemplate', newTemplate);
      this.set('currentTemplateId', newTemplate.get('id'));
      this.set('selectedSubcomponent', newTemplate);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,name,subcomponents.[]}', function () {
      this.get('_setupNodeAndTemplate').perform();
    }),
    'onActiveTabChanged': Ember.observer('selectedDisplayView', function () {
      if (!this.get('selectedDisplayView')) return;
      const currTabIdx = this.get('displayViews').indexOf(this.get('selectedDisplayView'));

      if (currTabIdx === 0) {
        this.set('selectedFrontendHook', this.get('frontendHooks').objectAt(0));
        (0, _emberLifeline.runTask)(this, this._fillFrontendCodeEditor, 150);
        return;
      }

      if (currTabIdx === 1) {
        (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
        return;
      }
    }),
    'onActiveFrontendHookTabChanged': Ember.observer('selectedFrontendHook', function () {
      if (!this.get('selectedFrontendHook')) return;
      (0, _emberLifeline.runTask)(this, this._fillFrontendCodeEditor, 150);
    }),
    actions: {
      'resetSelectedSubcomponent': function () {
        this.resetSelectedSubcomponent();
      }
    },
    '_setupNodeAndTemplate': (0, _emberConcurrency.task)(function* () {
      this.set('selectedNode', null);
      if (!this.get('model')) return;
      this.set('currentTemplateId', this.get('selectedSubcomponent').get('id'));
      this.set('currentTemplate', this.get('selectedSubcomponent'));
      yield;
    }).keepLatest(),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.panel-frontend-editor-subcomponent-container');
      const panelDivs = this.$('div.panel-frontend-editor-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.panel-frontend-editor-subcomponent-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    },
    '_fillFrontendCodeEditor': function () {
      const containerDiv = this.$('div.panel-frontend-editor-component-hooks-container');
      const panelDivs = this.$('div.panel-frontend-editor-component-hooks-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.panel-frontend-editor-component-hooks-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});