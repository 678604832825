define("plantworks/components/manufacturing/watch/station-display", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'lockedOpen': true,
    'selectedNode': null,
    'selectedEntity': null,
    'selectedEntityType': null,
    'setSelectedNode': function (selectedNode) {
      this.set('selectedNode', selectedNode);

      if (!selectedNode || !selectedNode.data) {
        this.set('selectedEntity', null);
        this.set('selectedEntityType', null);
        return;
      }

      this.set('selectedEntity', selectedNode.data.model);
      this.set('selectedEntityType', selectedNode.data.type);
    }
  });

  _exports.default = _default;
});