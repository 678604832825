define("plantworks/components/profile/delete-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
    },

    'deleteAccount': (0, _emberConcurrency.task)(function* () {
      yield this.invokeAction('controller-action', 'displayModal', {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.profile_feature.main_component.delete_modal.title'),
          'iconType': 'mdi',
          'icon': 'account-off'
        },
        'content': this.intl.t('plant_works_webapp_server_server.profile_feature.main_component.delete_modal.question'),
        'confirmButton': {
          'text': this.intl.t('modal.default_delete_text'),
          'iconType': 'md',
          'icon': 'check',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteAccount').perform();
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'primary': true,
          'raised': true,
          'callback': null
        }
      });
    }).drop(),
    '_confirmedDeleteAccount': (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('model').destroyRecord();
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.profile_feature.main_component.succesful_delete')
        });
        this.get('currentUser').one('userDataUpdated', () => {
          this.get('router').transitionTo('index');
        });
        window.PlantWorksApp.trigger('userChanged');
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});