define("plantworks/models/emd/configuration", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes", "ember-concurrency"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'rawDataTransformCode': _emberData.default.attr('string'),
    'processedDataTransformCode': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'emdConfigurations'
    }),
    'folder': _emberData.default.belongsTo('emd/folder', {
      'async': true,
      'inverse': 'configurations'
    }),
    'watchers': _emberData.default.hasMany('emd/emd-watcher', {
      'async': true,
      'inverse': 'tenantEmd'
    }),
    'operationMetadata': _emberData.default.attr('string', {
      'defaultValue': 'Merge'
    }),
    'attributeSetMetadata': (0, _attributes.fragmentArray)('emd/fragments/configuration-attribute-set-metadata'),
    'attributeSets': _emberData.default.hasMany('emd/configuration-attribute-set', {
      'async': true,
      'inverse': 'tenantEmdConfiguration'
    }),
    'path': Ember.computed('name', 'folder.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const folder = yield this.get('folder');
      const folderPath = yield folder.get('path');
      return `${folderPath} > ${this.get('name')}`;
    }).keepLatest()
  });

  _exports.default = _default;
});