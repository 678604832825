define("plantworks/components/common/single-attribute-set/function-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'bg-grey'],
    'viewMode': 'configure',
    'addAttributeSetFunctionObservedProperties': (0, _emberConcurrency.task)(function* () {
      const attributeSetFunction = this.get('model');
      const attributeSet = yield attributeSetFunction.get('attributeSet');
      const attributeSetProperties = yield attributeSet.get('properties');
      const attributeSetFunctionObservedProperties = yield attributeSetFunction.get('observedProperties');
      const alreadyUsedProperties = attributeSetFunctionObservedProperties.map(observedProperty => {
        return observedProperty.get('attributeSetProperty.id');
      });
      const availableProperties = attributeSetProperties.filter(attributeSetProperty => {
        return !alreadyUsedProperties.includes(attributeSetProperty.get('id'));
      });
      const chooseModalModel = availableProperties.map(availableProperty => {
        return {
          'selected': false,
          'property': availableProperty
        };
      });
      const self = this;
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_add_attribute_set_observerfunc_observed_property'),
          'iconType': 'mdi',
          'icon': 'sprout'
        },
        'dialogClass': 'flex',
        'componentName': 'Common/SingleAttributeSet/FunctionObservedPropertyEditor',
        'componentState': {
          'model': chooseModalModel,
          'sourceTypes': this.get('sourceTypes')
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('createObservedProperties').perform(chooseModalModel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deleteAttributeSetFunctionObservedProperty': (0, _emberConcurrency.task)(function* (observedProperty) {
      yield observedProperty.destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'createObservedProperties': (0, _emberConcurrency.task)(function* (chooseModalModel) {
      const attributeSetFunction = this.get('model');
      const attributeSet = yield attributeSetFunction.get('attributeSet');
      const tenant = yield attributeSetFunction.get('tenant');
      const siblingObservedProperties = yield attributeSetFunction.get('observedProperties');
      const chosenProperties = chooseModalModel.filter(availableProperty => {
        return availableProperty.selected && (!availableProperty.observedProperty || availableProperty.observedProperty.get('isNew'));
      });

      for (let idx = 0; idx < chosenProperties.length; idx++) {
        const availableProperty = chosenProperties[idx];

        if (!availableProperty.observedProperty) {
          availableProperty.observedProperty = this.get('store').createRecord(`${this.get('modelModule')}/attribute-set-function-observed-property`, {
            'tenant': tenant,
            'attributeSet': attributeSet,
            'attributeSetFunction': attributeSetFunction,
            'attributeSetProperty': availableProperty.property
          });
          siblingObservedProperties.addObject(availableProperty.observedProperty);
        }

        yield availableProperty.observedProperty.save();
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});