define("plantworks/components/board/watch/realtime-standard-display", ["exports", "plantworks/framework/base-component", "moment", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _moment, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'categorizedDataSetPropertyView': false,
    'selectedDataSetSourceType': 'static',

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'state', undefined, Ember.Object.create({}));
      this.set('state.isGraphVisible', false);
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      const attributeSetMetadata = this.get('model.attributeSetMetadata');
      const dataPointTimePeriods = {};
      let graphCategories = new Set();
      attributeSetMetadata.forEach(dataPointMetadata => {
        if (dataPointMetadata.get('dataDisplayPeriod') === 'realtime') return;
        const dataPoint = this.get('arrayModel').filter(dataSetProperty => {
          return dataSetProperty.get('id') === dataPointMetadata.get('parameterId');
        }).objectAt(0);
        if (!dataPoint) return;
        graphCategories.add(dataPointMetadata.get('dataDisplayPeriod'));
        dataPointTimePeriods[dataPoint.get('internalTag')] = dataPointMetadata.get('dataDisplayPeriod');
      });
      graphCategories = [...graphCategories].map(graphCategory => {
        return {
          'category': graphCategory,
          'vegaView': null
        };
      });
      this.set('state.graphCategories', graphCategories);

      if (Object.keys(dataPointTimePeriods).length) {
        const historicalData = yield this.get('ajax').post(`/board/historicalData/${this.get('model.id')}`, {
          'dataType': 'json',
          'data': dataPointTimePeriods
        });
        Object.keys(historicalData).forEach(timePeriodKey => {
          const thisHistoricData = historicalData[timePeriodKey];
          Object.keys(thisHistoricData).forEach(dataPoint => {
            const historicDataPoints = thisHistoricData[dataPoint];
            historicDataPoints.forEach(historicDataPoint => {
              historicDataPoint['timestamp'] = (0, _moment.default)(historicDataPoint['timestamp']).valueOf();
            });
          });
        });
        this.set('state.historicalData', historicalData);
        this.set('state.isGraphVisible', true);
        yield (0, _emberConcurrency.timeout)(500);
        yield this.get('onGraphVisibilityChanged').perform(true);
      }
    }).on('init').keepLatest(),
    'onDataChanged': Ember.observer('arrayModel.@each.currentValue', function () {
      (0, _emberLifeline.debounceTask)(this, 'executeCustomOnDataFunction', 1000);
    }),

    executeCustomOnDataFunction() {
      const currentTime = (0, _moment.default)();
      currentTime.set({
        'seconds': 0,
        'milliseconds': 0
      });
      Ember.RSVP.resolve().then(() => {
        const historicalData = this.get('state.historicalData');
        Object.keys(historicalData || {}).forEach(timePeriod => {
          let minTime = currentTime.clone();

          switch (timePeriod) {
            case 'last_one_hour':
              minTime.subtract(1, 'hour');
              break;

            case 'last_two_hours':
              minTime.subtract(2, 'hour');
              break;

            case 'last_four_hours':
              minTime.subtract(4, 'hour');
              break;

            case 'last_eight_hours':
              minTime.subtract(8, 'hour');
              break;

            case 'last_twelve_hours':
              minTime.subtract(12, 'hour');
              break;

            case 'last_twenty_four_hours':
              minTime.subtract(24, 'hour');
              break;

            case 'current_shift':
              // TODO: Once Rahul Khunt's API come online
              break;

            case 'current_day':
              minTime.set({
                'hour': 0,
                'minute': 0
              });
              break;

            default:
              minTime.subtract(1, 'hour');
              break;
          }

          minTime = minTime.valueOf();
          const categoryData = historicalData[timePeriod];
          Object.keys(categoryData || {}).forEach(tag => {
            if (!this.get(`objectModel.${tag}`)) return;
            let tagHistoricalData = categoryData[tag];
            tagHistoricalData.push({
              'timestamp': currentTime.valueOf(),
              'value': this.get(`objectModel.${tag}.currentValue`)
            });
            tagHistoricalData = tagHistoricalData.filter(tagData => {
              return tagData.timestamp >= minTime;
            });
            categoryData[tag] = tagHistoricalData;
          });
        });
        if (!this.get('state.isGraphVisible')) return;
        const graphCategories = this.get('state.graphCategories');
        if (!graphCategories) return;
        (graphCategories || []).forEach(graphCategory => {
          if (!graphCategory.vegaView) return;
          const historicalData = this.get('state.historicalData');
          const categoryValues = [];
          let minTime = currentTime.clone();

          switch (graphCategory.category) {
            case 'last_one_hour':
              minTime.subtract(1, 'hour');
              break;

            case 'last_two_hours':
              minTime.subtract(2, 'hour');
              break;

            case 'last_four_hours':
              minTime.subtract(4, 'hour');
              break;

            case 'last_eight_hours':
              minTime.subtract(8, 'hour');
              break;

            case 'last_twelve_hours':
              minTime.subtract(12, 'hour');
              break;

            case 'last_twenty_four_hours':
              minTime.subtract(24, 'hour');
              break;

            case 'current_shift':
              // TODO: Once Rahul Khunt's API come online
              break;

            case 'current_day':
              minTime.set({
                'hour': 0,
                'minute': 0
              });
              break;

            default:
              minTime.subtract(1, 'hour');
              break;
          }

          minTime = minTime.valueOf();
          Object.keys(historicalData || {}).forEach(timePeriod => {
            if (timePeriod !== graphCategory.category) return;
            const categoryData = historicalData[timePeriod];
            Object.keys(categoryData || {}).forEach(tag => {
              const tagHistoricalData = categoryData[tag];
              tagHistoricalData.forEach(dataPoint => {
                categoryValues.push({
                  'tag': tag,
                  'timestamp': dataPoint.timestamp,
                  'value': dataPoint.value
                });
              });
            });
          });
          const changeSet = graphCategory.vegaView.changeset().insert(categoryValues.filter(catDataVal => {
            return catDataVal.timestamp >= currentTime.valueOf();
          })).remove(catDataVal => {
            return catDataVal.timestamp < minTime;
          });
          graphCategory.vegaView.change('realtime-data', changeSet).run();
        });
      }).catch(err => {
        this.debug(`executeCustomOnDataFunction::error: `, err);
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      });
    },

    'onGraphVisibilityChanged': (0, _emberConcurrency.task)(function* (expanded) {
      this.set('state.isGraphVisible', expanded);
      const graphCategories = this.get('state.graphCategories');
      if (!graphCategories) return;

      if (!expanded) {
        graphCategories.forEach(graphCategory => {
          if (!graphCategory.vegaView) return;
          graphCategory.vegaView.finalize();
          graphCategory.vegaView = null;
        });
        return;
      }

      yield (0, _emberConcurrency.timeout)(100);
      graphCategories.forEach(graphCategory => {
        if (graphCategory.vegaView) return;
        const historicalData = this.get('state.historicalData');
        const categoryValues = [];
        Object.keys(historicalData).forEach(timePeriod => {
          if (timePeriod !== graphCategory.category) return;
          const categoryData = historicalData[timePeriod];
          Object.keys(categoryData).forEach(tag => {
            const tagHistoricalData = categoryData[tag];
            categoryValues.push({
              'tag': tag,
              'timestamp': (0, _moment.default)().valueOf(),
              'value': 0
            });
            tagHistoricalData.forEach(dataPoint => {
              categoryValues.push({
                'tag': tag,
                'timestamp': dataPoint.timestamp,
                'value': dataPoint.value
              });
            });
          });
        });
        window.vegaEmbed(`#${this.get('model.id')}-${Ember.String.dasherize(graphCategory.category)}`, {
          '$schema': 'https://vega.github.io/schema/vega-lite/v4.json',
          'description': `${this.get('model.name')} ${this.intl.t('plant_works_webapp_server_server.outgoing_feature.title')}`,
          'autosize': {
            'type': 'fit',
            'resize': true,
            'contains': 'padding'
          },
          'width': 'container',
          'data': {
            'name': 'realtime-data',
            'values': categoryValues
          },
          'mark': {
            'type': 'line',
            'point': true
          },
          'encoding': {
            'x': {
              'timeUnit': 'hoursminutes',
              'field': 'timestamp',
              'type': 'temporal',
              'axis': {
                'title': this.intl.t('general.label_current_time')
              }
            },
            'y': {
              'aggregate': 'mean',
              'field': 'value',
              'type': 'quantitative',
              'axis': {
                'title': this.intl.t('general.label_value')
              }
            },
            'color': {
              'field': 'tag',
              'type': 'nominal',
              'title': this.intl.t('plant_works_webapp_server_server.common.label_attribute_set_property_tag')
            },
            'tooltip': {
              'field': 'value',
              'type': 'quantitative'
            }
          }
        }, {
          'actions': false
        }).then(vegaView => {
          graphCategory.vegaView = vegaView.view;
        }).catch(err => {
          this.get('notification').display({
            'type': 'error',
            'error': err
          });
        });
      });
    }).keepLatest()
  });

  _exports.default = _default;
});