define("plantworks/templates/components/manufacturing/configure/plant-unit-station-editor/basics-sub-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dNs68XBk",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[5,\"plant-works-card\",[],[[\"@class\"],[\"m-0 flex\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"m-0 p-0 pt-4\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"layout-row layout-align-space-between-center layout-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"plant-works-input\",[],[[\"@class\",\"@type\",\"@label\",\"@value\",\"@onChange\"],[\"mx-2 flex-100 flex-gt-md-55\",\"text\",[28,\"t\",[\"general.label_name\"],null],[24,[\"model\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"name\"]]],null]],null]]]],[0,\"\\n\\n\\t\\t\\t\"],[5,\"plant-works-input\",[],[[\"@class\",\"@type\",\"@label\",\"@value\",\"@passThru\",\"@onChange\"],[\"mx-2 flex-100 flex-gt-md-40\",\"text\",[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_current\"],null],[28,\"await\",[[24,[\"model\",\"tenantPlantUnit\",\"path\"]]],null],[28,\"hash\",null,[[\"readonly\"],[\"true\"]]],null]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"layout-row layout-align-start-center\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"plant-works-input\",[],[[\"@class\",\"@type\",\"@label\",\"@value\",\"@onChange\"],[\"mx-2 flex\",\"text\",[28,\"t\",[\"general.label_description\"],null],[24,[\"model\",\"description\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"description\"]]],null]],null]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/configure/plant-unit-station-editor/basics-sub-editor.hbs"
    }
  });

  _exports.default = _default;
});