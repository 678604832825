define("plantworks/components/report/execute/request-wrapper", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HTMLBars
  } = window.Ember;

  var _default = _baseComponent.default.extend({
    'inputComponentLayout': null,
    'resultComponentLayout': null,
    'inputComponentModel': null,
    'resultComponentModel': null,
    'isExecuting': false,
    'displayResult': false,
    'isLoading': false,
    'emptyTemplate': false,
    'resultData': null,
    'filters': null,
    'aggregations': null,
    'disableSelectionComponent': false,
    'lockedOpen': true,
    'sidenavOpen': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'report-watch');
      this.get('_setupReportExecutorComponents').perform();
    },

    'onModelChanged': Ember.observer('model', function () {
      this.set('inputComponentLayout', null);
      this.set('resultComponentLayout', null);
      this.set('inputComponentModel', null);
      this.set('resultComponentModel', null);
      this.set('isExecuting', false);
      this.set('displayResult', false);
      this.set('isLoading', false);
      this.set('resultData', null);
      this.set('filters', null);
      this.set('aggregations', null);
      this.get('_setupReportExecutorComponents').perform();
    }),
    'submitReportData': function (inputData) {
      this.get('_executeReport').perform(inputData);
    },
    'changeFilters': function (inputData) {
      this.get('_changeFilters').perform(inputData);
    },
    'changeAggregations': function (inputData) {
      this.get('_changeAggregations').perform(inputData);
    },
    '_setupReportExecutorComponents': (0, _emberConcurrency.task)(function* () {
      const report = this.get('model');

      if (!report) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('isLoading', false);
        this.set('resultData', null);
        return;
      }

      let currentInputTemplate = null;
      let currentResultTemplate = null;
      const inputTemplates = yield this.get('model.inputTemplates');

      if (!inputTemplates.get('length')) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('isLoading', false);
        this.set('resultData', null);
        return;
      }

      if (this.get('mode') === 'development') {
        currentInputTemplate = inputTemplates.sortBy('createdAt').get('lastObject');
      } else {
        currentInputTemplate = inputTemplates.filterBy('publishStatus', true).sortBy('createdAt').get('lastObject');
      }

      if (!currentInputTemplate) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('isLoading', false);
        this.set('resultData', null);
        return;
      } else {
        this.set('inputComponentModel', currentInputTemplate);

        if (currentInputTemplate.get('template') === '') {
          this.set('emptyTemplate', true);
        } else {
          this.set('emptyTemplate', false);
        }

        this.set('inputComponentLayout', HTMLBars.compile((currentInputTemplate.get('template') || '').trim()));
      }

      const resultTemplates = yield this.get('model.resultTemplates');

      if (!resultTemplates.get('length')) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('isLoading', false);
        this.set('resultData', null);
        return;
      }

      if (this.get('mode') === 'development') {
        currentResultTemplate = resultTemplates.sortBy('createdAt').get('lastObject');
      } else {
        currentResultTemplate = resultTemplates.filterBy('publishStatus', true).sortBy('createdAt').get('lastObject');
      }

      if (!currentResultTemplate) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('isLoading', false);
        this.set('resultData', null);
        return;
      } else {
        this.set('resultComponentModel', currentResultTemplate);
        this.set('resultComponentLayout', HTMLBars.compile((currentResultTemplate.get('template') || '').trim()));
      }
    }).keepLatest(),
    '_executeReport': (0, _emberConcurrency.task)(function* (inputData) {
      if (this.get('isExecuting')) return;
      this.set('isExecuting', true);
      this.set('displayResult', false);
      this.set('isLoading', true);
      this.set('resultData', null);
      inputData.reportId = this.get('model.id');
      inputData.reportMode = this.get('mode');
      const resultData = yield this.get('ajax').post('/report/execute', {
        'dataType': 'json',
        'data': inputData
      });
      this.set('resultData', resultData);
      let startDate = new Date(resultData.formattedData.startTime);
      let endDate = new Date(resultData.formattedData.endTime);
      this.set('sDate', startDate.getFullYear() + '-' + (startDate.getMonth() < 10 ? '0' : '') + (startDate.getMonth() + 1) + '-' + startDate.getDate() + ' ' + (startDate.getHours() < 10 ? '0' : '') + startDate.getHours() + ':' + (startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes());
      this.set('eDate', endDate.getFullYear() + '-' + (endDate.getMonth() < 10 ? '0' : '') + (endDate.getMonth() + 1) + '-' + endDate.getDate() + ' ' + (endDate.getHours() < 10 ? '0' : '') + endDate.getHours() + ':' + (endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes());
    }).keepLatest().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_changeFilters': (0, _emberConcurrency.task)(function* (data) {
      yield;
      this.set('filters', data);
    }).enqueue(),
    '_changeAggregations': (0, _emberConcurrency.task)(function* (data) {
      yield;
      this.set('aggregations', data);
    }).enqueue(),
    '_executeReportSucceeded': Ember.on('_executeReport:succeeded', function () {
      this.set('isExecuting', false);
      this.set('displayResult', true);
      this.set('isLoading', false);
    }),
    '_executeReportErrored': Ember.on('_executeReport:errored', function (taskInstance, err) {
      this.set('isExecuting', false);
      this.set('displayResult', false);
      this.set('isLoading', false);
      this.set('resultData', null);
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});