define("plantworks/components/manufacturing/watch/snacks-approve-shift-form", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'moment': Ember.inject.service('moment'),
    'userAssigned': true,
    'selectedLine': null,
    'selectedUnit': null,
    'observedLineMachines': null,
    'selectedLineMachineNode': null,
    'selectedObservedMachine': null,
    'currentMachineTab': 'downtime',
    'actualMachineDowntimes': null,
    'noInternetConnectivity': null,
    'noMachineConnectivity': null,
    'machineDowntimes': null,
    'machineSpeedDeviations': null,
    'downtimesLoaded': null,
    'approveFormLoaded': null,
    'editMachineOperator': null,
    'realtimeData': Ember.inject.service('realtime-data'),
    'operatorFormType': null,
    'disableIdletimes': true,
    'workOrderOperationTask': (0, _emberConcurrency.task)(function* () {
      yield this.get('_updateCurrentEndTimeForDowntimes').perform();
    }),

    init() {
      this._super(...arguments);

      this.set('approvedShiftData', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      (0, _emberLifeline.pollTask)(this, '_updateCurrentEndTime', 'CURRENT_TIME_POLL_1');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const settins = yield tenant.get('settings');
      if (settins && settins.get('type') === 'haldiram') this.set('disableIdletimes', false);
      this.set('operatorFormType', 'SnacksApproveShiftForm');
      this.set('approveFormLoaded', false);
      const unitId = yield this.get('model.-change-tracker.tenantPlantUnit'); // get all the shifts from units

      const selectedUnit = yield this.get('store').findRecord('manufacturing/plant-unit', unitId);
      this.set('selectedUnit', selectedUnit);
      let shifts = yield selectedUnit.get('schedules');

      try {
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
        yield null;
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    willDestroyElement() {
      (0, _emberLifeline.cancelPoll)(this, 'CURRENT_TIME_POLL_1');

      this._super(...arguments);
    },

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');
      const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

      if (currentlySelectedMachineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedMachineNode['observedEntityId'],
          'type': 'machine.event'
        });
      }

      this._super(...arguments);
    },

    _updateCurrentEndTime(next) {
      this.get('_updateCurrentEndTimeForDowntimes').perform(next);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_toggleLineSelectors').perform();
    }).on('didInsertElement').keepLatest(),
    'onSelectedLineChanged': Ember.observer('selectedLine', function () {
      this.get('_displayLineMachines').perform();
    }),
    'onSelectedLineMachineChanged': Ember.observer('selectedLineMachine', function () {
      this.set('downtimesLoaded', false);
      this.set('currentMachineTab', 'downtime');
      this.get('_setSelectedLineMachineNode').perform();
      this.get('_setSelectedObservedMachine').perform(); // if(!this.get('selectedLineMachine'))
      // 	return;

      this.set('approvedShiftData', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.get('_updateCurrentEndTimeForDowntimes').perform();
      this.set('approveFormLoaded', true);
    }),
    'toggleDurationReasons': (0, _emberConcurrency.task)(function* (downtime) {
      yield downtime.set('isEditing', !downtime.get('isEditing'));
    }).enqueue(),
    '_toggleLineSelectors': (0, _emberConcurrency.task)(function* () {
      let selectedLine = yield this.get('selectedNode');
      this.set('selectedLine', selectedLine);
      this.set('editMachineOperator', false);
      return;
    }).keepLatest(),
    '_displayLineMachines': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedLine')) {
        this.set('observedLineMachines', null);
        this.set('selectedLineMachine', null);
        return;
      }

      const plantUnitLine = yield this.get('selectedLine.tenantPlantUnitLine');
      const selectedLineConstituents = yield plantUnitLine.get('inputConstituents');
      const allLineMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      let allMachines = [];

      if (selectedLineConstituents['machine']) {
        selectedLineConstituents['machine'].forEach(constituentMachine => {
          if (constituentMachine.get('actualEntity.description') == 'Weigher') {
            allMachines.push(constituentMachine.get('actualEntity'));
          }
        });
      }

      allMachines.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      allMachines.forEach(machine => {
        allLineMachines.addObject(machine);
      });

      if (selectedLineConstituents['block']) {
        for (let blockIdx = 0; blockIdx < selectedLineConstituents['block'].length; blockIdx++) {
          const constituentBlock = selectedLineConstituents['block'].objectAt(blockIdx);
          const blockEntities = constituentBlock.get('blockEntities');

          for (let blockEntityIdx = 0; blockEntityIdx < blockEntities.length; blockEntityIdx++) {
            const blockEntity = blockEntities.objectAt(blockEntityIdx);
            if (blockEntity.get('entityType') !== 'machine') continue;
            const machine = yield this.get('store').find('manufacturing/plant-unit-machine', blockEntity.get('entityId'));
            allLineMachines.addObject(machine);
          }
        }
      }

      const stationLineMachines = [];
      const observedMachines = yield this.get('model.observedMachines');

      for (let observedMachineIdx = 0; observedMachineIdx < observedMachines.length; observedMachineIdx++) {
        const observedMachine = observedMachines.objectAt(observedMachineIdx);
        const machine = yield observedMachine.get('tenantPlantUnitMachine');
        stationLineMachines.addObject(machine.get('id'));
      }

      const monitoredLineMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      allLineMachines.forEach(lineMachine => {
        if (!stationLineMachines.includes(lineMachine.get('id'))) return;
        monitoredLineMachines.addObject(lineMachine);
      });
      if (monitoredLineMachines.length < 1) return;
      this.set('observedLineMachines', monitoredLineMachines);
      this.set('selectedLineMachine', monitoredLineMachines.objectAt(0));
    }).keepLatest(),
    '_updateCurrentEndTimeForDowntimes': (0, _emberConcurrency.task)(function* (next) {
      this.set('unaccIdle', true);
      this.set('unaccForThisWorkOrder', true);
      this.set('unaccSpeedDeviations', true);

      if (!this.get('selectedLineMachine') && next) {
        (0, _emberLifeline.runTask)(this, next, 2000);
        return;
      }

      const lineMachine = this.get('selectedLineMachine');
      yield lineMachine.reload();
      yield lineMachine.get('approvedDowntimes').reload();
      yield lineMachine.get('setuptimes').reload();
      yield lineMachine.get('idletimes').reload();
      let connDetails = yield this.get('ajax').request(`/manufacturing/plant-unit-machine-connection-details/${this.get('selectedLineMachine.id')}`, {
        'method': 'GET'
      });

      if (connDetails) {
        let date;

        if (connDetails.end_time) {
          date = moment(connDetails.end_time).format('DD-MMM-YYYY hh:mm A');
        } else {
          date = moment(connDetails.start_time).format('DD-MMM-YYYY hh:mm A');
        }

        this.set('date', date);
        this.set('connectionDetails', connDetails);
      }

      let totalUnaccTime = 0;
      let totalUnAccIdleTime = 0;
      let totalUnAccSpeedDeviations = 0;
      let totalUnAccDowntimes = 0; // entries for downtimes tab only - not considering speed deviations
      // Loop through all the machines to calculate unaccounted times

      const machines = this.get('observedLineMachines');

      for (let i = 0; i < machines.length; i++) {
        const machine = machines.objectAt(i);
        const machineId = yield machine.get('id');
        const machineDowntimes = yield yield machine.get('approvedDowntimes');
        const downtimes = machineDowntimes.filter(downtime => {
          return downtime.get('type') === 'downtime';
        });
        const idletimes = machineDowntimes.filter(downtime => {
          return downtime.get('type') === 'IDLE_TIME_FLAG';
        }); // get the start time from last downtime in past

        if (downtimes && downtimes.length) {
          if (machineId == this.get('selectedLineMachine.id')) {
            let startInterval;
            const getFirstNonMetaDowntime = yield this.get('ajax').request(`/manufacturing/getFirstNonMetaDowntime/${this.get('selectedLineMachine.id')}`, {
              'method': 'GET'
            });

            if (getFirstNonMetaDowntime['firstNonApprovedDowntime']) {
              if (getFirstNonMetaDowntime['firstNonApprovedDowntime'].start_time) {
                startInterval = moment(getFirstNonMetaDowntime['firstNonApprovedDowntime'].start_time).tz('Asia/Kolkata');
              }
            }

            startInterval = moment(startInterval).tz('Asia/Kolkata').subtract(1, 'day');
            let endInterval = moment().tz('Asia/Kolkata');
            yield this.get('_truncateShiftTimes').perform(startInterval, endInterval, downtimes);
          }
        }

        if (idletimes && idletimes.length) {
          if (machineId == this.get('selectedLineMachine.id')) {
            yield this.get('_truncateShiftIdleTimes').perform(idletimes);
          }
        }
      }

      this.set('downtimesLoaded', true);
      if (next) (0, _emberLifeline.runTask)(this, next, 30000);
    }).keepLatest(),
    '_setSelectedLineMachineNode': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');

      for (let idx = 0; idx < observedMachines.length; idx++) {
        const observedMachine = observedMachines.objectAt(idx);

        if (observedMachine.get('tenantPlantUnitMachine.id') === this.get('selectedLineMachine.id')) {
          const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

          if (currentlySelectedMachineNode) {
            this.get('realtimeData.streamer').write({
              'action': 'unsubscribe',
              'id': currentlySelectedMachineNode['observedEntityId'],
              'type': 'machine.event'
            });
          }

          this.set('selectedLineMachineNode', {
            'id': observedMachine.get('id'),
            'observedEntityId': this.get('selectedLineMachine.id'),
            'data': {
              'type': 'machine'
            }
          });
          this.get('realtimeData.streamer').write({
            'action': 'subscribe',
            'id': this.get('selectedLineMachine.id'),
            'type': 'machine.event'
          });
          break;
        }
      }
    }).keepLatest(),
    '_setSelectedObservedMachine': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');
      const selectedLineMachine = this.get('selectedLineMachine');
      let selectedObservedMachine = null;
      if (!selectedLineMachine || !observedMachines || !observedMachines.get('length')) return;

      for (let idx = 0; idx < observedMachines.get('length'); idx++) {
        const observedMachine = observedMachines.objectAt(idx);
        const observedPlantUnitMachine = yield observedMachine.get('tenantPlantUnitMachine');

        if (observedPlantUnitMachine.get('id') === selectedLineMachine.get('id')) {
          selectedObservedMachine = observedMachine;
          break;
        }
      }

      this.set('selectedObservedMachine', selectedObservedMachine);
    }).keepLatest(),

    _resubscribeRealtimeData() {
      const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

      if (currentlySelectedMachineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'subscribe',
          'id': currentlySelectedMachineNode['observedEntityId'],
          'type': 'machine.event'
        });
      }
    },

    _processRealtimeData(data) {
      try {
        this.debug(`manufacturing::work-order-operations::_processRealtimeData::data: `, data);
        if (!data || !data.tag) return;

        if (data.tag === 'operator_change') {
          this.get('_refreshOperator').perform();
        }
      } catch (err) {
        this.debug(`_processRealtimeArrayData::Error: `, err);
      }
    },

    'updateShifts': (0, _emberConcurrency.task)(function* (shifts) {
      yield shifts.set('isEditing', !shifts.get('isEditing'));
    }).keepLatest(),
    '_truncateShiftIdleTimes': (0, _emberConcurrency.task)(function* (machineIdletimes) {
      // get the current unit
      let selectedUnit = this.get('selectedUnit');
      let shifts = yield selectedUnit.get('schedules');
      let actualShiftIdleData = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      machineIdletimes.forEach(idletime => {
        let actualShiftArray = shifts.filter(schedule => {
          let daycross = moment(schedule.startTime, 'HH:mm') >= moment(schedule.endTime, 'HH:mm');
          let condition;
          if (!daycross) condition = moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') >= moment(schedule.startTime, 'HH:mm') && moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') < moment(schedule.endTime, 'HH:mm');else condition = moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') >= moment(schedule.startTime, 'HH:mm') && moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') > moment(schedule.endTime, 'HH:mm') || moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') < moment(schedule.startTime, 'HH:mm') && moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') < moment(schedule.endTime, 'HH:mm');
          return schedule.type == 'shift' && moment(schedule.startDate).isSameOrBefore(moment(idletime.get('startTime'))) && moment(schedule.endDate).isSameOrAfter(moment(idletime.get('startTime'))) && condition;
        });
        const shiftObj1 = Ember.Object.extend({});

        if (actualShiftArray.length) {
          let thisShift = actualShiftArray[0];
          let daycross = moment(thisShift.startTime, 'HH:mm') >= moment(thisShift.endTime, 'HH:mm');
          let st = thisShift.startTime.split(':');
          let et = thisShift.endTime.split(':');
          let condition = false;
          if (daycross) condition = moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') >= moment('00:00', 'HH:mm') && moment(moment(idletime.get('startTime')).format('HH:mm'), 'HH:mm') <= moment(thisShift.endTime, 'HH:mm');
          let intervalStart, intervalEnd;
          intervalStart = condition ? moment(idletime.get('startTime')).clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0).subtract(1, 'day') : moment(idletime.get('startTime')).clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0);

          if (moment(thisShift.startTime, 'HH:mm') >= moment(thisShift.endTime, 'HH:mm')) {
            intervalEnd = intervalStart.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0).add(1, 'day');
          } else {
            intervalEnd = intervalStart.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0);
          }

          if (actualShiftIdleData.findBy('timestampValue', moment(intervalStart).valueOf())) {
            let obj = actualShiftIdleData.findBy('timestampValue', moment(intervalStart).valueOf());
            obj.get('actualMachineIdletimes').addObject(idletime);
          } else {
            //create an ember object
            let shiftObj = shiftObj1.create({
              intervalStart: moment(intervalStart).tz('Asia/Kolkata'),
              intervalEnd: moment(intervalEnd).tz('Asia/Kolkata'),
              date: moment(intervalStart).tz('Asia/Kolkata').format('DD/MM/YYYY'),
              shiftName: thisShift.description,
              displayStatus: false,
              actualMachineIdletimes: Ember.ArrayProxy.create({
                'content': Ember.A([])
              }),
              timestampValue: moment(intervalStart).valueOf(),
              isEditing: false
            });

            if (this.get('approvedShiftIdleData') && this.get('approvedShiftIdleData').findBy('timestampValue', moment(intervalStart).valueOf())) {
              shiftObj['isEditing'] = this.get('approvedShiftIdleData').findBy('timestampValue', moment(intervalStart).valueOf()).get('isEditing');
            }

            if (moment().tz('Asia/Kolkata').isBetween(moment(intervalStart).tz('Asia/Kolkata'), moment(intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
              shiftObj['displayStatus'] = true;
            }

            shiftObj.get('actualMachineIdletimes').addObject(idletime);
            actualShiftIdleData.addObject(shiftObj);
          }
        }
      });
      actualShiftIdleData = actualShiftIdleData.sortBy('timestampValue').reverse();
      this.set('approvedShiftIdleData', actualShiftIdleData);
    }),
    '_truncateShiftTimes': (0, _emberConcurrency.task)(function* (startInterval, endInterval, machineDowntimes) {
      // get the current unit
      let selectedUnit = this.get('selectedUnit');
      let shifts = yield selectedUnit.get('schedules');
      let actualApprovedShiftData;
      actualApprovedShiftData = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      shifts = shifts.filter(schedule => {
        if (schedule.type == 'shift') {
          if (moment(schedule.startDate) >= moment(startInterval) || moment(schedule.endDate) <= moment(endInterval) || moment(schedule.startDate) <= moment(startInterval) && moment(schedule.endDate) >= moment(startInterval)) {
            return true;
          }
        } else {
          return false;
        }
      });
      endInterval = moment(endInterval).add(1, 'day');
      let shiftIntervalArr = [];

      while (moment(endInterval).isSameOrAfter(moment(startInterval))) {
        for (let i = 0; i < shifts.length; i++) {
          let thisShift = shifts[i];
          const shiftObj1 = Ember.Object.extend({});
          let st = thisShift.startTime.split(':');
          let et = thisShift.endTime.split(':');
          let intervalStart, intervalEnd;

          if (moment(endInterval).clone().startOf('day') >= moment(thisShift.startDate).tz('Asia/Kolkata') && moment(endInterval).clone().startOf('day') <= moment(thisShift.endDate).tz('Asia/Kolkata')) {
            intervalStart = endInterval.clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0);

            if (st[0] > et[0]) {
              intervalEnd = endInterval.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0).add(1, 'day');
            } else {
              intervalEnd = endInterval.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0);
            }
          }

          let shiftObj;

          if (this.get('approvedShiftData').findBy('timestampValue', moment(intervalStart).valueOf())) {
            shiftObj = this.get('approvedShiftData').findBy('timestampValue', moment(intervalStart).valueOf());
          } else {
            //create an ember object
            shiftObj = shiftObj1.create({
              intervalStart: moment(intervalStart).tz('Asia/Kolkata'),
              intervalEnd: moment(intervalEnd).tz('Asia/Kolkata'),
              date: moment(intervalStart).tz('Asia/Kolkata').format('DD/MM/YYYY'),
              shiftName: thisShift.description,
              displayStatus: false,
              timestampValue: moment(intervalStart).valueOf(),
              isEditing: false
            });
          }

          if (moment().tz('Asia/Kolkata').isBetween(moment(intervalStart).tz('Asia/Kolkata'), moment(intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
            shiftObj['displayStatus'] = true;
          }

          shiftIntervalArr.push(shiftObj);
        }

        endInterval = endInterval.clone().tz('Asia/Kolkata').subtract(1, 'day');
      }

      shiftIntervalArr = shiftIntervalArr.sort(function (a, b) {
        return moment(a['timestampValue']).tz('Asia/Kolkata').diff(moment(b['timestampValue']).tz('Asia/Kolkata'));
      });
      shiftIntervalArr = shiftIntervalArr.reverse();

      for (let i = 0; i < shiftIntervalArr.length; i++) {
        let shiftObj = shiftIntervalArr[i];
        let actualMachineDowntimes = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        let thisFilterDowntime = machineDowntimes.filter(function (downtime) {
          return moment(downtime.startTime).tz('Asia/Kolkata') >= moment(shiftObj['intervalStart']).tz('Asia/Kolkata') && moment(downtime.endTime).tz('Asia/Kolkata') <= moment(shiftObj['intervalEnd']).tz('Asia/Kolkata');
        });

        if (thisFilterDowntime.length) {
          for (let f = 0; f < thisFilterDowntime.length; f++) {
            actualMachineDowntimes.addObject(thisFilterDowntime[f]);
          }
        }

        shiftObj.set('actualMachineDowntimes', actualMachineDowntimes);

        if (actualMachineDowntimes.length) {
          actualApprovedShiftData.addObject(shiftObj);
        }
      }

      this.set('approvedShiftData', actualApprovedShiftData);
    }),
    'confirmSubmit': (0, _emberConcurrency.task)(function* (data, type) {
      let modalData = null;
      modalData = {
        'title': {
          'text': 'Submit Shift',
          'iconType': 'mdi',
          'icon': 'file-upload'
        },
        'content': this.intl.t('plant_works_webapp_server_server.work_order_feature.perform_submit_message', {
          'htmlSafe': true,
          'displayName': data.shiftName,
          'displayDate': data.date
        }),
        'confirmButton': {
          'text': 'Submit',
          'iconType': 'md',
          'icon': 'save',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('submitShiftData').perform(data, type);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }),
    'submitShiftData': (0, _emberConcurrency.task)(function* (shiftData, type) {
      let tag, key, limit;

      if (type === 'downtime') {
        tag = 'actualMachineDowntimes';
        key = 'approvedShiftData';
        limit = 10;
      } else if (type === 'idletime') {
        tag = 'actualMachineIdletimes';
        key = 'approvedShiftIdleData';
        limit = 0;
      }

      const downtimes = shiftData.get(tag);
      let updateDowntimeData = [];

      for (let i = 0; i < downtimes.length; i++) {
        const downtime = downtimes.objectAt(i);
        const unaccountedDuration = yield downtime.get('unaccountedDuration');
        const actualTime = moment(downtime.get('endTime') || undefined).startOf('minute').diff(moment(downtime.get('startTime')).startOf('minute'), 'minutes');

        if (unaccountedDuration > 0 && actualTime > limit) {
          this.get('notification').display({
            'type': 'error',
            'error': `${unaccountedDuration} Minutes of unaccounted time detected for the ${type} entry which has more than ${limit} minutes duration`
          });
          return;
        }

        updateDowntimeData.push({
          'id': downtime.get('id'),
          'tenant_id': downtime.get('tenant.id'),
          'start_time': downtime.get('startTime'),
          'end_time': downtime.get('endTime')
        });
      }

      try {
        const selectedLineName = yield this.get('selectedLine.tenantPlantUnitLine.name');
        const selectedUnitName = yield this.get('selectedLine.tenantPlantUnitLine.tenantPlantUnit.name');
        yield this.get('ajax').post(`/manufacturing/submitShiftData`, {
          'data': {
            'approvedDowntimeUpdate': true,
            'downtimeList': JSON.stringify(updateDowntimeData),
            'tenantPlantUnitMachineId': this.get('selectedLineMachine.id'),
            'tenantPlantUnitLine': selectedLineName,
            'tenantPlantUnit': selectedUnitName
          }
        });
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.submit_success_messgae', {
            'htmlSafe': true,
            'displayName': shiftData.shiftName,
            'displayDate': shiftData.date
          })
        });
        this.get(key).removeObject(shiftData);
        this.get('_updateCurrentEndTimeForDowntimes').perform();
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    })
  });

  _exports.default = _default;
});