define("plantworks/models/settings/account/features/tenant-feature", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'tenantFeatures'
    }),
    'feature': _emberData.default.belongsTo('server-administration/feature', {
      'async': true,
      'inverse': 'tenantFeature'
    })
  });

  _exports.default = _default;
});