define("plantworks/components/event-alert/configure/watcher-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'addUser': (0, _emberConcurrency.task)(function* () {
      try {
        const self = this;
        const tenantUsersToBeAdded = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.event_alert_feature.label_add_users'),
            'iconType': 'md',
            'icon': 'verified-user'
          },
          'componentName': 'EventAlert/Configure/EventAlertAddWatchers',
          'componentState': {
            'eventAlert': this.get('model'),
            'model': tenantUsersToBeAdded
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_add_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('_doAddWatchers').perform(tenantUsersToBeAdded);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': null
          }
        };
        yield this.send('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    '_doAddWatchers': (0, _emberConcurrency.task)(function* (tenantUserList) {
      const tenantEventAlert = this.get('model');
      let defaultEscalationLevel = yield tenantEventAlert.get('escalationLevels');
      defaultEscalationLevel = defaultEscalationLevel.objectAt(0);

      for (let idx = 0; idx < tenantUserList.get('length'); idx++) {
        const tenantUser = tenantUserList.objectAt(idx);
        let watcher = this.get('store').peekAll('event-alert/event-alert-watcher').filterBy('tenantUser.id', tenantUser.get('id'));
        watcher = watcher.filterBy('tenantEventAlert.id', this.get('model.id')).objectAt(0);

        if (!watcher) {
          let storedTenantUser = this.get('store').peekRecord('pug/user-manager/tenant-user', tenantUser.get('id'));
          if (!storedTenantUser) storedTenantUser = yield this.get('store').findRecord('pug/user-manager/tenant-user', tenantUser.get('id'));
          watcher = this.get('store').createRecord('event-alert/event-alert-watcher', {
            'tenant': this.get('model.tenant'),
            'tenantUser': storedTenantUser,
            'tenantEventAlert': this.get('model')
          });
        }

        yield defaultEscalationLevel.get('watchers').createFragment({
          'watcherId': watcher.get('id')
        });

        if (watcher.get('isNew')) {
          yield watcher.save();
        }
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_doAddWatchersSucceeded': Ember.on('_doAddWatchers:succeeded', function (taskInstance) {
      const tenantUserList = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.add_watchers_message', {
          'numAdded': tenantUserList.get('length'),
          'name': this.get('model.name')
        })
      });
    }),
    '_doAddWatchersErrored': Ember.on('_doAddWatchers:errored', function (taskInstance, err) {
      const tenantUserList = taskInstance.args[0];

      for (let idx = 0; idx < tenantUserList.get('length'); idx++) {
        const tenantUser = tenantUserList.objectAt(idx);
        let watcher = this.get('store').peekAll('event-alert/event-alert-watcher').filterBy('tenantUser.id', tenantUser.get('id'));
        if (watcher && !watcher.get('isNew')) continue;
        watcher.deleteRecord();
      }

      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'removeUser': (0, _emberConcurrency.task)(function* (watcher) {
      const user = yield watcher.get('tenantUser.user');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.event_alert_feature.label_delete_user'),
          'iconType': 'md',
          'icon': 'verified-user'
        },
        'content': this.intl.t('plant_works_webapp_server_server.event_alert_feature.delete_watcher_message', {
          'htmlSafe': true,
          'userFullName': user.get('fullName'),
          'eventAlertName': this.get('model.name')
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedRemoveUser').perform(watcher, user);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedRemoveUser': (0, _emberConcurrency.task)(function* (watcher, user) {
      // eslint-disable-line no-unused-vars
      const tenantEventAlert = this.get('model');
      const escalationLevels = tenantEventAlert.get('escalationLevels');

      for (let idx = 0; idx < escalationLevels.get('length'); idx++) {
        const escalationLevel = escalationLevels.objectAt(idx);
        const escalationLevelWatchers = escalationLevel.get('watchers');

        for (let idx = 0; idx < escalationLevelWatchers.get('length'); idx++) {
          const escalationLevelWatcher = escalationLevelWatchers.objectAt(idx);
          if (escalationLevelWatcher.get('watcherId') === watcher.get('id')) yield escalationLevelWatchers.removeFragment(escalationLevelWatcher);
        }
      }

      yield watcher.destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedRemoveUserSucceeded': Ember.on('_confirmedRemoveUser:succeeded', function (taskInstance) {
      const user = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_watcher_delete', {
          'htmlSafe': true,
          'displayName': user.get('fullName')
        })
      });
      const userId = user.get('id');
      if (userId !== window.plantworksUserId) return;
    }),
    '_confirmedRemoveUserErrored': Ember.on('_confirmedRemoveUser:errored', function (taskInstance, err) {
      const watcher = taskInstance.args[0];
      watcher.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelUser': (0, _emberConcurrency.task)(function* (watcher) {
      yield watcher.rollback();
    }),
    'saveUser': (0, _emberConcurrency.task)(function* (watcher) {
      yield watcher.save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_saveUserSucceeded': Ember.on('saveUser:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_watcher_update')
      });
    }),
    '_saveUserErrored': Ember.on('saveUser:errored', function (taskInstance, err) {
      const watcher = taskInstance.args[0];
      watcher.rollback();
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('', {
          'type': 'error',
          'error': err
        })
      });
    })
  });

  _exports.default = _default;
});