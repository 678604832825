define("plantworks/components/pug/user-manager/main-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-on-calls-in-components */
  var _default = _baseComponent.default.extend({
    'selectedAccordionItem': 0,
    'editable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'user-manager-read');
    },

    'onModelData': Ember.observer('model', function () {
      let loggedInUserEmail = this.currentUser.userData.email;
      let loggedInUserDomain = loggedInUserEmail.replace(/.*@/, "").split('.')[0];
      this.get('model').forEach(tenantUser => {
        let domain = tenantUser.user.get('email').replace(/.*@/, "").split('.')[0];

        if (loggedInUserDomain !== domain) {
          tenantUser.set('resetPasswordAllowed', false);
        } else {
          tenantUser.set('resetPasswordAllowed', true);
        }
      });
    }),
    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('user-manager-update');
      this.set('editable', updatePerm);
    }),
    'setFeatureAccess': (0, _emberConcurrency.task)(function* (tenantUser) {
      try {
        const self = this;
        const panels = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const reports = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const hdashboards = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const stations = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const lines = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const machines = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const emds = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const wofs = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const componentState = {
          'tenantUser': tenantUser,
          'features': {
            panels: panels,
            reports: reports,
            hdashboards: hdashboards,
            stations: stations,
            lines: lines,
            machines: machines,
            emds: emds,
            wofs: wofs
          }
        };
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.label_access'),
            'iconType': 'mdi',
            'icon': 'all-inclusive'
          },
          'componentName': 'Pug/UserManager/FeatureAccess',
          'componentState': componentState,
          'confirmButton': {
            'text': this.intl.t('modal.default_save_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('_doUpdateFeaturesAccess').perform(componentState.features, tenantUser);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': null
          }
        };
        yield this.invokeAction('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    '_doUpdateFeaturesAccess': (0, _emberConcurrency.task)(function* (features, tenantUser) {
      const userId = tenantUser.get('id');
      const tenantPanels = [];
      const tenantReports = [];
      const tenantHdashboards = [];
      const tenantStations = [];
      const tenantLines = [];
      const tenantMachines = [];
      const tenantemds = [];
      const tenantwofs = [];

      for (let idx = 0; idx < features.panels.get('length'); idx++) {
        const panel = features.panels.objectAt(idx);
        tenantPanels.push({
          id: panel.get('id'),
          name: panel.get('name'),
          path: panel.get('path'),
          tenant_folder_id: panel.get('tenant_folder_id'),
          newAccess: panel.get('newAccess'),
          currentAccess: panel.get('currentAccess')
        });
      }

      const tenantPanelsStr = JSON.stringify(tenantPanels);

      for (let idx = 0; idx < features.reports.get('length'); idx++) {
        const report = features.reports.objectAt(idx);
        tenantReports.push({
          id: report.get('id'),
          name: report.get('name'),
          path: report.get('path'),
          tenant_folder_id: report.get('tenant_folder_id'),
          newAccess: report.get('newAccess'),
          currentAccess: report.get('currentAccess')
        });
      }

      const tenantReportsStr = JSON.stringify(tenantReports);

      for (let idx = 0; idx < features.hdashboards.get('length'); idx++) {
        const dashboard = features.hdashboards.objectAt(idx);
        tenantHdashboards.push({
          id: dashboard.get('id'),
          name: dashboard.get('name'),
          path: dashboard.get('path'),
          tenant_folder_id: dashboard.get('tenant_folder_id'),
          newAccess: dashboard.get('newAccess'),
          currentAccess: dashboard.get('currentAccess')
        });
      }

      const tenantHdashboardsStr = JSON.stringify(tenantHdashboards);

      for (let idx = 0; idx < features.stations.get('length'); idx++) {
        const station = features.stations.objectAt(idx);
        tenantStations.push({
          id: station.get('id'),
          name: station.get('name'),
          path: station.get('path'),
          tenant_folder_id: station.get('tenant_folder_id'),
          newAccess: station.get('newAccess'),
          currentAccess: station.get('currentAccess')
        });
      }

      const tenantStationsStr = JSON.stringify(tenantStations);

      for (let idx = 0; idx < features.lines.get('length'); idx++) {
        const station = features.lines.objectAt(idx);
        tenantLines.push({
          id: station.get('id'),
          name: station.get('name'),
          path: station.get('path'),
          tenant_folder_id: station.get('tenant_folder_id'),
          newAccess: station.get('newAccess'),
          currentAccess: station.get('currentAccess')
        });
      }

      const tenantLinesStr = JSON.stringify(tenantLines);

      for (let idx = 0; idx < features.machines.get('length'); idx++) {
        const station = features.machines.objectAt(idx);
        tenantMachines.push({
          id: station.get('id'),
          name: station.get('name'),
          path: station.get('path'),
          tenant_folder_id: station.get('tenant_folder_id'),
          newAccess: station.get('newAccess'),
          currentAccess: station.get('currentAccess')
        });
      }

      const tenantMachinesStr = JSON.stringify(tenantMachines);

      for (let idx = 0; idx < features.emds.get('length'); idx++) {
        const emd = features.emds.objectAt(idx);
        tenantemds.push({
          id: emd.get('id'),
          name: emd.get('name'),
          path: emd.get('path'),
          tenant_folder_id: emd.get('tenant_folder_id'),
          newAccess: emd.get('newAccess'),
          currentAccess: emd.get('currentAccess')
        });
      }

      const tenantEmdsStr = JSON.stringify(tenantemds);

      for (let idx = 0; idx < features.wofs.get('length'); idx++) {
        const wof = features.wofs.objectAt(idx);
        tenantwofs.push({
          id: wof.get('id'),
          name: wof.get('name'),
          path: wof.get('path'),
          tenant_folder_id: wof.get('tenant_folder_id'),
          newAccess: wof.get('newAccess'),
          currentAccess: wof.get('currentAccess')
        });
      }

      const tenantWofsStr = JSON.stringify(tenantwofs);

      try {
        yield this.get('ajax').post('/pug/user-manager/panels', {
          'data': {
            'tenantPanels': tenantPanelsStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/reports', {
          'data': {
            'tenantReports': tenantReportsStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/hdashboards', {
          'data': {
            'tenantHdashboards': tenantHdashboardsStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/plant-unit-stations', {
          'data': {
            'tenantStations': tenantStationsStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/plant-unit-lines', {
          'data': {
            'tenantLines': tenantLinesStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/plant-unit-machines', {
          'data': {
            'tenantMachines': tenantMachinesStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/plant-emd', {
          'data': {
            'tenantEmds': tenantEmdsStr,
            'tenant_user_id': userId
          }
        });
        yield this.get('ajax').post('/pug/user-manager/plant-wof', {
          'data': {
            'tenantWofs': tenantWofsStr,
            'tenant_user_id': userId
          }
        });
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.update_watch_access_success')
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.update_watch_access_error')
        });
      }
    }),
    'resetPassword': (0, _emberConcurrency.task)(function* (tenantUser) {
      try {
        const self = this;
        const user = yield tenantUser.get('user');
        const componentState = {
          'tenantUser': tenantUser,
          'model': user,
          'generateRandomPassword': false,
          'newPassword': ''
        };
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.reset_password'),
            'iconType': 'mdi',
            'icon': 'lock-reset'
          },
          'componentName': 'Pug/UserManager/ResetPassword',
          'componentState': componentState,
          'confirmButton': {
            'text': this.intl.t('modal.default_change_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('doResetPassword').perform(componentState);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true
          }
        };
        yield this.invokeAction('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'doResetPassword': (0, _emberConcurrency.task)(function* (componentState) {
      try {
        componentState.tenantUser.set('operationIsRunning', true);
        yield this.get('ajax').post('/pug/user-manager/resetPassword', {
          'dataType': 'json',
          'data': {
            'user': componentState.model.get('id'),
            'password': componentState.newPassword,
            'generate': componentState.generateRandomPassword
          }
        });
      } catch (err) {
        throw err;
      } finally {
        componentState.tenantUser.set('operationIsRunning', false);
      }
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'editAccount': (0, _emberConcurrency.task)(function* (tenantUser) {
      try {
        const self = this;
        const user = yield tenantUser.get('user');
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.edit_user'),
            'iconType': 'mdi',
            'icon': 'account-edit'
          },
          'dialogClass': 'flex-100  flex-gt-md-50 flex-gt-xl-30',
          'componentName': 'Pug/UserManager/EditAccount',
          'componentState': {
            'tenantUser': tenantUser,
            'model': user
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_save_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('doUpdateAccount').perform(user);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': () => {
              user.rollback();
            }
          }
        };
        yield this.invokeAction('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'doUpdateAccount': (0, _emberConcurrency.task)(function* (user) {
      yield user.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'doUpdateAccountSucceeded': Ember.on('doUpdateAccount:succeeded', function (taskInstance) {
      const user = taskInstance.args[0];
      const loggedInUser = this.get('currentUser').getUser();
      if (loggedInUser['user_id'] !== user.get('id')) return;
      window.PlantWorksApp.trigger('userChanged');
    }),
    'doUpdateAccountErrored': Ember.on('doUpdateAccount:errored', function (taskInstance, err) {
      const user = taskInstance.args[0];
      user.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cloneAccount': (0, _emberConcurrency.task)(function* (originalTenantUser) {
      try {
        const self = this;
        const tenant = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
        const originalUser = yield originalTenantUser.get('user');
        const clonedUser = this.get('store').createRecord('pug/user-manager/user', {
          'firstName': this.intl.t('general.label_new'),
          'lastName': this.intl.t('general.label_user'),
          'email': `${this.intl.t('general.label_new')}.${this.intl.t('general.label_user')}@${tenant.get('subDomain')}.com`.toLocaleLowerCase()
        });
        const clonedTenantUser = this.get('store').createRecord('pug/user-manager/tenant-user', {
          'tenant': tenant,
          'user': clonedUser
        });
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.user_manager_feature.clone_user'),
            'iconType': 'mdi',
            'icon': 'account-switch'
          },
          'dialogClass': 'flex-100  flex-gt-md-50 flex-gt-xl-30',
          'componentName': 'Pug/UserManager/CloneAccount',
          'componentState': {
            'originalTenantUser': originalTenantUser,
            'originalUser': originalUser,
            'clonedTenantUser': clonedTenantUser,
            'clonedUser': clonedUser
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_create_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('doCloneAccount').perform(clonedUser, clonedTenantUser, originalUser);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': () => {
              clonedTenantUser.destroyRecord();
              clonedUser.destroyRecord();
            }
          }
        };
        yield this.invokeAction('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'changeAccountStatus': (0, _emberConcurrency.task)(function* (tenantUser, newStatus) {
      const oldStatus = tenantUser.get('accessStatus');
      tenantUser.set('operationIsRunning', true);
      tenantUser.set('accessStatus', newStatus);
      yield tenantUser.save();
      const remainingUsersWithOldStatus = this.get('model').filterBy('accessStatus', oldStatus).get('length');
      if (remainingUsersWithOldStatus) return;
      yield this.get('onChangeAccordionItem').perform(undefined);
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'changeAccountStatusSucceeded': Ember.on('changeAccountStatus:succeeded', function (taskInstance) {
      const tenantUser = taskInstance.args[0];
      tenantUser.set('operationIsRunning', false);
    }),
    'changeAccountStatusErrored': Ember.on('changeAccountStatus:errored', function (taskInstance, err) {
      const tenantUser = taskInstance.args[0];
      tenantUser.rollback();
      tenantUser.set('operationIsRunning', false);
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'doCloneAccount': (0, _emberConcurrency.task)(function* (clonedUser, clonedTenantUser, originalUser) {
      if (clonedUser.get('isDirty')) yield clonedUser.save();
      if (clonedTenantUser.get('isDirty')) yield clonedTenantUser.save();
      yield this.get('ajax').post('/pug/user-manager/cloneAccount', {
        'dataType': 'json',
        'data': {
          'originalUserId': originalUser.get('id'),
          'clonedUserId': clonedUser.get('id')
        }
      });
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'doCloneAccountErrored': Ember.on('doCloneAccount:errored', function (taskInstance, err) {
      const clonedUser = taskInstance.args[0];
      const clonedTenantUser = taskInstance.args[1];
      if (!clonedTenantUser.get('isDirty')) clonedTenantUser.destroyRecord();
      if (!clonedUser.get('isDirty')) clonedUser.destroyRecord();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onChangeAccordionItem': (0, _emberConcurrency.task)(function* (newSelectedItem) {
      this.set('selectedAccordionItem', newSelectedItem);
      yield (0, _emberConcurrency.timeout)(10);
      if (!newSelectedItem) this.set('selectedAccordionItem', '1');
    }).keepLatest()
  });

  _exports.default = _default;
});