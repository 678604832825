define("plantworks/models/manufacturing/plant-unit-schedule-extension", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'shift': _emberData.default.belongsTo('manufacturing/plant-unit-schedule', {
      'async': true,
      'inverse': 'extensions'
    }),
    'extensionDate': _emberData.default.attr('string'),
    'extensionDuration': _emberData.default.attr('string'),
    'tenantPlantUnit': _emberData.default.belongsTo('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'extensions'
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true
    }),
    'pastEntry': Ember.computed('extensionDate', function () {
      return window.moment(this.get('extensionDate')).isSameOrAfter(window.moment().format(), 'day');
    })
  });

  _exports.default = _default;
});