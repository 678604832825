define("plantworks/components/pug/group-manager/main-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'editable': false,
    'subComponents': null,
    'selectedComponent': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'group-manager-read');
      this.set('subComponents', [{
        'iconType': 'mdi',
        'icon': 'group',
        'title': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_group_children'),
        'component': 'Pug/GroupManager/SubGroupEditorComponent'
      }, {
        'iconType': 'md',
        'icon': 'all-inclusive',
        'title': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_group_permissions'),
        'component': 'Pug/GroupManager/PermissionGroupEditorComponent'
      }, {
        'iconType': 'md',
        'icon': 'verified-user',
        'title': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_group_users'),
        'component': 'Pug/GroupManager/UserGroupEditorComponent'
      }]);
      this.set('selectedComponent', this.get('subComponents')[0]);
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('group-manager-update');
      this.set('editable', updatePerm);
    }),
    'saveGroup': (0, _emberConcurrency.task)(function* () {
      this.get('selectedGroup').set('isProcessing', true);
      const didDefaultForNewUserChange = this.get('selectedGroup').didChange('defaultForNewUser');
      yield this.get('selectedGroup').save();
      if (!didDefaultForNewUserChange) return;
      const loadedGroups = this.get('store').peekAll('pug/group-manager/tenant-group');
      let oldDefaultGroup = null;
      loadedGroups.forEach(tenantGroup => {
        if (tenantGroup.get('id') === this.get('selectedGroup.id')) return;
        if (!tenantGroup.get('defaultForNewUser')) return;
        oldDefaultGroup = tenantGroup;
      });
      if (oldDefaultGroup) yield oldDefaultGroup.reload();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveGroupSucceeded': Ember.on('saveGroup:succeeded', function () {
      this.get('selectedGroup').set('isProcessing', false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.succesful_save', {
          'htmlSafe': true,
          'displayName': this.get('selectedGroup.displayName')
        })
      });
    }),
    'saveGroupErrored': Ember.on('saveGroup:errored', function (taskInstance, err) {
      this.get('selectedGroup').set('isProcessing', false);
      this.get('selectedGroup').rollback();
      this.get('selectedGroup').reload();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelGroup': (0, _emberConcurrency.task)(function* () {
      yield this.get('selectedGroup').rollback();
    }).drop(),
    'deleteGroup': (0, _emberConcurrency.task)(function* () {
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_delete_group'),
          'iconType': 'md',
          'icon': 'group'
        },
        'content': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.delete_group_message', {
          'htmlSafe': true,
          'displayName': this.get('selectedGroup.displayName')
        }),
        'confirmButton': {
          'text': this.intl.t('modal.default_delete_text'),
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteGroup').perform();
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteGroup': (0, _emberConcurrency.task)(function* () {
      this.get('selectedGroup').set('isProcessing', true);
      const parentGroup = yield this.get('selectedGroup.parent');
      const groupSiblings = yield parentGroup.get('groups');
      if (this.get('selectedGroup.isNew')) this.get('selectedGroup').deleteRecord();else yield this.get('selectedGroup').destroyRecord();
      groupSiblings.removeObject(this.get('selectedGroup'));
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteGroupSucceeded': Ember.on('_confirmedDeleteGroup:succeeded', function () {
      this.get('selectedGroup').set('isProcessing', false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.succesful_delete', {
          'htmlSafe': true,
          'displayName': this.get('selectedGroup.displayName')
        })
      });
      const parentGroup = this.get('selectedGroup.parent');
      this.invokeAction('controller-action', 'setSelectedGroup', parentGroup);
    }),
    '_confirmedDeleteGroupErrored': Ember.on('_confirmedDeleteGroup:errored', function (taskInstance, err) {
      this.get('selectedGroup').set('isProcessing', false);
      this.get('selectedGroup').rollback();
      this.get('selectedGroup').reload();
      const parentGroup = this.get('selectedGroup.parent');
      const groupSiblings = parentGroup.get('groups');
      groupSiblings.addObject(this.get('selectedGroup'));
      this.invokeAction('controller-action', 'setSelectedGroup', this.get('selectedGroup'));
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});