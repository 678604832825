define("plantworks/services/current-user", ["exports", "boolean-parser", "ember-concurrency"], function (_exports, _booleanParser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    'ajax': Ember.inject.service('ajax'),
    'notification': Ember.inject.service('integrated-notification'),
    'userData': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const _fetchUserData = this.get('_fetchUserData');

      yield _fetchUserData.perform();
      window.PlantWorksApp.on('userChanged', this, this.onUserChanged);
    }).on('init').drop(),

    destroy() {
      window.PlantWorksApp.off('userchanged', this, this.onUserChanged);

      this._super(...arguments);
    },

    onUserChanged() {
      this.get('_fetchUserData').perform();
    },

    isLoggedIn() {
      return this.get('userData.loggedIn');
    },

    hasPermission(permission) {
      if (!this.get('userData')) return false;
      const userPermissionNames = this.get('userData.permissions') || [];
      if (!userPermissionNames || !userPermissionNames.length) return false;
      if (permission === '*') return true;
      let parsedPermissions = (0, _booleanParser.parseBooleanQuery)(permission);
      if (parsedPermissions.length === 1 && parsedPermissions[0].length === 1) parsedPermissions = permission;

      if (!Array.isArray(parsedPermissions)) {
        return userPermissionNames.includes(permission);
      }

      let doesUserHavePermission = false;
      const memoizedPermissions = {};

      for (let permIdx = 0; permIdx < parsedPermissions.length; permIdx++) {
        if (doesUserHavePermission) break;
        const permissionSet = parsedPermissions[permIdx];

        if (permissionSet.length === 1) {
          const permission = permissionSet[0];
          if (memoizedPermissions[permission] === undefined) memoizedPermissions[permission] = userPermissionNames.includes(permission);
          doesUserHavePermission = doesUserHavePermission || memoizedPermissions[permission];
          continue;
        }

        let isPermissionSetActive = true;

        for (let permSetIdx = 0; permSetIdx < permissionSet.length; permSetIdx++) {
          if (!isPermissionSetActive) break;
          const permission = permissionSet[permSetIdx];
          if (memoizedPermissions[permission] === undefined) memoizedPermissions[permission] = userPermissionNames.includes(permission);
          isPermissionSetActive = isPermissionSetActive && memoizedPermissions[permission];
        }

        doesUserHavePermission = doesUserHavePermission || isPermissionSetActive;
      }

      return doesUserHavePermission;
    },

    getUser() {
      return this.get('userData');
    },

    getSubDomain() {
      const subDomain = this.get('userData');

      if (subDomain != null && subDomain != undefined) {
        if (subDomain.otherTenants.length === 1) return subDomain.otherTenants[0].sub_domain;
      }
    },

    '_fetchUserData': (0, _emberConcurrency.task)(function* () {
      this.trigger('userDataUpdating');

      try {
        const userData = yield this.get('ajax').request('/session/user', {
          'method': 'GET'
        });
        this.set('userData', Ember.Object.create(userData));
        const subDomain = this.get('userData');

        if (subDomain != null && subDomain != undefined) {
          if (subDomain.otherTenants.length === 1) window.PlantWorksApp.subDomain = subDomain.otherTenants[0].sub_domain;
        }

        if (userData.loggedIn) {
          window.plantworksUserId = userData['user_id'];
          window.plantworksTenantId = userData['tenant_id'];
        } else {
          window.plantworksUserId = null;
          window.plantworksTenantId = null;
        }

        this.trigger('userDataUpdated');
      } catch (err) {
        this.set('userData', null);
        window.plantworksUserId = null;
        window.plantworksTenantId = null;
        this.trigger('userDataUpdated');
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).keepLatest()
  });

  _exports.default = _default;
});