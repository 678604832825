define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/add-machine", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'oemMachineList': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const oemMachineList = yield this.get('store').findAll('masterdata/machine-master', {
        'reload': true
      });
      this.set('oemMachineList', oemMachineList);
    }).on('init').drop(),
    'setMachineMachine': function (newMachineId) {
      const newMachine = this.get('store').peekRecord('masterdata/machine-master', newMachineId);
      this.set('state.model.machine', newMachine);
    }
  });

  _exports.default = _default;
});