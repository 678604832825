define("plantworks/components/common/dev-env/template-task-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'addComponentTask': (0, _emberConcurrency.task)(function* () {
      const allTasks = this.get('model');
      yield allTasks.createFragment({
        'name': 'NewTask',
        'arguments': '',
        'functionCode': ''
      });
    }).drop(),
    'deleteComponentTask': (0, _emberConcurrency.task)(function* (componentTask) {
      const allTasks = this.get('model');
      yield allTasks.removeFragment(componentTask);
    }).drop(),
    'showComponentTask': (0, _emberConcurrency.task)(function* (componentTask) {
      yield componentTask.set('show', true);
    }).drop(),
    'hideComponentTask': (0, _emberConcurrency.task)(function* (componentTask) {
      yield componentTask.set('show', false);
    }).drop()
  });

  _exports.default = _default;
});