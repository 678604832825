define("plantworks/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FhWGrWCc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/head.hbs"
    }
  });

  _exports.default = _default;
});