define("plantworks/initializers/manufacturing/add-manufacturing-plant-unit-line-to-work-order-format", ["exports", "ember-data", "plantworks/models/work-order/work-order-format", "plantworks/models/manufacturing/plant-unit-line"], function (_exports, _emberData, _workOrderFormat, _plantUnitLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _workOrderFormat.default.reopen({
      'lines': _emberData.default.hasMany('manufacturing/plant-unit-line-work-order-format', {
        'async': true,
        'inverse': 'tenantWorkOrderFormat'
      })
    });

    _plantUnitLine.default.reopen({
      'workOrderFormats': _emberData.default.hasMany('manufacturing/plant-unit-line-work-order-format', {
        'async': true,
        'inverse': 'tenantPlantUnitLine'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});