define("plantworks/initializers/board/add-board-panels-to-tenant", ["exports", "ember-data", "plantworks/models/settings/account/basics/tenant"], function (_exports, _emberData, _tenant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _tenant.default.reopen({
      'panels': _emberData.default.hasMany('board/panel', {
        'async': true,
        'inverse': 'tenant'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});