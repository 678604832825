define("plantworks/components/manufacturing/watch/coke-plant-unit-machine-operator-display", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'operatorList': null,
    'uniqueParameterId': null,
    'operatorListDataPoints': null,
    'isLoading': false,
    'currentOperator': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const currentOperator = yield this.get('model.tenantPlantUnitMachine.currentOperator');
      this.set('showInDropdownTag', 'masterdata_id');
      this.set('currentOperator', currentOperator);
    }).on('init').keepLatest(),
    'onModelChanged': Ember.observer('model.tenantPlantUnitMachine', function () {
      this.get('updateOperators').perform();
    }),
    'updateOperators': (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);

      try {
        this.set('isLoading', false);
      } catch (err) {
        this.set('isLoading', false);
      }
    }).drop(),
    'logout': (0, _emberConcurrency.task)(function* () {
      // reset the current operator - required to handle operator change event for the same line
      this.get('_updateOperator').perform({
        'selectedMasterDataId': ''
      });
    }).drop(),
    'currentOperatorObserver': Ember.observer('currentOperator', function () {
      if (!this.get('currentOperator') || !this.get('currentOperator.masterdata_id')) {
        this.get('authorizeOperator').perform();
      }
    }),
    'authorizeOperator': (0, _emberConcurrency.task)(function* () {
      // reset the current operator - required to handle operator change event for the same line
      const tenantPlantUnitMachine = yield this.get('model.tenantPlantUnitMachine');
      this.set('currentOperator', (yield tenantPlantUnitMachine.get('currentOperator')));
      let previousEventValue = yield tenantPlantUnitMachine.get('currentOperator');
      const modalState = {
        'previousEventValue': previousEventValue ? previousEventValue : null,
        'selectedMasterDataId': previousEventValue ? previousEventValue['masterdata_id'] : null
      };
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_authorize_operator'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex-30',
        'componentName': 'Manufacturing/Watch/CokeStationObservedMachineAddOperator',
        'componentState': {
          'model': modalState,
          'callback': () => {
            this.get('_updateOperator').perform(modalState);
          }
        },
        'confirmButton': null,
        'cancelButton': null
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_updateOperator': (0, _emberConcurrency.task)(function* (modalState) {
      if (!modalState || modalState.selectedMasterDataId == null) return;
      let selectedOperator = modalState.selectedMasterDataId;
      const newEventObj = {
        'masterdata_id': selectedOperator,
        'ROLE': 'Operator'
      };
      const newEventValue = JSON.stringify(newEventObj);
      let previousEventValue = yield this.get('model.tenantPlantUnitMachine.currentOperator');
      previousEventValue = JSON.stringify(previousEventValue);
      let operatorChangeEventType = null;
      let retry = 3;

      while (retry > 0 && operatorChangeEventType === null) {
        const allEventTypes = yield this.get('store').findAll('masterdata/event-type');
        operatorChangeEventType = allEventTypes.filterBy('tag', 'operator_change');
        retry--;
      }

      if (!operatorChangeEventType.get('length')) {
        this.get('notification').display({
          'type': 'error',
          'error': 'No event data found'
        });
        return;
      }

      operatorChangeEventType = operatorChangeEventType.objectAt(0);

      for (let i = 0; i < this.get('operatorFormObservedMachines').length; i++) {
        const model = yield this.get('operatorFormObservedMachines').objectAt(i);
        const tenantPlantUnitMachine = yield model.get('tenantPlantUnitMachine');
        const tenantPlantUnitStation = yield model.get('tenantPlantUnitStation');
        const operatorChangeEvent = this.get('store').createRecord('manufacturing/plant-unit-machine-event', {
          'tenant': this.get('model.tenant'),
          'tenantPlantUnitStation': tenantPlantUnitStation,
          'tenantPlantUnitMachine': tenantPlantUnitMachine,
          'eventType': operatorChangeEventType
        });
        operatorChangeEvent.set('eventMetadata', {
          'value': newEventValue,
          'previousValue': previousEventValue
        });
        yield operatorChangeEvent.save();
      } // reset the current operator to the selected operator - once event is recoreded properly


      this.set('currentOperator', newEventObj);
    }).drop()
  });

  _exports.default = _default;
});