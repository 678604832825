define("plantworks/framework/base-component", ["exports", "ember-lifeline", "ember-invoke-action", "ember-debug-logger"], function (_exports, _emberLifeline, _emberInvokeAction, _emberDebugLogger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberLifeline.ContextBoundTasksMixin, _emberLifeline.ContextBoundEventListenersMixin, _emberLifeline.DisposableMixin, Ember.Evented, _emberInvokeAction.InvokeActionMixin, {
    'ajax': Ember.inject.service('ajax'),
    'intl': Ember.inject.service('intl'),
    'store': Ember.inject.service('store'),
    'currentUser': Ember.inject.service('current-user'),
    'notification': Ember.inject.service('integrated-notification'),
    'debug': (0, _emberDebugLogger.default)('plantworks-component'),
    'permissions': null,
    'hasPermission': false,

    init() {
      this._super(...arguments);

      this.set('permissions', '*');
      this.get('currentUser').on('userDataUpdated', this, 'updatePermissions');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'updatePermissions');

      this._super(...arguments);
    },

    'onPermissionChanges': Ember.on('init', Ember.observer('permissions', function () {
      this.updatePermissions();
    })),

    updatePermissions() {
      const currentUser = this.get('currentUser');
      this.set('hasPermission', currentUser.hasPermission(this.get('permissions')));
    },

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'actions': {
      'controller-action': function (action, data) {
        if (this[action] && typeof this[action] === 'function') {
          this[action](data);
          return;
        }

        this.invokeAction('controller-action', action, data);
      }
    }
  });

  _exports.default = _default;
});