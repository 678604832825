define("plantworks/components/report/devenv/result-component-editor", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'displayViews': null,
    'selectedDisplayView': null,
    'frontendHooks': null,
    'selectedFrontendHook': null,
    'customTemplateTop': null,
    'customTemplateBottom': null,
    'selectedNode': null,
    'currentResultTemplate': null,
    'currentResultTemplateId': null,

    init() {
      this._super(...arguments);

      this.set('displayViews', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_frontend_dev_component_code'
        }, {
          'name': 'general.label_frontend_dev_template_code'
        }])
      }));
      this.set('frontendHooks', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_frontend_dev_component_before_render'
        }, {
          'name': 'general.label_frontend_dev_component_after_render'
        }, {
          'name': 'general.label_frontend_dev_component_on_data'
        }, {
          'name': 'general.label_frontend_dev_component_observers'
        }, {
          'name': 'general.label_frontend_dev_component_tasks'
        }, {
          'name': 'general.label_frontend_dev_component_before_destroy'
        }])
      }));
      this.set('customTemplateTop', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.set('customTemplateBottom', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.set('selectedDisplayView', this.get('displayViews').objectAt(0));
      this.set('selectedFrontendHook', this.get('frontendHooks').objectAt(0));
      this.get('_setupNodeAndResultTemplate').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'cancelReportResultTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentResultTemplate')) return;
      yield this.get('currentResultTemplate').rollback();
    }).enqueue(),
    'saveReportResultTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentResultTemplate')) return;
      yield this.get('currentResultTemplate').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveReportResultTemplateSucceeded': Ember.on('saveReportResultTemplate:succeeded', function () {
      const report = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_save', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    'saveReportResultTemplateErrored': Ember.on('saveReportResultTemplate:errored', function (taskInstance, err) {
      const reportResultTemplate = this.get('currentResultTemplate');
      reportResultTemplate.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveReportResultTemplate': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentResultTemplate')) return;
      this.set('currentResultTemplate.publishStatus', publish);
      yield this.get('currentResultTemplate').save();
    }).keepLatest(),
    'editReportResultTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentResultTemplate')) return;
      if (!this.get('model')) return;
      const currentResultTemplate = this.get('currentResultTemplate'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const resultTemplates = yield this.get('model.resultTemplates');
      const newResultTemplate = this.get('store').createRecord(`report/report-result-template`, {
        'tenant': tenant,
        'tenantReport': this.get('model')
      });
      newResultTemplate.set('publishStatus', false);
      newResultTemplate.set('componentState', currentResultTemplate.get('componentState'));
      newResultTemplate.set('componentBeforeRenderCode', currentResultTemplate.get('componentBeforeRenderCode'));
      newResultTemplate.set('componentAfterRenderCode', currentResultTemplate.get('componentAfterRenderCode'));
      newResultTemplate.set('componentOnDataCode', currentResultTemplate.get('componentOnDataCode'));
      newResultTemplate.set('componentBeforeDestroyCode', currentResultTemplate.get('componentBeforeDestroyCode'));
      newResultTemplate.set('template', currentResultTemplate.get('template'));
      currentResultTemplate.get('componentObservers').forEach(componentObserver => {
        newResultTemplate.get('componentObservers').createFragment({
          'name': componentObserver.get('name'),
          'observedProperty': componentObserver.get('observedProperty'),
          'functionCode': componentObserver.get('functionCode')
        });
      });
      currentResultTemplate.get('componentTasks').forEach(componentTask => {
        newResultTemplate.get('componentTasks').createFragment({
          'name': componentTask.get('name'),
          'arguments': componentTask.get('arguments'),
          'functionCode': componentTask.get('functionCode')
        });
      });
      resultTemplates.addObject(newResultTemplate);
      this.set('currentResultTemplate', newResultTemplate);
      this.set('currentResultTemplateId', newResultTemplate.get('id'));
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,name,resultTemplates.[]}', function () {
      this.get('_setupNodeAndResultTemplate').perform();
    }),
    'onActiveTabChanged': Ember.observer('selectedDisplayView', function () {
      if (!this.get('selectedDisplayView')) return;
      const currTabIdx = this.get('displayViews').indexOf(this.get('selectedDisplayView'));

      if (currTabIdx === 0) {
        this.set('selectedFrontendHook', this.get('frontendHooks').objectAt(0));
        (0, _emberLifeline.runTask)(this, this._fillFrontendCodeEditor, 150);
        return;
      }

      if (currTabIdx === 1) {
        (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
        return;
      }
    }),
    'onActiveFrontendHookTabChanged': Ember.observer('selectedFrontendHook', function () {
      if (!this.get('selectedFrontendHook')) return;
      (0, _emberLifeline.runTask)(this, this._fillFrontendCodeEditor, 150);
    }),
    actions: {
      'resetSelectedSubcomponent': function () {
        this.resetSelectedSubcomponent();
      }
    },
    '_setupNodeAndResultTemplate': (0, _emberConcurrency.task)(function* () {
      this.set('selectedNode', null);
      if (!this.get('model')) return;
      const resultTemplates = yield this.get('model.resultTemplates');
      if (!resultTemplates) return;
      let currentResultTemplate = null;

      if (resultTemplates.length) {
        currentResultTemplate = resultTemplates.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentResultTemplate = this.get('store').createRecord(`report/report-result-template`, {
          'tenant': tenant,
          'tenantReport': this.get('model')
        });
        resultTemplates.addObject(currentResultTemplate);
      }

      this.set('selectedNode', {
        'id': this.get('model.id'),
        'text': this.get('model.name'),
        'data': {
          'type': 'report'
        }
      });
      this.set('currentResultTemplateId', currentResultTemplate.get('id'));
      this.set('currentResultTemplate', currentResultTemplate);
    }).keepLatest(),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.report-frontend-result-editor-subcomponent-container');
      const reportDivs = this.$('div.report-frontend-result-editor-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let reportDivTotalHeight = 0;

      for (let reportIdx = 0; reportIdx < reportDivs.length; reportIdx++) {
        const thisReport = reportDivs[reportIdx];
        reportDivTotalHeight += this.$(thisReport).outerHeight();
      }

      const topReportDivs = this.$('div.report-frontend-result-editor-subcomponent-container div.panel.top');
      if (topReportDivs.length > 1) reportDivTotalHeight += topReportDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - reportDivTotalHeight > 300 ? containerDivHeight - reportDivTotalHeight : 300);
    },
    '_fillFrontendCodeEditor': function () {
      const containerDiv = this.$('div.report-frontend-result-editor-component-hooks-container');
      const reportDivs = this.$('div.report-frontend-result-editor-component-hooks-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let reportDivTotalHeight = 0;

      for (let reportIdx = 0; reportIdx < reportDivs.length; reportIdx++) {
        const thisReport = reportDivs[reportIdx];
        reportDivTotalHeight += this.$(thisReport).outerHeight();
      }

      const topReportDivs = this.$('div.report-frontend-result-editor-component-hooks-container div.panel.top');
      if (topReportDivs.length > 1) reportDivTotalHeight += topReportDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - reportDivTotalHeight > 300 ? containerDivHeight - reportDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});