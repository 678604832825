define("plantworks/components/historical-dashboard/configure/data-point-aggregations-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'bg-grey'],
    'viewMode': 'configure',
    'config': null,
    'chosenType': null,
    'choosenPeriod': null,
    'comparisonOperatorOptions': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      let config = yield this.get('state.model.config');

      if (!config || config === null) {
        this.set('state.model.config', {
          'type': null,
          'period': null
        });
        config = yield this.get('state.model.config');
      }

      this.set('config', config);
      this.set('chosenType', config.type);
      this.set('chosenPeriod', config.period);
      this.set('comparisonOperatorOptions', ['>', '<', '=']);
    }).on('init').drop(),
    'onChosenTypeChanged': Ember.observer('chosenType', function () {
      this.set('config.type', this.get('chosenType'));
    }),
    'onChosenPeriodChanged': Ember.observer('chosenPeriod', function () {
      this.set('config.period', this.get('chosenPeriod'));
    }),
    'addAggregateFilter': (0, _emberConcurrency.task)(function* () {
      yield this.get('state.model').get('filters').createFragment({
        'comparisonOperator': null,
        'value': null
      });
    }).drop(),
    'deleteAggregateFilter': (0, _emberConcurrency.task)(function* (filter) {
      yield this.get('state.model.filters').removeFragment(filter);
    }).enqueue()
  });

  _exports.default = _default;
});