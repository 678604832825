define("plantworks/components/session/log-in", ["exports", "plantworks/framework/base-component", "plantworks/config/environment", "ember-computed-style", "ember-concurrency"], function (_exports, _baseComponent, _environment, _emberComputedStyle, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'router': Ember.inject.service('router'),
    'attributeBindings': ['style'],
    //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    'style': (0, _emberComputedStyle.default)('display'),
    'displayForm': 'loginForm',
    'startYear': _environment.default.plantworks.startYear,
    'currentYear': _environment.default.plantworks.startYear,
    'username': '',
    'password': '',
    'confirmPassword': '',
    'firstName': '',
    'lastName': '',
    'mobileNumber': '',
    'showLogin': false,
    'display': Ember.computed('hasPermission', function () {
      return {
        'display': this.get('hasPermission') ? 'none' : 'block',
        'min-width': this.get('hasPermission') ? '0rem' : '20rem'
      };
    }),

    init() {
      this._super(...arguments);

      const currentYear = new Date().getFullYear();
      this.set('currentYear', currentYear);
      this.set('permissions', 'registered');
    },

    'doLogin': (0, _emberConcurrency.task)(function* () {
      const notification = this.get('notification');
      notification.display({
        'type': 'info',
        'message': this.intl.t('plant_works_webapp_server_server.session_component.login_message')
      });

      try {
        window.AndroidInterface.setEmail(this.get('username'));
        const loginResult = yield this.get('ajax').post('/session/login', {
          'dataType': 'json',
          'data': {
            'username': this.get('username'),
            'password': this.get('password')
          }
        });
        notification.display({
          'type': loginResult.status < 400 ? 'success' : 'error',
          'message': loginResult.info.message,
          'error': loginResult.info.message
        });

        if (loginResult.nextAction === 'proceed') {
          if (window.AndroidInterface) window.AndroidInterface.setLogInStatus(true);
          this.get('currentUser').one('userDataUpdated', () => {
            const userData = this.get('currentUser').getUser();

            if (userData.defaultApplication === '' || userData.defaultApplication === 'dashboard') {
              this.get('router').transitionTo('index');
              return;
            }

            if (userData.defaultApplicationParameters) {
              this.get('router').transitionTo(userData.defaultApplication, userData.defaultApplicationParameters.id);
              return;
            }

            this.get('router').transitionTo(userData.defaultApplication);
          });
          window.PlantWorksApp.trigger('userChanged');
          return;
        }

        if (loginResult.nextAction === 'redirect') {
          let currentSubDomain = (window.location.hostname || '').replace(_environment.default.plantworks.domain, '');
          if (currentSubDomain[currentSubDomain.length - 1] === '.') currentSubDomain = currentSubDomain.slice(0, -1);
          const newSubDomain = loginResult.redirectDomain.trim();

          if (!newSubDomain || newSubDomain === '' || newSubDomain === currentSubDomain || newSubDomain === 'www' && currentSubDomain === 'localhost') {
            this.get('currentUser').one('userDataUpdated', () => {
              const userData = this.get('currentUser').getUser();

              if (userData.defaultApplication === '' || userData.defaultApplication === 'dashboard') {
                this.get('router').transitionTo('index');
                return;
              }

              if (userData.defaultApplicationParameters) {
                this.get('router').transitionTo(userData.defaultApplication, userData.defaultApplicationParameters.id);
                return;
              }

              this.get('router').transitionTo(userData.defaultApplication);
            });
            window.PlantWorksApp.trigger('userChanged');
            return;
          }

          if (_environment.default.IS_CORBER) {
            window.PlantWorksApp.newSubDomain = newSubDomain;
          }

          if (currentSubDomain === '' || currentSubDomain === window.location.hostname) {
            window.location.hostname = `${newSubDomain}.${window.location.hostname}`;
            return;
          }

          const newHref = window.location.href.replace(currentSubDomain, newSubDomain);
          window.location.href = newHref;
          return;
        }

        if (loginResult.nextAction === 'choose') {
          notification.display({
            'type': 'info',
            'message': 'TBD: Allow user to choose tenant'
          });
          return;
        }
      } catch (err) {
        notification.display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'resetPassword': (0, _emberConcurrency.task)(function* () {
      const notification = this.get('notification');
      notification.display({
        'type': 'info',
        'message': this.intl.t('plant_works_webapp_server_server.session_component.resetting_password_message')
      });

      try {
        const resetPassResult = yield this.get('ajax').post('/session/reset-password', {
          'dataType': 'json',
          'data': {
            'username': this.get('username')
          }
        });
        notification.display({
          'type': resetPassResult.status < 400 ? 'success' : 'error',
          'message': resetPassResult.message,
          'error': resetPassResult.message
        });
      } catch (err) {
        notification.display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'registerAccount': (0, _emberConcurrency.task)(function* () {
      const notification = this.get('notification');

      if (this.get('password') !== this.get('confirmPassword')) {
        notification.display({
          'type': 'error',
          'message': this.intl.t('plant_works_webapp_server_server.session_component.password_dont_match_message')
        });
        return;
      }

      notification.display({
        'type': 'info',
        'message': this.intl.t('plant_works_webapp_server_server.session_component.registering_account')
      });

      try {
        const registerResult = yield this.get('ajax').post('/session/register-account', {
          'dataType': 'json',
          'data': {
            'firstname': this.get('firstName'),
            'lastname': this.get('lastName'),
            'username': this.get('username'),
            'mobileNumber': this.get('mobileNumber'),
            'password': this.get('password')
          }
        });
        notification.display({
          'type': registerResult.status < 400 ? 'success' : 'error',
          'message': registerResult.message,
          'error': registerResult.message
        });
      } catch (err) {
        notification.display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),

    setDisplayForm(formName) {
      this.set('displayForm', formName);
    },

    'showLoginScreen': (0, _emberConcurrency.task)(function* () {
      yield this.set('showLogin', true);
    }).enqueue()
  });

  _exports.default = _default;
});