define("plantworks/components/manufacturing/configure/plant-unit-line-editor/list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'addPlantUnitLine': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitLine = this.get('store').createRecord('manufacturing/plant-unit-line', {
        'tenant': tenant,
        'tenantPlantUnit': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_unit_line_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnitLine.set('name', displayName);
      newPlantUnitLine.set('description', displayName);
      const siblingUnitLines = yield this.get('model.lines');
      siblingUnitLines.addObject(newPlantUnitLine);
      this.get('editPlantUnitLine').perform(newPlantUnitLine);
    }).enqueue(),
    'editPlantUnitLine': (0, _emberConcurrency.task)(function* (unitLine) {
      yield unitLine.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitLine': (0, _emberConcurrency.task)(function* (unitLine) {
      unitLine.set('isEditing', false);
      yield unitLine.rollback();
    }).enqueue(),
    'savePlantUnitLine': (0, _emberConcurrency.task)(function* (unitLine) {
      unitLine.set('isEditing', false);
      yield unitLine.save();
      yield unitLine.reload();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitLineSucceeded': Ember.on('savePlantUnitLine:succeeded', function (taskInstance) {
      const unitLine = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_line_save', {
          'htmlSafe': true,
          'displayName': unitLine.get('name')
        })
      });
    }),
    'savePlantUnitLineErrored': Ember.on('savePlantUnitLine:errored', function (taskInstance, err) {
      const unitLine = taskInstance.args[0];
      unitLine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitLine': (0, _emberConcurrency.task)(function* (unitLine) {
      const unitLineName = unitLine.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_unit_line'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_unit_line_message', {
          'htmlSafe': true,
          'displayName': unitLineName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitLine').perform(unitLine, unitLineName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitLine': (0, _emberConcurrency.task)(function* (unitLine, unitLineName) {
      // eslint-disable-line no-unused-vars
      yield unitLine.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitLineSucceeded': Ember.on('_confirmedDeletePlantUnitLine:succeeded', function (taskInstance) {
      const unitLineName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_line_delete', {
          'htmlSafe': true,
          'displayName': unitLineName
        })
      });
    }),
    '_confirmedDeletePlantUnitLineErrored': Ember.on('_confirmedDeletePlantUnitLine:errored', function (taskInstance, err) {
      const unitLine = taskInstance.args[0];
      unitLine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});