define("plantworks/components/devenv/tree-component", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'router': Ember.inject.service('router'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'devenv-access');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this._createTree();
    },

    willDestroyElement() {
      this.invokeAction('controller-action', 'setSelectedNode', null);
      (0, _jquery.default)('div#devenv-tree-container').jstree('destroy', true);

      this._super(...arguments);
    },

    onUserDataUpdated() {
      (0, _jquery.default)('div#devenv-tree-container').jstree('destroy', true);

      if (!this.get('currentUser').isLoggedIn()) {
        this.invokeAction('controller-action', 'setSelectedNode', null);
        return;
      }

      this._createTree();
    },

    _createTree() {
      if (!this.get('model')) return;
      if (!(0, _jquery.default)('div#devenv-tree-container').length) return;
      const self = this;
      const treeNodes = this.get('model').map(devenvNode => {
        return {
          'id': devenvNode.get('id'),
          'parent': '#',
          'text': devenvNode.get('i18n_name'),
          'children': devenvNode.get('nodeType') !== 'leaf',
          'li_attr': {
            'title': devenvNode.get('i18n_desc')
          },
          'data': {
            'devenvRoute': devenvNode.get('devenvRoute'),
            'dataUrl': devenvNode.get('dataUrl'),
            'type': devenvNode.get('nodeType')
          }
        };
      });
      const moduTree = (0, _jquery.default)('div#devenv-tree-container').jstree({
        'core': {
          'check_callback': function (operation) {
            return operation !== 'move_node';
          },
          'multiple': false,
          'themes': {
            'name': 'default',
            'icons': false,
            'dots': false,
            'responsive': true
          },
          'data': function (node, callback) {
            if (node.id === '#') {
              callback.call(this, treeNodes);
              return;
            }

            const that = this;
            self.get('ajax').request(node.data.dataUrl, {
              'dataType': 'json',
              'data': {
                'node_id': node.id,
                'node_type': node.data.type
              }
            }).then(function (childNodes) {
              childNodes = childNodes.map(childNode => {
                if (childNode.data.intl) {
                  childNode.text = self.intl.exists(`plant_works_webapp_server_server.${childNode.text}`) ? self.intl.t(`plant_works_webapp_server_server.${childNode.text}`) : childNode.text;
                  childNode['li_attr']['title'] = self.intl.exists(`plant_works_webapp_server_server.${childNode['li_attr']['title']}`) ? self.intl.t(`plant_works_webapp_server_server.${childNode['li_attr']['title']}`) : childNode['li_attr']['title'] || '';
                }

                childNode.children = true;
                return childNode;
              });
              callback.call(that, childNodes);
            }).catch(function (error) {
              self.get('debug')(error);
              callback.call(that, []);
            });
          }
        },
        'plugins': ['sort', 'unique']
      });
      moduTree.on('ready.jstree', () => {
        let currentRoute = this.get('router').get('currentRouteName');
        let nodetoBeActive = (0, _jquery.default)('div#devenv-tree-container > ul > li:first-child').attr('id');
        (0, _jquery.default)('div#devenv-tree-container').jstree('open_node', nodetoBeActive);

        if (currentRoute !== 'devenv.index') {
          nodetoBeActive = treeNodes.filter(treeNode => {
            return treeNode.data.devenvRoute === currentRoute;
          });
          if (!nodetoBeActive.length) return;
          nodetoBeActive = nodetoBeActive[0]['id'];
          (0, _jquery.default)('div#devenv-tree-container').jstree('open_node', nodetoBeActive);
        }

        (0, _jquery.default)('div#devenv-tree-container').jstree('activate_node', nodetoBeActive, false, false);
      });
      moduTree.on('activate_node.jstree', (event, data) => {
        this.get('onActivateNode').perform(data.node);
      });
    },

    'onModelChanged': Ember.observer('model', function () {
      this.onUserDataUpdated();
    }),
    'onActivateNode': (0, _emberConcurrency.task)(function* (treeNode) {
      if (treeNode && treeNode.id) yield this.get('store').peekRecord('devenv/node', treeNode.id);
    }).keepLatest().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onActivateNodeSucceeded': Ember.on('onActivateNode:succeeded', function (taskInstance) {
      const treeNode = taskInstance.args[0];
      let devenvNode = this.get('selectedNode');
      if (devenvNode && devenvNode.id === treeNode.id) return;
      (0, _jquery.default)('div#devenv-tree-container').jstree('open_node', treeNode.id);
      this.invokeAction('controller-action', 'setSelectedNode', treeNode);
    }),
    'onActivateNodeErrored': Ember.on('onActivateNode:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onSelectedNodeChanged': Ember.observer('selectedNode', function () {
      if (!this.get('selectedNode')) return;
      if ((0, _jquery.default)('div#devenv-tree-container').jstree('get_selected')[0] === this.get('selectedNode.id')) return;
      const treeNode = (0, _jquery.default)('div#devenv-tree-container').jstree('get_node', this.get('selectedNode.id'));

      if (treeNode) {
        (0, _jquery.default)('div#devenv-tree-container').jstree('activate_node', this.get('selectedNode.id'), false, false);
        (0, _jquery.default)('div#devenv-tree-container').jstree('open_node', this.get('selectedNode.id'));
        return;
      }

      const parentNode = (0, _jquery.default)('div#devenv-tree-container').jstree('get_node', this.get('selectedNode.parent.id'));
      (0, _jquery.default)('div#devenv-tree-container').one('refresh_node.jstree', () => {
        (0, _jquery.default)('div#devenv-tree-container').jstree('activate_node', this.get('selectedNode.id'), false, false);
        (0, _jquery.default)('div#devenv-tree-container').jstree('open_node', this.get('selectedNode.id'));
      });
      (0, _jquery.default)('div#devenv-tree-container').jstree('refresh_node', parentNode);
    })
  });

  _exports.default = _default;
});