define("plantworks/models/manufacturing/plant-unit-machine-setuptime-reason", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'reasonCode': _emberData.default.attr('string', {
      'defaultValue': 'UNKNOWN'
    }),
    'reasonDescription': _emberData.default.attr('string', {
      'defaultValue': 'UNKNOWN REASON'
    }),
    'reasonDurationInMin': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'maxReasonDuration': Ember.computed('reasonDurationInMin', 'tenantPlantUnitMachineSetuptime.unaccountedDuration', function () {
      return this.get('_computedMaxReasonDuration').perform();
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitMachineSetuptime': _emberData.default.belongsTo('manufacturing/plant-unit-machine-setuptime', {
      'async': true,
      'inverse': 'reasons'
    }),
    'onReasonDurationChanged': Ember.observer('reasonDurationInMin', function () {
      this.get('_trimDuration').perform();
    }),
    '_computedMaxReasonDuration': (0, _emberConcurrency.task)(function* () {
      const machineSetuptime = yield this.get('tenantPlantUnitMachineSetuptime');
      const durationUnaccountedTime = yield machineSetuptime.get('unaccountedDuration');
      return Number(this.get('reasonDurationInMin')) + durationUnaccountedTime;
    }).enqueue(),
    '_trimDuration': (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(250);
      const reasonDuration = Number(this.get('reasonDurationInMin'));
      const maxDuration = yield this.get('maxReasonDuration');
      if (reasonDuration <= maxDuration) return;
      this.set('reasonDurationInMin', maxDuration);
    }).keepLatest()
  });

  _exports.default = _default;
});