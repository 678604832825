define("plantworks/models/manufacturing/plant-unit-line-processor", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'processedDataTransformCode': _emberData.default.attr('string'),
    'preRealtimePushTransformCode': _emberData.default.attr('string'),
    'availabilityCalculationCode': _emberData.default.attr('string'),
    'performanceCalculationCode': _emberData.default.attr('string'),
    'qualityCalculationCode': _emberData.default.attr('string'),
    'isCustomOeeCalculation': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'customOeeCalculationCode': _emberData.default.attr('string'),
    'publishStatus': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitLine': _emberData.default.belongsTo('manufacturing/plant-unit-line', {
      'async': true,
      'inverse': 'processors'
    })
  });

  _exports.default = _default;
});