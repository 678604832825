define("plantworks/components/manufacturing/configure/plant-unit-station-editor/basics-sub-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    masterDataPropertyName: 'operatorList',
    masterDataFilterPropertyName: 'operatorListFilters',
    masterDataDisplayName: null,

    init() {
      this._super(...arguments);

      this.set('masterDataDisplayName', this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_operator_list'));
    }

  });

  _exports.default = _default;
});