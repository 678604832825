define("plantworks/components/common/single-attribute-set/property-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'isCategorizedView': false,
    'isSpace': false,
    'showError': false,
    'selectedSourceType': 'static',
    'viewMode': 'configure',
    'onCategorizedViewChange': Ember.observer('isCategorizedView', function () {
      if (this.get('isCategorizedView')) return;
      this.set('selectedSourceType', 'static');
    }),
    'addAttributeSetProperty': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newAttributeSetProperty = this.get('store').createRecord(`${this.get('modelModule')}/attribute-set-property`, {
        'tenant': tenant,
        'attributeSet': this.get('model')
      });

      if (this.get('isCategorizedView')) {
        newAttributeSetProperty.set('source', this.get('selectedSourceType'));
      }

      const displayName = this.intl.t('plant_works_webapp_server_server.common.new_attribute_set_property_name', {
        'now': window.moment().valueOf()
      });
      newAttributeSetProperty.set('name', displayName);
      newAttributeSetProperty.set('description', displayName);
      newAttributeSetProperty.set('internalTag', Ember.String.underscore(displayName).toLocaleUpperCase());
      const siblingProperties = yield this.get('model.properties');
      siblingProperties.addObject(newAttributeSetProperty);
      this.get('editAttributeSetProperty').perform(newAttributeSetProperty);
    }).enqueue(),
    'editAttributeSetProperty': (0, _emberConcurrency.task)(function* (property) {
      yield property.set('isEditing', true);
    }).enqueue(),
    'cancelAttributeSetProperty': (0, _emberConcurrency.task)(function* (property) {
      property.set('isEditing', false);
      yield property.rollback();
    }).enqueue(),
    'saveAttributeSetProperty': (0, _emberConcurrency.task)(function* (property) {
      const properties = yield this.get('model.properties');
      properties.forEach(oldProperty => {
        if ((oldProperty.get('name').toLowerCase() === property.get('name').toLowerCase() || oldProperty.get('internalTag').toLowerCase() === property.get('internalTag').toLowerCase()) && !(oldProperty.get('isNew') || oldProperty.get('isDirty'))) {
          this.set('showError', true);
        }
      });

      if (/\s/.test(property.get('internalTag'))) {
        this.set('isSpace', true);
      }

      if (this.get('showError') || this.get('isSpace')) {
        property.set('isEditing', true);
      } else {
        property.set('isEditing', false);
        yield property.save();
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveAttributeSetPropertySucceeded': Ember.on('saveAttributeSetProperty:succeeded', function (taskInstance) {
      const property = taskInstance.args[0];

      if (this.get('isSpace')) {
        this.set('isSpace', false);
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('plant_works_webapp_server_server.common.contain_space_attribute_set_property_space')
        });
      } else if (this.get('showError')) {
        this.set('showError', false);
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('plant_works_webapp_server_server.common.duplicate_attribute_set_property_error')
        });
      } else {
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.common.succesful_attribute_set_property_save', {
            'htmlSafe': true,
            'displayName': property.get('name')
          })
        });
      }
    }),
    'saveAttributeSetPropertyErrored': Ember.on('saveAttributeSetProperty:errored', function (taskInstance, err) {
      const property = taskInstance.args[0];
      property.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteAttributeSetProperty': (0, _emberConcurrency.task)(function* (property) {
      const propName = yield property.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_delete_attribute_set_property'),
          'iconType': 'mdi',
          'icon': 'sprout'
        },
        'content': this.intl.t('plant_works_webapp_server_server.common.delete_attribute_set_property_message', {
          'htmlSafe': true,
          'displayName': propName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteAttributeSetProperty').perform(property, propName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteAttributeSetProperty': (0, _emberConcurrency.task)(function* (property, propertyName) {
      // eslint-disable-line no-unused-vars
      yield property.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteAttributeSetPropertySucceeded': Ember.on('_confirmedDeleteAttributeSetProperty:succeeded', function (taskInstance) {
      const propertyName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_attribute_set_property_delete', {
          'htmlSafe': true,
          'displayName': propertyName
        })
      });
    }),
    '_confirmedDeleteAttributeSetPropertyErrored': Ember.on('_confirmedDeleteAttributeSetProperty:errored', function (taskInstance, err) {
      const property = taskInstance.args[0];
      property.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});