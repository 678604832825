define("plantworks/models/manufacturing/plant-unit-machine", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "plantworks/config/environment", "ember-data-model-fragments/attributes", "ember-concurrency"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _environment, _attributes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'type': _emberData.default.attr('string', {
      'defaultValue': 'machine'
    }),
    'description': _emberData.default.attr('string'),
    'dataPersistencePeriod': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'attributeSetMetadata': (0, _attributes.fragmentArray)('manufacturing/fragments/machine-attribute-set-metadata'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnit': _emberData.default.belongsTo('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'machines'
    }),
    'machine': _emberData.default.belongsTo('masterdata/machine-master', {
      'async': true,
      'inverse': 'machines'
    }),
    'plc': _emberData.default.belongsTo('masterdata/plc-master', {
      'async': true,
      'inverse': 'machines'
    }),
    'tenantPlantUnitDriver': _emberData.default.belongsTo('manufacturing/plant-unit-driver', {
      'async': true,
      'inverse': 'machines'
    }),
    'hardwareProtocol': _emberData.default.belongsTo('masterdata/hardware-protocol-master', {
      'async': true,
      'inverse': 'machines'
    }),
    'communicationProtocol': _emberData.default.belongsTo('masterdata/communication-protocol-master', {
      'async': true,
      'inverse': 'machines'
    }),
    'attributeSets': _emberData.default.hasMany('manufacturing/plant-unit-machine-attribute-set', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'dataPointAggregations': _emberData.default.hasMany('manufacturing/plant-unit-machine-aggregate', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'templates': _emberData.default.hasMany('manufacturing/plant-unit-machine-template', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'processors': _emberData.default.hasMany('manufacturing/plant-unit-machine-processor', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'linkedStations': _emberData.default.hasMany('manufacturing/plant-unit-station-observed-machine', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'downtimes': _emberData.default.hasMany('manufacturing/plant-unit-machine-downtime', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'approvedDowntimes': _emberData.default.hasMany('manufacturing/plant-unit-machine-approved-downtime', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'setuptimes': _emberData.default.hasMany('manufacturing/plant-unit-machine-setuptime', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'idletimes': _emberData.default.hasMany('manufacturing/plant-unit-machine-idletime', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'schedules': _emberData.default.hasMany('manufacturing/plant-unit-machine-schedule', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'attachedForms': _emberData.default.hasMany('manufacturing/plant-unit-machine-attached-form', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'watchers': _emberData.default.hasMany('manufacturing/plant-unit-machine-watcher', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'events': _emberData.default.hasMany('manufacturing/plant-unit-machine-events', {
      'async': true,
      'inverse': 'tenantPlantUnitMachine'
    }),
    'uploadUrl': Ember.computed('id', function () {
      return `${window.location.protocol}://${_environment.default.plantworks.domain}/incoming/data/${this.get('id')}`;
    }),
    'path': Ember.computed('name', 'parent.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const plantUnit = yield this.get('tenantPlantUnit');
      const parentPath = yield plantUnit.get('path');
      return `${parentPath} > ${this.get('name')}`;
    }).keepLatest(),
    'currentOperator': Ember.computed('events.[]', function () {
      return this.get('_getLatestOperator').perform();
    }),
    '_getLatestOperator': (0, _emberConcurrency.task)(function* () {
      const allEvents = yield this.get('events');
      let operatorEvents = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });

      for (let idx = 0; idx < allEvents.get('length'); idx++) {
        const event = allEvents.objectAt(idx);
        const eventType = yield event.get('eventType');
        if (eventType.get('tag') === 'operator_change') operatorEvents.addObject(event);
      }

      operatorEvents = operatorEvents.sortBy('createdAt');

      if (!operatorEvents.get('length')) {
        return {};
      }

      const latestEvent = operatorEvents.get('lastObject');
      return JSON.parse(latestEvent.get('eventMetadata.value'));
    }).keepLatest()
  });

  _exports.default = _default;
});