define("plantworks/models/emd/attribute-set", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'emdAttributeSets'
    }),
    'folder': _emberData.default.belongsTo('emd/folder', {
      'async': true,
      'inverse': 'attributeSets'
    }),
    'properties': _emberData.default.hasMany('emd/attribute-set-property', {
      'async': true,
      'inverse': 'attributeSet'
    }),
    'functions': _emberData.default.hasMany('emd/attribute-set-function', {
      'async': true,
      'inverse': 'attributeSet'
    }),
    'functionObservedProperties': _emberData.default.hasMany('emd/attribute-set-function-observed-property', {
      'async': true,
      'inverse': 'attributeSet'
    }),
    'configurations': _emberData.default.hasMany('emd/configuration-attribute-set', {
      'async': true,
      'inverse': 'tenantAttributeSet'
    })
  });

  _exports.default = _default;
});