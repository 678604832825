define("plantworks/components/work-order/work-order-app/list", ["exports", "plantworks/framework/base-component", "jquery"], function (_exports, _baseComponent, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'setSelectedWorkOrderFormat': function (selectedWorkOrderFormat) {
      (0, _jquery.default)('div#work-order-app-tree-container').jstree('activate_node', selectedWorkOrderFormat.get('id'), false, false);
    }
  });

  _exports.default = _default;
});