define("plantworks/components/profile/contact-manager", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'contactTypes': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const contactTypes = yield this.get('ajax').request('/masterdata/contactTypes', {
          'method': 'GET'
        });
        this.set('contactTypes', contactTypes);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addContact': (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const newContact = store.createRecord('profile/user-contact', {
          'user': this.get('model')
        });
        const contacts = yield this.get('model.contacts');
        contacts.pushObject(newContact);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    'saveContact': (0, _emberConcurrency.task)(function* (contact) {
      try {
        yield contact.save();
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.profile_feature.contact_manager.succesful_save')
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteContact': (0, _emberConcurrency.task)(function* (contact) {
      if (contact.get('isNew')) {
        const contacts = yield this.get('model.contacts');
        contacts.removeObject(contact);
        yield contact.destroyRecord();
        return;
      }

      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.profile_feature.contact_manager.delete_modal.title'),
          'iconType': 'mdi',
          'icon': 'contacts'
        },
        'content': this.intl.t('plant_works_webapp_server_server.profile_feature.contact_manager.delete_modal.question'),
        'confirmButton': {
          'text': this.intl.t('modal.default_delete_text'),
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteContact').perform(contact);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).enqueue(),
    // verifyContact: task(function* (contact) {
    // 	if(contact.get('isNew')) {
    // 		this.get('notification').display({
    // 			'type': 'info',
    // 			'message': 'You should save the contact before verification'
    // 		});
    // 		return;
    // 	}
    // 	const modalData = {
    //		'title': {
    //			'text': 'Verify Contact'
    //		},
    // 		'content': `Are you sure you want to delete the <strong>${contact.get('contact')}</strong> contact?`,
    // 		'confirmButton': {
    // 			'text': 'Delete',
    //			'iconType': 'md',
    // 			'icon': 'delete',
    // 			'warn': true,
    // 			'raised': true,
    // 			'callback': () => {
    // 				this.get('_confirmedDeleteContact').perform(contact);
    // 			}
    // 		},
    // 		'cancelButton': {
    // 			'text': 'Cancel',
    //			'iconType': 'md',
    // 			'icon': 'close',
    // 			'primary': true,
    // 			'raised': true
    // 		}
    // 	};
    // 	yield this.invokeAction('controller-action', 'displayModal', modalData);
    // }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteContact': (0, _emberConcurrency.task)(function* (contact) {
      const contacts = yield this.get('model.contacts');

      try {
        yield contact.destroyRecord();
        contacts.removeObject(contact);
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.profile_feature.contact_manager.succesful_delete')
        });
      } catch (err) {
        contacts.addObject(contact);
        yield contact.rollback();
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});