define("plantworks/routes/profile", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('profile/user');
        this.get('store').unloadAll('profile/user-contact');
        return;
      }

      const profileData = this.get('store').peekRecord('profile/user', window.plantworksUserId);
      if (profileData) return profileData.reload();
      return this.get('store').findRecord('profile/user', window.plantworksUserId);
    },

    onUserDataUpdated() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('profile/user');
        this.get('store').unloadAll('profile/user-contact');
      }

      const isActive = this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksUserId) {
        this.transitionTo('index');
        return;
      }

      this.get('refreshProfileModel').perform();
    },

    'refreshProfileModel': (0, _emberConcurrency.task)(function* () {
      let profileData = this.get('store').peekRecord('profile/user', window.plantworksUserId);
      if (!profileData) profileData = yield this.get('store').findRecord('profile/user', window.plantworksUserId);else yield profileData.reload();
      this.get('controller').set('model', profileData);
    }).keepLatest()
  });

  _exports.default = _default;
});