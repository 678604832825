define("plantworks/routes/pug/group-manager", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      if (!window.plantworksTenantId) {
        this.get('store').unloadAll('pug/group-manager/tenant-group');
        this.get('store').unloadAll('pug/group-manager/tenant-user-group');
        this.get('store').unloadAll('pug/group-manager/tenant-group-permission');
        return;
      }

      let tenantModel = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
      if (tenantModel) return tenantModel;
      return this.get('store').findRecord('settings/account/basics/tenant', window.plantworksTenantId);
    },

    onUserDataUpdated() {
      if (!window.plantworksTenantId) {
        this.get('store').unloadAll('pug/group-manager/tenant-group');
        this.get('store').unloadAll('pug/group-manager/tenant-user-group');
        this.get('store').unloadAll('pug/group-manager/tenant-group-permission');
      }

      const isActive = this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksTenantId) {
        this.transitionTo('index');
        return;
      }

      this.get('refreshTenantGroupModel').perform();
    },

    'refreshTenantGroupModel': (0, _emberConcurrency.task)(function* () {
      let tenantModel = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
      if (!tenantModel) tenantModel = yield this.get('store').findRecord('settings/account/basics/tenant', window.plantworksTenantId);
      this.get('controller').set('model', tenantModel);
    }).keepLatest()
  });

  _exports.default = _default;
});