define("plantworks/templates/historical-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pqiRfCqi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"page-title\",[[28,\"t\",[\"plant_works_webapp_server_server.historical_dashboard_feature.title\"],null]],null],false],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flex layout-row layout-align-center-start layout-wrap\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex layout-column layout-align-start-stretch layout-wrap\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/historical-dashboard.hbs"
    }
  });

  _exports.default = _default;
});