define("plantworks/initializers/manufacturing/add-manufacturing-attribute-sets-to-tenant", ["exports", "ember-data", "plantworks/models/settings/account/basics/tenant"], function (_exports, _emberData, _tenant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _tenant.default.reopen({
      'manufacturingAttributeSets': _emberData.default.hasMany('manufacturing/attribute-set', {
        'async': true,
        'inverse': 'tenant'
      }),
      'manufacturingAttributeSetProperties': _emberData.default.hasMany('manufacturing/attribute-set-property', {
        'async': true,
        'inverse': 'tenant'
      }),
      'manufacturingAttributeSetFunctions': _emberData.default.hasMany('manufacturing/attribute-set-function', {
        'async': true,
        'inverse': 'tenant'
      }),
      'manufacturingAttributeSetFunctionObservedProperties': _emberData.default.hasMany('manufacturing/attribute-set-function-observed-property', {
        'async': true,
        'inverse': 'tenant'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});