define("plantworks/translations/es-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "database_types": {
      "ISO8601": "ISO 8601 Format",
      "RFC2822": "RFC 2822 Format",
      "admin": "Administrator",
      "api": "API",
      "authorized": "Authorized",
      "block": "block",
      "block_any": "Any Block",
      "block_some": "Some Block",
      "boolean": "Boolean",
      "component": "Component",
      "computed": "Computed",
      "count": "Count",
      "cron": "Scheduled",
      "current_day": "Current Day",
      "current_shift": "Current Shift",
      "custom": "Custom",
      "customer": "Customer",
      "date": "Date",
      "day": "Day",
      "default": "Default",
      "difference": "Difference",
      "disabled": "Disabled",
      "disconnected": "No Machine Connectivity",
      "downtime": "Downtime",
      "eight_hours": "8 Hrs",
      "email": "Email",
      "feature": "Feature",
      "fifteen_minutes": "15 Min",
      "fortnight": "Fortnight",
      "four_hours": "4 Hrs",
      "generated": "Generated",
      "holiday": "Holiday",
      "http": "HTTP",
      "idle": "Idle",
      "input": "Input",
      "landline": "Landline",
      "last_eight_hours": "Last 8 Hours",
      "last_four_hours": "Last 4 Hours",
      "last_one_hour": "Last 1 Hour",
      "last_twelve_hours": "Last 12 Hours",
      "last_twenty_four_hours": "Last 24 Hours",
      "last_two_hours": "Last 2 Hours",
      "line": "Process Line",
      "machine": "Machine",
      "maintenance": "Maintenance",
      "mean": "Mean(Average)",
      "median": "Median",
      "message": "Queued Message",
      "middleware": "Middleware",
      "mobile": "Mobile",
      "month": "Month",
      "no_data": "No Internet Connectivity",
      "not_a_timestamp": "Not a Date/Time",
      "number": "Number",
      "object": "Object",
      "observer": "Observer",
      "oem": "Original Equipment Manufacturer",
      "one_hour": "1 Hr",
      "other": "Other",
      "panel": "Realtime Dashboard",
      "post": "Post Evaluation",
      "pre": "Pre Evaluation",
      "quarter": "Quarter",
      "realtime": "Realtime",
      "scheduled_downtime": "Scheduled Downtime",
      "server": "Server",
      "service": "Service",
      "service_provider": "Service Provider",
      "setup": "Setup",
      "shift": "Shift",
      "sms": "SMS",
      "splitter": "Splitter",
      "static": "Static",
      "station": "Station",
      "string": "String",
      "template": "Template",
      "thirty_minutes": "30 Min",
      "unix_epoch": "Standard Unix Epoch",
      "unix_epoch_with_milliseconds": "Unix Epoch with Milliseconds",
      "vendor": "Vendor",
      "waiting": "Waiting",
      "week": "Week",
      "year": "Year"
    },
    "footer": {
      "copyright": "&copy;",
      "dummy": " ",
      "erkn_name": "  ERONKAN",
      "pworks_name": "PLANT.WORKS",
      "reserved_rights": "ALL RIGHTS RESERVED"
    },
    "general": {
      "connectivity_lost": "Realtime connectivity lost!",
      "connectivity_lost_with_reconnect": "Realtime connectivity lost! Attempting to reconnect!!",
      "delete_folder_message": "Are you sure you want to delete the <strong>{displayName}</strong> folder?",
      "label_accounted_duration": "Accounted",
      "label_actual_duration": "Actual Duration",
      "label_add_folder": "New Folder",
      "label_add_planned_reason": "Add Planned Reason",
      "label_admin": "Administrator",
      "label_approve_upload": "Approve",
      "label_approved": "Approved",
      "label_backend_dev": "Backend Code",
      "label_basics": "Basics",
      "label_cannot_be_timestamp": "Cannot be a timestamp",
      "label_categorized": "Categorized",
      "label_code": "Code",
      "label_comparison_operator": "Comparison Operator",
      "label_created_on": "Created",
      "label_current_date": "Current Date",
      "label_current_time": "Current Time",
      "label_current_value": "Current Value",
      "label_custom": "Custom",
      "label_date": "Date",
      "label_default": "Default",
      "label_delete_folder": "Delete Folder",
      "label_description": "Description",
      "label_download_file": "Download File",
      "label_duration": "Duration",
      "label_editor": "Editor",
      "label_enabled": "Enabled",
      "label_end_date": "End Date",
      "label_end_time": "End Time",
      "label_execute": "Execute",
      "label_expression_and_config": "Expression and Config",
      "label_false": "False",
      "label_filter": "Filter",
      "label_folder": "Folders",
      "label_formats": "Format",
      "label_frontend_dev": "Visual Design",
      "label_frontend_dev_component_add_new_observer": "Add New Observer",
      "label_frontend_dev_component_add_new_task": "Add New Task",
      "label_frontend_dev_component_after_render": "After Render",
      "label_frontend_dev_component_before_destroy": "Before Destroy",
      "label_frontend_dev_component_before_render": "Before Render",
      "label_frontend_dev_component_code": "Component Code",
      "label_frontend_dev_component_observer_property": "Observed Property",
      "label_frontend_dev_component_observers": "Observers",
      "label_frontend_dev_component_on_data": "On Data",
      "label_frontend_dev_component_on_periodic_data": "On Periodic Data",
      "label_frontend_dev_component_on_submit": "On Submit",
      "label_frontend_dev_component_task_arguments": "Arguments",
      "label_frontend_dev_component_tasks": "Tasks",
      "label_frontend_dev_template_code": "Template Code",
      "label_frontend_dev_template_preview": "Preview",
      "label_global": "Global",
      "label_init_fetch": "Display Period",
      "label_is_null": "Prevent empty",
      "label_is_primary_output": "Is Primary Output",
      "label_is_secondary_output": "Is Secondary Output",
      "label_is_show_in_dropdown": "Show In Dropdown",
      "label_is_timestamp": "Is timestamp",
      "label_is_unique_id": "Is Unique Id",
      "label_metadata": "Metadata",
      "label_name": "Name",
      "label_new": "New",
      "label_no": "No",
      "label_no_end_date": "No End Date",
      "label_none": "None",
      "label_not_applicable": "N/A",
      "label_order": "Order",
      "label_persist_period": "Storage Period (Days)",
      "label_realtime_graphs": "Realtime Graphs",
      "label_remark": "Remark",
      "label_request_period": "Data Refresh Period (Seconds)",
      "label_search": "Search",
      "label_select_line_placeholder": "Select Line(s)",
      "label_select_lines": "Line(s)",
      "label_select_machine_placeholder": "Select Machine(s)",
      "label_select_machines": "Machine(s)",
      "label_select_operator_placeholder": "Select Operator(s)",
      "label_select_operators": "Operator(s)",
      "label_select_plant_placeholder": "Select Plant(s)",
      "label_select_plants": "Plant(s)",
      "label_select_process_placeholder": "Select Process(es)",
      "label_select_processes": "Process(es)",
      "label_select_shift_placeholder": "Select Shift",
      "label_select_sku_placeholder": "Select SKU(s)",
      "label_select_skus": "SKU(s)",
      "label_select_supervisor_placeholder": "Select Supervisor(s)",
      "label_select_supervisors": "Supervisor(s)",
      "label_select_unit_placeholder": "Select Unit(s)",
      "label_select_units": "Unit(s)",
      "label_select_work_order_placeholder": "Select Work Order(s)",
      "label_select_work_orders": "Work Order(s)",
      "label_selector_component": "Selector Component",
      "label_serial_order": "#",
      "label_start_date": "Start Date",
      "label_start_time": "Start Time",
      "label_step": "Order",
      "label_step_description": "Step Description",
      "label_step_no": "Step No",
      "label_sub_class": "Subclass",
      "label_submit": "Submit",
      "label_subscribed": "Subscribed",
      "label_tab": "Tabbed",
      "label_table": "Tabled",
      "label_true": "True",
      "label_type": "Type",
      "label_type_and_formats": "Type and Format",
      "label_types": "Types",
      "label_uncategorized": "Uncategorized",
      "label_unsubscribed": "Unsubscribed",
      "label_updated_on": "Updated",
      "label_upload_file": "Upload File",
      "label_user": "User",
      "label_value": "Value",
      "label_version": "Version",
      "label_yes": "Yes",
      "new_folder_name": "Folder {now}",
      "no_data_on_this_period_message": "No data for selected period",
      "start_date_greater_than_end_date_error_message": "End Date can not be less than Start Date",
      "succesful_folder_delete": "<strong>{displayName}</strong> succesfully deleted",
      "succesful_folder_save": "<strong>{displayName}</strong> succesfully saved"
    },
    "logo": {
      "alt": "Plant.Works Logo"
    },
    "modal": {
      "default_add_text": "Add",
      "default_apply_text": "Apply",
      "default_cancel_text": "Cancel",
      "default_change_text": "Change",
      "default_clear_text": "Clear",
      "default_close_text": "Close",
      "default_content": "Default content! Has to be overridden!!",
      "default_create_text": "Create",
      "default_custom_text": "Custom",
      "default_delete_text": "Delete",
      "default_edit_text": "Edit",
      "default_ok_text": "Ok",
      "default_save_text": "Save",
      "default_submit_text": "Submit",
      "default_title": "Plant.Works Modal",
      "default_today_text": "Today",
      "default_yesterday_text": "Yesterday",
      "multiple_error": "Cannot display multiple modal dialogs!!",
      "publish_save_text": "Save & Publish",
      "publish_text": "Publish",
      "unpublish_text": "Unpublish"
    },
    "plant_works_webapp_server_server": {
      "alert_feature": {
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} alert",
        "delete_alert_message": "Are you sure you want to delete the <strong>{displayName}</strong> alert?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{alertName}</strong> alert?",
        "description": "Configure Alerts",
        "folder_names": {
          "alerts": {
            "description": "Root container for all Alerts",
            "name": "Alerts"
          },
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Alerts feature",
            "name": "Data Sets"
          },
          "root": {
            "name": "Alerts"
          }
        },
        "label_add_alert": "New Alert",
        "label_add_users": "Add Watchers",
        "label_alert": "Alerts",
        "label_alert_add_line": "Add Line",
        "label_alert_add_machine": "Add Machine",
        "label_alert_add_panel": "Add Realtime Panel",
        "label_alert_current": "Current Alert",
        "label_alert_input_data_map": "Incoming Data Configuration",
        "label_alert_mqtt_topic": "MQTT Topic",
        "label_alert_mqtt_topic_placeholder": "Leave this empty to use default topic",
        "label_alert_observed_entities": "Tracked Entities",
        "label_alert_ttl": "TTL (minutes)",
        "label_delete_alert": "Delete Alert",
        "label_delete_user": "Remove Watcher",
        "label_email_distribution_list": "Send Email Alerts",
        "label_entity_type": "Entity Type",
        "label_message_distribution_list": "Send Message Alerts",
        "label_on_data": "On Data",
        "label_on_event": "On Event",
        "label_processed_data_transform": "Processed Data Transform",
        "label_response_formatter": "Response Formatter",
        "label_response_formatter_type_api": "API Response Formatter",
        "label_response_formatter_type_email": "Email Response Formatter",
        "label_response_formatter_type_http": "HTTP Response Formatter",
        "label_response_formatter_type_message": "Message Response Formatter",
        "label_response_formatter_type_mqtt": "MQTT Response Formatter",
        "label_response_formatter_type_sms": "SMS Response Formatter",
        "label_response_formatter_type_telegram": "Telegram Response Formatter",
        "label_response_types": "Response Types",
        "label_sms_distribution_list": "Send SMS Alerts",
        "label_tag": "Configure Tag Here",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_users": "Watchers",
        "new_alert_name": "Alert {now}",
        "permission": {
          "alert_create": {
            "description": "Access to create alerts",
            "name": "Create Permission"
          },
          "alert_delete": {
            "description": "Access to delete alerts",
            "name": "Delete Permission"
          },
          "alert_read": {
            "description": "View-only permissions for alerts",
            "name": "Read Permission"
          },
          "alert_update": {
            "description": "Access to update alerts",
            "name": "Update Permission"
          },
          "alert_watch": {
            "description": "Access alerts in production and watch the realtime data flow",
            "name": "View Permission"
          }
        },
        "succesful_alert_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_alert_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_watcher_update": "Watcher updated succesfully",
        "title": "Alerts"
      },
      "board_feature": {
        "add_subcomponent": "Add Subcomponent",
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} board",
        "delete_panel_message": "Are you sure you want to delete the <strong>{displayName}</strong> panel?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{panelName}</strong> board?",
        "description": "Configure Boards for displaying Realtime data",
        "edit_main_component": "Edit Main Component",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Boards feature",
            "name": "Data Sets"
          },
          "panels": {
            "description": "Root container for all Board Panel configurations",
            "name": "Panels"
          },
          "root": {
            "name": "Boards"
          }
        },
        "label_add_panel": "New Panel",
        "label_add_users": "Add Watchers",
        "label_delete_panel": "Delete Panel",
        "label_delete_user": "Remove Watcher",
        "label_entity_type": "Entity Type",
        "label_executor": "Main Processor",
        "label_executor_env": "Request Executor Code",
        "label_main_component": "Main Component",
        "label_panel": "Realtime Panels",
        "label_panel_add_line": "Add Line",
        "label_panel_add_machine": "Add Machine",
        "label_panel_add_panel": "Add Panel",
        "label_panel_add_station": "Add Station",
        "label_panel_current": "Current Panel",
        "label_panel_input_data_map": "Incoming Data Configuration",
        "label_panel_name": "Panel Name",
        "label_panel_observed_entities": "Tracked Entities",
        "label_request_formatter": "Input Formatter",
        "label_response_formatter": "Response Formatter",
        "label_subcomponents": "Subcomponents",
        "label_tag": "Configure Tag Here",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_users": "Watchers",
        "new_panel_name": "Panel {now}",
        "permission": {
          "board_create": {
            "description": "Access to create boards",
            "name": "Create Permission"
          },
          "board_delete": {
            "description": "Access to delete boards",
            "name": "Delete Permission"
          },
          "board_read": {
            "description": "View-only permissions for boards",
            "name": "Read Permission"
          },
          "board_update": {
            "description": "Access to update boards",
            "name": "Update Permission"
          },
          "board_watch": {
            "description": "Access boards in production and watch the realtime data flow",
            "name": "View Permission"
          }
        },
        "succesful_panel_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_panel_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_panel_subcomponent_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_panel_subcomponent_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "title": "Boards"
      },
      "common": {
        "contain_space_attribute_set_property_space": "The Tag should not contain space",
        "delete_attribute_set_message": "Are you sure you want to delete the <strong>{displayName}</strong> data set?",
        "delete_attribute_set_observerfunc_message": "Are you sure you want to delete the <strong>{displayName}</strong> data set observer function?",
        "delete_attribute_set_postfunc_message": "Are you sure you want to delete the <strong>{displayName}</strong> data set after function?",
        "delete_attribute_set_prefunc_message": "Are you sure you want to delete the <strong>{displayName}</strong> data set before function?",
        "delete_attribute_set_property_message": "Are you sure you want to delete the <strong>{displayName}</strong> data set property?",
        "delete_data_point_aggregate_message": "Are you sure you want to delete the <strong>{displayName}</strong> data point aggregate?",
        "duplicate_attribute_set_property_error": "Name or Tag Already Exists",
        "label_add_attribute_set": "New Data Set",
        "label_add_attribute_set_observerfunc": "New Data Set Observer Function",
        "label_add_attribute_set_observerfunc_observed_property": "Add Observed Properties",
        "label_add_attribute_set_postfunc": "New Data Set After Function",
        "label_add_attribute_set_prefunc": "New Data Set Before Function",
        "label_add_attribute_set_property": "New Data Set Property",
        "label_add_data_point_aggregate": "Add Aggregate",
        "label_add_data_set": "Add Data Set",
        "label_aggregation": "Aggregation",
        "label_attribute_set": "Data Sets",
        "label_attribute_set_observerfunc": "Data Set Observer Functions",
        "label_attribute_set_postfunc": "Data Set After Functions",
        "label_attribute_set_prefunc": "Data Set Before Functions",
        "label_attribute_set_property": "Data Set Properties",
        "label_attribute_set_property_datatype": "Value Type",
        "label_attribute_set_property_expression": "Expression",
        "label_attribute_set_property_index": "Index",
        "label_attribute_set_property_source": "Source",
        "label_attribute_set_property_static_value": "Static Value",
        "label_attribute_set_property_tag": "Tag",
        "label_attribute_set_property_timestamp_format": "Date/Time Format",
        "label_attribute_set_property_units": "Units",
        "label_data_point_aggregate_expression": "Expression",
        "label_data_point_aggregate_period": "Aggregation Period",
        "label_data_point_aggregate_type": "Aggregation Type",
        "label_data_point_aggregations": "Data Point Aggregations",
        "label_delete_attribute_set": "Delete Data Set",
        "label_delete_attribute_set_observerfunc": "Delete Data Set Observer Function",
        "label_delete_attribute_set_observerfunc_observed_property": "Observed Properties",
        "label_delete_attribute_set_postfunc": "Delete Data Set After Function",
        "label_delete_attribute_set_prefunc": "Delete Data Set Before Function",
        "label_delete_attribute_set_property": "Delete Data Set Property",
        "label_delete_data_point_aggregate": "Delete Data Point Aggregate",
        "label_edit_attribute_set_observerfunc": "Edit Observer Function",
        "label_edit_attribute_set_postfunc": "Edit After Function",
        "label_edit_attribute_set_prefunc": "Edit Before Function",
        "label_edit_attribute_set_property": "Edit Property",
        "label_edit_data_point_aggregate": "Edit Aggregate",
        "label_line": "Line",
        "label_machine": "Machine",
        "label_operator": "Operator",
        "label_process": "Process",
        "label_sku": "SKU",
        "label_supervisor": "Supervisor",
        "label_work_order": "Work Order",
        "new_attribute_set_name": "Data Set {now}",
        "new_attribute_set_observerfunc_name": "Data Set Observer Function {now}",
        "new_attribute_set_postfunc_name": "Data Set After Function {now}",
        "new_attribute_set_prefunc_name": "Data Set Before Function {now}",
        "new_attribute_set_property_name": "Data Set Property {now}",
        "new_data_point_aggregate_name": "Data Point Aggregate {now}",
        "succesful_attribute_set_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_attribute_set_observerfunc_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_attribute_set_observerfunc_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_attribute_set_postfunc_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_attribute_set_postfunc_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_attribute_set_prefunc_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_attribute_set_prefunc_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_attribute_set_property_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_attribute_set_property_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_attribute_set_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_data_point_aggregate_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_data_point_aggregate_save": "<strong>{displayName}</strong> succesfully saved"
      },
      "configure_feature": {
        "alert": {
          "description": "Configure Alerts",
          "title": "Alerts"
        },
        "board": {
          "description": "Configure Boards for displaying Realtime data",
          "title": "Board"
        },
        "description": "Configuration for all Features subscribed to by a Tenant",
        "emd": {
          "description": "Configure External Master Data Upload Formats",
          "title": "External Master Data"
        },
        "event_alert": {
          "description": "Configure Event Alerts",
          "title": "Event Alerts"
        },
        "historical_dashboard": {
          "description": "Develop Historical Dashboards for interacting with historical data",
          "title": "Historical Dashboards"
        },
        "label_menu": "Menu",
        "manufacturing": {
          "description": "Configure Plant/Units, and the Machines/Stations/Lines attached to them",
          "title": "Manufacturing"
        },
        "operator_form": {
          "description": "Develop Operator Forms for digitally capturing logbook data",
          "title": "Operator Forms"
        },
        "permission": {
          "config_access": {
            "description": "Access to modify configurations for the account",
            "name": "Configure Access"
          }
        },
        "report": {
          "description": "Configure Reports for displaying historical data",
          "title": "Report"
        },
        "title": "Configure",
        "work_order": {
          "description": "Configure work orders",
          "title": "Work Orders"
        }
      },
      "dashboard_feature": {
        "application": "Application",
        "description": "Dashboard Feature",
        "main_component": {
          "administration": "Admin",
          "applications": "Applications",
          "historical_dashboards": "Historical",
          "manufacturing_stations": "Stations",
          "realtime_dashboards": "Realtime",
          "reports": "Reports"
        },
        "title": "Dashboard"
      },
      "description": "The frontend portal for the entire Plant.Works Web Application Server Platform",
      "devenv_feature": {
        "alert": {
          "description": "Develop Alerts for generating notifications",
          "title": "Alerts"
        },
        "board": {
          "description": "Develop Boards for displaying Realtime data",
          "title": "Board"
        },
        "description": "Development Environment for all Features subscribed to by a Tenant",
        "emd": {
          "description": "environment to author code/functions that will operate when master data is uploaded",
          "title": "Emd"
        },
        "event_alert": {
          "description": "Develop Event Alerts for generating notifications",
          "title": "Event Alerts"
        },
        "historical_dashboard": {
          "description": "Develop Historical Dashboards for interacting with historical data",
          "title": "Historical Dashboards"
        },
        "label_menu": "Menu",
        "manufacturing": {
          "description": "Develop code/functions that will operate on incoming data",
          "title": "Manufacturing"
        },
        "operator_form": {
          "description": "Develop Operator Forms for digitally capturing logbook data",
          "title": "Operator Forms"
        },
        "permission": {
          "devenv_access": {
            "description": "Access to modify developed artifacts for the account",
            "name": "Development Environment Access"
          }
        },
        "report": {
          "description": "Develop Reports for displaying historical data",
          "title": "Report"
        },
        "title": "IDE",
        "work_order": {
          "description": "environment to author code/functions that will operate when work orders are uploaded",
          "title": "Work Order"
        }
      },
      "emd_feature": {
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} Emd",
        "delete_emd_configuration_message": "Are you sure you want to delete the <strong>{displayName}</strong> emd upload configuration?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{emdName}</strong> Emd?",
        "description": "Configure External Master Data Upload Formats",
        "emd_file_upload_preview": "Master Data File Preview",
        "emd_row_value_change_message": "<b>{name}</b> Changed From <b>{oldValue}</b> To <b>{newValue}</b>",
        "emd_row_value_reject_deactivate_row": "Deactivate",
        "emd_row_value_reject_message": "<b>{name}</b> can't be empty",
        "emd_row_value_reject_unique_message": "<b>{name}</b> should be unique",
        "error_emd_file_upload": "The file should not contain extra or less column than configured",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the External Master Data configuration feature",
            "name": "Data Sets"
          },
          "emd_configurations": {
            "description": "Root container for all External Master Data configurations",
            "name": "Upload Configurations"
          },
          "root": {
            "name": "External Master Data"
          }
        },
        "label_add_emd_configuration": "New EMD Upload Configuration",
        "label_add_emd_configuration_attribute_set": "Add Emd Configuration Attribute Set",
        "label_add_users": "Add Watchers",
        "label_delete_emd_configuration": "Delete EMD Configuration",
        "label_delete_emd_configuration_attribute_set": "Delete Emd Configuration Attribute Set",
        "label_delete_user": "Remove Watcher",
        "label_emd_configuration": "EMD Upload Configurations",
        "label_emd_configuration_attribute_sets": "Emd Configuration Attribute Sets",
        "label_emd_configuration_observerfunc": "Emd Configuration Observer Functions",
        "label_emd_configuration_operation": "Select operation",
        "label_emd_configuration_postfunc": "Emd Configuration After Functions",
        "label_emd_configuration_prefunc": "Emd Configuration Before Functions",
        "label_emd_configuration_property": "Emd Configuration Properties",
        "label_emd_row_status": "STATUS",
        "label_emd_row_status_new": "New",
        "label_emd_row_status_no_change": "No Change",
        "label_emd_row_status_rejected": "Rejected",
        "label_emd_row_status_update": "Update",
        "label_processed_data_transformer": "Processed Data Transform",
        "label_raw_data_transformer": "Raw Data Transform",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "new_emd_configuration_name": "Emd Upload Configuration {now}",
        "permission": {
          "emd_create": {
            "description": "Access to create external master data artifacts",
            "name": "Create Permission"
          },
          "emd_delete": {
            "description": "Access to delete external master data artifacts",
            "name": "Delete Permission"
          },
          "emd_read": {
            "description": "View-only permissions for external master data artifacts",
            "name": "Read Permission"
          },
          "emd_update": {
            "description": "Access to update external master data artifacts",
            "name": "Update Permission"
          }
        },
        "succesful_emd_configuration_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_emd_configuration_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_emd_file_upload": "<strong>{displayName}</strong> File Upload Successful",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "title": "External Master Data"
      },
      "event_alert_feature": {
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} event alert",
        "delete_event_alert_message": "Are you sure you want to delete the <strong>{displayName}</strong> event alert?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{eventAlertName}</strong> event alert?",
        "description": "Configure Event Alerts",
        "folder_names": {
          "event_alerts": {
            "description": "Root container for all Event Alerts",
            "name": "Event Alerts"
          },
          "root": {
            "name": "Event Alerts"
          }
        },
        "label_add_event_alert": "New Event Alert",
        "label_add_users": "Add Watchers",
        "label_delete_event_alert": "Delete Event Alert",
        "label_delete_user": "Remove Watcher",
        "label_email_distribution_list": "Send Email Event Alerts",
        "label_entity_type": "Entity Type",
        "label_event_alert": "Event Alerts",
        "label_event_alert_add_line": "Add Line",
        "label_event_alert_add_machine": "Add Machine",
        "label_event_alert_current": "Current Event Alert",
        "label_event_alert_input_data_map": "Incoming Data Configuration",
        "label_event_alert_mqtt_topic": "MQTT Topic",
        "label_event_alert_mqtt_topic_placeholder": "Leave this empty to use default topic",
        "label_event_alert_observed_entities": "Tracked Entities",
        "label_message_distribution_list": "Send Message Event Alerts",
        "label_on_data": "On Data",
        "label_on_event": "On Event",
        "label_processed_data_transform": "Processed Data Transform",
        "label_response_formatter": "Response Formatter",
        "label_response_formatter_type_api": "API Response Formatter",
        "label_response_formatter_type_email": "Email Response Formatter",
        "label_response_formatter_type_http": "HTTP Response Formatter",
        "label_response_formatter_type_message": "Message Response Formatter",
        "label_response_formatter_type_mqtt": "MQTT Response Formatter",
        "label_response_formatter_type_sms": "SMS Response Formatter",
        "label_response_formatter_type_telegram": "Telegram Response Formatter",
        "label_response_types": "Response Types",
        "label_sms_distribution_list": "Send SMS Event Alerts",
        "label_tag": "Configure Tag Here",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_users": "Watchers",
        "new_event_alert_name": "Event Alert {now}",
        "permission": {
          "event_alert_create": {
            "description": "Access to create event alerts",
            "name": "Create Permission"
          },
          "event_alert_delete": {
            "description": "Access to delete event alerts",
            "name": "Delete Permission"
          },
          "event_alert_read": {
            "description": "View-only permissions for event alerts",
            "name": "Read Permission"
          },
          "event_alert_update": {
            "description": "Access to update event alerts",
            "name": "Update Permission"
          },
          "event_alert_watch": {
            "description": "Access event alerts in production and watch the realtime data flow",
            "name": "View Permission"
          }
        },
        "succesful_event_alert_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_event_alert_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_watcher_update": "Watcher updated succesfully",
        "title": "Event Alerts"
      },
      "historical_dashboard_feature": {
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} historical dashboard",
        "delete_historical_dashboard_message": "Are you sure you want to delete the <strong>{displayName}</strong> historical dashboard?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{historicalDashboardName}</strong> historical dashboard?",
        "description": "Configure Dashboards for displaying historical data",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Historical Dashboards feature",
            "name": "Data Sets"
          },
          "historical_dashboards": {
            "description": "Root container for all Historical Dashboard configurations",
            "name": "Historical Dashboards"
          },
          "root": {
            "name": "Historical Dashboards"
          }
        },
        "label_add_historical_dashboard": "New Historical Dashboard",
        "label_add_users": "Add Watchers",
        "label_delete_historical_dashboard": "Delete Historical Dashboard",
        "label_delete_user": "Remove Watcher",
        "label_entity_type": "Entity Type",
        "label_executor": "Main Processor",
        "label_executor_env": "Request Executor Code",
        "label_historical_dashboard": "Historical Dashboards",
        "label_historical_dashboard_add_line": "Add Line",
        "label_historical_dashboard_add_machine": "Add Machine",
        "label_historical_dashboard_add_station": "Add Station",
        "label_historical_dashboard_current": "Current Historical Dashboard",
        "label_historical_dashboard_input_data_map": "Incoming Data Configuration",
        "label_historical_dashboard_name": "Historical Dashboard Name",
        "label_historical_dashboard_observed_entities": "Tracked Entities",
        "label_input_component": "Input Component",
        "label_request_formatter": "Input Formatter",
        "label_response_formatter": "Response Formatter",
        "label_result_component": "Result Display Component",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_users": "Watchers",
        "new_historical_dashboard_name": "Historical Dashboard {now}",
        "permission": {
          "historical_dashboard_create": {
            "description": "Access to create historical dashboards",
            "name": "Create Permission"
          },
          "historical_dashboard_delete": {
            "description": "Access to delete historical dashboards",
            "name": "Delete Permission"
          },
          "historical_dashboard_read": {
            "description": "View-only permissions for historical dashboards",
            "name": "Read Permission"
          },
          "historical_dashboard_update": {
            "description": "Access to update historical dashboards",
            "name": "Update Permission"
          },
          "historical_dashboard_watch": {
            "description": "Access historical dashboards in production and execute them",
            "name": "View Permission"
          }
        },
        "succesful_historical_dashboard_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_historical_dashboard_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_historical_dashboard_subcomponent_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_historical_dashboard_subcomponent_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "title": "Historical Dashboard"
      },
      "manufacturing_feature": {
        "add_line_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} line",
        "add_machine_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} machine",
        "add_master_data_filter": "Add Filter",
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} station",
        "add_work_order_format": "Add Work Order Format",
        "bulk_uploader_desc1": "The downtime schedule file must be in XLSX format as per the established guidelines.",
        "bulk_uploader_desc2": "If the XLSX has any missing information, i.e if any column is missing or empty, then the file will be rejected.",
        "bulk_uploader_desc3": "Please make sure that the XLSX has no empty rows",
        "bulk_uploader_error": "There is no proper data found. Please Re-Check the excel file and try again.",
        "bulk_uploader_notes": "Notes",
        "delete_block_message": "Are you sure you want to delete this block",
        "delete_line_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{lineName}</strong> line?",
        "delete_machine_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{machineName}</strong> machine?",
        "delete_plant_message": "Are you sure you want to delete the <strong>{displayName}</strong> plant?",
        "delete_plant_sub_unit_message": "Are you sure you want to delete the <strong>{displayName}</strong> sub unit?",
        "delete_plant_unit_driver_message": "Are you sure you want to delete the <strong>{displayName}</strong> IoT gateway?",
        "delete_plant_unit_line_message": "Are you sure you want to delete the <strong>{displayName}</strong> line?",
        "delete_plant_unit_machine_message": "Are you sure you want to delete the <strong>{displayName}</strong> machine?",
        "delete_plant_unit_message": "Are you sure you want to delete the <strong>{displayName}</strong> unit?",
        "delete_plant_unit_station_message": "Are you sure you want to delete the <strong>{displayName}</strong> station?",
        "delete_schedule_extension_message": "Are you sure you want to delete the <strong>{displayName}</strong> schedule extension?",
        "delete_schedule_message": "Are you sure you want to delete the <strong>{displayName}</strong> schedule?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{stationName}</strong> station?",
        "description": "Configure and manage attribute sets, plant, plant units, gateways, lines, machine, stations, and other artifacts related to the manufacturing floor",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Manufacturing configuration feature",
            "name": "Data Sets"
          },
          "plants": {
            "description": "Root container for all Plants and their Sub-units, lines, gateways, etc. used by the Manufacturing configuration feature",
            "name": "Plants"
          },
          "root": {
            "name": "Manufacturing"
          }
        },
        "label_add_downtime": "New Downtime",
        "label_add_holiday": "New Holiday",
        "label_add_maintenance": "New Maintenance",
        "label_add_plant": "New Plant",
        "label_add_plant_sub_unit": "New Sub Unit",
        "label_add_plant_unit": "New Plant Unit",
        "label_add_plant_unit_driver": "New IoT Gateway",
        "label_add_plant_unit_line": "New Line",
        "label_add_plant_unit_machine": "New Machine",
        "label_add_plant_unit_station": "New Station",
        "label_add_schedule": "New Schedule",
        "label_add_shift": "New Shift/Day",
        "label_add_shift_extension": "New Shift Extension",
        "label_add_users": "Add Watchers",
        "label_authorize": "Authorize",
        "label_authorize_message": "Authorizing...",
        "label_bulk_uploader": "Bulk Uploader",
        "label_day": "Day",
        "label_delete_block": "Delete Block",
        "label_delete_plant": "Delete Plant",
        "label_delete_plant_sub_unit": "Delete Sub Unit",
        "label_delete_plant_unit": "Delete Unit",
        "label_delete_plant_unit_driver": "Delete IoT Gateway",
        "label_delete_plant_unit_driver_alert": "You can't delete <strong>{displayName}</strong> because machines are configured in this  IoT Gateway ",
        "label_delete_plant_unit_line": "Delete Line",
        "label_delete_plant_unit_machine": "Delete Machine",
        "label_delete_plant_unit_station": "Delete Station",
        "label_delete_schedule": "Delete Schedule",
        "label_delete_schedule_extension": "Delete Schedule Extension",
        "label_delete_user": "Remove Watcher",
        "label_downtime_list": "Downtime List",
        "label_downtime_list_filters": "Downtime List Filters",
        "label_entity_type": "Type",
        "label_extension_date": "Extension Date",
        "label_extension_duration": "Extension Duration",
        "label_holiday": "Holiday",
        "label_idletime_list_filters": "Idle Time List Filters",
        "label_is_first_shift": "Is First Shift",
        "label_line_add_block": "Add Block",
        "label_line_add_line": "Add Line",
        "label_line_add_machine": "Add Machine",
        "label_line_create_block": "Create Block",
        "label_line_officer": "Line Officer",
        "label_line_supervisor": "Line Supervisor",
        "label_machine_connected_since": "Machine Connected Since",
        "label_machine_disconnected_since": "Machine Disconncted Since",
        "label_machine_operator": "Machine Operator",
        "label_maintenance": "Maintenance",
        "label_maintenance_uploader": "Maintenance Uploader",
        "label_manufacturing_observed_entities": "Tracked Entities",
        "label_master_data_filter": "Filters",
        "label_operator": "Operator",
        "label_operator_list": "Operator List",
        "label_planned_downtime": "Planned Downtime",
        "label_planned_downtime_uploader": "Planned Downtime Uploader",
        "label_plant": "Manufacturing Plants",
        "label_plant_location": "Location",
        "label_plant_name": "Plant Name",
        "label_plant_sub_unit": "Sub Units",
        "label_plant_unit": "Plant Units",
        "label_plant_unit_block": "Block",
        "label_plant_unit_block_any": "Any Block",
        "label_plant_unit_block_some": "Some Block",
        "label_plant_unit_current": "Current Plant Unit",
        "label_plant_unit_driver": "IoT Gateways",
        "label_plant_unit_driver_current": "Current IoT Gateway",
        "label_plant_unit_driver_details": "Gateway",
        "label_plant_unit_driver_gateway": "Hardware",
        "label_plant_unit_driver_gateway_add_machine": "Add Machines",
        "label_plant_unit_driver_gateway_add_machine_notes": "You Have To Select Hardware To Add Machines",
        "label_plant_unit_driver_gateway_bidirectional": "Bi-Directional",
        "label_plant_unit_driver_gateway_communication_protocol": "Cloud Protocols",
        "label_plant_unit_driver_gateway_hardware_protocol": "Machine Protocols",
        "label_plant_unit_driver_gateway_manufacturer": "Gateway Manufacturer",
        "label_plant_unit_line": "Lines",
        "label_plant_unit_line_add_entity": "Add Entity",
        "label_plant_unit_line_add_form": "Add Operator Forms",
        "label_plant_unit_line_add_line": "Add Line",
        "label_plant_unit_line_add_machine": "Add Machine",
        "label_plant_unit_line_alert_definition": "Alert Definitions",
        "label_plant_unit_line_attached_forms": "Digitization Forms",
        "label_plant_unit_line_block_entities": "Block Entities",
        "label_plant_unit_line_constituent_block": "Block",
        "label_plant_unit_line_constituent_line": "Line",
        "label_plant_unit_line_constituent_machine": "Machine",
        "label_plant_unit_line_input_data_map": "Incoming Data Configuration",
        "label_plant_unit_line_observed_entities": "Tracked Machines/Processes",
        "label_plant_unit_line_oee_calculation": "OEE Calculations",
        "label_plant_unit_line_oee_calculation_availability": "Availability",
        "label_plant_unit_line_oee_calculation_performance": "Performance",
        "label_plant_unit_line_oee_calculation_quality": "Quality",
        "label_plant_unit_machine": "Machines",
        "label_plant_unit_machine_add_driver": "Add Driver/Gateway details",
        "label_plant_unit_machine_add_form": "Add Operator Forms",
        "label_plant_unit_machine_add_machine": "Add OEM Machine details",
        "label_plant_unit_machine_add_plc": "Add PLC/Controller details",
        "label_plant_unit_machine_alert_definition": "Alert Definitions",
        "label_plant_unit_machine_attached_forms": "Digitization Forms",
        "label_plant_unit_machine_category": "Category",
        "label_plant_unit_machine_changeover_time": "Changeover Times",
        "label_plant_unit_machine_current": "Current Machine",
        "label_plant_unit_machine_details": "Machine",
        "label_plant_unit_machine_downtime": "Downtimes",
        "label_plant_unit_machine_downtime_type_alarm_based": "Alarm Based",
        "label_plant_unit_machine_downtime_type_operator_based": "Operator Stop",
        "label_plant_unit_machine_driver_details": "Driver",
        "label_plant_unit_machine_firmware": "Firmware Version",
        "label_plant_unit_machine_hardware": "Hardware",
        "label_plant_unit_machine_idletime": "Idletimes",
        "label_plant_unit_machine_manufacturer": "Machine Manufacturer",
        "label_plant_unit_machine_model": "Model",
        "label_plant_unit_machine_oee_calculation": "OEE Calculations",
        "label_plant_unit_machine_oee_calculation_availability": "Availability",
        "label_plant_unit_machine_oee_calculation_performance": "Performance",
        "label_plant_unit_machine_oee_calculation_quality": "Quality",
        "label_plant_unit_machine_perform_calcstat_parameter": "Perform Calcstat",
        "label_plant_unit_machine_plc_category": "PLC/Controller Category",
        "label_plant_unit_machine_plc_details": "PLC/Controller",
        "label_plant_unit_machine_plc_manufacturer": "PLC/Controller Manufacturer",
        "label_plant_unit_machine_plc_model": "PLC/Controller Model",
        "label_plant_unit_machine_speed_deviation": "Speed Deviations",
        "label_plant_unit_machine_speed_deviation_parameter": "Speed Deviation Measure",
        "label_plant_unit_machine_upload_url": "Upload URL",
        "label_plant_unit_station": "Stations",
        "label_plant_unit_station_add_form": "Add Operator Forms",
        "label_plant_unit_station_attached_forms": "Digitization Forms",
        "label_plant_unit_station_coke_work_order_form_1": "Coke Operator Form",
        "label_plant_unit_station_coke_work_order_form_2": "Coke Operator Form 2",
        "label_plant_unit_station_coke_work_order_form_3": "Coke Operator Form 3",
        "label_plant_unit_station_coke_work_order_form_4": "Coke Operator Form 4",
        "label_plant_unit_station_coke_work_order_form_5": "Coke Operator Form 5",
        "label_plant_unit_station_current_work_order": "Current Production Orders",
        "label_plant_unit_station_marico_work_order_form": "Marico Work Order Form",
        "label_plant_unit_station_observed_line_supervisor": "LINE SUPERVISOR",
        "label_plant_unit_station_observed_lines": "Tracked Lines",
        "label_plant_unit_station_observed_machine_operator": "MACHINE OPERATOR",
        "label_plant_unit_station_observed_machines": "Tracked Machines",
        "label_plant_unit_station_snacks_incharge_form": "Snacks Incharge Form",
        "label_plant_unit_station_snacks_operator_form": "Snacks Operator Form",
        "label_plant_unit_station_upcoming_work_order": "Upcoming Production Orders",
        "label_plant_unit_station_work_order_admin_form": "Work Order Admin Form",
        "label_plant_unit_station_work_orders": "Work Order Operations",
        "label_plant_unit_station_zydus_work_order_form": "Zydus Work Order Admin Form",
        "label_plant_unit_station_zydus_work_orders": "Zydus Work Order Operations",
        "label_pre_realtime_transformer": "Display Transform",
        "label_processed_data_transformer": "Processed Data Transform",
        "label_raw_data_transformer": "Raw Data Transform",
        "label_schedule": "Schedule",
        "label_scheduled_downtime": "Scheduled Downtime",
        "label_scheduled_downtime_uploader": "Scheduled Downtime Uploader",
        "label_select_line": "Select Line",
        "label_select_machine": "Select Machine",
        "label_selected_line": "Selected Line",
        "label_selected_machine": "Selected Machine",
        "label_setuptime_list_filters": "Setup Time List Filters",
        "label_shift": "Shift",
        "label_shift_extension": "Shift Extension",
        "label_show_warning_of_no_upcoming_work_orders": "There are no upcoming work orders currently, Please upload next work orders!",
        "label_show_warning_of_no_work_order_running": "There is No running work order currently, Please select next work order!",
        "label_speed_deviation_list": "Speed Deviation List",
        "label_supervisor_list": "Supervisor List",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_line_access": "The user doesn't have access to any of the lines",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_users": "Watchers",
        "label_work_order_format": "Work Order Format",
        "new_holiday_name": "Holiday {now}",
        "new_maintenance_name": "Maintenance {now}",
        "new_planned_downtime_name": "Planned Downtime {now}",
        "new_plant_name": "Plant {now}",
        "new_plant_sub_unit_name": "Sub Unit {now}",
        "new_plant_unit_driver_name": "IoT Gateway {now}",
        "new_plant_unit_line_name": "Line {now}",
        "new_plant_unit_machine_name": "Machine {now}",
        "new_plant_unit_name": "Plant Unit {now}",
        "new_plant_unit_station_name": "Station {now}",
        "new_scheduled_downtime_name": "Scheduled Downtime {now}",
        "new_shift_name": "Shift {now}",
        "permission": {
          "manufacturing_create": {
            "description": "Access to create manufacturing artifacts",
            "name": "Create Permission"
          },
          "manufacturing_delete": {
            "description": "Access to delete manufacturing artifacts",
            "name": "Delete Permission"
          },
          "manufacturing_read": {
            "description": "View-only permissions for manufacturing artifacts",
            "name": "Read Permission"
          },
          "manufacturing_update": {
            "description": "Access to update manufacturing artifacts",
            "name": "Update Permission"
          }
        },
        "schedule_save_time_validate": "Start Time should be less than end Time",
        "succesful_block_save": "block successfully saved",
        "succesful_constituent_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_constituent_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_manufacturing_block_delete": "block successfully deleted",
        "succesful_plant_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_plant_sub_unit_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_sub_unit_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_plant_unit_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_unit_driver_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_unit_driver_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_plant_unit_line_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_unit_line_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_plant_unit_machine_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_unit_machine_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_plant_unit_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_plant_unit_station_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_plant_unit_station_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_schedule_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_schedule_extension_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_schedule_extension_save": "Schedule Extension succesfully saved",
        "succesful_schedule_save": "Schedule succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "successful_schedule_approval": "Schedules succesfully Uploaded",
        "title": "Manufacturing"
      },
      "operator_form_feature": {
        "delete_operator_form_message": "Are you sure you want to delete the <strong>{displayName}</strong> operator form?",
        "description": "Configure Operator Forms for digitally capturing logbook data",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Operator Forms feature",
            "name": "Data Sets"
          },
          "operator_forms": {
            "description": "Root container for all Operator Form configurations",
            "name": "Operator Forms"
          }
        },
        "label_add_operator_form": "New Operator Form",
        "label_delete_operator_form": "Delete Operator Form",
        "label_executor": "Main Processor",
        "label_executor_env": "Request Executor Code",
        "label_input_component": "Input Component",
        "label_operator_form": "Operator Forms",
        "label_operator_form_current": "Current Operator Form",
        "label_operator_form_name": "Operator Form Name",
        "label_request_formatter": "Input Formatter",
        "label_response_formatter": "Response Formatter",
        "label_result_component": "Result Display Component",
        "new_operator_form_name": "Operator Form {now}",
        "permission": {
          "operator_form_create": {
            "description": "Access to create operator forms",
            "name": "Create Permission"
          },
          "operator_form_delete": {
            "description": "Access to delete operator forms",
            "name": "Delete Permission"
          },
          "operator_form_read": {
            "description": "View-only permissions for operator forms",
            "name": "Read Permission"
          },
          "operator_form_update": {
            "description": "Access to update operator forms",
            "name": "Update Permission"
          }
        },
        "succesful_operator_form_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_operator_form_save": "<strong>{displayName}</strong> succesfully saved",
        "title": "Operator Form"
      },
      "outgoing_feature": {
        "description": "Watch machines and lines in real-time",
        "permission": {
          "realtime_data_access": {
            "description": "Access to realtime data",
            "name": "Streaming Data"
          }
        },
        "title": "Realtime Streaming Data"
      },
      "permission": {
        "administrator": {
          "description": "The permission for all tenant administrators",
          "name": "Administrator"
        },
        "public": {
          "description": "The only permission available to the portal visitors",
          "name": "Public"
        },
        "registered": {
          "description": "The default permission available to all logged-in users",
          "name": "Registered"
        },
        "super_administrator": {
          "description": "All permissions on the portal",
          "name": "Super Administrator"
        }
      },
      "profile_feature": {
        "contact_manager": {
          "contact": "Contact",
          "contact_type": "Type",
          "delete_modal": {
            "question": "Are you sure you want to delete the contact?",
            "title": "Delete Contact"
          },
          "succesful_delete": "Contact deleted succesfully",
          "succesful_save": "Contact saved succesfully",
          "verified_yn": "Verified"
        },
        "description": "Personal data management, including password reset, profile picture upload, etc.",
        "main_component": {
          "delete_modal": {
            "question": "Are you sure you want to delete your account?",
            "title": "Delete Account"
          },
          "label_confirm_password": "Re-enter New Password",
          "label_current_password": "Current Password",
          "label_default_application": "Default Application",
          "label_first_name": "First / Given Name",
          "label_last_name": "Last Name / Surname",
          "label_middle_name": "Middle Name(s)",
          "label_new_password": "New Password",
          "label_username": "Username / Login",
          "pane_basics": "Basics",
          "pane_contact_information": "Manage Contact Information",
          "pane_danger": "Danger Zone",
          "pane_password": "Manage Password",
          "succesful_delete": "Account deleted succesfully",
          "succesful_save": "Profile saved succesfully",
          "title": "Profile Editor"
        },
        "title": "Profile"
      },
      "pug_feature": {
        "description": "Manages Users, Groups, and Permissions",
        "group_manager_feature": {
          "add_group_user_message": "{numAdded} users added to the {groupDisplayName} group",
          "delete_group_message": "Are you sure you want to delete the <strong>{displayName}</strong> group?",
          "delete_group_user_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{groupDisplayName}</strong> group?",
          "description": "Manages Groups, their Permissions, and the Users associated with them",
          "label_add_group": "Add Group",
          "label_add_group_users": "Add Members",
          "label_default_group": "Default Group",
          "label_delete_group": "Delete Group",
          "label_delete_group_user": "Delete Member",
          "label_group_children": "Sub-Groups",
          "label_group_permission_description": "Permission Description",
          "label_group_permission_name": "Permission Name",
          "label_group_permissions": "Permissions",
          "label_group_user_first_name": "First / Given Name",
          "label_group_user_last_name": "Last Name / Surname",
          "label_group_user_middle_name": "Middle Name(s)",
          "label_group_user_username": "Username / Login",
          "label_group_users": "Members",
          "label_is_default_group": "Default (Y/N)",
          "label_name": "Group Name",
          "label_non_default_group": "Non-default Group",
          "label_save_group": "Save Group",
          "new_subgroup_name": "Child Group {now}",
          "permission": {
            "group_manager_all": {
              "description": "Superset implying all permissions defined by the Group Manager",
              "name": "All Group Manager Permissions"
            },
            "group_manager_read": {
              "description": "Readonly permissions for the Group Manager",
              "name": "Readonly Group Permissions"
            },
            "group_manager_update": {
              "description": "Modify/Update permissions for the Group Manager",
              "name": "Update Group Permissions"
            }
          },
          "permission_toggle_message": "<strong>{permissionDisplayName}</strong> permission successfully modified in <strong>{groupDisplayName}</strong>",
          "succesful_delete": "<strong>{displayName}</strong> succesfully deleted",
          "succesful_save": "<strong>{displayName}</strong> succesfully saved",
          "title": "Group Manager"
        },
        "permission": {
          "pug_all": {
            "description": "Superset implying all permissions defined by the Permissions, Users, and Groups Manager",
            "name": "All PUG Permissions"
          },
          "pug_read": {
            "description": "Readonly permissions for the PUG Manager",
            "name": "Readonly PUG Permissions"
          },
          "pug_update": {
            "description": "Modify/Update permissions for the PUG Manager",
            "name": "Update PUG Permissions"
          }
        },
        "title": "Permissions, Users, and Groups",
        "user_manager_feature": {
          "add_existing_user": "Add User",
          "authorize_user": "Authorize User",
          "clone_user": "Clone User",
          "create_user": "Create User",
          "deauthorize_user": "De-authorize User",
          "delete_user": "Delete User",
          "description": "Manages Users, their credentials, etc. associated with them",
          "edit_user": "Edit User",
          "label_access": "Watch Access",
          "label_awaiting_authorization": "Awaiting Authorization",
          "label_confirm_password": "Re-enter New Password",
          "label_current_password": "Current Password",
          "label_current_users": "Current Users",
          "label_deauthorized_users": "De-authorized Users",
          "label_emd": "Emd",
          "label_first_name": "First / Given Name",
          "label_generate_password": "Generate Password",
          "label_hdashboards": "Historical Dashboards",
          "label_last_name": "Last Name / Surname",
          "label_lines": "Lines",
          "label_machines": "Machines",
          "label_middle_name": "Middle Name(s)",
          "label_new_password": "New Password",
          "label_panels": "Panels",
          "label_reauthorize_user": "Re-authorize User",
          "label_reports": "Reports",
          "label_stations": "Stations",
          "label_username": "Username / Login",
          "label_wof": "Work Order Format",
          "no_dashboards_available": "There are no Dashboards available",
          "no_emd_available": "There are no Emd available",
          "no_lines_available": "There are no Lines available",
          "no_machines_available": "There are no Machines available",
          "no_panels_available": "There are no Panels available",
          "no_reports_available": "There are no Reports available",
          "no_stations_available": "There are no stations available",
          "no_wof_available": "There are no Work Order Format available",
          "permission": {
            "user_manager_all": {
              "description": "Superset implying all permissions defined by the User Manager",
              "name": "All User Manager Permissions"
            },
            "user_manager_read": {
              "description": "Readonly permissions for the User Manager",
              "name": "Readonly User Permissions"
            },
            "user_manager_update": {
              "description": "Update permissions for the User Manager",
              "name": "Update User Permissions"
            }
          },
          "reset_password": "Reset Password",
          "search_user": "Search using Username / Login",
          "succesful_add": "{numAccounts, plural, =0 {No Users} =1 {One User} other {# Users}} succesfully added",
          "succesful_create": "account created succesfully",
          "title": "User Manager",
          "update_access": "Update Access",
          "update_watch_access_error": "Error updating access",
          "update_watch_access_success": "Access updated successfully"
        }
      },
      "report_feature": {
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} report",
        "delete_report_message": "Are you sure you want to delete the <strong>{displayName}</strong> report?",
        "delete_schedule_message": "Are you sure you want to delete report schedule",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{reportName}</strong> report?",
        "description": "Configure Reports for displaying historical data",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Reports feature",
            "name": "Data Sets"
          },
          "reports": {
            "description": "Root container for all Report configurations",
            "name": "Reports"
          },
          "root": {
            "name": "Reports"
          }
        },
        "label_add_report": "New Report",
        "label_add_report_schedule": "Add Schedule",
        "label_add_users": "Add Watchers",
        "label_delete_report": "Delete Report",
        "label_delete_schedule": "Delete Report Schedule",
        "label_delete_user": "Remove Watcher",
        "label_email_distribution_list": "Send Email Reports",
        "label_entity_type": "Entity Type",
        "label_executor": "Main Processor",
        "label_executor_env": "Request Executor Code",
        "label_input_component": "Input Component",
        "label_message_distribution_list": "Send Message Reports",
        "label_report": "Reports",
        "label_report_add_line": "Add Line",
        "label_report_add_machine": "Add Machine",
        "label_report_add_station": "Add Station",
        "label_report_current": "Current Report",
        "label_report_input_data_map": "Incoming Data Configuration",
        "label_report_name": "Report Name",
        "label_report_observed_entities": "Tracked Entities",
        "label_report_schedule_cron_string": "CRON String",
        "label_report_schedule_response_types": "Response Types",
        "label_report_schedule_timezone": "Timezone",
        "label_report_schedules": "Schedules",
        "label_request_formatter": "Input Formatter",
        "label_request_formatter_type_api": "API Input Formatter",
        "label_request_formatter_type_cron": "Cron Input Formatter",
        "label_request_formatter_type_email": "Email Input Formatter",
        "label_request_formatter_type_http": "HTTP Input Formatter",
        "label_request_formatter_type_message": "Message Input Formatter",
        "label_request_formatter_type_sms": "SMS Input Formatter",
        "label_response_formatter": "Response Formatter",
        "label_response_formatter_type_api": "API Response Formatter",
        "label_response_formatter_type_email": "Email Response Formatter",
        "label_response_formatter_type_http": "HTTP Response Formatter",
        "label_response_formatter_type_message": "Message Response Formatter",
        "label_response_formatter_type_sms": "SMS Response Formatter",
        "label_response_formatter_type_telegram": "Telegram Response Formatter",
        "label_result_component": "Result Display Component",
        "label_sms_distribution_list": "Send SMS Reports",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_users": "Watchers",
        "new_report_name": "Report {now}",
        "permission": {
          "report_create": {
            "description": "Access to create reports",
            "name": "Create Permission"
          },
          "report_delete": {
            "description": "Access to delete reports",
            "name": "Delete Permission"
          },
          "report_read": {
            "description": "View-only permissions for reports",
            "name": "Read Permission"
          },
          "report_update": {
            "description": "Access to update reports",
            "name": "Update Permission"
          },
          "report_watch": {
            "description": "Access reports in production and execute them",
            "name": "View Permission"
          }
        },
        "succesful_report_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_report_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_schedule_delete": "Report schedule deleted",
        "succesful_schedule_save": "Report schedule succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_watcher_update": "Watcher updated succesfully",
        "title": "Reports"
      },
      "schedule_feature": {
        "description": "Schedule for all Features subscribed to by a Tenant",
        "label_menu": "Menu",
        "manufacturing": {
          "description": "environment to author code/functions that will operate on incoming data",
          "title": "Manufacturing"
        },
        "permission": {
          "schedule_access": {
            "description": "Access to modify schedules/calendars for the account",
            "name": "Schedule Access"
          }
        },
        "title": "Schedule"
      },
      "server_administration_feature": {
        "description": "Readonly view of the backend services configuration - available only to super-administrators of the www tenant",
        "title": "Server Administration"
      },
      "session_component": {
        "header_forgot_password": "Password Reset",
        "header_login": "Login",
        "header_register_account": "Register Account",
        "label_confirm_password": "Re-enter Password",
        "label_email": "Email",
        "label_first_name": "First / Given Name",
        "label_last_name": "Last Name / Surname",
        "label_mobile": "Mobile Number",
        "label_password": "Password",
        "label_reset": "Reset",
        "label_username": "Email / Username",
        "login_message": "Logging in...",
        "logout_message": "Logging out...",
        "password_dont_match_message": "Passwords do not match!",
        "registering_account": "Creating your account...",
        "resetting_password_message": "Resetting password..."
      },
      "settings_feature": {
        "account": {
          "basics": {
            "description": "Organization name & sub-domain, etc.",
            "title": "Account Basics"
          },
          "features": {
            "description": "Allows Tenants to subscribe/unsubscribe from non-default features on the portal",
            "title": "Features"
          },
          "webhooks": {
            "description": "Allows Tenants to route the events data",
            "title": "Webhooks"
          },
          "work-orders": {
            "description": "Work Order settings",
            "title": "Work Orders"
          }
        },
        "account_feature": {
          "basics": {
            "description": "Organization name & sub-domain, etc.",
            "label_tenant_domain": "Sub-domain",
            "label_tenant_name": "Organization Name",
            "succesful_save": "Account saved succesfully",
            "title": "Account Basics"
          },
          "description": "Tenant Account related settings",
          "features": {
            "description": "Allows Tenants to subscribe/unsubscribe from non-default features on the portal",
            "label_feature_access": "Access",
            "label_permissions": "Permissions",
            "label_sub_features": "Sub-Features",
            "title": "Features"
          },
          "locations": {
            "description": "Organization location, etc.",
            "label_add_location": "Add Location",
            "label_approximate_location": "Approximate Location...",
            "label_area": "Area",
            "label_city": "City",
            "label_country": "Country",
            "label_delete_location": "Delete Location",
            "label_fetching_map": "Fetching map...",
            "label_latitude": "Latitude",
            "label_line1": "Line #1",
            "label_line2": "Line #2",
            "label_line3": "Line #3",
            "label_longitude": "Longitude",
            "label_name": "Name",
            "label_postcode": "Postal / ZIP Code",
            "label_state": "State",
            "label_timezone": "Timezone",
            "question_delete_location": "Are you sure you want to delete this location?",
            "title": "Locations"
          },
          "permission": {
            "settings_account_basics_all": {
              "description": "Superset implying all permissions defined by the Basic Account Manager",
              "name": "All Basic Account Permissions"
            },
            "settings_account_basics_read": {
              "description": "Readonly permissions for the Basic Account Manager",
              "name": "Readonly Basic Account Permissions"
            },
            "settings_account_basics_update": {
              "description": "Modify/Update permissions for the Basic Account Manager",
              "name": "Update Basic Account Permissions"
            },
            "settings_account_features_all": {
              "description": "Superset implying all permissions defined by the Feature Manager",
              "name": "All Feature Manager Permissions"
            },
            "settings_account_features_read": {
              "description": "Readonly permissions for the Feature Manager",
              "name": "Readonly Feature Permissions"
            },
            "settings_account_features_update": {
              "description": "Modify/Update permissions for the Feature Manager",
              "name": "Update Feature Permissions"
            },
            "settings_account_webhooks_create": {
              "description": "Access to create a webhook",
              "name": "Create Webhook Permissions"
            },
            "settings_account_webhooks_delete": {
              "description": "Access to remove a webhook",
              "name": "Delete Webhook Permissions"
            },
            "settings_account_webhooks_read": {
              "description": "Readonly permissions for the Feature Manager",
              "name": "Readonly Webhook Permissions"
            },
            "settings_account_webhooks_update": {
              "description": "Access to update a webhook",
              "name": "Update Webhook Permissions"
            }
          },
          "title": "Account",
          "webhooks": {
            "delete_webhook_message": "Are you sure you want to delete the <strong>{displayName}</strong> Webhook?",
            "description": "Allows Tenants to route the events data",
            "label_add_webhook": "New Webhook",
            "label_add_webhook_request_header": "New Request Header",
            "label_delete_webhook": "Delete Webhook",
            "label_webhook_event": "Event",
            "label_webhook_request_header": "Header",
            "label_webhook_request_header_value": "Value",
            "label_webhook_request_headers": "Request Headers",
            "label_webhook_test": "Test Webhook",
            "label_webhook_url": "URL",
            "new_webhook_name": "Webhook {now}",
            "succesful_webhook_delete": "<strong>{displayName}</strong> succesfully deleted",
            "succesful_webhook_save": "<strong>{displayName}</strong> succesfully saved",
            "succesful_webhook_test": "Status OK",
            "title": "Webhooks",
            "webhook_event_option_day_change": "Day Change",
            "webhook_event_option_downtime_update": "Downtime Start/End Events",
            "webhook_event_option_logical_day_change": "Beginning of the First Shift of the Day",
            "webhook_event_option_operator_change": "Operator Change",
            "webhook_event_option_realtime_stream": "Real-time Data Stream",
            "webhook_event_option_shift_change": "Shift Change",
            "webhook_event_option_sku_change": "SKU Change",
            "webhook_event_option_supervisor_change": "Supervisor Change",
            "webhook_event_option_work_order_status_change": "Work Order Status Change"
          },
          "work-orders": {
            "description": "Work Order settings",
            "disable_work_order_operations_cancelation": "Disable cancellation under work order operations",
            "succesful_save": "Work Order settings saved succesfully",
            "title": "Work Orders"
          }
        },
        "description": "Settings for all Features subscribed to by a Tenant",
        "label_menu": "Menu",
        "permission": {
          "settings_access": {
            "description": "Access to modify settings for the account",
            "name": "Settings Access"
          }
        },
        "title": "Settings"
      },
      "title": "Plant.Works Web Application Server",
      "work_order_feature": {
        "add_watchers_message": "{numAdded, plural, =0 {No Users} =1 {One User} other {# Users}} added to the {name} Work Order Format",
        "delete_format_message": "Are you sure you want to delete the <strong>{displayName}</strong> work order format?",
        "delete_watcher_message": "Are you sure you want to remove <strong>{userFullName}</strong> from the <strong>{wofName}</strong> Work Order Format?",
        "description": "Configure and manage attribute sets, work order formats, and other artifacts related to the work orders",
        "error_file_upload": "The file should not contain extra or less column than configured",
        "folder_names": {
          "attribute_sets": {
            "description": "Root container for all Data Sets used by the Work Order configuration feature",
            "name": "Data Sets"
          },
          "format": {
            "description": "Root container for all Work Order format used by the Wprk Order configuration feature",
            "name": "Work Order Format"
          },
          "root": {
            "name": "Work Order Format"
          }
        },
        "label_add_format": "Add Format",
        "label_add_format_attribute_set": "add attribute set",
        "label_add_users": "Add Watchers",
        "label_add_work_order_line": "Select Line for this work order",
        "label_assign_operator_machine": "Select Operator for the Machine",
        "label_assign_supervisor_line": "Select Supervisor for the Line",
        "label_attribute_set": "data sets",
        "label_authorize_operator": "Authorize Operator",
        "label_block_details": "Block Detail",
        "label_calculate_setuptime": "Calculate Setuptime",
        "label_change_workorder": "Change Workorder",
        "label_current_work_order": "Current Work Order",
        "label_delete_format": "Delete Format",
        "label_delete_user": "Remove Watcher",
        "label_enter_work_order_start_time": "Enter Work Order Start Time",
        "label_format": "Work Order Format",
        "label_format_add_attribute_set": "Add Data Set",
        "label_format_attribute_set": "Data Sets",
        "label_format_attribute_set_list": "Work Order Format Attribute Sets",
        "label_format_name": "Format Name",
        "label_format_observerfunc": "Work Order Format Observer Functions",
        "label_format_postfunc": "Work Order Format After Functions",
        "label_format_prefunc": "Work Order Format Before Functions",
        "label_format_property": "Work Order Format Properties",
        "label_format_property_diplay_in_station": "Display In Station",
        "label_line_config": "configuration",
        "label_perform_operation": "Change Status",
        "label_processed_data_transformer": "processed data transform",
        "label_raw_data_transformer": "raw data transform",
        "label_upcoming_work_orders": "Upcoming Work Orders",
        "label_user_first_name": "First / Given Name",
        "label_user_last_name": "Last Name / Surname",
        "label_user_middle_name": "Middle Name(s)",
        "label_user_username": "Username / Login",
        "label_wo_started_at": "Started at:",
        "label_work_order_add_line": "Add Line",
        "label_work_order_add_operator": "Add Operator",
        "label_work_order_add_supervisor": "Add Supervisor",
        "label_work_order_id": "Work Order Id",
        "label_work_order_no_production": "0.00",
        "label_work_order_operation": "Work Order Operations",
        "label_work_order_status": "STATUS",
        "label_work_order_status_cancel": "cancel",
        "label_work_order_status_complete": "complete",
        "label_work_order_status_discontinue": "discontinue",
        "label_work_order_status_in_progress": "in progress",
        "label_work_order_status_start": "start",
        "label_work_order_uploader": "Work Order Uploader",
        "new_format_name": "Format {now}",
        "perform_operation_message": "Are you sure you want to change work order status to <strong>{displayName}</strong> ?",
        "perform_submit_message": "Are you sure you want to submit <strong>{displayName}</strong> data ?",
        "permission": {
          "coke_operator_order_form_1": {
            "description": "Access to Coke operator form 1",
            "name": "Coke Operator Form 1"
          },
          "coke_operator_order_form_2": {
            "description": "Access to Coke operator form 2",
            "name": "Coke Operator Form 2"
          },
          "coke_operator_order_form_3": {
            "description": "Access to Coke operator form 3",
            "name": "Coke Operator Form 3"
          },
          "coke_operator_order_form_4": {
            "description": "Access to Coke operator form 4",
            "name": "Coke Operator Form 4"
          },
          "coke_operator_order_form_5": {
            "description": "Access to Coke operator form 5",
            "name": "Coke Operator Form 5"
          },
          "marico_work_order_form": {
            "description": "Access to Marico work order form",
            "name": "Work Order Admin Form"
          },
          "snacks_operator_form": {
            "description": "Access to snacks operator form",
            "name": "Snacks Operator Form"
          },
          "snacks_shift_incharge_form": {
            "description": "Access to snacks shift incharge form",
            "name": "Snacks Shift Incharge Form"
          },
          "work_order_admin_form_update": {
            "description": "Access to work order admin form",
            "name": "Work Order Admin Form"
          },
          "work_order_create": {
            "description": "Access to create work order formats",
            "name": "Create Permission"
          },
          "work_order_delete": {
            "description": "Access to delete work order formats",
            "name": "Delete Permission"
          },
          "work_order_operation": {
            "description": "Access to update work order status",
            "name": "Work Order Operations"
          },
          "work_order_read": {
            "description": "View-only permissions for work order formats",
            "name": "Read Permission"
          },
          "work_order_update": {
            "description": "Access to update work order formats",
            "name": "Update Permission"
          },
          "work_order_upload": {
            "description": "Access to upload work orders",
            "name": "Work Order Upload"
          },
          "zydus_work_order_admin_form_update": {
            "description": "Access to work order admin form",
            "name": "Work Order Admin Form"
          },
          "zydus_work_order_operation": {
            "description": "Access to update work order status",
            "name": "Work Order Operations"
          }
        },
        "submit_success_messgae": "Shift <strong>{displayDate} {displayName}</strong> submit successful",
        "succesful_format_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesful_format_save": "<strong>{displayName}</strong> succesfully saved",
        "succesful_watcher_delete": "<strong>{displayName}</strong> succesfully deleted",
        "succesfull_wo_configuration_save": "Configuration Saved Successfully",
        "successfull_format_upload_approval": "<b>{count}</b> records have been successfully uploaded",
        "title": "Work Order"
      }
    },
    "timezone": {
      "id": {
        "asia_kolkata": "Asia/Kolkata"
      },
      "name": {
        "ist": "India Standard Time (IST)"
      }
    }
  };
  _exports.default = _default;
});