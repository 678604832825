define("plantworks/templates/components/profile/notification-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oi9zYj7Z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"text-white mr-4\",\"profile\"]],{\"statements\":[[0,\"\\t\\t\"],[4,\"if\",[[28,\"not-eq\",[[24,[\"displayImage\"]],\"\"],null]],null,{\"statements\":[[7,\"img\",true],[11,\"src\",[22,\"displayImage\"]],[11,\"alt\",[22,\"displayName\"]],[10,\"style\",\"max-width:4rem; max-height:2rem;\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/profile/notification-area.hbs"
    }
  });

  _exports.default = _default;
});