define("plantworks/components/historical-dashboard/devenv/historical-dashboard-frontend-environment", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDevMode': null,
    'allDevModes': null,

    init() {
      this._super(...arguments);

      this.set('allDevModes', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.historical_dashboard_feature.label_input_component',
          'component': 'HistoricalDashboard/Devenv/InputComponentEditor'
        }, {
          'name': 'plant_works_webapp_server_server.historical_dashboard_feature.label_result_component',
          'component': 'HistoricalDashboard/Devenv/ResultComponentEditor'
        }, {
          'name': 'general.label_frontend_dev_template_preview',
          'component': 'HistoricalDashboard/Execute/RequestWrapper'
        }])
      }));
      this.set('currentDevMode', this.get('allDevModes').objectAt(0));
    },

    actions: {
      'resetSelectedSubcomponent': function () {
        this.resetSelectedSubcomponent();
      }
    }
  });

  _exports.default = _default;
});