define("plantworks/models/board/panel-subcomponent", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'componentId': _emberData.default.attr('string'),
    'componentState': _emberData.default.attr('string', {
      'defaultValue': '{}'
    }),
    'componentBeforeRenderCode': _emberData.default.attr('string'),
    'componentAfterRenderCode': _emberData.default.attr('string'),
    'componentBeforeDestroyCode': _emberData.default.attr('string'),
    'template': _emberData.default.attr('string'),
    'componentObservers': (0, _attributes.fragmentArray)('board/fragments/panel-template-component-observer'),
    'componentTasks': (0, _attributes.fragmentArray)('board/fragments/panel-template-component-task'),
    'publishStatus': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPanel': _emberData.default.belongsTo('board/panel', {
      'async': true,
      'inverse': 'subcomponents'
    })
  });

  _exports.default = _default;
});