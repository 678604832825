define("plantworks/components/dashboard/sub-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'selectedDashboardCategory': null,
    'router': Ember.inject.service('router'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
      this.get('currentUser').on('dashboardCategoryUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_setupDashboardFeatures').perform();
    }).on('willInsertElement').keepLatest(),
    'onModelChanged': Ember.observer('model', 'model.@each.dashboardCategory', 'model.@each.dashboardCategory.active', function () {
      this.get('_setupDashboardFeatures').perform();
    }),

    onUserDataUpdated() {
      this.get('_setupDashboardFeatures').perform();
    },

    '_setupDashboardFeatures': (0, _emberConcurrency.task)(function* () {
      let featureData = yield this.get('store').findAll('dashboard/feature');
      this.set('model', featureData);
      this.set('selectedDashboardCategory', null);

      if (!this.get('model')) {
        return;
      }

      const model = yield this.get('model');

      for (let i = 0; i < model.length; i++) {
        let dashboardFeature = model.objectAt(i);

        if (!this.get('selectedDashboardCategory') && dashboardFeature.get('active')) {
          this.set('selectedDashboardCategory', dashboardFeature.get('dashboardCategory'));
          break;
        }
      }
    }).keepLatest(),
    'transitionToFeature': function (feature) {
      const onlyRoute = feature.get('route');
      const onlyRouteParams = feature.get('routeParams');
      if (!onlyRouteParams) this.get('router').transitionTo(onlyRoute);else this.get('router').transitionTo(onlyRoute, onlyRouteParams);
    }
  });

  _exports.default = _default;
});