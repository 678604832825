define("plantworks/models/report/report-response-formatter", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'responseType': _emberData.default.attr('string', {
      'defaultValue': 'http'
    }),
    'formatterCode': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'publishStatus': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantReport': _emberData.default.belongsTo('report/report', {
      'async': true,
      'inverse': 'responseFormatters'
    })
  });

  _exports.default = _default;
});