define("plantworks/models/manufacturing/fragments/downtime-reason-metadata", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'remark': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'class': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'STANDARD_DURATION': _emberData.default.attr('string', {
      'defaultValue': ''
    })
  });

  _exports.default = _default;
});