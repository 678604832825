define("plantworks/templates/components/manufacturing/watch/station-tree-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/xYrG7v",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[5,\"plant-works-card\",[[12,\"style\",\"margin-right:1px !important;\"]],[[\"@class\"],[\"m-0 flex\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"p-0 pt-1\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"station-tree-container\"],[10,\"class\",\"p-2\"],[8],[0,\"\\n\\t\\t\\t \\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/watch/station-tree-component.hbs"
    }
  });

  _exports.default = _default;
});