define("plantworks/models/event-alert/event-alert-watcher", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantEventAlert': _emberData.default.belongsTo('event-alert/event-alert', {
      'async': true,
      'inverse': 'watchers'
    }),
    'tenantUser': _emberData.default.belongsTo('pug/user-manager/tenant-user', {
      'async': true,
      'inverse': 'tenantEventAlerts'
    }),
    'onEmailDistributionList': _emberData.default.attr('boolean', {
      'defaultValue': true
    }),
    'onSmsDistributionList': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'onMessageDistributionList': _emberData.default.attr('boolean', {
      'defaultValue': false
    })
  });

  _exports.default = _default;
});