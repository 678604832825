define("plantworks/components/plant-works-speed-dial/actions", ["exports", "pworks-dsl/components/plant-works-speed-dial/actions"], function (_exports, _actions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _actions.default;
    }
  });
});