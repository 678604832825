define("plantworks/components/report/devenv/report-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDevMode': null,
    'allDevModes': null,

    init() {
      this._super(...arguments);

      this.set('allDevModes', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_backend_dev',
          'component': 'Report/Devenv/ReportBackendEnvironment'
        }, {
          'name': 'plant_works_webapp_server_server.report_feature.label_executor_env',
          'component': 'Report/Devenv/ReportExecutorEnvironment'
        }, {
          'name': 'general.label_frontend_dev',
          'component': 'Report/Devenv/ReportFrontendEnvironment'
        }])
      }));
      this.set('currentDevMode', this.get('allDevModes').objectAt(0));
    }

  });

  _exports.default = _default;
});