define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['bg-grey', 'p-2'],
    'currentTab': 'basic',
    'propertyMetadataEditor': 'Manufacturing/Configure/PlantUnitMachineEditor/PropertyMetadataEditor',
    'propertyMetadataField': 'attributeSetMetadata',
    'onInit': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      if (this.get('model.isNew')) return; // this.set('currentTab', 'attributeSets');

      yield this.get('model.attributeSetMetadata');
    }).on('init').keepLatest(),
    'addMachineAttributeSet': (0, _emberConcurrency.task)(function* () {
      const machineAttrSets = yield this.get('model.attributeSets');
      const alreadyUsedIds = [];

      for (let idx = 0; idx < machineAttrSets.length; idx++) {
        const machineAttrSet = machineAttrSets.objectAt(idx);
        const attributeSet = yield machineAttrSet.get('tenantAttributeSet');
        alreadyUsedIds.push(attributeSet.get('id'));
      }

      const store = this.get('store');
      const allAttributeSets = yield store.findAll('manufacturing/attribute-set', {
        'reload': true
      });
      const availableAttrSets = allAttributeSets.filter(attributeSet => {
        return !alreadyUsedIds.includes(attributeSet.get('id'));
      }).map(attributeSet => {
        return {
          'attributeSet': attributeSet,
          'selected': false
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_add_data_set'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Common/AddAttributeSet',
        'componentState': {
          'model': availableAttrSets
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addMachineAttributeSets').perform(availableAttrSets);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addMachineAttributeSets': (0, _emberConcurrency.task)(function* (availableAttributeSets) {
      const toBeAdded = availableAttributeSets.filter(availableAttributeSet => {
        return availableAttributeSet.selected;
      });

      for (let idx = 0; idx < toBeAdded.length; idx++) {
        let machineAttributeSet = toBeAdded[idx]['machineAttributeSet'];

        if (!machineAttributeSet) {
          const toBeAddedAttributeSet = toBeAdded[idx]['attributeSet'];
          machineAttributeSet = this.get('store').createRecord('manufacturing/plant-unit-machine-attribute-set', {
            'tenant': this.get('model.tenant'),
            'tenantAttributeSet': toBeAddedAttributeSet,
            'tenantPlantUnitMachine': this.get('model')
          });
          toBeAdded[idx]['machineAttributeSet'] = machineAttributeSet;
        }

        if (machineAttributeSet.get('isNew')) {
          yield machineAttributeSet.save();
          this.get('model.attributeSets').addObject(machineAttributeSet);
        }
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_addMachineAttributeSetsErrored': Ember.on('_addMachineAttributeSets:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addDataSet': function () {
      this.get('addMachineAttributeSet').perform();
    }
  });

  _exports.default = _default;
});