define("plantworks/components/event-alert/devenv/event-alert-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDevMode': null,
    'allDevModes': null,

    init() {
      this._super(...arguments);

      this.set('allDevModes', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_backend_dev',
          'component': 'EventAlert/Devenv/EventAlertBackendEnvironment'
        }, {
          'name': 'plant_works_webapp_server_server.event_alert_feature.label_response_formatter',
          'component': 'EventAlert/Devenv/ResponseFormatterList'
        }])
      }));
      this.set('currentDevMode', this.get('allDevModes').objectAt(0));
    }

  });

  _exports.default = _default;
});