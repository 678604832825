define("plantworks/templates/components/historical-dashboard/configure/add-entity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAv+WDnr",
    "block": "{\"symbols\":[\"Table\",\"Body\",\"availableEntity\",\"Row\",\"Head\"],\"statements\":[[5,\"plant-works-table\",[],[[\"@sortProp\",\"@sortDir\"],[\"entity.path\",\"asc\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Head\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,5,[\"Column\"]],[],[[\"@checkbox\"],[true]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"plant-works-checkbox\",[],[[\"@disabled\",\"@onChange\"],[true,null]]],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[6,[23,5,[\"Column\"]],[],[[\"@sortProp\"],[\"entity.path\"]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"t\",[\"general.label_name\"],null],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[5]}],[0,\"\\n\\t\"],[6,[23,1,[\"Body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[[23,1,[\"sortDesc\"]],[24,[\"state\",\"model\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[6,[23,2,[\"Row\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[6,[23,4,[\"Cell\"]],[],[[\"@checkbox\"],[true]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"plant-works-checkbox\",[],[[\"@value\",\"@onChange\"],[[23,3,[\"selected\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,3,[\"selected\"]]],null]],null]]]],[0,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\\t\\t\\t\"],[6,[23,4,[\"Cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"await\",[[23,3,[\"entity\",\"path\"]]],null],false],[0,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[4]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/historical-dashboard/configure/add-entity.hbs"
    }
  });

  _exports.default = _default;
});