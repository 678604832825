define("plantworks/components/manufacturing/configure/master-data-selector", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    comparisonOperatorOptions: null,

    init() {
      this._super(...arguments);

      this.set('comparisonOperatorOptions', ['>', '<', '=']);
    },

    'addMasterData': (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const emdConfigurations = yield store.findAll('emd/configuration', {
        'reload': true
      });
      const modalOptions = {
        'selectedEmdConfigurationId': null,
        'availableEmdConfigurations': emdConfigurations
      };
      const modalData = {
        'title': {
          'text': `Add ${this.get('masterDataDisplayName')}`,
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/AddMasterData',
        'componentState': {
          'model': modalOptions
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addMasterData').perform(modalOptions);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addMasterData': (0, _emberConcurrency.task)(function* (chosenList) {
      const model = this.get('model');
      const chosenConfiguration = chosenList.availableEmdConfigurations.filter(emdConfiguration => {
        return emdConfiguration.get('id') === chosenList.selectedEmdConfigurationId;
      })[0];
      yield model.set(this.get('masterDataPropertyName'), chosenConfiguration);
    }).drop(),
    'deleteMasterData': (0, _emberConcurrency.task)(function* () {
      const model = this.get('model');
      const filterList = yield model.get(this.get('masterDataFilterPropertyName'));
      filterList.clear();
      model.set(this.get('masterDataPropertyName'), null);
    }).drop(),
    'addMasterDataFilter': (0, _emberConcurrency.task)(function* () {
      const newFilter = yield this.get('model').get(this.get('masterDataFilterPropertyName')).createFragment({
        'parameterId': null,
        'comparisonOperator': null,
        'value': null
      });
      newFilter.set('isEditing', true);
    }).drop(),
    'deleteMasterDataFilter': (0, _emberConcurrency.task)(function* (masterDataFilter) {
      yield this.get('model').get(this.get('masterDataFilterPropertyName')).removeFragment(masterDataFilter);
    }).enqueue(),
    'editMasterDataFilter': (0, _emberConcurrency.task)(function* (filter) {
      yield filter.set('isEditing', true);
    }).drop(),
    'cancelMasterDataFilter': (0, _emberConcurrency.task)(function* (filter) {
      filter.rollbackAttributes();
      yield filter.set('isEditing', false);
    }).drop(),
    'saveMasterDataFilter': (0, _emberConcurrency.task)(function* (filter) {
      yield filter.set('isEditing', false);
    }).drop()
  });

  _exports.default = _default;
});