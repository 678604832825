define("plantworks/components/emd/devenv/emd-config-editor/property-metadata-viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['layout-row', 'layout-align-start-center', 'py-4'],
    'metadata': null,
    'isTimestamp': false,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const allMetadata = yield this.get(`entity.${this.get('metadataField')}`);
      let metadata = allMetadata.filterBy('parameterId', this.get('model.id')).objectAt(0);

      if (!metadata) {
        metadata = allMetadata.createFragment({
          'parameterId': this.get('model.id'),
          'isTimestamp': false
        });
      }

      const isTimestamp = this.get('model.datatype') === 'date';
      if (!isTimestamp) metadata.set('isTimestamp', isTimestamp);
      this.set('isTimestamp', isTimestamp);
      this.set('metadata', metadata);
    }).on('init').drop()
  });

  _exports.default = _default;
});