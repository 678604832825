define("plantworks/models/operator-form/operator-form-executor", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'executorCode': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'publishStatus': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantOperatorForm': _emberData.default.belongsTo('operator-form/operator-form', {
      'async': true,
      'inverse': 'executors'
    })
  });

  _exports.default = _default;
});