define("plantworks/models/manufacturing/plant-unit-machine-downtime", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes", "ember-moment/computeds/moment", "ember-moment/computeds/format", "ember-moment/computeds/locale", "ember-concurrency"], function (_exports, _baseModel, _emberData, _attributes, _moment2, _format, _locale, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'moment': Ember.inject.service('moment'),
    'type': _emberData.default.attr('string', {
      'defaultValue': 'downtime'
    }),
    'work_order_id': _emberData.default.attr('string'),
    'sku': _emberData.default.attr('string'),
    'operator': _emberData.default.attr('string'),
    'startTime': _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    'endTime': _emberData.default.attr('date'),
    // 'currentEndTime': DS.attr('date', { defaultValue() {
    // 		return new Date();
    // 	}
    // }),
    'metadata': (0, _attributes.fragment)('manufacturing/fragments/downtime-metadata'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitMachine': _emberData.default.belongsTo('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'downtimes'
    }),
    'reasons': _emberData.default.hasMany('manufacturing/plant-unit-machine-downtime-reason', {
      'async': true,
      'inverse': 'tenantPlantUnitMachineDowntime'
    }),
    'accountedDurationForCalculations': Ember.computed('duration', 'reasons.@each.reasonDurationInMin', function () {
      return this.get('_computedAccountedDurationsForCalc').perform();
    }),
    'accountedDuration': Ember.computed('duration', 'reasons.@each.reasonDurationInMin', function () {
      return this.get('_computedAccountedDurations').perform();
    }),
    'unaccountedDurationForCalculations': Ember.computed('accountedDuration', function () {
      return this.get('_computeUnaccountedDurationForCalc').perform();
    }),
    'unaccountedDuration': Ember.computed('accountedDuration', function () {
      return this.get('_computeUnaccountedDuration').perform();
    }),
    'formattedStartTime': (0, _format.default)((0, _locale.default)((0, _moment2.default)('startTime'), 'moment.locale'), 'DD/MMM/YYYY hh:mm A'),
    'formattedEndTime': (0, _format.default)((0, _locale.default)((0, _moment2.default)('endTime'), 'moment.locale'), 'DD/MMM/YYYY hh:mm A'),
    'formattedCurrentEndTime': (0, _format.default)((0, _locale.default)((0, _moment2.default)('currentEndTime'), 'moment.locale'), 'DD/MMM/YYYY hh:mm A'),
    'duration': Ember.computed('startTime', 'endTime', 'currentEndTime', function () {
      const startTime = window.moment(this.get('startTime')).startOf('minute');
      const endTime = window.moment(this.get('endTime') || this.get('currentEndTime')).startOf('minute');
      const duration = window.moment.duration(endTime.diff(startTime));
      return duration;
    }),
    'formattedDuration': Ember.computed('duration', function () {
      return window.moment.utc(this.get('duration').asMilliseconds()).format("HH:mm");
    }),
    '_computedAccountedDurations': (0, _emberConcurrency.task)(function* () {
      const allReasons = yield this.get('reasons');
      let accountedTime = 0;
      allReasons.forEach(reason => {
        accountedTime += Number(reason.get('reasonDurationInMin'));
      });
      accountedTime = window.moment.duration(accountedTime, 'minutes');
      return window.moment.utc(accountedTime.asMilliseconds()).format("HH:mm");
    }).enqueue(),
    '_computeUnaccountedDuration': (0, _emberConcurrency.task)(function* () {
      let accountedDuration = 0;
      const allReasons = yield this.get('reasons');
      allReasons.forEach(reason => {
        accountedDuration += Number(reason.get('reasonDurationInMin'));
      });
      const durationInMin = Math.ceil(this.get('duration').asMinutes());
      const unaccountedTimeDuration = durationInMin - accountedDuration;
      return unaccountedTimeDuration;
    }).enqueue(),
    '_computeUnaccountedDurationForCalc': (0, _emberConcurrency.task)(function* () {
      let accountedDuration = 0;
      const allReasons = yield this.get('reasons');
      allReasons.forEach(reason => {
        if (!reason.get('isDirty')) {
          accountedDuration += Number(reason.get('reasonDurationInMin'));
        }
      });
      const durationInMin = Math.ceil(this.get('duration').asMinutes());
      const unaccountedTimeDuration = durationInMin - accountedDuration;
      return unaccountedTimeDuration;
    }).enqueue(),
    '_computedAccountedDurationsForCalc': (0, _emberConcurrency.task)(function* () {
      const allReasons = yield this.get('reasons');
      let accountedTime = 0;
      allReasons.forEach(reason => {
        if (!reason.get('isDirty')) {
          accountedTime += Number(reason.get('reasonDurationInMin'));
        }
      });
      accountedTime = window.moment.duration(accountedTime, 'minutes');
      return window.moment.utc(accountedTime.asMilliseconds()).format("HH:mm");
    }).enqueue()
  });

  _exports.default = _default;
});