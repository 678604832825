define("plantworks/initializers/manufacturing/add-manufacturing-plants-to-tenant-location", ["exports", "ember-data", "plantworks/models/settings/account/basics/tenant-location"], function (_exports, _emberData, _tenantLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _tenantLocation.default.reopen({
      'plants': _emberData.default.hasMany('manufacturing/plant', {
        'async': true,
        'inverse': 'tenantLocation'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});