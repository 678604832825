define("plantworks/models/masterdata/communication-protocol-master", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'version': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'gateways': _emberData.default.hasMany('masterdata/gateway-communication-protocol', {
      'async': true,
      'inverse': 'protocol'
    }),
    'machines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'communicationProtocol'
    })
  });

  _exports.default = _default;
});