define("plantworks/models/manufacturing/fragments/downtime-metadata", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'operator_form_1': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'operator_form_2': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'operator_form_3': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'operator_form_4': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'operator_form_5': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'submitted': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'approved': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'prev_sku': _emberData.default.attr('string', {
      'defaultValue': null
    }),
    'current_sku': _emberData.default.attr('string', {
      'defaultValue': null
    })
  });

  _exports.default = _default;
});