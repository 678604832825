define("plantworks/services/plant-works-sidenav", ["exports", "pworks-dsl/services/plant-works-sidenav"], function (_exports, _plantWorksSidenav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksSidenav.default;
    }
  });
});