define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/add-driver", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'plantDriverList': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const currentPlc = yield this.get('state.model.plc');
      const plcHardwareProtocols = yield currentPlc.get('hardwareProtocols');
      const plantUnits = yield this.get('state.model.tenantPlantUnit.plant.units');
      const supportedHWProtocols = [];
      const driverList = [];

      for (let idx = 0; idx < plcHardwareProtocols.length; idx++) {
        const plcHwProtocol = plcHardwareProtocols.objectAt(idx);
        const hwProtocolId = yield plcHwProtocol.get('protocol.id');
        supportedHWProtocols.push(hwProtocolId);
      }

      for (let idx = 0; idx < plantUnits.length; idx++) {
        const plantUnit = plantUnits.objectAt(idx);
        const plantUnitDrivers = yield plantUnit.get('drivers');

        for (let jdx = 0; jdx < plantUnitDrivers.length; jdx++) {
          const driver = plantUnitDrivers.objectAt(jdx);
          const driverHardwareProtocols = yield driver.get('gateway.hardwareProtocols');

          for (let kdx = 0; kdx < driverHardwareProtocols.length; kdx++) {
            const driverHwProtocol = driverHardwareProtocols.objectAt(kdx);
            const hwProtocolId = yield driverHwProtocol.get('protocol.id');
            if (!hwProtocolId.includes(hwProtocolId)) continue;
            driverList.push(driver);
            break;
          }
        }
      }

      this.set('plantDriverList', driverList);
    }).on('init').drop(),
    'setMachineDriver': function (newDriverId) {
      const newDriver = this.get('store').peekRecord('manufacturing/plant-unit-driver', newDriverId);
      this.set('state.model.tenantPlantUnitDriver', newDriver);
    }
  });

  _exports.default = _default;
});