define("plantworks/controllers/manufacturing", ["exports", "plantworks/framework/base-controller"], function (_exports, _baseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'manufacturing-read');
    }

  });

  _exports.default = _default;
});