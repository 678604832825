define("plantworks/components/common/consolidated-attribute-set/viewer", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'currentDataSetTab': 'properties',
    'allowModifications': true,
    'prefuncMetadataField': null,
    'prefuncMetadataEditor': null,
    'propertyMetadataField': null,
    'propertyMetadataEditor': null,
    'observerfuncMetadataField': null,
    'observerfuncMetadataEditor': null,
    'postfuncMetadataField': null,
    'postfuncMetadataEditor': null
  });

  _exports.default = _default;
});