define("plantworks/components/work-order/work-order-app/explorer", ["exports", "plantworks/framework/base-component", "jquery", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'actualModel': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_setActualModel').perform();
    }).drop().on('willInsertElement'),
    'onModelChange': Ember.observer('selectedNode', function () {
      this.get('_setActualModel').perform();
    }),
    'onActualModelChange': Ember.observer('actualModel', function () {
      this.get('_setBreadcrumbHierarchy').perform();
    }),
    '_setActualModel': (0, _emberConcurrency.task)(function* () {
      const model = this.get('selectedNode');

      if (!model) {
        this.set('actualModel', null);
        return;
      }

      if (model.data.type === 'format-folder') {
        const actualModel = yield this.get('store').findRecord('work-order/folder', model.id);
        this.set('actualModel', actualModel);
      }

      if (model.data.type === 'format') {
        const actualModel = yield this.get('store').findRecord('work-order/work-order-format', model.id);
        this.set('actualModel', actualModel);
      }
    }).keepLatest(),
    '_setBreadcrumbHierarchy': (0, _emberConcurrency.task)(function* () {
      let treeNodeType = this.get('selectedNode.data.type');
      let actualModel = this.get('actualModel');
      if (!actualModel) return;
      const actualModelHierarchy = [];

      while (actualModel) {
        if (actualModel.get('name')) actualModelHierarchy.unshift(actualModel);

        if (treeNodeType === 'format-folder') {
          actualModel = yield actualModel.get('parent');
        }

        if (['format'].includes(treeNodeType)) {
          actualModel = yield actualModel.get('folder');
          treeNodeType = 'format-folder';
        }
      }

      let treeNode = (0, _jquery.default)('div#work-order-app-tree-container').jstree('get_node', this.get('actualModel.id'));

      if (!treeNode) {
        const treeNodePath = actualModelHierarchy.map(x => {
          return x.get('id');
        });
        this.set('_treeNodesToOpen', treeNodePath);
        (0, _emberLifeline.runTask)(this, this._openTreeNode, 2000);
        return;
      }

      const breadcrumbHierarchy = actualModelHierarchy.map(x => {
        return (0, _jquery.default)('div#work-order-app-tree-container').jstree('get_node', x.get('id'));
      });
      this.set('breadcrumbStack', breadcrumbHierarchy);
    }).keepLatest(),

    _openTreeNode() {
      const nodesToOpen = this.get('_treeNodesToOpen');
      const openThisNode = nodesToOpen.shift();

      if (nodesToOpen.length) {
        const self = this;
        (0, _jquery.default)('div#work-order-app-tree-container').jstree('open_node', openThisNode, function () {
          self._openTreeNode();
        });
        return;
      }

      this.set('_treeNodesToOpen', null);
      this.get('_setBreadcrumbHierarchy').perform();
      (0, _jquery.default)('div#work-order-app-tree-container').jstree('activate_node', openThisNode, false, false);
    }

  });

  _exports.default = _default;
});