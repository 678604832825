define("plantworks/models/work-order/wof-watcher", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantWof': _emberData.default.belongsTo('work-order/work-order-format', {
      'async': true,
      'inverse': 'watchers'
    }),
    'tenantUser': _emberData.default.belongsTo('pug/user-manager/tenant-user', {
      'async': true,
      'inverse': 'tenantWofs'
    })
  });

  _exports.default = _default;
});