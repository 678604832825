define("plantworks/components/historical-dashboard/execute/result-component-viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-unused-vars
  var _default = _baseComponent.default.extend({
    'media': Ember.inject.service('media'),
    'onInitTaskDone': false,
    'onBeforeRenderTaskDone': false,
    'onAfterRenderTaskDone': false,

    init() {
      this._super(...arguments);

      this._setupFrontEndHooks();
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      yield this.get('historicalDashboard.attributeSetMetadata');
      this.set('onInitTaskDone', true);
    }).on('init').keepLatest(),
    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      while (!this.get('onInitTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('componentBeforeRenderTask').perform();
    }).on('willInsertElement').enqueue(),
    'onDidRender': (0, _emberConcurrency.task)(function* () {
      if (this.get('onAfterRenderTaskDone')) return;

      while (!this.get('onBeforeRenderTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('componentAfterRenderTask').perform();
    }).on('didRender').enqueue(),
    'onResultDataChanged': Ember.observer('resultData', function () {
      this.get('onDataFunc').perform();
    }),

    _setupFrontEndHooks() {
      if (!this.get('resultComponentModel')) return; // Step 1: Sanity checks

      let componentBeforeRenderCode = this.get('resultComponentModel.componentBeforeRenderCode');
      if (!componentBeforeRenderCode) componentBeforeRenderCode = '';else componentBeforeRenderCode = componentBeforeRenderCode.trim();
      let componentAfterRenderCode = this.get('resultComponentModel.componentAfterRenderCode');
      if (!componentAfterRenderCode) componentAfterRenderCode = '';else componentAfterRenderCode = componentAfterRenderCode.trim();
      let componentOnDataCode = this.get('resultComponentModel.componentOnDataCode');
      if (!componentOnDataCode) componentOnDataCode = '';else componentOnDataCode = componentOnDataCode.trim();
      let componentBeforeDestroyCode = this.get('resultComponentModel.componentBeforeDestroyCode');
      if (!componentBeforeDestroyCode) componentBeforeDestroyCode = '';else componentBeforeDestroyCode = componentBeforeDestroyCode.trim(); // Step 2: Set defaults

      if (componentBeforeRenderCode === '') componentBeforeRenderCode = `this.debug('Executing custom componentBeforeRenderCode');\nyield;`;
      if (componentAfterRenderCode === '') componentAfterRenderCode = `this.debug('Executing custom componentAfterRenderCode');\nyield;`;
      if (componentOnDataCode === '') componentOnDataCode = `this.debug('Executing custom componentOnDataCode');\nyield;`;
      if (componentBeforeDestroyCode === '') componentBeforeDestroyCode = `this.debug('Executing custom componentBeforeDestroyCode');\nyield;`; // Step 3: Create functions using eval

      const GeneratorFunction = Object.getPrototypeOf(function* () {}).constructor;
      const componentBeforeRenderFunc = new GeneratorFunction(`
try {
	${componentBeforeRenderCode}

	this.set('onBeforeRenderTaskDone', true);
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentAfterRenderFunc = GeneratorFunction(`
try {
	${componentAfterRenderCode}

	this.set('onAfterRenderTaskDone', true);
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentBeforeDestroyFunc = GeneratorFunction(`
try {
	${componentBeforeDestroyCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentOnDataFunc = GeneratorFunction(`
try {
	${componentOnDataCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`); // Step 4: Set the new functions as properties of this object

      Ember.defineProperty(this, 'state', undefined, Ember.Object.create(this.get('resultComponentModel.frontendComponentState') || {}));
      Ember.defineProperty(this, 'componentBeforeRenderTask', (0, _emberConcurrency.task)(componentBeforeRenderFunc).enqueue());
      Ember.defineProperty(this, 'componentAfterRenderTask', (0, _emberConcurrency.task)(componentAfterRenderFunc).enqueue());
      Ember.defineProperty(this, 'onWillDestroyElement', (0, _emberConcurrency.task)(componentBeforeDestroyFunc).on('willDestroyElement').enqueue());
      Ember.defineProperty(this, 'onDataFunc', (0, _emberConcurrency.task)(componentOnDataFunc).enqueue()); // Repeating above steps for observers

      const Function = Object.getPrototypeOf(function () {}).constructor;
      const allObservers = this.get('resultComponentModel.componentObservers');
      allObservers.forEach(componentObserver => {
        if (!componentObserver.get('name') || !componentObserver.get('observedProperty') || !componentObserver.get('functionCode')) return;
        const templateObservedProperty = componentObserver.get('observedProperty');
        let componentObserverCode = componentObserver.get('functionCode');
        componentObserverCode = `
try {
	${componentObserverCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
			`;
        const componentObserverFunction = new Function(componentObserverCode);
        Ember.addObserver(this, templateObservedProperty, this, componentObserverFunction);
      }); // Repeating above steps for tasks

      const allTasks = this.get('resultComponentModel.componentTasks');
      allTasks.forEach(componentTask => {
        if (!componentTask.get('name') || !componentTask.get('functionCode')) return;
        let componentTaskCode = componentTask.get('functionCode');
        let componentTaskArgs = componentTask.get('arguments');
        if (componentTaskArgs) componentTaskArgs = componentTaskArgs.split(',').map(arg => {
          return arg.trim();
        });else componentTaskArgs = [];
        componentTaskCode = `
try {
	${componentTaskCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
			`;
        const componentTaskGeneratorFunction = new GeneratorFunction(...componentTaskArgs, componentTaskCode);
        Ember.defineProperty(this, componentTask.get('name'), (0, _emberConcurrency.task)(componentTaskGeneratorFunction).keepLatest());
      });
    }

  });

  _exports.default = _default;
});