define("plantworks/routes/index", ["exports", "plantworks/framework/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      const userData = this.get('currentUser').getUser();

      if (!userData) {
        this.get('store').unloadAll();
      }

      return null;
    },

    redirect() {
      const userData = this.get('currentUser').getUser();
      if (!userData) return;
      if (!userData.defaultApplication || userData.defaultApplication === '' || userData.defaultApplication === 'index' || userData.defaultApplication === 'dashboard') return;
      this.transitionTo(userData.defaultApplication);
    },

    onUserDataUpdated() {
      const userData = this.get('currentUser').getUser();

      if (!userData) {
        this.get('store').unloadAll();
      }

      const isActive = this.get('router').isActive(this.get('fullRouteName'));
      if (!isActive) return;
      if (userData.defaultApplication === '' || userData.defaultApplication === this.get('fullRouteName') || userData.defaultApplication === 'dashboard') return;
      this.transitionTo(userData.defaultApplication);
    }

  });

  _exports.default = _default;
});