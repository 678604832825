define("plantworks/templates/components/pug/user-manager/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "76sbA1sc",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-card\",[],[[\"@class\"],[\"m-0 mt-2 flex\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"flex layout-column layout-align-start-stretch\"]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"plant-works-checkbox\",[],[[\"@value\",\"@onChange\"],[[24,[\"state\",\"generateRandomPassword\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"state\",\"generateRandomPassword\"]]],null]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"t\",[\"plant_works_webapp_server_server.pug_feature.user_manager_feature.label_generate_password\"],null],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\\t\\t\"],[5,\"plant-works-input\",[],[[\"@type\",\"@label\",\"@value\",\"@onChange\",\"@disabled\"],[\"password\",[28,\"t\",[\"plant_works_webapp_server_server.pug_feature.user_manager_feature.label_new_password\"],null],[24,[\"state\",\"newPassword\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"state\",\"newPassword\"]]],null]],null],[24,[\"state\",\"generateRandomPassword\"]]]]],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/pug/user-manager/reset-password.hbs"
    }
  });

  _exports.default = _default;
});