define("plantworks/components/manufacturing/watch/plant-unit-line-work-order-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency", "moment", "ember-lifeline"], function (_exports, _baseComponent, _emberConcurrency, _moment, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'workOrderSets': null,
    'realtimeData': Ember.inject.service('realtime-data'),
    'subscribedLineId': null,
    'woLoaded': null,
    'disableWorkOrderCancelation': null,
    'disableWorkOrderStart': null,
    'workOrderTab': 'current',
    'uniqueWorkOrders': null,
    'uniqueInternalTag': 'PRODUCTION ORDER NO',
    'actualProductionInternalTag': 'ACTUAL IN KG',
    'workOrderProductionData': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
        yield null;
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }

      this._resubscribeRealtimeData();
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    didInsertElement() {
      this._super(...arguments);

      this.get('refreshOperationData').perform();
    },

    willDestroyElement() {
      (0, _emberLifeline.cancelPoll)(this, 'PRODUCTION_DATA_POLL');

      this._super(...arguments);
    },

    destroy() {
      this._super(...arguments);
    },

    '_selectedNodeChangeReactor': Ember.observer('selectedNode', function () {
      this.set('woLoaded', false);
      this.get('refreshOperationData').perform();
    }),
    'refreshOperationData': (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1000); // cancel the production data poll for old work order sets

      (0, _emberLifeline.cancelPoll)(this, 'PRODUCTION_DATA_POLL');
      let workOrderSets = yield this.get('ajax').request(`/manufacturing/plant-unit-line-work-orders/${this.get('selectedNode.tenantPlantUnitLine.id')}`, {
        'method': 'GET'
      });
      this.set('uniqueWorkOrders', null);
      let uniqueWorkOrders = new Set();
      workOrderSets.forEach(workOrderSet => {
        workOrderSet.rows.forEach(element => {
          uniqueWorkOrders.add(element.work_order_id);
          element.data.forEach((entry, idx) => {
            if (window.moment(entry, _moment.default.ISO_8601, true).isValid()) {
              element.data[idx] = window.moment(entry).format('DD/MM/YYYY HH:mm');
            }
          });
        });
      }); // set the poll interval to fetch the work order production data

      this.set('uniqueWorkOrders', uniqueWorkOrders);
      (0, _emberLifeline.pollTask)(this, '_getProductionData', 'PRODUCTION_DATA_POLL'); // converting to ember types

      workOrderSets = Ember.ArrayProxy.create({
        'content': Ember.A(workOrderSets.map(workOrderSet => {
          const uniqueInternalTagIndex = workOrderSet.head.indexOf(this.get('uniqueInternalTag'));
          workOrderSet.head = Ember.ArrayProxy.create({
            'content': Ember.A(workOrderSet.head)
          });
          workOrderSet.rows = Ember.ArrayProxy.create({
            'content': Ember.A(workOrderSet.rows.map(row => {
              const uniqueInternalTagValue = row.data[uniqueInternalTagIndex];
              row.data = row.data.filter((element, idx) => {
                return idx !== uniqueInternalTagIndex;
              });
              row = Ember.Object.create(row);
              row.set('showLineConfig', false);
              row.set('head', workOrderSet.head);
              row.set('tenantWorkOrderFormatId', workOrderSet.tenantWorkOrderFormatId);
              row.set('uniqueInternalTagValue', uniqueInternalTagValue);
              row.set('htmlSafeWorkOrderId', row.work_order_id.replace(/[^a-zA-Z0-9]/g, '_'));
              return row;
            }))
          });
          return Ember.Object.create(workOrderSet);
        }))
      });
      this.set('workOrderSets', workOrderSets);
      yield this.set('woLoaded', true);
    }),

    _getProductionData(next) {
      this.get('_getOperationProductionData').perform(next);
    },

    '_getOperationProductionData': (0, _emberConcurrency.task)(function* (next) {
      const productionData = yield this.get('ajax').request('/manufacturing/plant-unit-line-work-orders-production', {
        'dataType': 'json',
        'data': {
          'plant-unit-line-id': this.get('selectedNode.tenantPlantUnitLine.id'),
          'plant-unit-line-work-orders': JSON.stringify([...this.get('uniqueWorkOrders')]),
          'plant-unit-line-constituent-description': 'Weigher'
        },
        'method': 'GET'
      });
      this.set('workOrderProductionData', productionData);
      if (next) (0, _emberLifeline.runTask)(this, next, 60000);
    }),
    'changeStatus': (0, _emberConcurrency.task)(function* (newStatus, workOrder) {
      const statusData = Ember.Object.create();
      statusData.set('status', newStatus);
      statusData.set('updatedAt', (0, _moment.default)().format());
      let modalData = null;
      modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_perform_operation'),
          'iconType': 'mdi',
          'icon': 'file-upload'
        },
        'content': this.intl.t('plant_works_webapp_server_server.work_order_feature.perform_operation_message', {
          'htmlSafe': true,
          'displayName': newStatus
        }),
        'confirmButton': {
          'text': 'Change Status',
          'iconType': 'md',
          'icon': 'save',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('confirmChangeStatus').perform(statusData, workOrder);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }),
    'confirmChangeStatus': (0, _emberConcurrency.task)(function* (statusData, workOrder) {
      const requestData = {
        'workOrderId': workOrder.get('work_order_id'),
        'status': statusData.get('status'),
        'updatedAt': statusData.get('updatedAt'),
        'tenantPlantUnitStationObservedLineId': this.get('selectedNode.id')
      };
      yield this.get('ajax').request(`/work-order/change-work-order-status/${workOrder.get('tenantWorkOrderFormatId')}`, {
        'method': 'POST',
        'dataType': 'json',
        'data': requestData
      });
      workOrder.set('status', statusData.get('status'));
    }).keepLatest().evented(),
    'onChangeStatusErrored': Ember.on('confirmChangeStatus:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'actions': {
      'controller-action': function (action, data) {
        if (this[action] && typeof this[action] === 'function') {
          this[action](data);
          return;
        }

        this.invokeAction('controller-action', action, data);
      },
      'controller-action-2': function (action, data, data2) {
        if (this[action] && typeof this[action] === 'function') {
          this[action](data, data2);
          return;
        }
      }
    },

    _resubscribeRealtimeData() {
      if (this.get('subscribedLineId')) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': this.get('subscribedLineId'),
          'type': 'line.event'
        });
      }

      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'id': this.get('selectedNode.tenantPlantUnitLine.id'),
        'type': 'line.event'
      });
      this.set('subscribedLineId', this.get('selectedNode.tenantPlantUnitLine.id'));
    },

    _processRealtimeData(data) {
      try {
        this.debug(`manufacturing::work-order-operations::work-order-list:_processRealtimeData::data: `, data);
        if (!data || !data.tag) return;

        if (data.tag === 'work_order_status_change') {
          this.get('refreshOperationData').perform();
        }
      } catch (err) {
        this.debug(`_processRealtimeData::Error: `, err);
      }
    }

  });

  _exports.default = _default;
});