define("plantworks/components/manufacturing/schedule/plant-unit-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,
    'allowedTypes': null,
    'subComponents': null,
    'selectedComponent': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        this._super(...arguments);

        this.set('permissions', 'manufacturing-read');
        const allowedScheduleTypes = yield this.get('ajax').request('/masterdata/plant-unit-schedule-types', {
          'method': 'GET'
        });
        this.set('allowedTypes', allowedScheduleTypes);
        this.set('subComponents', [{
          'iconType': 'fa',
          'icon': 'calendar-day',
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_holiday'),
          'component': 'Manufacturing/Schedule/PlantUnitHolidayList'
        }, {
          'iconType': 'fa',
          'icon': 'tools',
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_planned_downtime'),
          'component': 'Manufacturing/Schedule/PlantUnitPlannedDowntimeList'
        }, {
          'iconType': 'fa',
          'icon': 'stopwatch',
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_scheduled_downtime'),
          'component': 'Manufacturing/Schedule/PlantUnitScheduledDowntimeList'
        }, {
          'iconType': 'fa',
          'icon': 'briefcase',
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_shift'),
          'component': 'Manufacturing/Schedule/PlantUnitShiftList'
        }, {
          'iconType': 'fa',
          'icon': 'file-upload',
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_bulk_uploader'),
          'component': 'Manufacturing/Schedule/PlantUnitDowntimeUploader'
        }, {
          'iconType': 'fa',
          'icon': 'stopwatch',
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_shift_extension'),
          'component': 'Manufacturing/Schedule/PlantUnitShiftExtension'
        }]);
        this.set('selectedComponent', this.get('subComponents')[0]);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('manufacturing-create');
      const updatePerm = this.get('currentUser').hasPermission('manufacturing-update');
      const deletePerm = this.get('currentUser').hasPermission('manufacturing-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest()
  });

  _exports.default = _default;
});