define("plantworks/components/board/devenv/panel-executor-environment", ["exports", "plantworks/framework/base-component", "ember-lifeline"], function (_exports, _baseComponent, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDevMode': null,
    'allDevModes': null,

    init() {
      this._super(...arguments);

      this.set('allDevModes', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.board_feature.label_request_formatter',
          'component': 'Board/Devenv/RequestFormatter'
        }, {
          'name': 'plant_works_webapp_server_server.board_feature.label_executor',
          'component': 'Board/Devenv/RequestExecutor'
        }, {
          'name': 'plant_works_webapp_server_server.board_feature.label_response_formatter',
          'component': 'Board/Devenv/ResponseFormatter'
        }])
      }));
      this.set('currentDevMode', this.get('allDevModes').objectAt(0));
    },

    didRender() {
      if (!this.get('currentDevMode')) return;
      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'onActiveTabChanged': Ember.observer('currentDevMode', function () {
      if (!this.get('currentDevMode')) return;
      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    }),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.panel-executor-editor-subcomponent-container');
      const panelDivs = this.$('div.panel-executor-editor-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.panel-executor-editor-subcomponent-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});