define("plantworks/models/pug/user-manager/user-contact", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'type': _emberData.default.attr('string', {
      'defaultValue': 'mobile'
    }),
    'contact': _emberData.default.attr('string'),
    'verified': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'user': _emberData.default.belongsTo('pug/user-manager/user', {
      'async': true,
      'inverse': 'contacts'
    })
  });

  _exports.default = _default;
});