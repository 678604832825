define("plantworks/adapters/manufacturing/plant-unit-machine", ["exports", "ember-data", "ember-cli-uuid/mixins/adapters/uuid", "ember-ajax/mixins/ajax-support"], function (_exports, _emberData, _uuid, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_uuid.default, _ajaxSupport.default, {
    findRecord(store, type, id, snapshot) {
      console.log('snapshot: ', snapshot);
      let query = snapshot.adapterOptions && snapshot.adapterOptions.query;
      let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');

      if (query) {
        url += '?' + Object.keys(query).map(function (key) {
          return key + '=' + encodeURIComponent(query[key]);
        }).join('&');
      }

      return this.ajax(url, 'GET');
    }

  });

  _exports.default = _default;
});