define("plantworks/helpers/plant-works-power-select-is-selected", ["exports", "pworks-dsl/helpers/plant-works-power-select-is-selected"], function (_exports, _plantWorksPowerSelectIsSelected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectIsSelected.default;
    }
  });
  Object.defineProperty(_exports, "emberPowerSelectIsSelected", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectIsSelected.emberPowerSelectIsSelected;
    }
  });
});