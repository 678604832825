define("plantworks/services/realtime-data", ["exports", "plantworks/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    'boundStreamerOpen': null,
    'boundStreamerClose': null,
    'boundStreamerEnd': null,
    'boundStreamerError': null,
    'boundDataProcessor': null,
    'boundUserUpdating': null,
    'boundUserUpdated': null,
    'connectOptions': null,
    'currentUser': Ember.inject.service('current-user'),

    init() {
      this._super(...arguments);

      this.set('boundStreamerOpen', this.onStreamerOpen.bind(this));
      this.set('boundStreamerClose', this.onStreamerClose.bind(this));
      this.set('boundStreamerEnd', this.onStreamerEnd.bind(this));
      this.set('boundStreamerError', this.onStreamerError.bind(this));
      this.set('boundDataProcessor', this._websocketDataProcessor.bind(this));
      this.set('boundUserUpdating', this._boundUserUpdating.bind(this));
      this.set('boundUserUpdated', this._boundUserUpdated.bind(this));
      this.set('connectOptions', {
        'manual': true,
        'strategy': 'online, timeout, disconnect',
        'reconnect': {
          'min': 1000,
          'max': Infinity,
          'retries': 25
        }
      });
      let streamer, subDomain;

      if (_environment.default.IS_CORBER) {
        subDomain = this.get('currentUser').getSubDomain();
        if (subDomain != null && subDomain != undefined) streamer = new window.Primus(`wss://${subDomain}.${_environment.default.plantworks.domain}/`, this.get('connectOptions'));else streamer = new window.Primus(`wss://${_environment.default.plantworks.domain}/`, this.get('connectOptions'));
      } else streamer = new window.Primus('/', this.get('connectOptions'));

      streamer.on('open', this.get('boundStreamerOpen'));
      streamer.on('close', this.get('boundStreamerClose'));
      streamer.on('end', this.get('boundStreamerEnd'));
      streamer.on('error', this.get('boundStreamerError'));
      this.set('streamer', streamer);
      this.get('streamer').open();
      this.get('currentUser').on('userDataUpdating', this.get('boundUserUpdating'));
      this.get('currentUser').on('userDataUpdated', this.get('boundUserUpdated'));
    },

    destroy() {
      this.get('currentUser').off('userDataUpdating', this.get('boundUserUpdating'));
      this.get('currentUser').off('userDataUpdated', this.get('boundUserUpdated'));
      this.get('streamer').off('error', this.get('boundStreamerError'));
      this.get('streamer').off('end', this.get('boundStreamerEnd'));
      this.get('streamer').off('close', this.get('boundStreamerClose'));
      this.get('streamer').off('open', this.get('boundStreamerOpen'));
      this.get('streamer').end();

      this._super(...arguments);
    },

    onStreamerOpen() {
      this.get('streamer').on('data', this.get('boundDataProcessor'));
      this.trigger('websocket-open');
    },

    onStreamerClose() {
      this.get('streamer').off('data', this.get('boundDataProcessor'));
      this.trigger('websocket-close');
    },

    onStreamerEnd() {
      this.get('streamer').off('data', this.get('boundDataProcessor'));
      this.trigger('websocket-end');
    },

    onStreamerError() {
      this.trigger('websocket-error');
    },

    _websocketDataProcessor(websocketData) {
      this.trigger(`websocket-data::${websocketData.channel}`, websocketData.data);
      this.trigger(`data`, websocketData.channel, websocketData.data);
    },

    _boundUserUpdating() {
      this.get('streamer').end();
    },

    _boundUserUpdated() {
      this.get('streamer').open();
    }

  });

  _exports.default = _default;
});