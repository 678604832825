define("plantworks/templates/components/manufacturing/watch/coke-plant-unit-machine-operator-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kttljul6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"await\",[[24,[\"model\",\"tenantPlantUnitMachine\",\"currentOperator\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"model\",\"tenantPlantUnitMachine\",\"currentOperator\"]]],null],[24,[\"showInDropdownTag\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"\\n\"],[5,\"plant-works-button\",[],[[\"@iconButton\",\"@accent\",\"@raised\",\"@disabled\",\"@title\",\"@onClick\",\"@bubbles\"],[true,true,false,[22,\"isLoading\"],\"Unauthorize\",[28,\"perform\",[[24,[\"logout\"]]],null],false]],{\"statements\":[[0,\"\\n\\t\"],[5,\"fa-icon\",[],[[\"@icon\"],[\"sign-out-alt\"]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/watch/coke-plant-unit-machine-operator-display.hbs"
    }
  });

  _exports.default = _default;
});