define("plantworks/models/board/panel-aggregate", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModel, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'internalTag': _emberData.default.attr('string'),
    'evaluationExpression': _emberData.default.belongsTo('board/attribute-set-property', {
      'async': true,
      'inverse': 'dataPointExpression'
    }),
    'units': _emberData.default.attr('string'),
    'datatype': _emberData.default.attr('string', {
      'defaultValue': 'number'
    }),
    'timestampFormat': _emberData.default.attr('string', {
      'defaultValue': 'not_a_timestamp'
    }),
    'config': (0, _attributes.fragment)('board/fragments/panel-aggregate-config'),
    'filters': (0, _attributes.fragmentArray)('board/fragments/panel-aggregate-filter'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPanel': _emberData.default.belongsTo('board/panel', {
      'async': true,
      'inverse': 'dataPointAggregations'
    }),
    'onDatatypeChanged': Ember.on('didLoad', Ember.observer('datatype', function () {
      if (this.get('isDeleted') || this.get('isEmpty') || this.get('isError') || this.get('isLoading')) return;

      if (this.get('datatype') !== 'date') {
        this.set('timestampFormat', 'not_a_timestamp');
        return;
      }

      if (this.get('timestampFormat') !== 'not_a_timestamp') return;
      this.set('timestampFormat', 'unix_epoch_with_milliseconds');
    })),
    'saveDisabled': Ember.computed('internalTag', 'name', 'evaluationExpression', function () {
      if (this.get('internalTag').trim() === '' || this.get('name').trim() === '' || !this.get('evaluationExpression').get('id')) return true;
      return false;
    })
  });

  _exports.default = _default;
});