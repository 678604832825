define("plantworks/components/pug/user-manager/user-emds-access", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'togglePanelAccess': (0, _emberConcurrency.task)(function* (emd) {
      const newAccess = !emd.get('newAccess');
      yield emd.set('newAccess', newAccess);
    }).enqueue()
  });

  _exports.default = _default;
});