define("plantworks/templates/components/historical-dashboard/devenv/property-metadata-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wMjqgP9E",
    "block": "{\"symbols\":[\"Row\",\"@Body\"],\"statements\":[[6,[23,2,[\"Row\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[24,[\"InputDataPoint\",\"name\"]],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[24,[\"InputDataPoint\",\"internalTag\"]],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[\"@class\"],[\"px-2\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[24,[\"chosenEntityType\"]],\"machine\"],null],[28,\"eq\",[[24,[\"chosenEntityType\"]],\"line\"],null]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"t\",[[28,\"concat\",[\"plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_\",[24,[\"chosenEntityType\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"t\",[\"plant_works_webapp_server_server.historical_dashboard_feature.label_historical_dashboard\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[\"@class\"],[\"px-2\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"await\",[[24,[\"chosenEntity\",\"actualEntity\",\"path\"]]],null],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[\"@class\"],[\"px-2\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[24,[\"chosenDataPoint\",\"name\"]],false],[0,\" <\"],[1,[24,[\"chosenDataPoint\",\"internalTag\"]],false],[0,\">\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[6,[23,1,[\"Cell\"]],[],[[\"@class\"],[\"text-center\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"plant-works-checkbox\",[],[[\"@value\",\"@disabled\",\"@onChange\"],[[24,[\"metadata\",\"isGlobal\"]],true,null]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/historical-dashboard/devenv/property-metadata-viewer.hbs"
    }
  });

  _exports.default = _default;
});