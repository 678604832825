define("plantworks/models/board/fragments/panel-template-component-task", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'name': _emberData.default.attr('string'),
    'arguments': _emberData.default.attr('string'),
    'functionCode': _emberData.default.attr('string')
  });

  _exports.default = _default;
});