define("plantworks/components/settings/account/features/chooser-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'editable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-features-read');
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('settings-account-features-update');
      this.set('editable', updatePerm);
    }),
    'modifyTenantFeatureStatus': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedFeature')) return;
      let tenantFeature = yield this.get('selectedFeature.tenantFeature');

      if (tenantFeature) {
        try {
          yield tenantFeature.destroyRecord();
          window.PlantWorksApp.trigger('userChanged');
        } catch (err) {
          tenantFeature.rollback();
          throw err;
        }
      } else {
        const tenant = this.get('store').peekRecord('settings/account/basics/tenant', window.plantworksTenantId);
        tenantFeature = this.get('store').createRecord('settings/account/features/tenant-feature', {
          'tenant': tenant,
          'feature': this.get('selectedFeature')
        });

        try {
          yield tenantFeature.save();
          window.PlantWorksApp.trigger('userChanged');
        } catch (err) {
          tenantFeature.deleteRecord();
          throw err;
        }
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});