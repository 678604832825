define("plantworks/models/manufacturing/plant-unit-station", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-concurrency"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnit': _emberData.default.belongsTo('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'stations'
    }),
    'observedMachines': _emberData.default.hasMany('manufacturing/plant-unit-station-observed-machine', {
      'async': true,
      'inverse': 'tenantPlantUnitStation'
    }),
    'tenantPlantUnitMachineEvents': _emberData.default.hasMany('manufacturing/plant-unit-machine-event', {
      'async': true,
      'inverse': 'tenantPlantUnitStation'
    }),
    'tenantPlantUnitLineEvents': _emberData.default.hasMany('manufacturing/plant-unit-line-event', {
      'async': true,
      'inverse': 'tenantPlantUnitStation'
    }),
    'observedLines': _emberData.default.hasMany('manufacturing/plant-unit-station-observed-line', {
      'async': true,
      'inverse': 'tenantPlantUnitStation'
    }),
    'attachedForms': _emberData.default.hasMany('manufacturing/plant-unit-station-attached-form', {
      'async': true,
      'inverse': 'tenantPlantUnitStation'
    }),
    'watchers': _emberData.default.hasMany('manufacturing/plant-unit-station-watcher', {
      'async': true,
      'inverse': 'tenantPlantUnitStation'
    }),
    'inputMachines': Ember.computed('observedMachines.@each.tenantPlantUnitMachine', function () {
      return this.get('_observedMachines').perform();
    }),
    'inputLines': Ember.computed('observedLines.@each.tenantPlantUnitLine', function () {
      return this.get('_observedLines').perform();
    }),
    '_observedMachines': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('observedMachines');
      const machines = [];

      for (let idx = 0; idx < observedMachines.length; idx++) {
        const observedMachine = observedMachines.objectAt(idx);
        const machine = yield observedMachine.get('tenantPlantUnitMachine');
        machines.push(machine);
      }

      return Ember.ArrayProxy.create({
        'content': Ember.A(machines)
      });
    }).enqueue(),
    '_observedLines': (0, _emberConcurrency.task)(function* () {
      const observedLines = yield this.get('observedLines');
      const lines = [];

      for (let idx = 0; idx < observedLines.length; idx++) {
        const observedLine = observedLines.objectAt(idx);
        const line = yield observedLine.get('tenantPlantUnitLine');
        lines.push(line);
      }

      return Ember.ArrayProxy.create({
        'content': Ember.A(lines)
      });
    }).enqueue(),
    'path': Ember.computed('name', 'parent.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const plantUnit = yield this.get('tenantPlantUnit');
      const parentPath = yield plantUnit.get('path');
      return `${parentPath} > ${this.get('name')}`;
    }).keepLatest()
  });

  _exports.default = _default;
});