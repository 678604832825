define("plantworks/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(
  /* KeepOnlyChanged, */
  {
    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },

    keyForLink(attr) {
      return Ember.String.underscore(attr);
    },

    keyForRelationship(attr) {
      return Ember.String.underscore(attr);
    },

    serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});