define("plantworks/components/manufacturing/configure/plant-sub-unit-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'addPlantUnit': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const plant = yield this.get('model.plant');
      const newPlantUnit = this.get('store').createRecord('manufacturing/plant-unit', {
        'tenant': tenant,
        'plant': plant,
        'parent': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_sub_unit_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnit.set('name', displayName);
      newPlantUnit.set('description', displayName);
      const siblingUnits = yield this.get('model.units');
      siblingUnits.addObject(newPlantUnit);
      this.get('editPlantUnit').perform(newPlantUnit);
    }).enqueue(),
    'editPlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      yield unit.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      unit.set('isEditing', false);
      yield unit.rollback();
    }).enqueue(),
    'savePlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      unit.set('isEditing', false);
      yield unit.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitSucceeded': Ember.on('savePlantUnit:succeeded', function (taskInstance) {
      const unit = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_sub_unit_save', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    'savePlantUnitErrored': Ember.on('savePlantUnit:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      const unitName = unit.get('name');
      const shouldNavigateTree = unit.get('id') === this.get('model.id');
      let unitParent = yield unit.get('parent.id');
      if (!unitParent) unitParent = yield unit.get('plant.id');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_sub_unit'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_sub_unit_message', {
          'htmlSafe': true,
          'displayName': unitName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnit').perform(unit, unitName, unitParent, shouldNavigateTree);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnit': (0, _emberConcurrency.task)(function* (unit, unitName, unitParent, shouldNavigateTree) {
      // eslint-disable-line no-unused-vars
      yield unit.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitSucceeded': Ember.on('_confirmedDeletePlantUnit:succeeded', function (taskInstance) {
      const unitName = taskInstance.args[1];
      const parentNode = taskInstance.args[2];
      const shouldNavigateTree = taskInstance.args[3];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', parentNode, false, false);
      if (shouldNavigateTree) (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', parentNode, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_sub_unit_delete', {
          'htmlSafe': true,
          'displayName': unitName
        })
      });
    }),
    '_confirmedDeletePlantUnitErrored': Ember.on('_confirmedDeletePlantUnit:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedPlantUnit': function (selectedPlantUnit) {
      if (selectedPlantUnit.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedPlantUnit.get('id'), false, false);
    }
  });

  _exports.default = _default;
});