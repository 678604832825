define("plantworks/models/report/report-schedule", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModel, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'enabled': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'cronString': _emberData.default.attr('string'),
    'timezoneId': _emberData.default.attr('string'),
    'scheduledOutputTypes': (0, _attributes.array)('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantReport': _emberData.default.belongsTo('report/report', {
      'async': true,
      'inverse': 'schedules'
    })
  });

  _exports.default = _default;
});