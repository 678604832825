define("plantworks/components/manufacturing/schedule/plant-unit-shift-extension", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'shiftOptions': null,
    'adding': null,
    'currentModel': null,
    'currentDate': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      let today = new Date();
      today.setSeconds(0);
      today.setMinutes(0);
      today.setHours(0);
      const shiftList = yield this.get('model.schedules').filterBy('type', 'shift');
      this.set('shiftOptions', shiftList);
      this.set('currentDate', today);
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addShiftExtension': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitShiftExtension = this.get('store').createRecord('manufacturing/plant-unit-schedule-extension', {
        'tenant': tenant,
        'plantUnit': this.get('model')
      });
      newPlantUnitShiftExtension.set('extensionDate', window.moment().format());
      newPlantUnitShiftExtension.set('extensionDuration', '01:00');
      const siblingShifts = yield this.get('model.extensions');
      siblingShifts.addObject(newPlantUnitShiftExtension);
      this.get('editPlantUnitShiftExtension').perform(newPlantUnitShiftExtension);
    }).enqueue(),
    'editPlantUnitShiftExtension': (0, _emberConcurrency.task)(function* (Shift) {
      yield Shift.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitShiftExtension': (0, _emberConcurrency.task)(function* (Shift) {
      Shift.set('isEditing', false);
      yield Shift.rollback();
    }).enqueue(),
    'savePlantUnitShiftExtension': (0, _emberConcurrency.task)(function* (Shift) {
      Shift.set('isEditing', false);
      yield Shift.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onDateChange': (0, _emberConcurrency.task)(function* (Shift) {
      const dateExt = Shift.get('extensionDate');
      yield Shift.set('extensionDate', window.moment(dateExt).format());
    }).enqueue(),
    'savePlantUnitShiftExtensionSucceeded': Ember.on('savePlantUnitShiftExtension:succeeded', function (taskInstance) {
      const Shift = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_schedule_extension_save', {
          'htmlSafe': true,
          'displayName': Shift.get('shift.description')
        })
      });
    }),
    'savePlantUnitShiftExtensionErrored': Ember.on('savePlantUnitShiftExtension:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitShiftExtension': (0, _emberConcurrency.task)(function* (Shift) {
      const ShiftName = yield Shift.get('shift.description');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_schedule_extension'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_schedule_extension_message', {
          'htmlSafe': true,
          'displayName': ShiftName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitShiftExtension').perform(Shift);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitShiftExtension': (0, _emberConcurrency.task)(function* (Shift) {
      yield Shift.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitShiftExtensionSucceeded': Ember.on('_confirmedDeletePlantUnitShiftExtension:succeeded', function (taskInstance) {
      const Shift = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_schedule_extension_delete', {
          'htmlSafe': true,
          'displayName': Shift.get('shift.description')
        })
      });
    }),
    '_confirmedDeletePlantUnitShiftExtensionErrored': Ember.on('_confirmedDeletePlantUnitShiftExtension:errored', function (taskInstance, err) {
      const Shift = taskInstance.args[0];
      Shift.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});