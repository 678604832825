define("plantworks/components/manufacturing/configure/plant-unit-station-editor/upstream-machine-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'addObservedMachine': (0, _emberConcurrency.task)(function* () {
      const plantUnitStation = this.get('model');
      const plantUnitStationMachines = yield plantUnitStation.get('observedMachines');
      const alreadyAddedMachines = [];

      for (let idx = 0; idx < plantUnitStationMachines.length; idx++) {
        const plantUnitStationMachine = plantUnitStationMachines.objectAt(idx);
        const plantUnitMachine = yield plantUnitStationMachine.get('tenantPlantUnitMachine');
        alreadyAddedMachines.push(plantUnitMachine.get('id'));
      }

      const plantUnit = yield plantUnitStation.get('tenantPlantUnit');
      const plant = yield plantUnit.get('plant');
      const plantUnits = yield plant.get('units');
      const allPlantMachines = [];

      for (let idx = 0; idx < plantUnits.length; idx++) {
        const unit = plantUnits.objectAt(idx);
        const unitMachines = yield unit.get('machines');

        for (let jdx = 0; jdx < unitMachines.length; jdx++) {
          const machine = unitMachines.objectAt(jdx);
          if (machine.get('isNew')) continue;
          allPlantMachines.push(machine);
        }
      }

      const availableMachines = allPlantMachines.filter(plantMachine => {
        return !alreadyAddedMachines.includes(plantMachine.get('id'));
      });
      const self = this;
      const chooseModalModel = availableMachines.map(availableMachine => {
        return {
          'selected': false,
          'machine': availableMachine
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_driver_gateway_add_machine'),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitStationEditor/AddMachine',
        'componentState': {
          'model': chooseModalModel
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('addMachines').perform(chooseModalModel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deleteObservedMachine': (0, _emberConcurrency.task)(function* (observedMachine) {
      yield observedMachine.destroyRecord();
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addMachines': (0, _emberConcurrency.task)(function* (chooseModalModel) {
      const plantUnitStation = this.get('model');
      const tenant = yield plantUnitStation.get('tenant');
      const chosenMachines = chooseModalModel.filter(chosenMachine => {
        return chosenMachine.selected;
      });

      for (let idx = 0; idx < chosenMachines.length; idx++) {
        const chosenMachine = chosenMachines[idx];
        let observedMachine = chosenMachine.observedMachine;

        if (observedMachine) {
          if (observedMachine.get('isDirty')) {
            yield observedMachine.save();
          }

          continue;
        }

        chosenMachine.observedMachine = this.get('store').createRecord('manufacturing/plant-unit-station-observed-machine', {
          'tenant': tenant,
          'tenantPlantUnitStation': plantUnitStation,
          'tenantPlantUnitMachine': chosenMachine.machine
        });
        observedMachine = chosenMachine.observedMachine;
        plantUnitStation.get('observedMachines').addObject(observedMachine);
        chosenMachine.machine.get('linkedStations').addObject(observedMachine);
        yield observedMachine.save();
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});