define("plantworks/components/manufacturing/watch/snacks-shift-incharge-form", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency", "ember-moment/computeds/moment"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency, _moment2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'moment': Ember.inject.service('moment'),
    'userAssigned': true,
    'selectedLines': null,
    'selectedLineNodes': null,
    'editSupervisor': null,
    'realtimeData': Ember.inject.service('realtime-data'),
    'disableWorkOrderCancelation': false,

    init() {
      this._super(...arguments);
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const settins = yield tenant.get('settings');
      if (settins && settins.get('disableWorkOrderOperationsCancelation')) this.set('disableWorkOrderCancelation', true);

      try {
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
        yield null;
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    willDestroyElement() {
      (0, _emberLifeline.cancelPoll)(this, 'CURRENT_TIME_POLL');

      this._super(...arguments);
    },

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');
      const currentlySelectedLineNodes = this.get('selectedLineNodes');

      if (currentlySelectedLineNodes && currentlySelectedLineNodes.length) {
        for (let idx = 0; idx < currentlySelectedLineNodes.length; idx++) {
          const currentlySelectedLineNode = currentlySelectedLineNodes.objectAt(idx);
          this.get('realtimeData.streamer').write({
            'action': 'unsubscribe',
            'id': currentlySelectedLineNode['observedEntityId'],
            'type': 'line.event'
          });
        }
      }

      this._super(...arguments);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_toggleLineSelectors').perform();
    }).on('didInsertElement').keepLatest(),
    'onObservedLinesChanged': Ember.observer('model.observedLines.[]', function () {
      this.get('_toggleLineSelectors').perform();
    }),
    'onSelectedLineChanged': Ember.observer('selectedLines', function () {
      this.get('_setSelectedLineNodes').perform();
      this.get('_refreshSupervisor').perform();
    }),
    '_toggleLineSelectors': (0, _emberConcurrency.task)(function* () {
      const observedLines = yield this.get('model.observedLines');

      if (observedLines.get('length') === 0) {
        this.set('selectedLines', null);
        return;
      }

      if (observedLines.length >= 1) {
        const lineArray = [];

        for (let i = 0; i < observedLines.length; i++) {
          const line = observedLines.objectAt(i);
          yield line.get('tenantPlantUnitLine').reload();
          let plantUnitLineWatchers = yield line.get('tenantPlantUnitLine.watchers');

          for (let j = 0; j < plantUnitLineWatchers.length; j++) {
            const user = plantUnitLineWatchers.objectAt(j);
            const userId = yield user.get('tenantUser');

            if (this.get('currentUser').getUser().user_id === userId.get('user').get('id')) {
              lineArray.push(line);
            }
          }
        }

        lineArray.sort(function (a, b) {
          return a.get('tenantPlantUnitLine.name').localeCompare(b.get('tenantPlantUnitLine.name'), undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        const lines = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        lineArray.forEach(line => {
          lines.addObject(line);
        });
        this.set('userAssigned', lines.length > 0);
        this.set('assignedLines', lines);
        this.set('selectedLines', lines);
        this.set('editSupervisor', true);
        this.set('displayLineSelector', lines.length > 1);
        return;
      }
    }).keepLatest(),
    '_setSelectedLineNodes': (0, _emberConcurrency.task)(function* () {
      const currentlySelectedLineNodes = this.get('selectedLineNodes');

      if (currentlySelectedLineNodes && currentlySelectedLineNodes.length) {
        for (let idx = 0; idx < currentlySelectedLineNodes.length; idx++) {
          const currentlySelectedLineNode = currentlySelectedLineNodes.objectAt(idx);
          this.get('realtimeData.streamer').write({
            'action': 'unsubscribe',
            'id': currentlySelectedLineNode['observedEntityId'],
            'type': 'line.event'
          });
        }
      }

      const selectedLines = this.get('selectedLines');
      const selectedLineNodes = [];

      for (let idx = 0; idx < selectedLines.length; idx++) {
        const selectedLine = selectedLines.objectAt(idx);
        selectedLineNodes.push({
          'id': selectedLine.get('id'),
          'observedEntityId': selectedLine.get('tenantPlantUnitLine.id'),
          'data': {
            'type': 'line'
          }
        });
        this.get('realtimeData.streamer').write({
          'action': 'subscribe',
          'id': selectedLine.get('tenantPlantUnitLine.id'),
          'type': 'line.event'
        });
      }

      this.set('selectedLineNodes', selectedLineNodes);
      yield null;
    }).keepLatest(),

    _resubscribeRealtimeData() {
      const currentlySelectedLineNodes = this.get('selectedLineNodes');

      if (currentlySelectedLineNodes && currentlySelectedLineNodes.length) {
        for (let idx = 0; idx < currentlySelectedLineNodes.length; idx++) {
          const currentlySelectedLineNode = currentlySelectedLineNodes.objectAt(idx);
          this.get('realtimeData.streamer').write({
            'action': 'subscribe',
            'id': currentlySelectedLineNode['observedEntityId'],
            'type': 'line.event'
          });
        }
      }
    },

    _processRealtimeData(data) {
      try {
        this.debug(`manufacturing::work-order-operations::_processRealtimeData::data: `, data);
        if (!data || !data.tag) return;

        if (data.tag === 'supervisor_change') {
          this.get('_refreshSupervisor').perform(data.tenantPlantUnitLineId);
        }
      } catch (err) {
        this.debug(`_processRealtimeArrayData::Error: `, err);
      }
    },

    '_refreshSupervisor': (0, _emberConcurrency.task)(function* (tenantPlantUnitLineId) {
      let selectedLines = this.get('selectedLines');

      if (tenantPlantUnitLineId) {
        selectedLines = selectedLines.filterBy('tenantPlantUnitLine.id', tenantPlantUnitLineId);
      }

      if (selectedLines.length) {
        for (let idx = 0; idx < selectedLines.length; idx++) {
          const selectedLine = selectedLines.objectAt(idx).get('tenantPlantUnitLine');
          yield selectedLine.reload();
        }
      }
    }).keepLatest(),
    'updateLineWorkOrders': (0, _emberConcurrency.task)(function* (line) {
      line.set('isEditing', !line.get('isEditing'));
    }).keepLatest()
  });

  _exports.default = _default;
});