define("plantworks/models/server-administration/feature", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'type': _emberData.default.attr('string'),
    'deploy': _emberData.default.attr('string', {
      'defaultValue': 'default'
    }),
    'parent': _emberData.default.belongsTo('server-administration/feature', {
      'async': true,
      'inverse': 'features'
    }),
    'features': _emberData.default.hasMany('server-administration/feature', {
      'async': true,
      'inverse': 'parent'
    }),
    'permissions': _emberData.default.hasMany('server-administration/feature-permission', {
      'async': true,
      'inverse': 'feature'
    }),
    'i18n_name': Ember.on('init', Ember.observer('i18n_tag', function () {
      this.get('_computeI18nName').perform();
    })),
    'i18n_desc': Ember.on('init', Ember.observer('i18n_tag', function () {
      this.get('_computeI18nDesc').perform();
    })),
    'i18n_tag': Ember.computed('name', 'type', 'parent', 'intl.locale', function () {
      return this.get('_computeI18nTag').perform();
    }),
    '_computeI18nName': (0, _emberConcurrency.task)(function* () {
      const tag = yield this.get('i18n_tag');
      const title = this.intl.t(`${tag}.title`);
      this.set('displayName', title);
    }).keepLatest(),
    '_computeI18nDesc': (0, _emberConcurrency.task)(function* () {
      const tag = yield this.get('i18n_tag');
      const desc = this.intl.t(`${tag}.description`);
      this.set('displayDesc', desc);
    }).keepLatest(),
    '_computeI18nTag': (0, _emberConcurrency.task)(function* () {
      const parentChain = [];
      parentChain.push(`${Ember.String.underscore(this.get('name') || '').toLowerCase()}_${Ember.String.underscore(this.get('type') || '').toLowerCase()}`);
      const parentModule = yield this.get('parent');

      if (parentModule) {
        const parentTag = yield parentModule.get('i18n_tag');
        if (parentTag && parentTag !== '') parentChain.unshift(parentTag);
      }

      return parentChain.length ? parentChain.join('.') : '';
    }).keepLatest()
  });

  _exports.default = _default;
});