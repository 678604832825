define("plantworks/models/operator-form/operator-form-result-template", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'componentState': _emberData.default.attr('string', {
      'defaultValue': '{}'
    }),
    'componentBeforeRenderCode': _emberData.default.attr('string'),
    'componentAfterRenderCode': _emberData.default.attr('string'),
    'componentOnDataCode': _emberData.default.attr('string'),
    'componentBeforeDestroyCode': _emberData.default.attr('string'),
    'componentObservers': (0, _attributes.fragmentArray)('operator-form/fragments/operator-form-template-component-observer'),
    'componentTasks': (0, _attributes.fragmentArray)('operator-form/fragments/operator-form-template-component-task'),
    'template': _emberData.default.attr('string'),
    'publishStatus': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantOperatorForm': _emberData.default.belongsTo('operator-form/operator-form', {
      'async': true,
      'inverse': 'resultTemplates'
    }),
    'frontendComponentState': Ember.computed('componentState', {
      get() {
        return JSON.parse(this.get('componentState'));
      },

      set(key, value) {
        this.set('componentState', JSON.stringify(value));
        return value;
      }

    })
  });

  _exports.default = _default;
});