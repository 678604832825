define("plantworks/components/operator-form/devenv/input-component-editor", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'displayViews': null,
    'selectedDisplayView': null,
    'frontendHooks': null,
    'selectedFrontendHook': null,
    'customTemplateTop': null,
    'customTemplateBottom': null,
    'selectedNode': null,
    'currentInputTemplate': null,
    'currentInputTemplateId': null,

    init() {
      this._super(...arguments);

      this.set('displayViews', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_frontend_dev_component_code'
        }, {
          'name': 'general.label_frontend_dev_template_code'
        }])
      }));
      this.set('frontendHooks', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'general.label_frontend_dev_component_before_render'
        }, {
          'name': 'general.label_frontend_dev_component_after_render'
        }, {
          'name': 'general.label_frontend_dev_component_on_submit'
        }, {
          'name': 'general.label_frontend_dev_component_observers'
        }, {
          'name': 'general.label_frontend_dev_component_tasks'
        }, {
          'name': 'general.label_frontend_dev_component_before_destroy'
        }])
      }));
      this.set('customTemplateTop', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.set('customTemplateBottom', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.set('selectedDisplayView', this.get('displayViews').objectAt(0));
      this.set('selectedFrontendHook', this.get('frontendHooks').objectAt(0));
      this.get('_setupNodeAndInputTemplate').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'cancelOperatorFormInputTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentInputTemplate')) return;
      yield this.get('currentInputTemplate').rollback();
    }).enqueue(),
    'saveOperatorFormInputTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentInputTemplate')) return;
      yield this.get('currentInputTemplate').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveOperatorFormInputTemplateSucceeded': Ember.on('saveOperatorFormInputTemplate:succeeded', function () {
      const operatorForm = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.operator_form_feature.succesful_operator_form_save', {
          'htmlSafe': true,
          'displayName': operatorForm.get('name')
        })
      });
    }),
    'saveOperatorFormInputTemplateErrored': Ember.on('saveOperatorFormInputTemplate:errored', function (taskInstance, err) {
      const operatorFormInputTemplate = this.get('currentInputTemplate');
      operatorFormInputTemplate.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveOperatorFormInputTemplate': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentInputTemplate')) return;
      this.set('currentInputTemplate.publishStatus', publish);
      yield this.get('currentInputTemplate').save();
    }).keepLatest(),
    'editOperatorFormInputTemplate': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentInputTemplate')) return;
      if (!this.get('model')) return;
      const currentInputTemplate = this.get('currentInputTemplate'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const inputTemplates = yield this.get('model.inputTemplates');
      const newInputTemplate = this.get('store').createRecord(`operator-form/operator-form-input-template`, {
        'tenant': tenant,
        'tenantOperatorForm': this.get('model')
      });
      newInputTemplate.set('publishStatus', false);
      newInputTemplate.set('componentState', currentInputTemplate.get('componentState'));
      newInputTemplate.set('componentBeforeRenderCode', currentInputTemplate.get('componentBeforeRenderCode'));
      newInputTemplate.set('componentAfterRenderCode', currentInputTemplate.get('componentAfterRenderCode'));
      newInputTemplate.set('componentOnSubmitCode', currentInputTemplate.get('componentOnSubmitCode'));
      newInputTemplate.set('componentBeforeDestroyCode', currentInputTemplate.get('componentBeforeDestroyCode'));
      newInputTemplate.set('template', currentInputTemplate.get('template'));
      currentInputTemplate.get('componentObservers').forEach(componentObserver => {
        newInputTemplate.get('componentObservers').createFragment({
          'name': componentObserver.get('name'),
          'observedProperty': componentObserver.get('observedProperty'),
          'functionCode': componentObserver.get('functionCode')
        });
      });
      currentInputTemplate.get('componentTasks').forEach(componentTask => {
        newInputTemplate.get('componentTasks').createFragment({
          'name': componentTask.get('name'),
          'arguments': componentTask.get('arguments'),
          'functionCode': componentTask.get('functionCode')
        });
      });
      inputTemplates.addObject(newInputTemplate);
      this.set('currentInputTemplate', newInputTemplate);
      this.set('currentInputTemplateId', newInputTemplate.get('id'));
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,name,inputTemplates.[]}', function () {
      this.get('_setupNodeAndInputTemplate').perform();
    }),
    'onActiveTabChanged': Ember.observer('selectedDisplayView', function () {
      if (!this.get('selectedDisplayView')) return;
      const currTabIdx = this.get('displayViews').indexOf(this.get('selectedDisplayView'));

      if (currTabIdx === 0) {
        this.set('selectedFrontendHook', this.get('frontendHooks').objectAt(0));
        (0, _emberLifeline.runTask)(this, this._fillFrontendCodeEditor, 150);
        return;
      }

      if (currTabIdx === 1) {
        (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
        return;
      }
    }),
    'onActiveFrontendHookTabChanged': Ember.observer('selectedFrontendHook', function () {
      if (!this.get('selectedFrontendHook')) return;
      (0, _emberLifeline.runTask)(this, this._fillFrontendCodeEditor, 150);
    }),
    actions: {
      'resetSelectedSubcomponent': function () {
        this.resetSelectedSubcomponent();
      }
    },
    '_setupNodeAndInputTemplate': (0, _emberConcurrency.task)(function* () {
      this.set('selectedNode', null);
      if (!this.get('model')) return;
      const inputTemplates = yield this.get('model.inputTemplates');
      if (!inputTemplates) return;
      let currentInputTemplate = null;

      if (inputTemplates.length) {
        currentInputTemplate = inputTemplates.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentInputTemplate = this.get('store').createRecord(`operator-form/operator-form-input-template`, {
          'tenant': tenant,
          'tenantOperatorForm': this.get('model')
        });
        inputTemplates.addObject(currentInputTemplate);
      }

      this.set('selectedNode', {
        'id': this.get('model.id'),
        'text': this.get('model.name'),
        'data': {
          'type': 'operator-form'
        }
      });
      this.set('currentInputTemplateId', currentInputTemplate.get('id'));
      this.set('currentInputTemplate', currentInputTemplate);
    }).keepLatest(),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.operator-form-frontend-input-editor-subcomponent-container');
      const operatorFormDivs = this.$('div.operator-form-frontend-input-editor-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let operatorFormDivTotalHeight = 0;

      for (let operatorFormIdx = 0; operatorFormIdx < operatorFormDivs.length; operatorFormIdx++) {
        const thisOperatorForm = operatorFormDivs[operatorFormIdx];
        operatorFormDivTotalHeight += this.$(thisOperatorForm).outerHeight();
      }

      const topOperatorFormDivs = this.$('div.operator-form-frontend-input-editor-subcomponent-container div.panel.top');
      if (topOperatorFormDivs.length > 1) operatorFormDivTotalHeight += topOperatorFormDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - operatorFormDivTotalHeight > 300 ? containerDivHeight - operatorFormDivTotalHeight : 300);
    },
    '_fillFrontendCodeEditor': function () {
      const containerDiv = this.$('div.operator-form-frontend-input-editor-component-hooks-container');
      const operatorFormDivs = this.$('div.operator-form-frontend-input-editor-component-hooks-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let operatorFormDivTotalHeight = 0;

      for (let operatorFormIdx = 0; operatorFormIdx < operatorFormDivs.length; operatorFormIdx++) {
        const thisOperatorForm = operatorFormDivs[operatorFormIdx];
        operatorFormDivTotalHeight += this.$(thisOperatorForm).outerHeight();
      }

      const topOperatorFormDivs = this.$('div.operator-form-frontend-input-editor-component-hooks-container div.panel.top');
      if (topOperatorFormDivs.length > 1) operatorFormDivTotalHeight += topOperatorFormDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - operatorFormDivTotalHeight > 300 ? containerDivHeight - operatorFormDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});