define("plantworks/models/manufacturing/plant-unit-driver", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'status': _emberData.default.attr('boolean', {
      'defaultValue': true
    }),
    'deployedCloud': _emberData.default.attr('string', {
      'defaultValue': 'Plant.Works'
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnit': _emberData.default.belongsTo('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'drivers'
    }),
    'gateway': _emberData.default.belongsTo('masterdata/gateway-master', {
      'async': true,
      'inverse': 'drivers'
    }),
    'machines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'tenantPlantUnitDriver'
    }),
    'iotDeleteDisabled': Ember.computed('machines', function () {
      if (this.get('machines').get('length') == 0) return false;
      return true;
    }),
    'addMachineDisabled': Ember.computed('gateway', function () {
      if (!this.get('gateway').get('id')) return true;
      return false;
    })
  });

  _exports.default = _default;
});