define("plantworks/models/alert/fragments/alert-attribute-set-metadata", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'parameterId': _emberData.default.attr('string'),
    'constituentType': _emberData.default.attr('string'),
    'constituentId': _emberData.default.attr('string'),
    'externalTag': _emberData.default.attr('string'),
    'isGlobal': _emberData.default.attr('boolean', {
      'defaultValue': true
    })
  });

  _exports.default = _default;
});