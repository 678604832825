define("plantworks/templates/components/emd/configure/property-metadata-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FrpLMid",
    "block": "{\"symbols\":[],\"statements\":[[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_unique_id\"],null],[24,[\"metadata\",\"isUniqueId\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isUniqueId\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_null\"],null],[24,[\"metadata\",\"isNull\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isNull\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_show_in_dropdown\"],null],[24,[\"metadata\",\"showInDropdown\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"showInDropdown\"]]],null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/emd/configure/property-metadata-editor.hbs"
    }
  });

  _exports.default = _default;
});