define("plantworks/framework/base-controller", ["exports", "ember-invoke-action", "ember-debug-logger"], function (_exports, _emberInvokeAction, _emberDebugLogger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, _emberInvokeAction.InvokeActionMixin, {
    'ajax': Ember.inject.service('ajax'),
    'intl': Ember.inject.service('intl'),
    'router': Ember.inject.service('router'),
    'store': Ember.inject.service('store'),
    'debug': (0, _emberDebugLogger.default)('plantworks-controller'),
    'currentUser': Ember.inject.service('current-user'),
    'notification': Ember.inject.service('integrated-notification'),
    'permissions': null,
    'hasPermission': true,

    init() {
      this._super(...arguments);

      this.set('permissions', '*');
      this.get('currentUser').on('userDataUpdated', this, 'updatePermissions');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'updatePermissions');

      this._super(...arguments);
    },

    'onPermissionChanges': Ember.on('init', Ember.observer('permissions', function () {
      this.updatePermissions();
    })),

    updatePermissions() {
      const currentUser = this.get('currentUser');
      this.set('hasPermission', currentUser.hasPermission(this.get('permissions')));
    },

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'actions': {
      'controller-action': function (action, data) {
        if (this[action] && typeof this[action] === 'function') {
          this[action](data);
          return false;
        }

        this.get('target').send('controller-action', action, data);
        return false;
      }
    }
  });

  _exports.default = _default;
});