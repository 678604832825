define("plantworks/components/manufacturing/watch/coke-work-order-form", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency", "ember-moment/computeds/moment"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency, _moment2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'moment': Ember.inject.service('moment'),
    'reasonReload': false,
    'displayLineSelector': false,
    'userAssigned': true,
    'selectedLine': null,
    'selectedUnit': null,
    'selectedLineNode': null,
    'observedLineMachines': null,
    'operatorFormMachines': null,
    'operatorFormMachinesIds': null,
    'operatorFormMachinesNames': null,
    'selectedLineMachine': null,
    'selectedLineMachineNode': null,
    'selectedObservedMachine': null,
    'currentMachineTab': 'downtime',
    'machineTabSelected': false,
    'actualMachineDowntimes': null,
    'editSupervisor': null,
    'noInternetConnectivity': null,
    'noMachineConnectivity': null,
    'machineDowntimes': null,
    'machineSpeedDeviations': null,
    'downtimesLoaded': null,
    'currentOperator': null,
    'realtimeData': Ember.inject.service('realtime-data'),
    'flag': null,
    'selectedLineConstituents': null,
    'operatorFormReferenceMachine': null,
    'operatorFormObservedMachines': null,
    'operatorFormStationObservedMachines': null,
    'operatorFormName': null,
    'downtimesReasons': null,
    'disableWorkOrderCancelation': false,
    'workOrderOperationTask': (0, _emberConcurrency.task)(function* () {
      yield this.get('_updateCurrentEndTimeForDowntimes').perform();
    }),

    init() {
      this._super(...arguments);

      (0, _emberLifeline.pollTask)(this, '_updateCurrentEndTime', 'UPDATE_TIME_POLL');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const settins = yield tenant.get('settings');
      const unitId = yield this.get('model.-change-tracker.tenantPlantUnit'); // get all the shifts from units

      const selectedUnit = yield this.get('store').findRecord('manufacturing/plant-unit', unitId);
      this.set('selectedUnit', selectedUnit);
      if (settins && settins.get('disableWorkOrderOperationsCancelation')) this.set('disableWorkOrderCancelation', true);

      try {
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-error', this, '_unsubscribeRealtimeData');
        this.get('realtimeData').on('websocket-close', this, '_unsubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
        yield null;
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    willDestroyElement() {
      (0, _emberLifeline.cancelPoll)(this, 'UPDATE_TIME_POLL');

      this._super(...arguments);
    },

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');
      const currentlySelectedLineNode = this.get('selectedLineNode');

      if (currentlySelectedLineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedLineNode['observedEntityId'],
          'type': 'line.event'
        });
      }

      const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

      if (currentlySelectedMachineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedMachineNode['observedEntityId'],
          'type': 'machine.event'
        });
      }

      this._super(...arguments);
    },

    _updateCurrentEndTime(next) {
      this.get('_updateCurrentEndTimeForDowntimes').perform(next);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_toggleLineSelectors').perform();
    }).on('didInsertElement').keepLatest(),
    'onObservedLinesChanged': Ember.observer('model.observedLines.[]', function () {
      this.get('_toggleLineSelectors').perform();
    }),
    'onSelectedLineChanged': Ember.observer('selectedLine', function () {
      this.get('_displayLineMachines').perform();
      this.get('_setSelectedLineNode').perform();
      this.get('_refreshSupervisor').perform();
      this.set('flag', true);
      this.set('shiftData', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
    }),
    'onSelectedLineMachineChanged': Ember.observer('selectedLineMachine', function () {
      this.set('downtimesLoaded', false);
      this.set('currentMachineTab', 'downtime');
      this.get('_setSelectedLineMachineNode').perform();
      this.get('_setSelectedObservedMachine').perform();
      this.get('_refreshOperator').perform(); // if(!this.get('selectedLineMachine'))
      // 	return;

      this.set('flag', true);
      this.set('shiftData', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.get('_updateCurrentEndTimeForDowntimes').perform();
    }),
    'onCurrentMachineTabChanged': Ember.observer('currentMachineTab', function () {
      if (this.get('operatorFormMachines').includes(this.get('currentMachineTab'))) this.set('machineTabSelected', true);else this.set('machineTabSelected', false);
    }),
    'toggleDurationReasons': (0, _emberConcurrency.task)(function* (downtime) {
      yield downtime.set('isEditing', !downtime.get('isEditing'));
    }).enqueue(),
    '_toggleLineSelectors': (0, _emberConcurrency.task)(function* () {
      const observedLines = yield this.get('model.observedLines');

      if (observedLines.get('length') === 0) {
        this.set('selectedLine', null);
        this.set('displayLineSelector', false);
        return;
      }

      if (observedLines.length >= 1) {
        this.set('downtimesLoaded', false);
        const lineArray = [];

        for (let i = 0; i < observedLines.length; i++) {
          const line = observedLines.objectAt(i);
          yield line.get('tenantPlantUnitLine').reload();
          let plantUnitLineWatchers = yield line.get('tenantPlantUnitLine.watchers');

          for (let j = 0; j < plantUnitLineWatchers.length; j++) {
            const user = plantUnitLineWatchers.objectAt(j);
            const userId = yield user.get('tenantUser');

            if (this.get('currentUser').getUser().user_id === userId.get('user').get('id')) {
              lineArray.push(line);
            }
          }
        }

        const lines = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        lineArray.forEach(line => {
          lines.addObject(line);
        });
        this.set('userAssigned', lines.length > 0);
        this.set('assignedLines', lines);
        this.set('selectedLine', lines.objectAt(0));
        this.set('editSupervisor', true);
        this.set('displayLineSelector', lines.length > 1);
        return;
      }
    }).keepLatest(),
    '_displayLineMachines': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedLine')) {
        this.set('observedLineMachines', null);
        this.set('operatorFormMachines', null);
        this.set('selectedLineMachine', null);
        return;
      }

      const plantUnitLine = yield this.get('selectedLine.tenantPlantUnitLine');
      const selectedLineConstituents = yield plantUnitLine.get('inputConstituents');
      const allLineMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      const culpritMachineList = [];
      const selectedLineMachineConstituents = yield selectedLineConstituents['machine'];

      for (let idx = 0; idx < selectedLineMachineConstituents.length; idx++) {
        const observedMachine = selectedLineMachineConstituents.objectAt(idx).get('actualEntity');
        culpritMachineList.addObject({
          'id': observedMachine.get('id'),
          'name': observedMachine.get('name')
        });
      }

      const actualLineMachine = yield selectedLineConstituents['machine'].filterBy('actualEntity.description', 'line').objectAt(0);
      if (actualLineMachine) allLineMachines.addObject(actualLineMachine.get('actualEntity'));
      const stationLineMachines = [];
      const observedMachines = yield this.get('model.observedMachines');

      for (let observedMachineIdx = 0; observedMachineIdx < observedMachines.length; observedMachineIdx++) {
        const observedMachine = observedMachines.objectAt(observedMachineIdx);
        const machine = yield observedMachine.get('tenantPlantUnitMachine');
        stationLineMachines.addObject(machine.get('id'));
      }

      const monitoredLineMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      const operatorFormMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      const operatorFormMachinesIds = [];
      const operatorFormMachinesNames = [];
      let operatorFormName = '';
      let operatorFormObservedMachines = yield selectedLineConstituents['machine'].filterBy('actualEntity.description', 'operator_form_' + this.get('formNo'));

      for (let i = 0; i < operatorFormObservedMachines.length; i++) {
        const actualMachine = operatorFormObservedMachines.objectAt(i).get('actualEntity');
        operatorFormMachines.addObject(actualMachine);
        operatorFormMachinesIds.push(actualMachine.get('id'));
        operatorFormMachinesNames.push(actualMachine.get('name'));
      }

      allLineMachines.forEach(lineMachine => {
        if (!stationLineMachines.includes(lineMachine.get('id'))) return;
        monitoredLineMachines.addObject(lineMachine);
      });

      if (monitoredLineMachines.length < 1) {
        this.set('observedLineMachines', []);
        this.set('operatorFormMachines', []);
        this.set('selectedLineMachine', null);
        return;
      }

      operatorFormMachinesNames.forEach((machine, idx) => {
        if (idx == 0) operatorFormName = machine;else if (idx < operatorFormMachinesNames.length - 1) operatorFormName = operatorFormName + ',' + machine;else if (idx == operatorFormMachinesNames.length - 1) operatorFormName = operatorFormName + '&' + machine;
      });
      this.set('culpritMachineList', culpritMachineList);
      this.set('operatorFormObservedMachines', operatorFormObservedMachines);
      this.set('operatorFormMachinesIds', operatorFormMachinesIds);
      this.set('operatorFormMachinesNames', operatorFormMachinesNames);
      this.set('operatorFormMachines', operatorFormMachines);
      this.set('operatorFormName', operatorFormName);
      this.set('operatorFormObservedActualMachines', operatorFormObservedMachines); // this.set('operatorFormReferenceMachine', operatorFormMachines.objectAt(0));

      this.set('observedLineMachines', monitoredLineMachines);
      this.set('selectedLineMachine', monitoredLineMachines.objectAt(0));
      yield this.get('_setoperatorFormReferenceMachine').perform();
    }).keepLatest(),
    '_updateCurrentEndTimeForDowntimes': (0, _emberConcurrency.task)(function* (next) {
      this.set('unaccSpeedDeviations', true);

      if (!this.get('selectedLineMachine') && next) {
        (0, _emberLifeline.runTask)(this, next, 2000);
        return;
      }

      const currentTime = new Date();
      const lineMachine = this.get('selectedLineMachine');
      yield lineMachine.reload({
        adapterOptions: {
          query: {
            operator_form: 'operator_form_' + this.get('formNo')
          }
        }
      });
      yield lineMachine.get('downtimes').reload();
      let connDetails = yield this.get('ajax').request(`/manufacturing/plant-unit-machine-connection-details/${this.get('selectedLineMachine.id')}`, {
        'method': 'GET'
      });

      if (connDetails) {
        let date;

        if (connDetails.end_time) {
          date = moment(connDetails.end_time).format('DD-MMM-YYYY hh:mm A');
        } else {
          date = moment(connDetails.start_time).format('DD-MMM-YYYY hh:mm A');
        }

        this.set('date', date);
        this.set('connectionDetails', connDetails);
      }

      let actualMachineDowntimes = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      let totalUnaccTime = 0;
      let totalUnAccChangeoverTime = 0;
      let totalUnAccDowntimes = 0; // entries for downtimes tab only - not considering speed deviations
      // Loop through all the machines to calculate unaccounted times

      const machines = this.get('observedLineMachines');

      for (let i = 0; i < machines.length; i++) {
        const machine = machines.objectAt(i);
        let machineDowntimes = yield machine.get('downtimes').reload(); // filter out the same instances of the setuptime and downtime, loading this only if there's no idletimes

        if (machineDowntimes && machineDowntimes.length) {
          // Filter out downtimes which have duration less than 3 minutes except current downtime
          machineDowntimes = machineDowntimes.filter(d => {
            return (!d.get('endTime') || moment(d.get('endTime')).startOf('minute').diff(moment(d.get('startTime')).startOf('minute'), 'minutes') > 0) && !d.get('metadata.operator_form_' + this.get('formNo'));
          });

          for (let idx = 0; idx < machineDowntimes.length; idx++) {
            let thisMachineDowntime = machineDowntimes.objectAt(idx);

            if (this.get('reasonReload')) {
              // reasons model reload
              // get the unsaved reasons, remove them from the existing reasons model, reload the reasons model and push the unsaved reasons back in the model
              const newReasons = yield thisMachineDowntime.get('reasons').filter(reason => {
                return reason.get('isDirty');
              });
              newReasons.forEach(reason => {
                thisMachineDowntime.get('reasons').removeObject(reason);
              });
              yield thisMachineDowntime.get('reasons').reload();
              newReasons.forEach(reason => {
                thisMachineDowntime.get('reasons').addObject(reason);
              });
            }

            if (thisMachineDowntime.get('endTime')) {
              yield thisMachineDowntime.set('currentEndTime', null);
            } else {
              yield thisMachineDowntime.set('currentEndTime', currentTime);
            }

            if (thisMachineDowntime.get('type') == 'CHANGEOVER_TIME') {
              totalUnaccTime += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
              totalUnAccChangeoverTime += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
            }

            if (thisMachineDowntime.get('tenantPlantUnitMachine.id') == this.get('selectedLineMachine.id')) {
              if (!thisMachineDowntime.get('currentEndTime') || thisMachineDowntime.get('currentEndTime') && moment(thisMachineDowntime.get('currentEndTime')).diff(moment(thisMachineDowntime.get('startTime')).format(), 'seconds') > 60) {
                actualMachineDowntimes.addObject(thisMachineDowntime);
              }
            }

            if (thisMachineDowntime.get('type') != 'CHANGEOVER_TIME') {
              totalUnaccTime += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
              totalUnAccDowntimes += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
            }
          }
        }
      }

      yield this.set('actualMachineDowntimes', actualMachineDowntimes);
      yield this.get('_truncateShiftDowntimes').perform(actualMachineDowntimes);
      this.set('flag', false);
      this.set('downtimesLoaded', true);
      this.set('changeoverAlert', totalUnAccChangeoverTime > 0);
      this.set('downtimesAlert', totalUnAccDowntimes > 0); // reset the reason reload flag

      if (this.get('reasonReload')) this.set('reasonReload', !this.get('reasonReload'));
      if (next) (0, _emberLifeline.runTask)(this, next, 30000);
    }).keepLatest(),
    '_setSelectedLineNode': (0, _emberConcurrency.task)(function* () {
      const currentlySelectedLineNode = this.get('selectedLineNode');

      if (currentlySelectedLineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedLineNode['observedEntityId'],
          'type': 'line.event'
        });
      }

      this.set('selectedLineNode', {
        'id': this.get('selectedLine.id'),
        'observedEntityId': this.get('selectedLine.tenantPlantUnitLine.id'),
        'data': {
          'type': 'line'
        }
      });
      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'id': this.get('selectedLine.tenantPlantUnitLine.id'),
        'type': 'line.event'
      });
      yield null;
    }).keepLatest(),
    '_setSelectedLineMachineNode': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');

      for (let idx = 0; idx < observedMachines.length; idx++) {
        const observedMachine = observedMachines.objectAt(idx);

        if (observedMachine.get('tenantPlantUnitMachine.id') === this.get('selectedLineMachine.id')) {
          const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

          if (currentlySelectedMachineNode) {
            this.get('realtimeData.streamer').write({
              'action': 'unsubscribe',
              'id': currentlySelectedMachineNode['observedEntityId'],
              'type': 'machine.event'
            });
          }

          this.set('selectedLineMachineNode', {
            'id': observedMachine.get('id'),
            'observedEntityId': this.get('selectedLineMachine.id'),
            'data': {
              'type': 'machine'
            }
          });
          this.get('realtimeData.streamer').write({
            'action': 'subscribe',
            'id': this.get('selectedLineMachine.id'),
            'type': 'machine.event'
          });
          break;
        }
      }
    }).keepLatest(),
    '_setSelectedObservedMachine': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');
      const selectedLineMachine = this.get('selectedLineMachine');
      let selectedObservedMachine = null;
      if (!selectedLineMachine || !observedMachines || !observedMachines.get('length')) return;

      for (let idx = 0; idx < observedMachines.get('length'); idx++) {
        const observedMachine = observedMachines.objectAt(idx);
        const observedPlantUnitMachine = yield observedMachine.get('tenantPlantUnitMachine');

        if (observedPlantUnitMachine.get('id') === selectedLineMachine.get('id')) {
          selectedObservedMachine = observedMachine;
          return;
        }
      }

      this.set('selectedObservedMachine', selectedObservedMachine);
    }).keepLatest(),
    '_setoperatorFormReferenceMachine': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');
      if (!this.get('operatorFormMachinesIds').get('length')) return;
      const operatorFormReferenceMachineId = this.get('operatorFormMachinesIds')[0];
      let operatorFormReferenceMachine = null;
      if (!operatorFormReferenceMachineId || !observedMachines || !observedMachines.get('length')) return;
      const operatorFormStationObservedMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });

      for (let idx = 0; idx < observedMachines.get('length'); idx++) {
        const observedMachine = observedMachines.objectAt(idx);
        const observedPlantUnitMachine = yield observedMachine.get('tenantPlantUnitMachine');
        if (this.get('operatorFormMachinesIds').includes(observedPlantUnitMachine.get('id'))) operatorFormStationObservedMachines.addObject(observedMachine);

        if (observedPlantUnitMachine.get('id') === operatorFormReferenceMachineId) {
          operatorFormReferenceMachine = observedMachine;
        }
      }

      this.set('operatorFormStationObservedMachines', operatorFormStationObservedMachines);
      this.set('operatorFormReferenceMachine', operatorFormReferenceMachine);
      const downtimeList = yield this.get('selectedLineMachine.downtimeList');
      if (!downtimeList) return;
      let downtimesReasons = yield this.get('ajax').request(`/emd/retrieveDowntimeReasons/${downtimeList.get('id')}/${this.get('selectedLineMachine.id')}/downtime`, {
        'method': 'GET'
      });
      downtimesReasons = downtimesReasons.filter(reason => {
        return this.get('operatorFormMachinesNames').includes(reason.CULPRIT_MACHINE);
      });
      this.set('downtimesReasons', downtimesReasons.map(downtimesReason => {
        return {
          'machine': downtimesReason['CULPRIT_MACHINE'] ? downtimesReason['CULPRIT_MACHINE'] : downtimesReason['masterdata_id'],
          'reasonDescription': downtimesReason['REASON'] ? downtimesReason['REASON'] : downtimesReason['masterdata_id'],
          'reasonCode': downtimesReason['REASON_CODE'] ? downtimesReason['REASON_CODE'] : downtimesReason['masterdata_id'],
          'class': downtimesReason['CLASS'] ? downtimesReason['CLASS'] : downtimesReason['masterdata_id']
        };
      }));
    }).keepLatest(),

    _resubscribeRealtimeData() {
      const currentlySelectedLineNode = this.get('selectedLineNode');

      if (currentlySelectedLineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'subscribe',
          'id': currentlySelectedLineNode['observedEntityId'],
          'type': 'line.event'
        });
      }

      const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

      if (currentlySelectedMachineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'subscribe',
          'id': currentlySelectedMachineNode['observedEntityId'],
          'type': 'machine.event'
        });
      } // set the reason reload flag


      this.set('reasonReload', !this.get('reasonReload'));
      (0, _emberLifeline.pollTask)(this, '_updateCurrentEndTime', 'UPDATE_TIME_POLL');
    },

    _unsubscribeRealtimeData() {
      (0, _emberLifeline.cancelPoll)(this, 'UPDATE_TIME_POLL');
    },

    _processRealtimeData(data) {
      try {
        this.debug(`manufacturing::work-order-operations::_processRealtimeData::data: `, data);
        if (!data || !data.tag) return;

        if (data.tag === 'operator_change') {
          this.get('_refreshOperator').perform();
        }

        if (data.tag === 'supervisor_change') {
          this.get('_refreshSupervisor').perform();
        }
      } catch (err) {
        this.debug(`_processRealtimeArrayData::Error: `, err);
      }
    },

    'updateShifts': (0, _emberConcurrency.task)(function* (shifts) {
      yield shifts.set('isEditing', !shifts.get('isEditing'));
    }).keepLatest(),
    'updateShiftWorkOrders': (0, _emberConcurrency.task)(function* (workOrder) {
      yield workOrder.set('isEditing', !workOrder.get('isEditing'));
    }).keepLatest(),
    '_truncateShiftDowntimes': (0, _emberConcurrency.task)(function* (machineDowntimes) {
      if (this.get('flag') == true) {
        // get the current unit
        let selectedUnit = this.get('selectedUnit');
        let shifts = yield selectedUnit.get('schedules');
        let actualShiftDowntimeData = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });

        for (let i = 0; i < machineDowntimes.length; i++) {
          let downtime = machineDowntimes.objectAt(i);
          let actualShiftArray = shifts.filter(schedule => {
            let daycross = moment(schedule.startTime, 'HH:mm') >= moment(schedule.endTime, 'HH:mm');
            let condition;
            if (!daycross) condition = moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') >= moment(schedule.startTime, 'HH:mm') && moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') < moment(schedule.endTime, 'HH:mm');else condition = moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') >= moment(schedule.startTime, 'HH:mm') && moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') > moment(schedule.endTime, 'HH:mm') || moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') < moment(schedule.startTime, 'HH:mm') && moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') < moment(schedule.endTime, 'HH:mm');
            return schedule.type == 'shift' && moment(schedule.startDate).isSameOrBefore(moment(downtime.get('startTime'))) && moment(schedule.endDate).isSameOrAfter(moment(downtime.get('startTime'))) && condition;
          });
          const shiftObj1 = Ember.Object.extend({});
          const workOrderObj = Ember.Object.extend({});

          if (actualShiftArray.length) {
            let thisShift = actualShiftArray[0];
            let daycross = moment(thisShift.startTime, 'HH:mm') >= moment(thisShift.endTime, 'HH:mm');
            let st = thisShift.startTime.split(':');
            let et = thisShift.endTime.split(':');
            let condition = false;
            if (daycross) condition = moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') >= moment('00:00', 'HH:mm') && moment(moment(downtime.get('startTime')).format('HH:mm'), 'HH:mm') <= moment(thisShift.endTime, 'HH:mm');
            let intervalStart, intervalEnd;
            intervalStart = condition ? moment(downtime.get('startTime')).clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0).subtract(1, 'day') : moment(downtime.get('startTime')).clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0);

            if (moment(thisShift.startTime, 'HH:mm') >= moment(thisShift.endTime, 'HH:mm')) {
              intervalEnd = intervalStart.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0).add(1, 'day');
            } else {
              intervalEnd = intervalStart.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0);
            }

            let workOrderId = downtime.get('work_order_id').split(':')[0];
            let sku = downtime.get('sku').split(':')[0];

            if (actualShiftDowntimeData.findBy('timestampValue', moment(intervalStart).valueOf())) {
              let obj = actualShiftDowntimeData.findBy('timestampValue', moment(intervalStart).valueOf());
              let unaccountedDuration = yield downtime.get('unaccountedDurationForCalculations');

              if (obj.get('workOrders').findBy('workOrderId', workOrderId)) {
                let woObj = obj.get('workOrders').findBy('workOrderId', workOrderId);
                woObj.get('actualMachineDowntimes').addObject(downtime);
                let unaccForThisShift = obj.get('unaccountedDuration') + unaccountedDuration;
                obj.set('unaccountedDuration', unaccForThisShift);
                let unaccForThisShiftWorkOrder = woObj.get('unaccountedDuration') + unaccountedDuration;
                woObj.set('unaccountedDuration', unaccForThisShiftWorkOrder);
              } else {
                let woObj = workOrderObj.create({
                  workOrderId: workOrderId,
                  sku: sku,
                  actualMachineDowntimes: Ember.ArrayProxy.create({
                    'content': Ember.A([])
                  }),
                  unaccountedDuration: 0,
                  isEditing: false
                });
                woObj.get('actualMachineDowntimes').addObject(downtime);
                let unaccForThisShift = obj.get('unaccountedDuration') + unaccountedDuration;
                obj.set('unaccountedDuration', unaccForThisShift);
                woObj.set('unaccountedDuration', unaccountedDuration);
                obj.get('workOrders').addObject(woObj);
              }
            } else {
              //create an ember object
              let shiftObj = shiftObj1.create({
                intervalStart: moment(intervalStart).tz('Asia/Kolkata'),
                intervalEnd: moment(intervalEnd).tz('Asia/Kolkata'),
                date: moment(intervalStart).tz('Asia/Kolkata').format('DD/MM/YYYY'),
                shiftName: thisShift.description,
                displayStatus: false,
                unaccountedDuration: 0,
                workOrders: Ember.ArrayProxy.create({
                  'content': Ember.A([])
                }),
                timestampValue: moment(intervalStart).valueOf(),
                isEditing: false
              });
              let woObj = workOrderObj.create({
                workOrderId: workOrderId,
                sku: sku,
                actualMachineDowntimes: Ember.ArrayProxy.create({
                  'content': Ember.A([])
                }),
                unaccountedDuration: 0,
                isEditing: false
              });

              if (moment().tz('Asia/Kolkata').isBetween(moment(intervalStart).tz('Asia/Kolkata'), moment(intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
                shiftObj['displayStatus'] = true;
              }

              let unaccountedDuration = yield downtime.get('unaccountedDurationForCalculations');
              woObj.get('actualMachineDowntimes').addObject(downtime);
              woObj.set('unaccountedDuration', unaccountedDuration);
              shiftObj.set('unaccountedDuration', unaccountedDuration);
              shiftObj.get('workOrders').addObject(woObj);
              actualShiftDowntimeData.addObject(shiftObj);
            }
          }
        }

        actualShiftDowntimeData = actualShiftDowntimeData.sortBy('timestampValue').reverse();
        this.set('shiftData', actualShiftDowntimeData); // this.set('flag',false);
      } else if (this.get('flag') == false) {
        // get the first shift obj for shift data
        let actualShiftData = this.get('shiftData');
        let latestShiftData = actualShiftData.objectAt(0); // if the latest shift is still ongoing then update the downtimes in it

        if (moment().tz('Asia/Kolkata').isBetween(moment(latestShiftData.intervalStart).tz('Asia/Kolkata'), moment(latestShiftData.intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
          let workOrders = Ember.ArrayProxy.create({
            'content': Ember.A([])
          });
          const workOrderObj = Ember.Object.extend({});
          let thisFilterDowntime = machineDowntimes.filter(function (idleTime) {
            return moment(idleTime.startTime).tz('Asia/Kolkata') >= moment(latestShiftData['intervalStart']).tz('Asia/Kolkata') && moment(idleTime.endTime).tz('Asia/Kolkata') <= moment(latestShiftData['intervalEnd']).tz('Asia/Kolkata');
          });
          let shiftUnaccountedDuration = 0;

          if (thisFilterDowntime.length) {
            for (let f = 0; f < thisFilterDowntime.length; f++) {
              // reasons model reload
              // get the unsaved reasons, remove them from the existing reasons model, reload the reasons model and push the unsaved reasons back in the model
              const newReasons = yield thisFilterDowntime[f].get('reasons').filter(reason => {
                return reason.get('isDirty');
              });
              newReasons.forEach(reason => {
                thisFilterDowntime[f].get('reasons').removeObject(reason);
              });
              yield thisFilterDowntime[f].get('reasons').reload();
              newReasons.forEach(reason => {
                thisFilterDowntime[f].get('reasons').addObject(reason);
              });
              let workOrderId = thisFilterDowntime[f].get('work_order_id').split(':')[0];
              let sku = thisFilterDowntime[f].get('sku').split(':')[0];

              if (workOrders.findBy('workOrderId', workOrderId)) {
                let unaccountedDuration = yield thisFilterDowntime[f].get('unaccountedDurationForCalculations');
                let woObj = workOrders.findBy('workOrderId', workOrderId);
                woObj.get('actualMachineDowntimes').addObject(thisFilterDowntime[f]);
                shiftUnaccountedDuration += unaccountedDuration;
                let unaccForThisShiftWorkOrder = woObj.get('unaccountedDuration') + unaccountedDuration;
                woObj.set('unaccountedDuration', unaccForThisShiftWorkOrder);
              } else {
                let woObj = workOrderObj.create({
                  workOrderId: workOrderId,
                  sku: sku,
                  actualMachineDowntimes: Ember.ArrayProxy.create({
                    'content': Ember.A([])
                  }),
                  unaccountedDuration: 0,
                  isEditing: true
                });
                woObj.get('actualMachineDowntimes').addObject(thisFilterDowntime[f]);
                let unaccountedDuration = yield thisFilterDowntime[f].get('unaccountedDurationForCalculations');
                woObj.set('unaccountedDuration', unaccountedDuration);
                shiftUnaccountedDuration = unaccountedDuration;
                workOrders.addObject(woObj);
              }
            }
          } // update actualMachineDowntimes if any


          this.get('shiftData').objectAt(0).set('workOrders', workOrders);
          this.get('shiftData').objectAt(0).set('unaccountedDuration', shiftUnaccountedDuration); // if no downtimes then remove object

          if (!thisFilterDowntime.length) {
            this.get('shiftData').removeObject(latestShiftData);
          }
        } else if (moment().tz('Asia/Kolkata').isAfter(moment(latestShiftData.intervalEnd).tz('Asia/Kolkata'))) {
          let selectedUnit = this.get('selectedUnit');
          let shifts = yield selectedUnit.get('schedules');
          let actualShiftArray = shifts.filter(schedule => {
            return schedule.type == 'shift' && moment(schedule.startDate).isSameOrBefore(moment()) && moment(schedule.endDate).isSameOrAfter(moment()) && moment(moment().format('HH:mm'), 'HH:mm') >= moment(schedule.startTime, 'HH:mm') && moment(moment().format('HH:mm'), 'HH:mm') <= moment(schedule.endTime, 'HH:mm');
          });
          const shiftObj1 = Ember.Object.extend({});

          if (actualShiftArray.length) {
            let thisShift = actualShiftArray[0];
            let st = thisShift.startTime.split(':');
            let et = thisShift.endTime.split(':');
            let intervalStart, intervalEnd;
            intervalStart = moment().clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0);

            if (moment(thisShift.startTime, 'HH:mm') >= moment(thisShift.startTime, 'HH:mm')) {
              intervalEnd = moment().clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0).add(1, 'day');
            } else {
              intervalEnd = moment().clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0);
            }

            if (moment().tz('Asia/Kolkata').isBetween(moment(intervalStart).tz('Asia/Kolkata'), moment(intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
              //create an ember object
              let shiftObj = shiftObj1.create({
                intervalStart: moment(intervalStart).tz('Asia/Kolkata'),
                intervalEnd: moment(intervalEnd).tz('Asia/Kolkata'),
                date: moment(intervalStart).tz('Asia/Kolkata').format('DD/MM/YYYY'),
                shiftName: thisShift.description,
                displayStatus: true,
                timestampValue: moment(intervalStart).valueOf(),
                isEditing: true
              });
              let workOrders = Ember.ArrayProxy.create({
                'content': Ember.A([])
              });
              const workOrderObj = Ember.Object.extend({});
              let actualMachineDowntimes = Ember.ArrayProxy.create({
                'content': Ember.A([])
              });
              let thisFilterDowntime = machineDowntimes.filter(function (idleTime) {
                return moment(idleTime.startTime).tz('Asia/Kolkata') >= moment(shiftObj['intervalStart']).tz('Asia/Kolkata') && moment(idleTime.endTime).tz('Asia/Kolkata') <= moment(shiftObj['intervalEnd']).tz('Asia/Kolkata');
              });
              let shiftUnaccountedDuration = 0;

              if (thisFilterDowntime.length) {
                for (let f = 0; f < thisFilterDowntime.length; f++) {
                  let workOrderId = thisFilterDowntime[f].get('work_order_id').split(':')[0];
                  let sku = thisFilterDowntime[f].get('sku').split(':')[0];
                  actualMachineDowntimes.addObject(thisFilterDowntime[f]);

                  if (workOrders.findBy('workOrderId', workOrderId)) {
                    let unaccountedDuration = yield thisFilterDowntime[f].get('unaccountedDurationForCalculations');
                    let woObj = workOrders.findBy('workOrderId', workOrderId);
                    woObj.get('actualMachineDowntimes').addObject(thisFilterDowntime[f]);
                    shiftUnaccountedDuration += unaccountedDuration;
                    let unaccForThisShiftWorkOrder = woObj.get('unaccountedDuration') + unaccountedDuration;
                    woObj.set('unaccountedDuration', unaccForThisShiftWorkOrder);
                  } else {
                    let woObj = workOrderObj.create({
                      workOrderId: workOrderId,
                      sku: sku,
                      actualMachineDowntimes: Ember.ArrayProxy.create({
                        'content': Ember.A([])
                      }),
                      unaccountedDuration: 0,
                      isEditing: true
                    });
                    woObj.get('actualMachineDowntimes').addObject(thisFilterDowntime[f]);
                    let unaccountedDuration = yield thisFilterDowntime[f].get('unaccountedDurationForCalculations');
                    woObj.set('unaccountedDuration', unaccountedDuration);
                    shiftUnaccountedDuration = unaccountedDuration;
                    workOrders.addObject(woObj);
                  }
                }

                shiftObj['workOrders'] = workOrders;
                shiftObj['unaccountedDuration'] = shiftUnaccountedDuration;
                this.get('shiftData').unshiftObject(shiftObj);
              }
            }
          }
        }
      }
    }),
    'confirmSubmit': (0, _emberConcurrency.task)(function* (shiftIdx, data) {
      let modalData = null;
      modalData = {
        'title': {
          'text': 'Submit Run',
          'iconType': 'mdi',
          'icon': 'file-upload'
        },
        'content': this.intl.t('plant_works_webapp_server_server.work_order_feature.perform_submit_message', {
          'htmlSafe': true,
          'displayName': data.workOrderId
        }),
        'confirmButton': {
          'text': 'Submit',
          'iconType': 'md',
          'icon': 'save',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('submitWorkorderData').perform(shiftIdx, data);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }),
    'submitWorkorderData': (0, _emberConcurrency.task)(function* (shiftIdx, workOrder) {
      let tag = 'actualMachineDowntimes';
      let key = 'shiftData';
      let limit = 10;
      const downtimes = workOrder.get(tag);
      let updateDowntimeData = [];

      for (let i = 0; i < downtimes.length; i++) {
        const downtime = downtimes.objectAt(i);
        const unaccountedDuration = yield downtime.get('unaccountedDuration');
        const actualTime = moment(downtime.get('endTime') || undefined).startOf('minute').diff(moment(downtime.get('startTime')).startOf('minute'), 'minutes');

        if (unaccountedDuration > 0 && actualTime > limit) {
          this.get('notification').display({
            'type': 'error',
            'error': `${unaccountedDuration} Minutes of unaccounted time detected for the downtime entry which has more than ${limit} minutes duration`
          });
          return;
        }

        downtime.get('metadata').set('operator_form_' + this.get('formNo'), true);
        updateDowntimeData.push({
          'id': downtime.get('id'),
          'tenant_id': downtime.get('tenant.id'),
          'start_time': downtime.get('startTime'),
          'end_time': downtime.get('endTime'),
          'metadata': downtime.get('metadata')
        });
      }

      try {
        const selectedLineName = yield this.get('selectedLine.tenantPlantUnitLine.name');
        const selectedUnitName = yield this.get('selectedLine.tenantPlantUnitLine.tenantPlantUnit.name');
        yield this.get('ajax').post(`/manufacturing/coke-update-downtime-entry`, {
          'data': {
            'approvedDowntimeUpdate': false,
            'downtimeList': JSON.stringify(updateDowntimeData),
            'tenantPlantUnitMachineId': this.get('selectedLineMachine.id'),
            'tenantPlantUnitLine': selectedLineName,
            'tenantPlantUnit': selectedUnitName,
            'type': 'downtime'
          }
        });
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.submit_success_message', {
            'htmlSafe': true,
            'displayName': workOrder.workOrderId
          })
        });
        yield this.get(key).objectAt(shiftIdx).get('workOrders').removeObject(workOrder);

        if (!this.get(key).objectAt(shiftIdx).get('workOrders').length) {
          const shift = this.get(key).objectAt(shiftIdx);
          yield this.get(key).removeObject(shift);
        }

        this.set('flag', false);
        this.get('_updateCurrentEndTimeForDowntimes').perform();
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }),
    '_refreshOperator': (0, _emberConcurrency.task)(function* () {
      const selectedLineMachine = this.get('selectedLineMachine');
      if (selectedLineMachine) yield selectedLineMachine.reload({
        adapterOptions: {
          query: {
            operator_form: 'operator_form_' + this.get('formNo')
          }
        }
      });
    }).keepLatest(),
    '_refreshSupervisor': (0, _emberConcurrency.task)(function* () {
      const selectedLine = this.get('selectedLine.tenantPlantUnitLine');
      if (selectedLine) yield selectedLine.reload();
    }).keepLatest()
  });

  _exports.default = _default;
});