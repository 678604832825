define("plantworks/components/manufacturing/watch/speed-deviations-reason-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'speedDeviationReasons': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      yield this.get('_retrieveSpeedDeviationsReasons').perform();
    }).on('init').keepLatest(),
    '_retrieveSpeedDeviationsReasons': (0, _emberConcurrency.task)(function* () {
      if (!this.get('machine')) return;
      const speedDeviationReasonList = yield this.get('machine.speedDeviationList');
      if (!speedDeviationReasonList) return;
      const speedDeviationReasons = yield this.get('ajax').request(`/emd/retrieveDowntimeReasons/${speedDeviationReasonList.get('id')}/${this.get('machine.id')}/speed_deviation`, {
        'method': 'GET'
      });
      this.set('speedDeviationReasons', speedDeviationReasons.map(speedDeviationReason => {
        return {
          'reasonCode': speedDeviationReason['masterdata_id'],
          'reasonDescription': speedDeviationReason['displayValue'] ? speedDeviationReason['displayValue'] : speedDeviationReason['masterdata_id']
        };
      }));
      this.set('speedDeviationReasonValues', speedDeviationReasons.map(speedDeviationReason => {
        return speedDeviationReason['displayValue'] ? speedDeviationReason['displayValue'] : speedDeviationReason['masterdata_id'];
      }));
    }).keepLatest(),
    'addReason': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('machine.tenant');
      const newReason = this.get('store').createRecord('manufacturing/plant-unit-machine-downtime-reason', {
        'tenant': tenant,
        'tenantPlantUnitMachineDowntime': this.get('model'),
        'metadata': {}
      });
      this.get('model.reasons').addObject(newReason);
    }).enqueue(),
    'cancelReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.rollback();
    }).enqueue(),
    'saveReason': (0, _emberConcurrency.task)(function* (reason) {
      if (reason.reasonDurationInMin > 0 && reason.reasonCode != 'UNKNOWN') yield reason.save();else {
        this.get('notification').display({
          'type': 'error',
          'error': 'Invalid Input'
        });
        return;
      }
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveReasonSucceeded': Ember.on('saveReason:succeeded', function () {
      this.get('parentTask').perform();
    }),
    'saveReasonErrored': Ember.on('saveReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteSucceeded': Ember.on('deleteReason:succeeded', function () {
      this.get('parentTask').perform();
    }),
    'deleteReasonErrored': Ember.on('deleteReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onReasonDescChanged': Ember.observer('model.reasons.@each.reasonDescription', function () {
      this.get('model.reasons').forEach(reason => {
        if (!this.get('speedDeviationReasons')) return;
        const speedDeviationReason = this.get('speedDeviationReasons').filter(speedDeviationReason => {
          return speedDeviationReason['reasonDescription'] === reason.get('reasonDescription');
        })[0];
        if (!speedDeviationReason) return;
        reason.set('reasonCode', speedDeviationReason['reasonCode']);
      });
    })
  });

  _exports.default = _default;
});