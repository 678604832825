define("plantworks/models/manufacturing/plant-unit-line-block-entity", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'entityId': _emberData.default.attr('string'),
    'entityType': _emberData.default.attr('string'),
    'isActive': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'block': _emberData.default.belongsTo('manufacturing/plant-unit-line-constituent', {
      'async': true,
      'inverse': 'entities'
    })
  });

  _exports.default = _default;
});