define("plantworks/components/work-order/devenv/format-editor/backend-environment", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'selectedEvaluationStep': null,
    'evaluationSteps': null,
    'selectedOeeEvaluationStep': null,
    'oeeEvaluationSteps': null,
    'propertyMetadataEditor': 'WorkOrder/Devenv/FormatEditor/PropertyMetadataViewer',
    'propertyMetadataField': 'attributeSetMetadata',
    'rawDataTransformTop': null,
    'rawDataTransformBottom': null,
    'processedDataTransformTop': null,
    'processedDataTransformBottom': null,
    'preRealtimePushTransformTop': null,
    'preRealtimePushTransformBottom': null,
    'availabilityCalculationTop': null,
    'availabilityCalculationBottom': null,
    'performanceCalculationTop': null,
    'performanceCalculationBottom': null,
    'qualityCalculationTop': null,
    'qualityCalculationBottom': null,

    init() {
      this._super(...arguments);

      this.set('evaluationSteps', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.work_order_feature.label_raw_data_transformer'
        }, {
          'name': 'plant_works_webapp_server_server.common.label_attribute_set'
        }, {
          'name': 'plant_works_webapp_server_server.work_order_feature.label_processed_data_transformer'
        }])
      }));
      this.set('rawDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {string} [currentData] - Raw incoming data
  * @returns  {object} Current set of sensor data as key-value pairs.
  *
  * @summary  Takes the raw data incoming from the field, converts it into a dict, and returns the dict.
  */
`, `async _rawDataTransformer(currentData) {`])
      }));
      this.set('rawDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // return currentData;
}`])
      }));
      this.set('processedDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [evaluatedAttributes] - Processed data.
  * @returns  {undefined} Nothing.
  *
  * @summary  Post-processing of data that has already been evaluated. Done in-place.
  */
`, `async _processedDataTransformer(evaluatedAttributes) {`])
      }));
      this.set('processedDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    return null;
}`])
      }));
      this.set('selectedEvaluationStep', this.get('evaluationSteps').objectAt(0));
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'cancelWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).enqueue(),
    'saveWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
      yield this.get('model').reload();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveWorkOrderFormatSucceeded': Ember.on('saveWorkOrderFormat:succeeded', function () {
      const workOrderFormat = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.succesful_format_save', {
          'htmlSafe': true,
          'displayName': workOrderFormat.get('name')
        })
      });
    }),
    'saveWorkOrderFormatErrored': Ember.on('saveWorkOrderFormat:errored', function (taskInstance, err) {
      const workOrderFormat = this.get('model');
      workOrderFormat.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onActiveTabChanged': Ember.observer('selectedEvaluationStep', function () {
      if (!this.get('selectedEvaluationStep')) return;
      const currTabIdx = this.get('evaluationSteps').indexOf(this.get('selectedEvaluationStep'));

      if (currTabIdx === 0 || currTabIdx === 2 || currTabIdx === 5) {
        (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
        return;
      }
    }),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.work-order-format-backend-editor-subcomponent-container');
      const panelDivs = this.$('div.work-order-format-backend-editor-subcomponent-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.work-order-format-backend-editor-subcomponent-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});