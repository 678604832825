define("plantworks/app", ["exports", "ember-concurrency-retryable/policies/exponential-backoff", "plantworks/resolver", "plantworks/config/environment", "ember-load-initializers", "ember-concurrency-retryable/define-modifier"], function (_exports, _exponentialBackoff, _resolver, _environment, _emberLoadInitializers, _defineModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Add a "retryable" to all ember-concurrency tasks
  (0, _defineModifier.default)();
  const App = Ember.Application.extend(Ember.Evented, {
    'backoffPolicy': null,
    'modulePrefix': _environment.default.modulePrefix,
    'podModulePrefix': _environment.default.podModulePrefix,
    Resolver: _resolver.default,

    init() {
      this._super(...arguments);

      let postUrlBeacon;
      let postUrlAjax;
      let postUrlRsvpBeacon;
      let postUrlRsvpAjax;
      this.set('backoffPolicy', new _exponentialBackoff.default({
        'multiplier': 1.5,
        'minDelay': 500,
        'maxDelay': 10000
      }));

      if (_environment.default.IS_CORBER) {
        postUrlBeacon = `https://${_environment.default.plantworks.domain}/collectClientErrorData?source=onerror&method=beacon`;
        postUrlAjax = `https://${_environment.default.plantworks.domain}/collectClientErrorData?source=onerror&method=ajax`;
        postUrlRsvpBeacon = `https://${_environment.default.plantworks.domain}/collectClientErrorData?source=rsvperror&method=beacon`;
        postUrlRsvpAjax = `https://${_environment.default.plantworks.domain}/collectClientErrorData?source=rsvperror&method=ajax`;
      } else {
        postUrlBeacon = '/collectClientErrorData?source=onerror&method=beacon';
        postUrlAjax = '/collectClientErrorData?source=onerror&method=ajax';
        postUrlRsvpBeacon = '/collectClientErrorData?source=rsvperror&method=beacon';
        postUrlRsvpAjax = '/collectClientErrorData?source=rsvperror&method=ajax';
      }

      window.Ember.onerror = function (error) {
        const beaconData = {
          'data': {
            'user': window.plantworksUserId,
            'tenant': window.plantworksTenantId,
            'urlPath': location.href,
            'error': error.message,
            'stack': error.stack
          }
        };
        let beaconStatus = false;

        if (navigator.sendBeacon) {
          const formData = new FormData();
          Object.keys(beaconData.data).forEach(key => {
            formData.append(key, beaconData.data[key]);
          });
          beaconStatus = navigator.sendBeacon(postUrlBeacon, formData);
        }

        if (!beaconStatus) {
          beaconData.dataType = 'json';
          beaconData.method = 'post';
          beaconData.type = 'post';
          beaconData.url = postUrlAjax;
          window.$.ajax(beaconData);
        }
      };

      Ember.RSVP.on('error', function (error) {
        const beaconData = {
          'data': {
            'user': window.plantworksUserId,
            'tenant': window.plantworksTenantId,
            'urlPath': location.href,
            'error': error.message,
            'stack': error.stack
          }
        };
        let beaconStatus = false;

        if (navigator.sendBeacon) {
          const formData = new FormData();
          Object.keys(beaconData.data).forEach(key => {
            formData.append(key, beaconData.data[key]);
          });
          beaconStatus = navigator.sendBeacon(postUrlRsvpBeacon, formData);
        }

        if (!beaconStatus) {
          beaconData.dataType = 'json';
          beaconData.method = 'post';
          beaconData.type = 'post';
          beaconData.url = postUrlRsvpAjax;
          window.$.ajax(beaconData);
        }
      });
    }

  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});