define("plantworks/components/work-order/work-order-app/viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'viewData': null,
    'showApprove': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'formatViewData': Ember.observer('uploadedData', function () {
      let rejected = false;

      if (!this.get('uploadedData')) {
        this.set('viewData', null);
        this.set('showApprove', false);
        return;
      }

      this.set('showApprove', true);
      const viewData = {
        'head': this.get('uploadedData.attributes'),
        'rows': []
      };
      this.get('uploadedData.rows').forEach(row => {
        let singleRow = [];
        let date = row.date;
        viewData.head.forEach(col => {
          if (date.includes(col) && row[col] !== '') {
            singleRow.push(window.moment(row[col]).format('DD/MM/YYYY HH:mm'));
          } else {
            singleRow.push(row[col]);
          }
        });
        if (singleRow.includes('REJECTED')) rejected = true;else rejected = false;
        let rowObj = {
          'data': singleRow,
          'showNotes': false,
          'notes': row.notes,
          'reject': rejected
        };
        viewData.rows.push(rowObj);
      });
      this.set('viewData', viewData);
    }),
    'approveUpload': (0, _emberConcurrency.task)(function* () {
      const self = this;
      yield self.get('ajax').request(`/work-order/approve-upload/${self.get('selectedNode.id')}`, {
        'method': 'POST',
        'contentType': 'application/json',
        'data': self.get('uploadedData')
      });
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onApproveUploadSucceeded': Ember.on('approveUpload:succeeded', function () {
      let count = 0;
      this.get('uploadedData').rows.forEach(row => {
        if (row.status == 'NEW' || row.status == 'UPDATED') {
          count++;
        }
      });
      this.set('uploadedData', null);
      this.set('showApprove', false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.successfull_format_upload_approval', {
          'htmlSafe': true,
          'count': count
        })
      });
    }),
    'onApproveUploadErrored': Ember.on('approveUpload:errored', function (taskInstance, err) {
      this.set('uploadedData', null);
      this.set('showApprove', false);
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});