define("plantworks/components/operator-form/execute/request-wrapper", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HTMLBars
  } = window.Ember;

  var _default = _baseComponent.default.extend({
    'inputComponentLayout': null,
    'resultComponentLayout': null,
    'inputComponentModel': null,
    'resultComponentModel': null,
    'isExecuting': false,
    'displayResult': false,
    'resultData': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('_setupOperatorFormExecutorComponents').perform();
    },

    'onModelChanged': Ember.observer('model', function () {
      this.get('_setupOperatorFormExecutorComponents').perform();
    }),
    'submitOperatorFormData': function (inputData) {
      this.get('_executeOperatorForm').perform(inputData);
    },
    '_setupOperatorFormExecutorComponents': (0, _emberConcurrency.task)(function* () {
      const operatorForm = this.get('model');

      if (!operatorForm) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('resultData', null);
        return;
      }

      let currentInputTemplate = null;
      let currentResultTemplate = null;
      const inputTemplates = yield this.get('model.inputTemplates');

      if (!inputTemplates.get('length')) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('resultData', null);
        return;
      }

      if (this.get('mode') === 'development') {
        currentInputTemplate = inputTemplates.sortBy('createdAt').get('lastObject');
      } else {
        currentInputTemplate = inputTemplates.filterBy('publishStatus', true).sortBy('createdAt').get('lastObject');
      }

      if (!currentInputTemplate) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('resultData', null);
        return;
      } else {
        this.set('inputComponentModel', currentInputTemplate);
        this.set('inputComponentLayout', HTMLBars.compile((currentInputTemplate.get('template') || '').trim()));
      }

      const resultTemplates = yield this.get('model.resultTemplates');

      if (!resultTemplates.get('length')) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('resultData', null);
        return;
      }

      if (this.get('mode') === 'development') {
        currentResultTemplate = resultTemplates.sortBy('createdAt').get('lastObject');
      } else {
        currentResultTemplate = resultTemplates.filterBy('publishStatus', true).sortBy('createdAt').get('lastObject');
      }

      if (!currentResultTemplate) {
        this.set('inputComponentLayout', null);
        this.set('resultComponentLayout', null);
        this.set('inputComponentModel', null);
        this.set('resultComponentModel', null);
        this.set('isExecuting', false);
        this.set('displayResult', false);
        this.set('resultData', null);
        return;
      } else {
        this.set('resultComponentModel', currentResultTemplate);
        this.set('resultComponentLayout', HTMLBars.compile((currentResultTemplate.get('template') || '').trim()));
      }
    }).keepLatest(),
    '_executeOperatorForm': (0, _emberConcurrency.task)(function* (inputData) {
      if (this.get('isExecuting')) return;
      this.set('isExecuting', true);
      this.set('displayResult', false);
      this.set('resultData', null);
      inputData.operatorFormId = this.get('model.id');
      inputData.operatorFormMode = this.get('mode');
      inputData.stationId = this.get('station.id');
      const resultData = yield this.get('ajax').post('/operator-form/execute', {
        'dataType': 'json',
        'data': inputData
      });
      this.set('resultData', resultData);
    }).keepLatest().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_executeOperatorFormSucceeded': Ember.on('_executeOperatorForm:succeeded', function () {
      this.set('isExecuting', false);
      this.set('displayResult', true);
    }),
    '_executeOperatorFormErrored': Ember.on('_executeOperatorForm:errored', function (taskInstance, err) {
      this.set('isExecuting', false);
      this.set('displayResult', false);
      this.set('resultData', null);
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});