define("plantworks/components/manufacturing/watch/station-tree-component", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'router': Ember.inject.service('router'),
    'disableMaricoWoForm': true,
    'disableCokeWoForm': true,
    'disableV3WoForm': true,

    init() {
      this._super(...arguments);

      this.set('permissions', 'manufacturing-read');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const settins = yield tenant.get('settings');
      this.set('tenantName', settins.get('type'));
      if (settins && settins.get('type') === 'marico') this.set('disableMaricoWoForm', false);
      if (settins && settins.get('type') === 'coke') this.set('disableCokeWoForm', false);
      if (settins && settins.get('category') === 'v3') this.set('disableV3WoForm', false);

      this._createTree();
    }).on('didInsertElement').keepLatest(),

    willDestroyElement() {
      this.invokeAction('controller-action', 'setSelectedNode', null);
      (0, _jquery.default)('div#station-tree-container').jstree('destroy', true);

      this._super(...arguments);
    },

    onUserDataUpdated() {
      (0, _jquery.default)('div#station-tree-container').jstree('destroy', true);

      if (!this.get('currentUser').isLoggedIn()) {
        this.invokeAction('controller-action', 'setSelectedNode', null);
        return;
      }

      this._createTree();
    },

    _createTree() {
      if (!this.get('model')) return;
      if (!(0, _jquery.default)('div#station-tree-container').length) return;
      const self = this;
      const tenant = this.get('tenantName');
      const treeNodes = [{
        'id': `${this.get('model.id')}-forms`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_attached_forms'),
        'children': true,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_attached_forms')
        },
        'data': {
          'type': 'attachedForms'
        }
      }];

      if (this.get('disableMaricoWoForm') && this.get('disableCokeWoForm')) {
        if (tenant === 'zydus') {
          treeNodes.push({
            'id': `${this.get('model.id')}-work-orders`,
            'parent': '#',
            'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_zydus_work_orders'),
            'children': false,
            'li_attr': {
              'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_zydus_work_orders')
            },
            'data': {
              'type': 'ZydusworkOrders'
            }
          });
        } else {
          treeNodes.push({
            'id': `${this.get('model.id')}-work-orders`,
            'parent': '#',
            'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_work_orders'),
            'children': false,
            'li_attr': {
              'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_work_orders')
            },
            'data': {
              'type': 'workOrders'
            }
          });
        }
      }

      if (this.get('currentUser').hasPermission('work-order-admin-form-update')) treeNodes.push({
        'id': `${this.get('model.id')}-work-order-admin-form`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_work_order_admin_form'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_work_order_admin_form')
        },
        'data': {
          'type': 'workOrderAdminForm'
        }
      });
      if (this.get('currentUser').hasPermission('zydus-work-order-admin-form-update')) treeNodes.push({
        'id': `${this.get('model.id')}-work-order-admin-form`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_zydus_work_orders'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_zydus_work_orders')
        },
        'data': {
          'type': 'ZydusworkOrderAdminForm'
        }
      });
      if (this.get('currentUser').hasPermission('snacks-operator-form') && !this.get('disableV3WoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-operator-form`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_snacks_operator_form'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_snacks_operator_form')
        },
        'data': {
          'type': 'snacksOperatorForm'
        }
      });
      if (this.get('currentUser').hasPermission('snacks-shift-incharge-form') && !this.get('disableV3WoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-shift-incharge-form`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_snacks_incharge_form'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_snacks_incharge_form')
        },
        'data': {
          'type': 'snacksShiftInchargeForm'
        }
      });
      if (this.get('currentUser').hasPermission('marico-work-order-form') && !this.get('disableMaricoWoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-work-order-form`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_marico_work_order_form'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_marico_work_order_form')
        },
        'data': {
          'type': 'maricoWorkOrderForm'
        }
      });
      if (this.get('currentUser').hasPermission('coke-operator-order-form-1') && !this.get('disableCokeWoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-operator-form-1`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_1'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_1')
        },
        'data': {
          'type': 'cokeWorkOrderForm1'
        }
      });
      if (this.get('currentUser').hasPermission('coke-operator-order-form-2') && !this.get('disableCokeWoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-operator-form-2`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_2'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_2')
        },
        'data': {
          'type': 'cokeWorkOrderForm2'
        }
      });
      if (this.get('currentUser').hasPermission('coke-operator-order-form-3') && !this.get('disableCokeWoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-operator-form-3`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_3'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_3')
        },
        'data': {
          'type': 'cokeWorkOrderForm3'
        }
      });
      if (this.get('currentUser').hasPermission('coke-operator-order-form-4') && !this.get('disableCokeWoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-operator-form-4`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_4'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_4')
        },
        'data': {
          'type': 'cokeWorkOrderForm4'
        }
      });
      if (this.get('currentUser').hasPermission('coke-operator-order-form-5') && !this.get('disableCokeWoForm')) treeNodes.push({
        'id': `${this.get('model.id')}-operator-form-5`,
        'parent': '#',
        'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_5'),
        'children': false,
        'li_attr': {
          'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station_coke_work_order_form_5')
        },
        'data': {
          'type': 'cokeWorkOrderForm5'
        }
      });
      const moduTree = (0, _jquery.default)('div#station-tree-container').jstree({
        'core': {
          'check_callback': function (operation) {
            return operation !== 'move_node';
          },
          'multiple': false,
          'themes': {
            'name': 'default',
            'icons': false,
            'dots': false,
            'responsive': true
          },
          'data': function (node, callback) {
            if (node.id === '#') {
              callback.call(this, treeNodes);
              return;
            }

            const that = this;
            self.get(`model.${node.data.type}`).then(function (childNodes) {
              const promises = [];

              if (node.data.type === 'attachedForms') {
                childNodes.forEach(childNode => {
                  promises.push(childNode.get('tenantOperatorForm'));
                });
              }

              return Ember.RSVP.all(promises);
            }).then(function (stationComponents) {
              const subTreeNodes = stationComponents.map(stationComponent => {
                return {
                  'id': stationComponent.get('id'),
                  'text': stationComponent.get('name'),
                  'parent': node.id,
                  'children': false,
                  'data': {
                    'type': node.data.type,
                    'model': stationComponent
                  }
                };
              });
              callback.call(that, subTreeNodes.toArray());
              return;
            }).catch(function (error) {
              self.get('debug')(error);
              callback.call(that, []);
            });
          }
        },
        'plugins': ['sort', 'unique']
      });
      moduTree.on('ready.jstree', () => {
        let nodetoBeActive = (0, _jquery.default)('div#station-tree-container > ul > li:first-child').attr('id');
        (0, _jquery.default)('div#station-tree-container').jstree('open_node', nodetoBeActive);
        (0, _jquery.default)('div#station-tree-container').jstree('activate_node', nodetoBeActive, false, false);
      });
      moduTree.on('activate_node.jstree', (event, data) => {
        (0, _jquery.default)('div#station-tree-container').jstree('open_node', data.node.id);
        this.invokeAction('controller-action', 'setSelectedNode', data.node);
      });
    },

    'onModelChanged': Ember.observer('model', function () {
      this.onUserDataUpdated();
    })
  });

  _exports.default = _default;
});