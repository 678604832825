define("plantworks/models/operator-form/operator-form", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes", "ember-concurrency"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'dataPersistencePeriod': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'attributeSetMetadata': (0, _attributes.fragmentArray)('operator-form/fragments/operator-form-attribute-set-metadata'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'operatorForms'
    }),
    'folder': _emberData.default.belongsTo('operator-form/folder', {
      'async': true,
      'inverse': 'operatorForms'
    }),
    'attributeSets': _emberData.default.hasMany('operator-form/operator-form-attribute-set', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'linkedStations': _emberData.default.hasMany('manufacturing/plant-unit-station-attached-form', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'linkedMachines': _emberData.default.hasMany('manufacturing/plant-unit-machine-attached-form', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'linkedLines': _emberData.default.hasMany('manufacturing/plant-unit-line-attached-form', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'requestFormatters': _emberData.default.hasMany('operator-form/operator-form-request-formatter', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'executors': _emberData.default.hasMany('operator-form/operator-form-executor', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'responseFormatters': _emberData.default.hasMany('operator-form/operator-form-response-formatter', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'inputTemplates': _emberData.default.hasMany('operator-form/operator-form-input-template', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'resultTemplates': _emberData.default.hasMany('operator-form/operator-form-result-template', {
      'async': true,
      'inverse': 'tenantOperatorForm'
    }),
    'inputDataPoints': Ember.computed('dataPoints.@each.{name,internalTag,units,source,datatype}', function () {
      return this.get('_inputDataPoints').perform();
    }),
    'path': Ember.computed('name', 'folder.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const folder = yield this.get('folder');
      const parentPath = yield folder.get('path');
      return `${parentPath} > ${this.get('name')}`;
    }).keepLatest(),
    '_inputDataPoints': (0, _emberConcurrency.task)(function* () {
      const allDataPoints = yield this.get('dataPoints');
      return allDataPoints.filterBy('source', 'input');
    }).enqueue()
  });

  _exports.default = _default;
});