define("plantworks/components/manufacturing/watch/coke-station-observed-machine-add-operator", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'displayForm': 'loginForm',
    'username': '',
    'password': '',
    'showLogin': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
    },

    'doLogin': (0, _emberConcurrency.task)(function* () {
      const notification = this.get('notification');
      notification.display({
        'type': 'info',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_authorize_message')
      });

      try {
        const loginResult = yield this.get('ajax').post('/manufacturing/authorize-operator', {
          'dataType': 'json',
          'data': {
            'username': this.get('username'),
            'password': this.get('password')
          }
        });
        notification.display({
          'type': loginResult.status < 400 ? 'success' : 'error',
          'message': loginResult.info.message,
          'error': loginResult.info.message
        });

        if (loginResult.status < 400) {
          yield this.set('state.model.selectedMasterDataId', loginResult.firstName);
          yield this.get('state.callback')();
          this.set('showDialog', false);
        }
      } catch (err) {
        notification.display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),

    setDisplayForm(formName) {
      this.set('displayForm', formName);
    },

    'showLoginScreen': (0, _emberConcurrency.task)(function* () {
      yield this.set('showLogin', true);
    }).enqueue()
  });

  _exports.default = _default;
});