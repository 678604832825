define("plantworks/components/operator-form/configure/operator-form-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'operator-form-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('operator-form-create');
      const updatePerm = this.get('currentUser').hasPermission('operator-form-update');
      const deletePerm = this.get('currentUser').hasPermission('operator-form-delete');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
      yield;
    }).keepLatest(),
    'addOperatorForm': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newOperatorForm = this.get('store').createRecord('operator-form/operator-form', {
        'tenant': tenant,
        'folder': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.operator_form_feature.new_operator_form_name', {
        'now': window.moment().valueOf()
      });
      newOperatorForm.set('name', displayName);
      newOperatorForm.set('description', displayName);
      const siblingOperatorForms = yield this.get('model.operatorForms');
      siblingOperatorForms.addObject(newOperatorForm);
      this.get('editOperatorForm').perform(newOperatorForm);
    }).enqueue(),
    'editOperatorForm': (0, _emberConcurrency.task)(function* (operatorForm) {
      yield operatorForm.set('isEditing', true);
    }).enqueue(),
    'cancelOperatorForm': (0, _emberConcurrency.task)(function* (operatorForm) {
      operatorForm.set('isEditing', false);
      yield operatorForm.rollback();
    }).enqueue(),
    'saveOperatorForm': (0, _emberConcurrency.task)(function* (operatorForm) {
      operatorForm.set('isEditing', false);
      yield operatorForm.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveOperatorFormSucceeded': Ember.on('saveOperatorForm:succeeded', function (taskInstance) {
      const operatorForm = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.operator_form_feature.succesful_operator_form_save', {
          'htmlSafe': true,
          'displayName': operatorForm.get('name')
        })
      });
    }),
    'saveOperatorFormErrored': Ember.on('saveOperatorForm:errored', function (taskInstance, err) {
      const operatorForm = taskInstance.args[0];
      operatorForm.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteOperatorForm': (0, _emberConcurrency.task)(function* (operatorForm) {
      const operatorFormName = yield operatorForm.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.operator_form_feature.label_delete_operator_form'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.operator_form_feature.delete_operator_form_message', {
          'htmlSafe': true,
          'displayName': operatorFormName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteOperatorForm').perform(operatorForm);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteOperatorForm': (0, _emberConcurrency.task)(function* (operatorForm) {
      yield operatorForm.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteOperatorFormSucceeded': Ember.on('_confirmedDeleteOperatorForm:succeeded', function (taskInstance) {
      const operatorForm = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.operator_form_feature.succesful_operator_form_delete', {
          'htmlSafe': true,
          'displayName': operatorForm.get('name')
        })
      });
    }),
    '_confirmedDeleteOperatorFormErrored': Ember.on('_confirmedDeleteOperatorForm:errored', function (taskInstance, err) {
      const operatorForm = taskInstance.args[0];
      operatorForm.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedOperatorForm': function (selectedOperatorForm) {
      if (selectedOperatorForm.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedOperatorForm.get('id'), false, false);
    }
  });

  _exports.default = _default;
});