define("plantworks/models/work-order/work-order-format", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'rawDataTransformCode': _emberData.default.attr('string'),
    'processedDataTransformCode': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'workOrderFormats'
    }),
    'folder': _emberData.default.belongsTo('work-order/folder', {
      'async': true,
      'inverse': 'workOrderFormats'
    }),
    'attributeSets': _emberData.default.hasMany('work-order/format-attribute-set', {
      'async': true,
      'inverse': 'workOrderFormat'
    }),
    'attributeSetMetadata': (0, _attributes.fragmentArray)('work-order/fragments/format-attribute-set-metadata'),
    'watchers': _emberData.default.hasMany('work-order/wof-watcher', {
      'async': true,
      'inverse': 'tenantWof'
    })
  });

  _exports.default = _default;
});