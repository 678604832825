define("plantworks/components/manufacturing/schedule/plant-unit-shift-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDate': null,
    'schedulesList': null,
    'schedulesOption': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      this._super(...arguments);

      let d = new Date();
      d.setSeconds(0);
      d.setMinutes(0);
      d.setHours(0);
      this.set('currentDate', d);
      let schedulesList = yield this.get('model.schedules');
      schedulesList = schedulesList.filter(schedule => {
        return schedule.get('type') === 'shift' || schedule.get('type') === 'day';
      });
      schedulesList.forEach(schedule => {
        if (schedule.get('endDate').valueOf() === new Date('9999-12-31T23:59:59').valueOf()) schedule.set('noEndDate', true);else schedule.set('noEndDate', false);
        schedule.addObserver('noEndDate', function () {
          if (this.get('noEndDate')) this.set('endDate', new Date('9999-12-31T23:59:59'));else if (this.get('endDate').valueOf() === new Date('9999-12-31T23:59:59').valueOf()) {
            this.set('endDate', new Date(this.get('startDate').valueOf()));
            this.get('endDate').setHours(23, 59, 59, 999);
          }
        });
      });
      this.set('schedulesList', schedulesList);
      this.set('schedulesOption', ['shift', 'day']);
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addSchedule': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitSchedule = this.get('store').createRecord('manufacturing/plant-unit-schedule', {
        'tenant': tenant,
        'plant': this.get('model')
      });
      const displayDescription = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_shift_name', {
        'now': window.moment().valueOf()
      });
      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      newPlantUnitSchedule.set('description', displayDescription);
      newPlantUnitSchedule.set('type', this.get('allowedTypes')[3]);
      newPlantUnitSchedule.set('startDate', startDate);
      newPlantUnitSchedule.set('endDate', endDate);
      newPlantUnitSchedule.set('startTime', '13:00');
      newPlantUnitSchedule.set('endTime', '15:00');
      newPlantUnitSchedule.set('noEndDate', false);
      newPlantUnitSchedule.addObserver('noEndDate', function () {
        if (this.get('noEndDate')) this.set('endDate', new Date('9999-12-31T23:59:59'));else if (this.get('endDate').valueOf() === new Date('9999-12-31T23:59:59').valueOf()) {
          this.set('endDate', new Date(this.get('startDate').valueOf()));
          this.get('endDate').setHours(23, 59, 59, 999);
        }
      });
      const siblingSchedules = yield this.get('model.schedules');
      siblingSchedules.addObject(newPlantUnitSchedule);
      this.get('schedulesList').addObject(newPlantUnitSchedule);
      this.get('editPlantUnitSchedule').perform(newPlantUnitSchedule);
    }).enqueue(),
    'editPlantUnitSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      yield schedule.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      schedule.set('isEditing', false);
      yield schedule.rollback();
    }).enqueue(),
    'savePlantUnitSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      schedule.set('isEditing', false);
      yield schedule.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitScheduleSucceeded': Ember.on('savePlantUnitSchedule:succeeded', function (taskInstance) {
      const schedule = taskInstance.args[0];
      (0, _jquery.default)('div#schedule-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_schedule_save', {
          'htmlSafe': true,
          'displayName': schedule.get('type')
        })
      });
    }),
    'savePlantUnitScheduleErrored': Ember.on('savePlantUnitSchedule:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      const scheduleName = yield schedule.get('i18n_type');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_schedule'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_schedule_message', {
          'htmlSafe': true,
          'displayName': scheduleName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitSchedule').perform(schedule);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      yield schedule.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitScheduleSucceeded': (0, _emberConcurrency.task)(function* (taskInstance) {
      const schedule = taskInstance.args[0];
      (0, _jquery.default)('div#schedule-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_schedule_delete', {
          'htmlSafe': true,
          'displayName': schedule.get('i18n_type')
        })
      });
      let schedulesList = yield this.get('model.schedules');
      schedulesList = schedulesList.filter(schedule => {
        return schedule.get('type') === 'shift' || schedule.get('type') === 'day';
      });
      this.set('schedulesList', schedulesList);
    }).on('_confirmedDeletePlantUnitSchedule:succeeded'),
    '_confirmedDeletePlantUnitScheduleErrored': Ember.on('_confirmedDeletePlantUnitSchedule:errored', function (taskInstance, err) {
      const schedule = taskInstance.args[0];
      schedule.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onStartDateChange': (0, _emberConcurrency.task)(function* (schedule) {
      const dateStr = schedule.get('startDate');
      let d = new Date(dateStr);
      d.setHours(0, 0, 0, 0);
      yield schedule.set('startDate', d);
    }).enqueue(),
    'onEndDateChange': (0, _emberConcurrency.task)(function* (schedule) {
      const dateStr = schedule.get('endDate');
      let d = new Date(dateStr);
      d.setHours(23, 59, 59, 999);
      yield schedule.set('endDate', d);
    }).enqueue()
  });

  _exports.default = _default;
});