define("plantworks/models/settings/account/basics/tenant", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModel, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'subDomain': _emberData.default.attr('string'),
    'settings': (0, _attributes.fragment)('settings/fragments/tenant-settings'),
    'tenantLocations': _emberData.default.hasMany('settings/account/basics/tenant-location', {
      'async': true,
      'inverse': 'tenant'
    })
  });

  _exports.default = _default;
});