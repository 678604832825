define("plantworks/routes/dashboard", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
      this.get('currentUser').on('dashboardCategoryUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');
      this.get('currentUser').off('dashboardCategoryUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('dashboard/feature');
        return;
      }

      const featureData = this.get('store').peekAll('dashboard/feature');
      if (featureData.get('length')) return featureData;
      return this.get('store').findAll('dashboard/feature');
    },

    redirect(model, transition) {
      if (transition.targetName !== this.get('fullRouteName')) return;
      const features = this.get('store').peekAll('dashboard/feature');
      let onlyRoute = null;
      let onlyRouteParams = null;
      let activeFeatures = [];
      features.forEach(feature => {
        if (feature.active) activeFeatures.push(feature);
      });

      if (features.get('length') == 1) {
        onlyRoute = features.objectAt(0).get('route');
        onlyRouteParams = features.objectAt(0).get('routeParams');
      } else if (activeFeatures.get('length') == 1) {
        onlyRoute = activeFeatures.objectAt(0).get('route');
        onlyRouteParams = activeFeatures.objectAt(0).get('routeParams');
      } else {
        return;
      }

      if (!onlyRouteParams) this.transitionTo(onlyRoute);else this.transitionTo(onlyRoute, onlyRouteParams);
    },

    onUserDataUpdated() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('dashboard/feature');
      }

      const isActive = this.get('router').get('currentRouteName') && this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksUserId) {
        this.transitionTo('index');
        return;
      }

      this.get('_routeToActiveFeature').perform();
      this.get('_refreshDashboardFeatures').perform();
    },

    '_routeToActiveFeature': (0, _emberConcurrency.task)(function* () {
      const features = yield this.get('store').findAll('dashboard/feature');
      let onlyRoute = null;
      let onlyRouteParams = null;
      let activeFeatures = [];
      features.forEach(feature => {
        if (feature.active) activeFeatures.push(feature);
      });

      if (features.get('length') == 1) {
        onlyRoute = features.objectAt(0).get('route');
        onlyRouteParams = features.objectAt(0).get('routeParams');
      } else if (activeFeatures.get('length') == 1) {
        onlyRoute = activeFeatures.objectAt(0).get('route');
        onlyRouteParams = activeFeatures.objectAt(0).get('routeParams');
      } else {
        return;
      }

      if (!onlyRouteParams) this.transitionTo(onlyRoute);else this.transitionTo(onlyRoute, onlyRouteParams);
    }),
    '_refreshDashboardFeatures': (0, _emberConcurrency.task)(function* () {
      let featureData = yield this.get('store').findAll('dashboard/feature');
      this.get('controller').set('model', featureData);
    }).keepLatest()
  });

  _exports.default = _default;
});