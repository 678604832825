define("plantworks/components/pug/group-manager/user-group-editor-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'editable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'group-manager-read');
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('group-manager-update');
      this.set('editable', updatePerm);
    }),
    'addUser': (0, _emberConcurrency.task)(function* () {
      try {
        const self = this;
        const tenantUsersToBeAdded = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        const modalData = {
          'title': {
            'text': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_add_group_users'),
            'iconType': 'md',
            'icon': 'verified-user'
          },
          'componentName': 'Pug/GroupManager/UserGroupAddAccounts',
          'componentState': {
            'group': this.get('selectedGroup'),
            'model': tenantUsersToBeAdded
          },
          'confirmButton': {
            'text': this.intl.t('modal.default_add_text'),
            'iconType': 'md',
            'icon': 'check',
            'primary': true,
            'raised': true,
            'callback': () => {
              self.get('_doAddAccounts').perform(tenantUsersToBeAdded);
            }
          },
          'cancelButton': {
            'text': this.intl.t('modal.default_cancel_text'),
            'iconType': 'md',
            'icon': 'cancel',
            'warn': true,
            'raised': true,
            'callback': null
          }
        };
        yield this.send('controller-action', 'displayModal', modalData);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop(),
    '_doAddAccounts': (0, _emberConcurrency.task)(function* (tenantUserList) {
      for (let idx = 0; idx < tenantUserList.get('length'); idx++) {
        const tenantUser = tenantUserList.objectAt(idx);
        let groupUser = this.get('store').peekAll('pug/group-manager/tenant-user-group').filterBy('tenantUser.id', tenantUser.get('id'));
        groupUser = groupUser.filterBy('tenantGroup.id', this.get('selectedGroup.id')).objectAt(0);
        if (groupUser && !groupUser.get('isNew')) continue;
        let storedTenantUser = this.get('store').peekRecord('pug/user-manager/tenant-user', tenantUser.get('id'));
        if (!storedTenantUser) storedTenantUser = yield this.get('store').findRecord('pug/user-manager/tenant-user', tenantUser.get('id'));
        if (!groupUser) groupUser = this.get('store').createRecord('pug/group-manager/tenant-user-group', {
          'tenantUser': storedTenantUser,
          'tenantGroup': this.get('selectedGroup')
        });
        yield groupUser.save();
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_doAddAccountsSucceeded': Ember.on('_doAddAccounts:succeeded', function (taskInstance) {
      const tenantUserList = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.add_group_user_message', {
          'numAdded': tenantUserList.get('length'),
          'groupDisplayName': this.get('selectedGroup.displayName')
        })
      });
    }),
    '_doAddAccountsErrored': Ember.on('_doAddAccounts:errored', function (taskInstance, err) {
      const tenantUserList = taskInstance.args[0];

      for (let idx = 0; idx < tenantUserList.get('length'); idx++) {
        const tenantUser = tenantUserList.objectAt(idx);
        let groupUser = this.get('store').peekAll('pug/group-manager/tenant-user-group').filterBy('tenantUser.id', tenantUser.get('id'));
        groupUser = groupUser.filterBy('tenantGroup.id', this.get('selectedGroup.id')).objectAt(0);
        if (groupUser && !groupUser.get('isNew')) continue;
        groupUser.deleteRecord();
      }

      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'removeUser': (0, _emberConcurrency.task)(function* (groupUser) {
      const user = yield groupUser.get('tenantUser.user');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_delete_group_user'),
          'iconType': 'md',
          'icon': 'verified-user'
        },
        'content': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.delete_group_user_message', {
          'htmlSafe': true,
          'userFullName': user.get('fullName'),
          'groupDisplayName': this.get('selectedGroup.displayName')
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedRemoveUser').perform(groupUser, user);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedRemoveUser': (0, _emberConcurrency.task)(function* (groupUser, user) {
      // eslint-disable-line no-unused-vars
      yield groupUser.destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedRemoveUserSucceeded': Ember.on('_confirmedRemoveUser:succeeded', function (taskInstance) {
      const user = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.succesful_delete', {
          'htmlSafe': true,
          'displayName': user.get('fullName')
        })
      });
    }),
    '_confirmedRemoveUserErrored': Ember.on('_confirmedRemoveUser:errored', function (taskInstance, err) {
      const groupUser = taskInstance.args[0];
      groupUser.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});