define("plantworks/components/settings/account/basics/basic-details", ["exports", "plantworks/framework/base-component", "plantworks/config/environment", "ember-concurrency"], function (_exports, _baseComponent, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'classNames': ['w-100'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    'editable': false,
    'protocol': '',
    'domain': '',

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-basics-read');
      let subDomain;
      const domainFields = [_environment.default.plantworks.domain];

      if (window.location.port !== '' && (window.location.protocol === 'http:' && window.location.port !== '80' || window.location.protocol === 'https:' && window.location.port !== '443')) {
        domainFields.push(`:${window.location.port}`);
      }

      if (_environment.default.IS_CORBER) {
        subDomain = this.get('currentUser').getSubDomain();
        if (subDomain != null && subDomain != undefined) domainFields.push(subDomain);
      }

      this.set('protocol', `${window.location.protocol}//`);
      this.set('domain', domainFields.join(''));
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('settings-account-basics-update');
      this.set('editable', updatePerm);
    }),
    'save': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveSucceeded': Ember.on('save:succeeded', function () {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.basics.succesful_save')
      });
    }),
    'saveErrored': Ember.on('save:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancel': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop()
  });

  _exports.default = _default;
});