define("plantworks/components/outgoing/tree-component", ["exports", "plantworks/framework/base-component", "jquery"], function (_exports, _baseComponent, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'realtime-data-access');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this._createTree();
    },

    willDestroyElement() {
      this.invokeAction('controller-action', 'setSelectedNode', null);
      (0, _jquery.default)('div#outgoing-tree-container').jstree('destroy', true);

      this._super(...arguments);
    },

    onUserDataUpdated() {
      (0, _jquery.default)('div#outgoing-tree-container').jstree('destroy', true);

      if (!this.get('currentUser').isLoggedIn()) {
        this.invokeAction('controller-action', 'setSelectedNode', null);
        return;
      }

      this._createTree();
    },

    _createTree() {
      if (!(0, _jquery.default)('div#outgoing-tree-container').length) return;
      const self = this;
      const moduTree = (0, _jquery.default)('div#outgoing-tree-container').jstree({
        'core': {
          'check_callback': function (operation) {
            return operation !== 'move_node';
          },
          'multiple': false,
          'themes': {
            'name': 'default',
            'icons': false,
            'dots': false,
            'responsive': true
          },
          'data': function (node, callback) {
            const that = this;
            self.get('ajax').request('/outgoing/tree', {
              'dataType': 'json',
              'data': {
                'id': node.id
              }
            }).then(function (childNodes) {
              callback.call(that, childNodes);
            }).catch(function (error) {
              self.get('debug')(error);
              callback.call(that, []);
            });
          }
        },
        'plugins': ['sort', 'unique']
      });
      moduTree.on('ready.jstree', () => {
        let nodetoBeActive = (0, _jquery.default)('div#outgoing-tree-container > ul > li:first-child').attr('id');
        (0, _jquery.default)('div#outgoing-tree-container').jstree('activate_node', nodetoBeActive, false, false);
      });
      moduTree.on('activate_node.jstree', (event, data) => {
        let currentNode = this.get('selectedNode');
        if (currentNode && currentNode.id === data.node.id) return;
        (0, _jquery.default)('div#outgoing-tree-container').jstree('open_node', data.node.id);
        this.invokeAction('controller-action', 'setSelectedNode', data.node);
      });
    }

  });

  _exports.default = _default;
});