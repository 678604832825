define("plantworks/components/report/devenv/explorer", ["exports", "plantworks/framework/base-component", "jquery", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'actualModel': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'report-read');
    },

    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_setActualModel').perform();
    }).drop().on('willInsertElement'),
    'onModelChange': Ember.observer('model', function () {
      this.get('_setActualModel').perform();
    }),
    'onActualModelChange': Ember.observer('actualModel', function () {
      this.get('_setBreadcrumbHierarchy').perform();
    }),
    '_setActualModel': (0, _emberConcurrency.task)(function* () {
      const model = this.get('model');

      if (!model) {
        this.set('actualModel', null);
        return;
      }

      if (['root-folder', 'attribute-folder', 'report-folder'].includes(model.type)) {
        const actualModel = yield this.get('store').findRecord('report/folder', model.id);
        this.set('actualModel', actualModel);
      }

      if (model.type === 'attribute-set') {
        const actualModel = yield this.get('store').findRecord('report/attribute-set', model.id);
        this.set('actualModel', actualModel);
      }

      if (model.type === 'report') {
        const actualModel = yield this.get('store').findRecord('report/report', model.id);
        this.set('actualModel', actualModel);
      }
    }).keepLatest(),
    '_setBreadcrumbHierarchy': (0, _emberConcurrency.task)(function* () {
      let treeNodeType = this.get('model.type');
      let actualModel = this.get('actualModel');
      if (!actualModel) return;
      const actualModelId = this.get('actualModel.id');
      const actualModelHierarchy = [];

      while (actualModel) {
        if (actualModel.get('name')) actualModelHierarchy.unshift(actualModel);

        if (['root-folder', 'attribute-folder', 'report-folder'].includes(treeNodeType)) {
          actualModel = yield actualModel.get('parent');
          continue;
        }

        if (treeNodeType === 'attribute-set') {
          actualModel = yield actualModel.get('folder');
          treeNodeType = 'attribute-folder';
          continue;
        }

        if (treeNodeType === 'report') {
          actualModel = yield actualModel.get('folder');
          treeNodeType = 'report-folder';
          continue;
        }
      }

      let treeNode = (0, _jquery.default)('div#devenv-tree-container').jstree('get_node', actualModelId);

      if (!treeNode) {
        const treeNodePath = actualModelHierarchy.map(x => {
          return x.get('id');
        });
        this.set('_treeNodesToOpen', treeNodePath);
        (0, _emberLifeline.runTask)(this, this._openTreeNode, 2000);
        return;
      }

      const breadcrumbHierarchy = actualModelHierarchy.map(x => {
        return (0, _jquery.default)('div#devenv-tree-container').jstree('get_node', x.get('id'));
      });
      this.set('breadcrumbStack', breadcrumbHierarchy);
    }).keepLatest(),

    _openTreeNode() {
      const nodesToOpen = this.get('_treeNodesToOpen');
      const openThisNode = nodesToOpen.shift();

      if (nodesToOpen.length) {
        const self = this;
        (0, _jquery.default)('div#devenv-tree-container').jstree('open_node', openThisNode, function () {
          self._openTreeNode();
        });
        return;
      }

      this.set('_treeNodesToOpen', null);
      this.get('_setBreadcrumbHierarchy').perform();
      (0, _jquery.default)('div#devenv-tree-container').jstree('activate_node', openThisNode, false, false);
    }

  });

  _exports.default = _default;
});