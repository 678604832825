define("plantworks/components/manufacturing/watch/plant-unit-machine-operator-display", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'operatorList': null,
    'uniqueParameterId': null,
    'operatorListDataPoints': null,
    'tenantPlantUnitMachine': null,
    'tenantPlantUnitStation': null,
    'isLoading': false,
    classNames: ['flex-30', 'w-100'],
    'onInit': (0, _emberConcurrency.task)(function* () {
      yield this.get('_setupMachineOperatorList').perform();
    }).on('init').keepLatest(),
    'modelChangeReactor': Ember.observer('model', function () {
      this.get('_setupMachineOperatorList').perform();
    }),
    '_setupMachineOperatorList': (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);

      try {
        const tenantPlantUnitMachine = yield this.get('model.tenantPlantUnitMachine');
        this.set('tenantPlantUnitMachine', tenantPlantUnitMachine);
        const tenantPlantUnitStation = yield this.get('model.tenantPlantUnitStation');
        this.set('tenantPlantUnitStation', tenantPlantUnitStation);
        const operatorListConfiguration = yield tenantPlantUnitMachine.get('operatorList');

        if (operatorListConfiguration) {
          const operatorListDataPoints = yield operatorListConfiguration.get('dataPoints');
          const operatorListMetadata = yield operatorListConfiguration.get('attributeSetMetadata');
          let uniqueParameterId = null;

          for (let idx = 0; idx < operatorListMetadata.get('length'); idx++) {
            const attrSetMetadata = operatorListMetadata.objectAt(idx);

            if (attrSetMetadata.get('isUniqueId')) {
              uniqueParameterId = attrSetMetadata.get('parameterId');
              break;
            }
          }

          this.set('uniqueParameterId', uniqueParameterId);
          this.set('operatorListDataPoints', operatorListDataPoints);
          let showInDropdownId = uniqueParameterId;

          for (let idx = 0; idx < operatorListMetadata.get('length'); idx++) {
            const attrSetMetadata = operatorListMetadata.objectAt(idx);

            if (attrSetMetadata.get('showInDropdown')) {
              showInDropdownId = attrSetMetadata.get('parameterId');
              break;
            }
          }

          this.set('showInDropdownId', showInDropdownId);
          let showInDropdownTag = 'masterdata_id';

          if (showInDropdownId !== uniqueParameterId) {
            // get the name and internal tag of the showInDropdownId
            const showInDropdownProp = yield this.get('store').findRecord('emd/attribute-set-property', showInDropdownId);
            showInDropdownTag = showInDropdownProp.internalTag;
          }

          this.set('showInDropdownTag', showInDropdownTag);
          const operatorList = yield this.get('ajax').request(`/manufacturing/plant-unit-machine-operators/${tenantPlantUnitMachine.get('id')}`);
          this.set('operatorList', operatorList);
        }

        this.set('isLoading', false);
      } catch (err) {
        this.set('isLoading', false);
      }
    }).keepLatest(),
    'changeOperator': (0, _emberConcurrency.task)(function* () {
      // reset the current operator - required to handle operator change event for the same machine
      const tenantPlantUnitMachine = yield this.get('model.tenantPlantUnitMachine');
      this.set('currentOperator', (yield tenantPlantUnitMachine.get('currentOperator')));
      let previousEventValue = yield this.get('currentOperator');
      const modalState = {
        'operatorListDataPoints': this.get('operatorListDataPoints'),
        'uniqueParameterId': this.get('uniqueParameterId'),
        'operatorList': this.get('operatorList'),
        'selectedMasterDataId': previousEventValue ? previousEventValue[this.get('showInDropdownTag')] : null,
        'showInDropdownTag': this.get('showInDropdownTag'),
        'showInDropdownId': this.get('showInDropdownId')
      };
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_assign_operator_machine'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex-25',
        'componentName': 'Manufacturing/Watch/StationObservedMachineAddOperator',
        'componentState': {
          'model': modalState
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'bubbles': false,
          'accent': false,
          'callback': () => {
            this.get('_updateOperator').perform(modalState);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_updateOperator': (0, _emberConcurrency.task)(function* (modalState) {
      if (!modalState || !modalState.selectedMasterDataId) return;
      let selectedOperator = this.get('operatorList').filterBy(this.get('showInDropdownTag'), modalState.selectedMasterDataId);
      if (!selectedOperator || !selectedOperator.get('length')) return;
      selectedOperator = selectedOperator.objectAt(0);
      const newEventValue = JSON.stringify(selectedOperator);
      let previousEventValue = yield this.get('currentOperator');
      previousEventValue = JSON.stringify(previousEventValue);
      let operatorChangeEventType = null;
      let retry = 3;

      while (retry > 0 && operatorChangeEventType === null) {
        const allEventTypes = yield this.get('store').findAll('masterdata/event-type');
        operatorChangeEventType = allEventTypes.filterBy('tag', 'operator_change');
        retry--;
      }

      if (!operatorChangeEventType.get('length')) {
        this.get('notification').display({
          'type': 'error',
          'error': 'No event data found'
        });
        return;
      }

      operatorChangeEventType = operatorChangeEventType.objectAt(0);
      const operatorChangeEvent = this.get('store').createRecord('manufacturing/plant-unit-machine-event', {
        'tenant': this.get('model.tenant'),
        'tenantPlantUnitMachine': this.get('tenantPlantUnitMachine'),
        'tenantPlantUnitStation': this.get('tenantPlantUnitStation'),
        'eventType': operatorChangeEventType
      });
      operatorChangeEvent.set('eventMetadata', {
        'value': newEventValue,
        'previousValue': previousEventValue
      });
      yield operatorChangeEvent.save(); // reset the current operator to the selected operator - once event is recoreded properly

      this.set('currentOperator', selectedOperator);
    }).drop()
  });

  _exports.default = _default;
});