define("plantworks/models/pug/user-manager/tenant-user", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'accessStatus': _emberData.default.attr('string', {
      'defaultValue': 'waiting'
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'tenantUsers'
    }),
    'user': _emberData.default.belongsTo('pug/user-manager/user', {
      'async': true,
      'inverse': 'tenantUsers'
    }),
    'profileImgUrl': Ember.computed('user.profileImage', function () {
      return `/pug/user-manager/get-image/${this.get('id')}/?random=${window.moment().valueOf()}`;
    })
  });

  _exports.default = _default;
});