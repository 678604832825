define("plantworks/instance-initializers/patch-plant-works-modals-manager", ["exports", "pworks-dsl/instance-initializers/patch-plant-works-modals-manager"], function (_exports, _patchPlantWorksModalsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _patchPlantWorksModalsManager.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _patchPlantWorksModalsManager.initialize;
    }
  });
});