define("plantworks/initializers/settings/account/features/add-tenant-feature-to-feature", ["exports", "ember-data", "plantworks/models/server-administration/feature", "ember-concurrency"], function (_exports, _emberData, _feature, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _feature.default.reopen({
      'tenantFeature': _emberData.default.belongsTo('settings/account/features/tenant-feature', {
        'async': true,
        'inverse': 'feature'
      }),
      'isTenantSubscribed': Ember.computed('tenantFeature', 'parent.isTenantSubscribed', {
        get() {
          return this.get('computeTenantSubscription').perform();
        }

      }),
      'computeTenantSubscription': (0, _emberConcurrency.task)(function* () {
        const isFeatureSubscribed = yield this.get('tenantFeature');
        if (!isFeatureSubscribed) return false;
        const parentModule = yield this.get('parent');
        if (!parentModule) return true;
        const isParentSubscribed = yield parentModule.get('isTenantSubscribed');
        return isParentSubscribed;
      }).keepLatest()
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});