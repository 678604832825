define("plantworks/controllers/work-order-app", ["exports", "plantworks/framework/base-controller"], function (_exports, _baseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'selectedNode': null,
    'lockedOpen': true,
    'sidenavOpen': false,
    'uploadedData': null,
    'formatWorkOrders': null,

    init() {
      this._super(...arguments); //this.set('permissions', 'work-order-read');

    },

    setSelectedNode(nodeModel) {
      if (!nodeModel) {
        this.set('selectedNode', null);
        return;
      }

      if (nodeModel.id === this.get('selectedNode.id')) return;
      this.set('selectedNode', nodeModel);
    },

    setUploadedData(fileData) {
      this.set('uploadedData', fileData);
    },

    setFormatWorkOrders(workOrderData) {
      this.set('formatWorkOrders', workOrderData);
    }

  });

  _exports.default = _default;
});