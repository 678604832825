define("plantworks/models/masterdata/gateway-communication-protocol", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'gateway': _emberData.default.belongsTo('masterdata/gateway-master', {
      'async': true,
      'inverse': 'communicationProtocols'
    }),
    'protocol': _emberData.default.belongsTo('masterdata/communication-protocol-master', {
      'async': true,
      'inverse': 'gateways'
    })
  });

  _exports.default = _default;
});