define("plantworks/components/pug/group-manager/permission-group-editor-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'editable': false,
    'groupPermissionIdList': null,
    'featureList': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'group-manager-read');
      this.set('groupPermissionIdList', []);
      this.get('_groupPermissionIdList').perform();
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('group-manager-update');
      this.set('editable', updatePerm);
    }),
    'onGroupPermissionListChanged': Ember.observer('selectedGroup', 'selectedGroup.permissions.[]', function () {
      this.get('_groupPermissionIdList').perform();
    }),
    '_groupPermissionIdList': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedGroup')) return;
      const uniqueFeatures = [];
      let permissionList = yield this.get('selectedGroup.permissions');

      if (this.get('selectedGroup.parent.permissions')) {
        let list = yield this.get('selectedGroup.parent.permissions');

        for (let idx = 0; idx < list.length; idx++) {
          const permission = list.objectAt(idx);
          const feature = yield permission.get('feature');
          if (uniqueFeatures.indexOf(feature.get('name')) === -1) uniqueFeatures.push(feature.get('name'));
        }
      }

      for (let idx = 0; idx < permissionList.length; idx++) {
        const permission = permissionList.objectAt(idx);
        yield permission.get('featurePermission');
        const feature = yield permission.get('feature');
        if (uniqueFeatures.indexOf(feature.get('name')) === -1) uniqueFeatures.push(feature.get('name'));
      }

      permissionList = permissionList.mapBy('featurePermission.id');
      this.set('featureList', uniqueFeatures.sort());
      this.set('groupPermissionIdList', permissionList.toArray());
    }).keepLatest(),
    'toggleGroupPermission': (0, _emberConcurrency.task)(function* (parentGroupPermission) {
      const groupPermissions = yield this.get('selectedGroup.permissions');
      const alreadyAdded = groupPermissions.filterBy('featurePermission.id', parentGroupPermission.get('featurePermission.id')).objectAt(0);

      if (alreadyAdded) {
        yield alreadyAdded.destroyRecord();
        return;
      }

      const newGroupPermission = this.get('store').createRecord('pug/group-manager/tenant-group-permission', {
        'tenant': this.get('selectedGroup.tenant'),
        'tenantGroup': this.get('selectedGroup'),
        'feature': parentGroupPermission.get('featurePermission.feature'),
        'featurePermission': parentGroupPermission.get('featurePermission')
      });
      yield newGroupPermission.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'toggleGroupPermissionSucceeded': Ember.on('toggleGroupPermission:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.permission_toggle_message', {
          'htmlSafe': true,
          'permissionDisplayName': taskInstance.args[0].get('featurePermission.displayName'),
          'groupDisplayName': this.get('selectedGroup.displayName')
        })
      });
    }),
    'toggleGroupPermissionErrored': Ember.on('toggleGroupPermission:errored', function (taskInstance, err) {
      const groupPermissions = this.get('selectedGroup.permissions');
      let alreadyAdded = groupPermissions.filterBy('featurePermission.id', taskInstance.args[0].get('featurePermission.id')).objectAt(0);

      if (!alreadyAdded) {
        alreadyAdded = this.get('store').peekAll('pug/group-manager/tenant-group-permission').filterBy('group.id', this.get('selectedGroup.id')).filterBy('featurePermission.id', taskInstance.args[0].get('featurePermission.id')).objectAt(0);
      }

      if (alreadyAdded) alreadyAdded.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});