define("plantworks/components/profile/password-manager", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentPassword': '',
    'newPassword1': '',
    'newPassword2': '',

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
    },

    'changePassword': (0, _emberConcurrency.task)(function* () {
      try {
        const changePasswordResult = yield this.get('ajax').post('/profile/changePassword', {
          'dataType': 'json',
          'data': {
            'currentPassword': this.get('currentPassword'),
            'newPassword1': this.get('newPassword1'),
            'newPassword2': this.get('newPassword2')
          }
        });
        this.get('notification').display({
          'type': changePasswordResult.status < 400 ? 'success' : 'error',
          'message': changePasswordResult.message,
          'error': changePasswordResult.message
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      } finally {
        this.get('cancelChangePassword').perform();
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'cancelChangePassword': (0, _emberConcurrency.task)(function* () {
      yield this.set('currentPassword', '');
      this.set('newPassword1', '');
      this.set('newPassword2', '');
    }).drop()
  });

  _exports.default = _default;
});