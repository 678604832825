define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'oemMachineTypes': null,
    'oemPlcTypes': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      let oemMachineTypes = yield store.findAll('masterdata/machine-master', {
        'reload': true
      });
      this.set('oemMachineTypes', oemMachineTypes);
      let oemPlcTypes = yield store.findAll('masterdata/plc-master', {
        'reload': true
      });
      this.set('oemPlcTypes', oemPlcTypes);
    }).on('init').drop(),
    'addPlantUnitMachine': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitMachine = this.get('store').createRecord('manufacturing/plant-unit-machine', {
        'tenant': tenant,
        'tenantPlantUnit': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_unit_machine_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnitMachine.set('name', displayName);
      newPlantUnitMachine.set('description', displayName);
      const siblingUnitMachines = yield this.get('model.machines');
      siblingUnitMachines.addObject(newPlantUnitMachine);
      this.get('editPlantUnitMachine').perform(newPlantUnitMachine);
    }).enqueue(),
    'editPlantUnitMachine': (0, _emberConcurrency.task)(function* (unitMachine) {
      yield unitMachine.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitMachine': (0, _emberConcurrency.task)(function* (unitMachine) {
      unitMachine.set('isEditing', false);
      yield unitMachine.rollback();
    }).enqueue(),
    'savePlantUnitMachine': (0, _emberConcurrency.task)(function* (unitMachine) {
      unitMachine.set('isEditing', false);
      yield unitMachine.save();
      yield unitMachine.reload();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitMachineSucceeded': Ember.on('savePlantUnitMachine:succeeded', function (taskInstance) {
      const unitMachine = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_machine_save', {
          'htmlSafe': true,
          'displayName': unitMachine.get('name')
        })
      });
    }),
    'savePlantUnitMachineErrored': Ember.on('savePlantUnitMachine:errored', function (taskInstance, err) {
      const unitMachine = taskInstance.args[0];
      unitMachine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitMachine': (0, _emberConcurrency.task)(function* (unitMachine) {
      const unitMachineName = unitMachine.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_unit_machine'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_unit_machine_message', {
          'htmlSafe': true,
          'displayName': unitMachineName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitMachine').perform(unitMachine, unitMachineName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitMachine': (0, _emberConcurrency.task)(function* (unitMachine, unitMachineName) {
      // eslint-disable-line no-unused-vars
      yield unitMachine.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitMachineSucceeded': Ember.on('_confirmedDeletePlantUnitMachine:succeeded', function (taskInstance) {
      const unitMachineName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_machine_delete', {
          'htmlSafe': true,
          'displayName': unitMachineName
        })
      });
    }),
    '_confirmedDeletePlantUnitMachineErrored': Ember.on('_confirmedDeletePlantUnitMachine:errored', function (taskInstance, err) {
      const unitMachine = taskInstance.args[0];
      unitMachine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});