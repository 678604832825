define("plantworks/components/board/configure/panel-editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'currentTab': 'users',
    'sourceTypes': null,
    'dataTypes': null,
    'timeFormats': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'board-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('board-create');
      const updatePerm = this.get('currentUser').hasPermission('board-update');
      const deletePerm = this.get('currentUser').hasPermission('board-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'cancelPanel': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').rollback();
    }).enqueue(),
    'savePanel': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePanelSucceeded': Ember.on('savePanel:succeeded', function () {
      const unit = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.board_feature.succesful_panel_save', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    'savePanelErrored': Ember.on('savePanel:errored', function (taskInstance, err) {
      const unit = this.get('model');
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePanel': (0, _emberConcurrency.task)(function* () {
      const panelName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.board_feature.label_delete_panel'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.board_feature.delete_panel_message', {
          'htmlSafe': true,
          'displayName': panelName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePanel').perform(panelName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePanel': (0, _emberConcurrency.task)(function* (panelName, folderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePanelSucceeded': Ember.on('_confirmedDeletePanel:succeeded', function (taskInstance) {
      const panelName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.board_feature.succesful_panel_delete', {
          'htmlSafe': true,
          'displayName': panelName
        })
      });
    }),
    '_confirmedDeletePanelErrored': Ember.on('_confirmedDeletePanel:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addPanelAttributeSet': (0, _emberConcurrency.task)(function* () {
      const panelAttrSets = yield this.get('model.attributeSets');
      const alreadyUsedIds = [];

      for (let idx = 0; idx < panelAttrSets.length; idx++) {
        const panelAttrSet = panelAttrSets.objectAt(idx);
        const attributeSet = yield panelAttrSet.get('tenantAttributeSet');
        alreadyUsedIds.push(attributeSet.get('id'));
      }

      const store = this.get('store');
      const allAttributeSets = yield store.findAll('board/attribute-set', {
        'reload': true
      });
      const availableAttrSets = allAttributeSets.filter(attributeSet => {
        return !alreadyUsedIds.includes(attributeSet.get('id'));
      }).map(attributeSet => {
        return {
          'attributeSet': attributeSet,
          'selected': false
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_add_data_set'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Common/AddAttributeSet',
        'componentState': {
          'model': availableAttrSets
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addPanelAttributeSets').perform(availableAttrSets);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addPanelAttributeSets': (0, _emberConcurrency.task)(function* (availableAttributeSets) {
      const toBeAdded = availableAttributeSets.filter(availableAttributeSet => {
        return availableAttributeSet.selected;
      });

      for (let idx = 0; idx < toBeAdded.length; idx++) {
        let panelAttributeSet = toBeAdded[idx]['panelAttributeSet'];

        if (!panelAttributeSet) {
          const toBeAddedAttributeSet = toBeAdded[idx]['attributeSet'];
          panelAttributeSet = this.get('store').createRecord('board/panel-attribute-set', {
            'tenant': this.get('model.tenant'),
            'tenantAttributeSet': toBeAddedAttributeSet,
            'tenantPanel': this.get('model')
          });
          toBeAdded[idx]['panelAttributeSet'] = panelAttributeSet;
        }

        if (panelAttributeSet.get('isNew')) {
          yield panelAttributeSet.save();
          this.get('model.attributeSets').addObject(panelAttributeSet);
        }
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_addPanelAttributeSetsErrored': Ember.on('_addPanelAttributeSets:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addDataSet': function () {
      this.get('addPanelAttributeSet').perform();
    }
  });

  _exports.default = _default;
});