define("plantworks/components/common/single-attribute-set/editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,
    'sourceTypes': null,
    'dataTypes': null,
    'timeFormats': null,
    'subComponents': null,
    'selectedComponent': null,
    'viewMode': 'configure',

    init() {
      this._super(...arguments);

      this.set('permissions', this.get('requiredPermissions.minPerm'));

      if (this.get('viewMode') === 'configure') {
        this.set('subComponents', [{
          'iconType': 'mdi',
          'icon': 'sprout',
          'title': this.intl.t('plant_works_webapp_server_server.common.label_attribute_set_property'),
          'component': 'Common/SingleAttributeSet/PropertyList'
        }]);
      }

      if (this.get('viewMode') === 'development') {
        this.set('subComponents', [{
          'iconType': 'md',
          'icon': 'functions',
          'title': this.intl.t('plant_works_webapp_server_server.common.label_attribute_set_prefunc'),
          'component': 'Common/SingleAttributeSet/FunctionList',
          'funcType': 'pre'
        }, {
          'iconType': 'mdi',
          'icon': 'sprout',
          'title': this.intl.t('plant_works_webapp_server_server.common.label_attribute_set_property'),
          'component': 'Common/SingleAttributeSet/PropertyList'
        }, {
          'iconType': 'md',
          'icon': 'functions',
          'title': this.intl.t('plant_works_webapp_server_server.common.label_attribute_set_postfunc'),
          'component': 'Common/SingleAttributeSet/FunctionList',
          'funcType': 'post'
        }]);
      }

      this.set('selectedComponent', this.get('subComponents')[0]);
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const sourceTypes = yield this.get('ajax').request('/masterdata/attributeSourceTypes', {
          'method': 'GET'
        });
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        this.setProperties({
          'sourceTypes': sourceTypes,
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission(this.get('requiredPermissions.createPerm'));
      const updatePerm = this.get('currentUser').hasPermission(this.get('requiredPermissions.updatePerm'));
      const deletePerm = this.get('currentUser').hasPermission(this.get('requiredPermissions.deletePerm'));
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'saveAttributeSet': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveAttributeSetSucceeded': Ember.on('saveAttributeSet:succeeded', function () {
      const attrSet = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', attrSet.get('id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_attribute_set_save', {
          'htmlSafe': true,
          'displayName': attrSet.get('name')
        })
      });
    }),
    'saveAttributeSetErrored': Ember.on('saveAttributeSet:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelAttributeSet': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteAttributeSet': (0, _emberConcurrency.task)(function* () {
      const attrSetName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_delete_attribute_set'),
          'iconType': 'md',
          'icon': 'edit-attributes'
        },
        'content': this.intl.t('plant_works_webapp_server_server.common.delete_attribute_set_message', {
          'htmlSafe': true,
          'displayName': attrSetName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteAttributeSet').perform(attrSetName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteAttributeSet': (0, _emberConcurrency.task)(function* (attrSetName, tenantFolderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteAttributeSetSucceeded': Ember.on('_confirmedDeleteAttributeSet:succeeded', function (taskInstance) {
      const attrSetName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_attribute_set_delete', {
          'htmlSafe': true,
          'displayName': attrSetName
        })
      });
    }),
    '_confirmedDeleteAttributeSetErrored': Ember.on('_confirmedDeleteAttributeSet:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});