define("plantworks/components/manufacturing/devenv/plant-unit-station-editor/editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'selectedLine': null,
    'selectedLineObservedMachines': null,
    '_selectedLineChangeReactor': Ember.observer('selectedLine', function () {
      this.get('_setSelectedMachines').perform();
    }),
    '_setSelectedMachines': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');
      const selectedLineInputConstituents = yield this.get('selectedLine.tenantPlantUnitLine.inputConstituents');
      const selectedLineMachineIds = selectedLineInputConstituents['machine'].map(lineMachine => {
        return lineMachine.get('actualEntity').get('id');
      });
      const selectedMachines = [];

      for (let idx = 0; idx < observedMachines.get('length'); idx++) {
        const observedMachine = observedMachines.objectAt(idx);
        const observedMachineId = observedMachine.get('tenantPlantUnitMachine.id');
        if (selectedLineMachineIds.indexOf(observedMachineId) < 0) continue;
        selectedMachines.push(observedMachine);
      }

      this.set('selectedMachines', selectedMachines);
    }).keepLatest()
  });

  _exports.default = _default;
});