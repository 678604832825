define("plantworks/templates/components/manufacturing/watch/plant-unit-line-supervisor-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kz6fBrwK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"supervisorListDataPoints\"]]],null,{\"statements\":[[4,\"if\",[[28,\"await\",[[24,[\"model\",\"tenantPlantUnitLine\",\"currentSupervisor\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"model\",\"tenantPlantUnitLine\",\"currentSupervisor\"]]],null],[24,[\"showInDropdownTag\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[5,\"plant-works-button\",[],[[\"@iconButton\",\"@accent\",\"@raised\",\"@disabled\",\"@title\",\"@onClick\",\"@bubbles\"],[true,true,false,[22,\"isLoading\"],\"Edit Supervisor\",[28,\"perform\",[[24,[\"changeSupervisor\"]]],null],false]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"plant-works-icon\",[],[[\"@icon\"],[\"edit\"]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/watch/plant-unit-line-supervisor-display.hbs"
    }
  });

  _exports.default = _default;
});