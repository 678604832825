define("plantworks/components/plant-works-checkbox", ["exports", "pworks-dsl/components/plant-works-checkbox"], function (_exports, _plantWorksCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksCheckbox.default;
    }
  });
});