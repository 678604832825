define("plantworks/components/manufacturing/configure/plant-unit-station-editor/editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['bg-grey', 'p-2'],
    'currentTab': 'basic'
  });

  _exports.default = _default;
});