define("plantworks/components/manufacturing/configure/plant-unit-line-editor/work-order-format-selector", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    comparisonOperatorOptions: null,

    init() {
      this._super(...arguments);

      this.set('comparisonOperatorOptions', ['>', '<', '=']);
    },

    'addWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const workOrderFormats = yield store.findAll('work-order/work-order-format', {
        'reload': true
      });
      const alreadySelectedFormats = yield this.get('model.workOrderFormats');
      const alreadySelectedFormatIds = new Set(alreadySelectedFormats.map(lineWoFormat => {
        return lineWoFormat.get('tenantWorkOrderFormat.id');
      }));
      const availableWorkOrderFormats = workOrderFormats.filter(woFormat => {
        return !alreadySelectedFormatIds.has(woFormat.get('id'));
      });
      const modalOptions = {
        'selectedWorkOrderFormatId': null,
        'availableWorkOrderFormats': availableWorkOrderFormats
      };
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.add_work_order_format'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitLineEditor/AddWorkOrderFormat',
        'componentState': {
          'model': modalOptions
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addWorkOrderFormat').perform(modalOptions);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addWorkOrderFormat': (0, _emberConcurrency.task)(function* (chosenList) {
      const model = this.get('model');
      const chosenFormat = chosenList.availableWorkOrderFormats.filter(workOrderFormat => {
        return workOrderFormat.get('id') === chosenList.selectedWorkOrderFormatId;
      })[0];
      const plantUnitLineWorkOrderFormat = this.get('store').createRecord('manufacturing/plant-unit-line-work-order-format', {
        'tenant': model.get('tenant'),
        'tenantPlantUnitLine': model,
        'tenantWorkOrderFormat': chosenFormat
      });
      yield plantUnitLineWorkOrderFormat.save();
    }).drop(),
    'saveWorkOrderFormat': (0, _emberConcurrency.task)(function* (plantUnitLineWorkOrderFormat) {
      yield plantUnitLineWorkOrderFormat.save();
    }).drop(),
    'cancelWorkOrderFormat': (0, _emberConcurrency.task)(function* (plantUnitLineWorkOrderFormat) {
      yield plantUnitLineWorkOrderFormat.rollbackAttributes();
    }).drop(),
    'deleteWorkOrderFormat': (0, _emberConcurrency.task)(function* (plantUnitLineWorkOrderFormat) {
      yield plantUnitLineWorkOrderFormat.destroyRecord();
    }).drop(),
    'addWorkOrderFilter': (0, _emberConcurrency.task)(function* (tenantPlantUnitLineWorkOrderFormat) {
      yield tenantPlantUnitLineWorkOrderFormat.get('filters').createFragment({
        'parameterId': null,
        'comparisonOperator': null,
        'value': null
      });
    }).drop(),
    'deleteWorkOrderFilter': (0, _emberConcurrency.task)(function* (tenantPlantUnitLineWorkOrderFormat, workOrderFilter) {
      yield tenantPlantUnitLineWorkOrderFormat.get('filters').removeFragment(workOrderFilter);
    }).enqueue()
  });

  _exports.default = _default;
});