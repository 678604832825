define("plantworks/helpers/coke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function contain([element, arr]) {
    const ele = element.split(':')[element.split(':').length - 1];
    return arr.includes(ele);
  }

  var _default = Ember.Helper.helper(contain);

  _exports.default = _default;
});