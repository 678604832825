define("plantworks/framework/base-route", ["exports", "ember-debug-logger"], function (_exports, _emberDebugLogger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    'intl': Ember.inject.service('intl'),
    'router': Ember.inject.service('router'),
    'currentUser': Ember.inject.service('current-user'),
    'debug': (0, _emberDebugLogger.default)(),
    actions: {
      'controller-action': function (action, data) {
        const controller = this.get('controller');
        if (controller && controller[action] && typeof controller[action] === 'function') return this.get('controller').send('controller-action', action, data);
        return true;
      }
    }
  });

  _exports.default = _default;
});