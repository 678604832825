define("plantworks/templates/components/common/plant-works-custom-subcomponent/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ddyTar6Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"subcomponentLayout\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"common/plant-works-custom-subcomponent/custom-display\",[],[[\"@class\",\"@layout\",\"@processor\",\"@model\",\"@state\",\"@controller-action\"],[\"flex layout-column layout-align-start-stretch bg-white\",[22,\"subcomponentLayout\"],[22,\"selectedSubcomponent\"],[22,\"model\"],[22,\"state\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/common/plant-works-custom-subcomponent/main.hbs"
    }
  });

  _exports.default = _default;
});