define("plantworks/components/pug/group-manager/tree-component", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'group-manager-read');
    },

    didInsertElement() {
      this._super(...arguments);

      if (!(0, _jquery.default)('div#pug-group-manager-tree-container').length) return;
      const self = this;
      const moduTree = this.$('div#pug-group-manager-tree-container').jstree({
        'core': {
          'check_callback': function (operation) {
            return operation !== 'move_node';
          },
          'multiple': false,
          'themes': {
            'name': 'default',
            'icons': false,
            'dots': false,
            'responsive': true
          },
          'data': function (node, callback) {
            const that = this;
            self.get('ajax').request('/pug/group-manager/tree', {
              'dataType': 'json',
              'data': {
                'id': node.id
              }
            }).then(function (childNodes) {
              callback.call(that, childNodes);
            }).catch(function (error) {
              self.get('debug')(error);
              callback.call(that, []);
            });
          }
        },
        'plugins': ['sort', 'unique']
      });
      moduTree.on('ready.jstree', () => {
        const rootNodeId = this.$('div#pug-group-manager-tree-container > ul > li:first-child').attr('id');
        this.$('div#pug-group-manager-tree-container').jstree('open_node', rootNodeId);
        this.$('div#pug-group-manager-tree-container').jstree('activate_node', rootNodeId, false, false);
      });
      moduTree.on('activate_node.jstree', (event, data) => {
        this.get('onActivateNode').perform(data.node);
      });
    },

    willDestroyElement() {
      this.invokeAction('controller-action', 'setSelectedGroup', null);
      this.$('div#pug-group-manager-tree-container').jstree('destroy', true);

      this._super(...arguments);
    },

    'onActivateNode': (0, _emberConcurrency.task)(function* (treeNode) {
      const tenantGroup = this.get('store').peekRecord('pug/group-manager/tenant-group', treeNode.id);
      if (tenantGroup) return;
      yield this.get('store').findRecord('pug/group-manager/tenant-group', treeNode.id);
    }).keepLatest().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onActivateNodeSucceeded': Ember.on('onActivateNode:succeeded', function (taskInstance) {
      const treeNode = taskInstance.args[0];
      let tenantGroup = this.get('selectedGroup');
      if (tenantGroup && tenantGroup.get('id') === treeNode.id) return;
      this.$('div#pug-group-manager-tree-container').jstree('open_node', treeNode.id);
      tenantGroup = this.get('store').peekRecord('pug/group-manager/tenant-group', treeNode.id);
      this.invokeAction('controller-action', 'setSelectedGroup', tenantGroup);
    }),
    'onActivateNodeErrored': Ember.on('onActivateNode:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onSelectedGroupChanged': Ember.observer('selectedGroup', function () {
      if (!this.get('selectedGroup')) return;
      if (this.$('div#pug-group-manager-tree-container').jstree('get_selected')[0] === this.get('selectedGroup.id')) return;
      const treeNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', this.get('selectedGroup.id'));

      if (treeNode) {
        this.$('div#pug-group-manager-tree-container').jstree('activate_node', this.get('selectedGroup.id'), false, false);
        this.$('div#pug-group-manager-tree-container').jstree('open_node', this.get('selectedGroup.id'));
        return;
      }

      const parentNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', this.get('selectedGroup.parent.id'));
      this.$('div#pug-group-manager-tree-container').one('refresh_node.jstree', () => {
        this.$('div#pug-group-manager-tree-container').jstree('activate_node', this.get('selectedGroup.id'), false, false);
        this.$('div#pug-group-manager-tree-container').jstree('open_node', this.get('selectedGroup.id'));
      });
      this.$('div#pug-group-manager-tree-container').jstree('refresh_node', parentNode);
    }),
    'onSelectedGroupNameChanged': Ember.observer('selectedGroup.displayName', function () {
      const treeNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', this.get('selectedGroup.id'));
      this.$('div#pug-group-manager-tree-container').jstree('rename_node', treeNode, this.get('selectedGroup.displayName'));
    }),
    'onSelectedGroupDestroyed': Ember.observer('selectedGroup.isDeleted', 'selectedGroup.hasDirtyAttributes', function () {
      Ember.run.once(this, 'processGroupDeletion');
    }),
    'processGroupDeletion': function () {
      if (this.get('selectedGroup.isDeleted')) {
        if (this.get('selectedGroup.hasDirtyAttributes')) return;
        const treeNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', this.get('selectedGroup.id'));
        this.$('div#pug-group-manager-tree-container').jstree('delete_node', treeNode);
      } else {
        const treeNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', this.get('selectedGroup.id'));
        if (treeNode) return;
        const parentNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', this.get('selectedGroup.parent.id'));
        this.$('div#pug-group-manager-tree-container').jstree('refresh_node', parentNode);
      }
    },
    'onTenantGroupNameChanged': Ember.observer('model.tenantGroups.@each.displayName', function () {
      this.get('_updateChildGroupText').perform();
    }),
    '_updateChildGroupText': (0, _emberConcurrency.task)(function* () {
      const tenantGroups = yield this.get('selectedGroup.groups');
      if (!tenantGroups) return;
      tenantGroups.forEach(subGroup => {
        const treeNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', subGroup.get('id'));
        if (!treeNode) return;
        this.$('div#pug-group-manager-tree-container').jstree('rename_node', treeNode, subGroup.get('displayName'));
      });
    }).enqueue(),
    'onTenantGroupsChanged': Ember.observer('model.tenantGroups.@each.isNew', 'model.tenantGroups.@each.isDeleted', function () {
      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.once(this, () => {
        this.get('_updateGroupTree').perform();
      });
    }),
    '_updateGroupTree': (0, _emberConcurrency.task)(function* () {
      const tenantGroups = yield this.get('selectedGroup.groups');
      if (tenantGroups) tenantGroups.forEach(subGroup => {
        let treeNode = this.$('div#pug-group-manager-tree-container').jstree('get_node', subGroup.get('id'));

        if (subGroup.get('isNew') && !treeNode) {
          treeNode = this.$('div#pug-group-manager-tree-container').jstree('create_node', subGroup.get('parent.id'), {
            'id': subGroup.get('id'),
            'text': subGroup.get('displayName')
          });
        }
      });
      const selectedTreeNodeId = this.$('div#pug-group-manager-tree-container').jstree('get_selected')[0];
      const selectedTreeNodeChildren = this.$('div#pug-group-manager-tree-container').jstree('get_children_dom', selectedTreeNodeId);

      for (let idx = 0; idx < selectedTreeNodeChildren.length; idx++) {
        const tenantGroup = this.get('store').peekRecord('pug/group-manager/tenant-group', window.$(selectedTreeNodeChildren[idx]).attr('id'));
        if (tenantGroup && !tenantGroup.get('isDeleted')) continue;
        this.$('div#pug-group-manager-tree-container').jstree('delete_node', window.$(selectedTreeNodeChildren[idx]).attr('id'));
      }
    }).enqueue()
  });

  _exports.default = _default;
});