define("plantworks/models/board/folder", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'boardFolders'
    }),
    'parent': _emberData.default.belongsTo('board/folder', {
      'async': true,
      'inverse': 'folders'
    }),
    'folders': _emberData.default.hasMany('board/folder', {
      'async': true,
      'inverse': 'parent'
    }),
    'panels': _emberData.default.hasMany('board/panel', {
      'async': true,
      'inverse': 'folder'
    }),
    'attributeSets': _emberData.default.hasMany('board/attribute-set', {
      'async': true,
      'inverse': 'folder'
    }),
    'i18n_name': Ember.computed('name', 'intl.locale', function () {
      return this.intl.exists(`plant_works_webapp_server_server.${this.get('name')}`) ? this.intl.t(`plant_works_webapp_server_server.${this.get('name')}`) : this.get('name');
    }),
    'i18n_description': Ember.computed('description', 'intl.locale', function () {
      return this.intl.exists(`plant_works_webapp_server_server.${this.get('description')}`) ? this.intl.t(`plant_works_webapp_server_server.${this.get('description')}`) : this.get('description');
    }),
    'path': Ember.computed('i18n_name', 'parent.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const parent = yield this.get('parent');

      if (!(parent && parent.get('id'))) {
        return '';
      }

      const parentPath = yield parent.get('path');
      if (parentPath === '') return this.get('i18n_name');
      return `${parentPath} > ${this.get('i18n_name')}`;
    }).keepLatest()
  });

  _exports.default = _default;
});