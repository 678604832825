define("plantworks/initializers/work-order/add-work-order-attribute-set-to-tenant", ["exports", "ember-data", "plantworks/models/settings/account/basics/tenant"], function (_exports, _emberData, _tenant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _tenant.default.reopen({
      'workOrderAttributeSets': _emberData.default.hasMany('work-order/attribute-set', {
        'async': true,
        'inverse': 'tenant'
      }),
      'workOrderAttributeSetProperties': _emberData.default.hasMany('work-order/attribute-set-property', {
        'async': true,
        'inverse': 'tenant'
      }),
      'workOrderAttributeSetFunctions': _emberData.default.hasMany('work-order/attribute-set-function', {
        'async': true,
        'inverse': 'tenant'
      }),
      'workOrderAttributeSetFunctionObservedProperties': _emberData.default.hasMany('work-order/attribute-set-function-observed-property', {
        'async': true,
        'inverse': 'tenant'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});