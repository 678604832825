define("plantworks/themes/ember-bootstrap-v3", ["exports", "ember-models-table/themes/ember-bootstrap-v3"], function (_exports, _emberBootstrapV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberBootstrapV.default;
    }
  });
});