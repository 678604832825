define("plantworks/components/emd/uploader/tree-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'emd-read');
    },

    didInsertElement() {
      this._super(...arguments);

      const self = this;
      const moduTree = this.$('div#emd-uploader-tree-container').jstree({
        'core': {
          'check_callback': function (operation) {
            return operation !== 'move_node';
          },
          'multiple': false,
          'themes': {
            'name': 'default',
            'icons': false,
            'dots': false,
            'responsive': true
          },
          'data': {
            'url': '/emd/tree',
            'dataType': 'json',
            'success': function (response) {
              return response.map(treeNode => {
                if (treeNode.data.intl) {
                  treeNode.text = self.intl.exists(`plant_works_webapp_server_server.${treeNode.text}`) ? self.intl.t(`plant_works_webapp_server_server.${treeNode.text}`) : treeNode.text;
                  treeNode['li_attr']['title'] = self.intl.exists(`plant_works_webapp_server_server.${treeNode['li_attr']['title']}`) ? self.intl.t(`plant_works_webapp_server_server.${treeNode['li_attr']['title']}`) : treeNode['li_attr']['title'] || '';
                }

                return treeNode;
              });
            },
            'data': function (node) {
              return {
                'id': node.id
              };
            }
          }
        },
        'plugins': ['sort', 'unique']
      });
      moduTree.on('ready.jstree', () => {
        const rootNodeId = this.$('div#emd-uploader-tree-container > ul > li:first-child').attr('id');
        this.$('div#emd-uploader-tree-container').jstree('open_node', rootNodeId);
        this.$('div#emd-uploader-tree-container').jstree('activate_node', rootNodeId, false, false);
      });
      moduTree.on('activate_node.jstree', (event, data) => {
        this.get('onActivateNode').perform(data.node);
      });
    },

    willDestroyElement() {
      this.invokeAction('controller-action', 'setSelectedNode', null);
      this.$('div#emd-uploader-tree-container').jstree('destroy', true);

      this._super(...arguments);
    },

    'onActivateNode': (0, _emberConcurrency.task)(function* (treeNode) {
      const nodeToSelect = this.get('store').peekRecord(treeNode.data.type, treeNode.id);
      if (nodeToSelect) return;
      yield this.get('store').findRecord(treeNode.data.type, treeNode.id);
    }).keepLatest().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onActivateNodeSucceeded': Ember.on('onActivateNode:succeeded', function (taskInstance) {
      const treeNode = taskInstance.args[0];
      this.invokeAction('controller-action', 'setParsedData', null);
      let selectedNode = this.get('selectedNode');
      let selectedNodeType = this.get('selectedNodeType');
      if (selectedNode && selectedNode.get('id') === treeNode.id && selectedNodeType && selectedNodeType === treeNode.data.type) return;
      this.$('div#emd-uploader-tree-container').jstree('open_node', treeNode.id);
      selectedNode = this.get('store').peekRecord(treeNode.data.type, treeNode.id);
      this.invokeAction('controller-action', 'setSelectedNode', selectedNode);
      this.invokeAction('controller-action', 'setSelectedNodeType', treeNode);
    }),
    'onActivateNodeErrored': Ember.on('onActivateNode:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onSelectedNodeChanged': Ember.observer('selectedNode', function () {
      if (!this.get('selectedNode')) return;
      if (this.$('div#emd-uploader-tree-container').jstree('get_selected')[0] === this.get('selectedNode.id')) return;
      const treeNode = this.$('div#emd-uploader-tree-container').jstree('get_node', this.get('selectedNode.id'));

      if (treeNode) {
        this.$('div#emd-uploader-tree-container').jstree('activate_node', this.get('selectedNode.id'), false, false);
        this.$('div#emd-uploader-tree-container').jstree('open_node', this.get('selectedNode.id'));
        return;
      }

      const parentNode = this.$('div#emd-uploader-tree-container').jstree('get_node', this.get('selectedNode.parent') ? this.get('selectedNode.parent.id') : this.get('selectedNode.folder.id'));
      this.$('div#emd-uploader-tree-container').one('refresh_node.jstree', () => {
        this.$('div#emd-uploader-tree-container').jstree('activate_node', this.get('selectedNode.id'), false, false);
        this.$('div#emd-uploader-tree-container').jstree('open_node', this.get('selectedNode.id'));
      });
      this.$('div#emd-uploader-tree-container').jstree('refresh_node', parentNode);
    })
  });

  _exports.default = _default;
});