define("plantworks/components/manufacturing/configure/plant-unit-driver-editor/editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'bg-grey'],
    'addPlantUnitMachine': (0, _emberConcurrency.task)(function* () {
      const plantUnitDriver = this.get('model');
      const plant = yield plantUnitDriver.get('tenantPlantUnit.plant');
      const plantUnits = yield plant.get('units');
      let allowedHardwareProtocols = yield plantUnitDriver.get('gateway.hardwareProtocols');
      const supportedHardwareProtocolIds = [];

      for (let idx = 0; idx < allowedHardwareProtocols.length; idx++) {
        const allowedHardwareProtocol = allowedHardwareProtocols.objectAt(idx);
        const hardwareProtocolId = yield allowedHardwareProtocol.get('protocol.id');
        supportedHardwareProtocolIds.push(hardwareProtocolId);
      }

      const plantUnitDriverMachines = yield plantUnitDriver.get('machines');
      const alreadyAddedMachines = plantUnitDriverMachines.map(machine => {
        return machine.get('id');
      });
      const allPlantMachines = [];

      for (let idx = 0; idx < plantUnits.length; idx++) {
        const unit = plantUnits.objectAt(idx);
        const unitMachines = yield unit.get('machines');

        for (let jdx = 0; jdx < unitMachines.length; jdx++) {
          const machine = unitMachines.objectAt(jdx);
          if (machine.get('isNew')) continue;
          const plc = yield machine.get('plc');
          if (!(plc && plc.get('id'))) continue;
          if (machine.get('tenantPlantUnitDriver.id') === this.get('model.id')) continue;
          allPlantMachines.push(machine);
        }
      }

      const allowedPlantMachines = [];

      for (let idx = 0; idx < allPlantMachines.length; idx++) {
        const machine = allPlantMachines[idx];
        const machinePlcHardwareProtocols = yield machine.get('plc.hardwareProtocols');

        for (let jdx = 0; jdx < machinePlcHardwareProtocols.length; jdx++) {
          const machinePlcHardwareProtocol = machinePlcHardwareProtocols.objectAt(jdx);
          const machinePlcHardwareProtocolId = yield machinePlcHardwareProtocol.get('protocol.id');

          if (supportedHardwareProtocolIds.includes(machinePlcHardwareProtocolId)) {
            allowedPlantMachines.push(machine);
            break;
          }
        }
      }

      const availableMachines = allowedPlantMachines.filter(plantMachine => {
        return !alreadyAddedMachines.includes(plantMachine.get('id'));
      });
      const self = this;
      const chooseModalModel = availableMachines.map(availableMachine => {
        return {
          'selected': false,
          'machine': availableMachine
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_driver_gateway_add_machine'),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitDriverEditor/AddMachine',
        'componentState': {
          'model': chooseModalModel
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('addMachines').perform(chooseModalModel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deletePlantUnitDriverMachine': (0, _emberConcurrency.task)(function* (chosenMachine) {
      chosenMachine.set('tenantPlantUnitDriver', null);
      chosenMachine.set('hardwareProtocol', null);
      chosenMachine.set('communicationProtocol', null);
      yield chosenMachine.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deletePlantUnitDriverMachineSucceeded': Ember.on('deletePlantUnitDriverMachine:succeeded', function (taskInstance) {
      const chosenMachine = taskInstance.args[0];
      chosenMachine.reload();
    }),
    'deletePlantUnitDriverMachineErrored': Ember.on('deletePlantUnitDriverMachine:errored', function (taskInstance, err) {
      const chosenMachine = taskInstance.args[0];
      chosenMachine.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addMachines': (0, _emberConcurrency.task)(function* (chooseModalModel) {
      const plantUnitDriver = this.get('model');
      const chosenMachines = chooseModalModel.filter(chosenMachine => {
        return chosenMachine.selected;
      }).map(chosenMachine => {
        return chosenMachine.machine;
      });

      for (let idx = 0; idx < chosenMachines.length; idx++) {
        const chosenMachine = chosenMachines[idx];
        if (chosenMachine.get('tenantPlantUnitDriver.id') === this.get('model.id') && !chosenMachine.get('isDirty')) continue;
        chosenMachine.set('tenantPlantUnitDriver', plantUnitDriver);
        plantUnitDriver.get('machines').addObject(chosenMachine);
        yield this.get('_setHardwareProtocol').perform(chosenMachine);
        yield this.get('_setCommunicationProtocol').perform(chosenMachine);
        yield chosenMachine.save();
        yield chosenMachine.reload();
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_setHardwareProtocol': (0, _emberConcurrency.task)(function* (chosenMachine) {
      if (!chosenMachine.get('hardwareProtocol')) return;
      const machineDriver = this.get('model');
      const machinePlc = yield chosenMachine.get('plc');

      if (!(machinePlc && machinePlc.get('id'))) {
        chosenMachine.set('hardwareProtocol', null);
        return;
      }

      const gatewayHardwareProtocols = yield machineDriver.get('gateway.hardwareProtocols');
      const allowedHardwareProtocols = [];

      for (let idx = 0; idx < gatewayHardwareProtocols.length; idx++) {
        const gatewayHardwareProtocol = gatewayHardwareProtocols.objectAt(idx);
        const allowedHardwareProtocol = yield gatewayHardwareProtocol.get('protocol');
        allowedHardwareProtocols.push(allowedHardwareProtocol);
      }

      const plcHardwareProtocols = yield machinePlc.get('hardwareProtocols');
      const supportedHardwareProtocols = [];

      for (let idx = 0; idx < plcHardwareProtocols.length; idx++) {
        const plcHardwareProtocol = plcHardwareProtocols.objectAt(idx);
        const supportedHardwareProtocol = yield plcHardwareProtocol.get('protocol');
        supportedHardwareProtocols.push(supportedHardwareProtocol);
      }

      const possibleHardwareProtocols = allowedHardwareProtocols.filter(allowedHardwareProtocol => {
        const thisProtoAllowed = supportedHardwareProtocols.filter(supportedHardwareProtocol => {
          return supportedHardwareProtocol.get('id') === allowedHardwareProtocol.get('id');
        });
        return !!thisProtoAllowed.length;
      });
      const machineHwProtocolId = yield this.get('machine.hardwareProtocol.id');
      const isHwProtocolAllowed = possibleHardwareProtocols.filter(possibleHardwareProtocol => {
        return possibleHardwareProtocol.get('id') === machineHwProtocolId;
      });
      if (isHwProtocolAllowed.length) return;

      if (possibleHardwareProtocols.length === 1) {
        chosenMachine.set('hardwareProtocol', possibleHardwareProtocols[0]);
        return;
      }

      chosenMachine.set('hardwareProtocol', null);
    }).keepLatest(),
    '_setCommunicationProtocol': (0, _emberConcurrency.task)(function* (chosenMachine) {
      if (!chosenMachine.get('communicationProtocol')) return;
      const machineDriver = this.get('model');
      const gatewayCommProtocols = yield machineDriver.get('gateway.communicationProtocols');
      const allowedCommProtocols = [];

      for (let idx = 0; idx < gatewayCommProtocols.length; idx++) {
        const gatewayCommProtocol = gatewayCommProtocols.objectAt(idx);
        const allowedCommProtocol = yield gatewayCommProtocol.get('protocol');
        allowedCommProtocols.push(allowedCommProtocol);
      }

      const machineCommProtocolId = yield chosenMachine.get('communicationProtocol.id');
      const isCommProtocolAllowed = allowedCommProtocols.filter(allowedCommProtocol => {
        return allowedCommProtocol.get('id') === machineCommProtocolId;
      });
      if (isCommProtocolAllowed.length) return;

      if (allowedCommProtocols.length === 1) {
        chosenMachine.set('communicationProtocol', allowedCommProtocols[0]);
        return;
      }

      chosenMachine.set('communicationProtocol', null);
    }).keepLatest()
  });

  _exports.default = _default;
});