define("plantworks/components/common/single-attribute-set/function-observed-property-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'isCategorizedView': false,
    'selectedSourceType': 'static',
    'viewMode': 'configure',
    'onCategorizedViewChange': Ember.observer('isCategorizedView', function () {
      if (this.get('isCategorizedView')) return;
      this.set('selectedSourceType', 'static');
    })
  });

  _exports.default = _default;
});