define("plantworks/models/masterdata/plc-master", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'manufacturer': _emberData.default.attr('string'),
    'category': _emberData.default.attr('string'),
    'model': _emberData.default.attr('string'),
    'firmwareVersion': _emberData.default.attr('string'),
    'machines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'plc'
    }),
    'hardwareProtocols': _emberData.default.hasMany('masterdata/plc-hardware-protocol', {
      'async': true,
      'inverse': 'plc'
    }),
    'tblDisplay': Ember.computed('manufacturer', 'model', function () {
      return `${this.get('manufacturer')} ${this.get('model')}`;
    })
  });

  _exports.default = _default;
});