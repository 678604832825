define("plantworks/templates/components/session/log-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gHmn7QG0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[10,\"style\",\"cursor:pointer;\"],[8],[0,\"\\n\\t\\t\"],[5,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"sign-out-alt\",\"h4 mb-0\"]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/session/log-out.hbs"
    }
  });

  _exports.default = _default;
});