define("plantworks/components/report/configure/schedules", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'viewMode': 'configure',
    'timezoneIdOptions': null,
    'reportResponseTypes': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const timezoneIdOptions = yield this.get('ajax').request('/masterdata/timezones', {
          'method': 'GET'
        });
        const reportResponseTypes = yield this.get('ajax').request('/masterdata/report-response-formatter-types', {
          'method': 'GET'
        });
        this.setProperties({
          'timezoneIdOptions': timezoneIdOptions,
          'reportResponseTypes': reportResponseTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'updateOutputTypes': function (modelField) {
      debugger;
    },
    'addSchedule': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const report = yield this.get('model');
      const newSchedule = this.get('store').createRecord('report/report-schedule', {
        'tenant': tenant,
        'tenantReport': report
      });
      const siblingSchedules = yield this.get('model.schedules');
      siblingSchedules.addObject(newSchedule);
      this.get('editSchedule').perform(newSchedule);
    }).enqueue(),
    'editSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      yield schedule.set('isEditing', true);
    }).enqueue(),
    'cancelSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      schedule.set('isEditing', false);
      yield schedule.rollback();
    }).enqueue(),
    'saveSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      schedule.set('isEditing', false);
      yield schedule.save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveScheduleSucceeded': Ember.on('saveSchedule:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_schedule_save')
      });
    }),
    'saveScheduleErrored': Ember.on('saveSchedule:errored', function (taskInstance, err) {
      const schedule = schedule.args[0];
      schedule.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.report_feature.label_delete_schedule'),
          'iconType': 'mdi',
          'icon': 'sprout'
        },
        'content': this.intl.t('plant_works_webapp_server_server.report_feature.delete_schedule_message'),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteSchedule').perform(schedule);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
      yield null;
    }).drop(),
    '_confirmedDeleteSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      // eslint-disable-line no-unused-vars
      yield schedule.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteScheduleSucceeded': Ember.on('_confirmedDeleteSchedule:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_schedule_delete')
      });
    }),
    '_confirmedDeleteScheduleErrored': Ember.on('_confirmedDeleteSchedule:errored', function (taskInstance, err) {
      const schedule = taskInstance.args[0];
      schedule.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});