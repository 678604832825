define("plantworks/templates/components/locale-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/VwRBwub",
    "block": "{\"symbols\":[\"Menu\",\"Content\",\"Locale\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"allowedLocales\",\"length\"]],1],null]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-menu\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"Trigger\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"plant-works-button\",[],[[\"@class\",\"@raised\",\"@bubbles\"],[\"min-w-0\",false,false]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[5,\"plant-works-icon\",[],[[\"@icon\"],[\"language\"]]],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\\t\"],[6,[23,1,[\"Content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"allowedLocales\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[6,[23,2,[\"MenuItem\"]],[],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],\"controller-action\",\"changeSelectedLocale\",[23,3,[]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,3,[\"name\"]],false],[0,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\\t\"]],\"parameters\":[2]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/locale-selector.hbs"
    }
  });

  _exports.default = _default;
});