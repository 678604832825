define("plantworks/components/manufacturing/schedule/plant-unit-downtime-uploader/planned-downtime", ["exports", "plantworks/framework/base-component", "@uppy/core", "@uppy/dashboard", "@uppy/xhr-upload", "ember-concurrency"], function (_exports, _baseComponent, _core, _dashboard, _xhrUpload, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    uppy: null,
    plantUnitId: null,
    viewData: null,
    showApprove: false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'manufacturing-read');
      this.set('plantUnitId', this.get('model.id'));
    },

    didInsertElement() {
      this._super(...arguments);

      this.initializeUppy();
    },

    didRender() {
      this._super(...arguments);

      if (this.get('uppy') && this.get('uppy').getPlugin('Dashboard') && this.get('uppy').getPlugin('XHRUpload')) this.get('uppy').getPlugin('XHRUpload').opts.endpoint = `/manufacturing/plant-unit-machine-schedules/upload?plantUnitId=${this.get('plantUnitId')}&scheduleType=planned_downtime`;
    },

    'initializeUppy': function () {
      const self = this;
      const uppy = (0, _core.default)({
        id: 'downtimeUploadUppy',
        debug: true,
        autoProceed: false,
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ['.xlsx', '.xls']
        }
      }).use(_dashboard.default, {
        trigger: '#upload-button-planned-downtime',
        inline: false,
        showProgressDetails: true,
        note: null,
        //change later
        closeModalOnClickOutside: true
      }).use(_xhrUpload.default, {
        endpoint: `/manufacturing/plant-unit-machine-schedules/upload?plantUnitId=${this.get('plantUnitId')}&scheduleType=planned_downtime`,
        method: 'post',
        timeout: 120000
      });
      uppy.on('upload-success', (file, response) => {
        self.setViewerData(response);
        const dashboard = uppy.getPlugin('Dashboard');

        if (dashboard.isModalOpen()) {
          dashboard.closeModal();
        }

        uppy.reset();
      });
      uppy.on('upload-error', (file, error, response) => {
        this.get('notification').display({
          'type': 'error',
          'error': error
        });
        uppy.reset();
      });
      uppy.on('dashboard:modal-closed', () => {
        uppy.reset();
      });
      self.set('uppy', uppy);
    },
    'setViewerData': function (data) {
      this.setUploadedData(data.body);
    },
    'formatViewData': Ember.observer('uploadedData', function () {
      if (!this.get('uploadedData') || !this.get('uploadedData.attributes') || !this.get('uploadedData.rows')) {
        this.set('viewData', null);
        this.set('showApprove', false);
        return;
      }

      const viewData = {
        'head': this.get('uploadedData.attributes'),
        'rows': []
      };
      let showApproveBtn = true;
      this.get('uploadedData.rows').forEach(row => {
        if (row.status === 'REJECTED') {
          showApproveBtn = false;
        }

        let singleRow = [];
        viewData.head.forEach(col => {
          singleRow.push(row[col]);
        });
        let rowObj = {
          'data': singleRow,
          'showNotes': false,
          'notes': row.notes
        };
        viewData.rows.push(rowObj);
      });

      if (showApproveBtn) {
        this.set('showApprove', true);
      } else {
        this.set('showApprove', false);
      }

      this.set('viewData', viewData);
    }),

    setUploadedData(fileData) {
      if (!fileData.rows) {
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.bulk_uploader_error')
        });
      }

      this.set('uploadedData', fileData);
    },

    'approveUpload': (0, _emberConcurrency.task)(function* () {
      const self = this;
      yield self.get('ajax').request(`/manufacturing/plant-unit-machine-schedules/approve-upload?plantUnitId=${self.get('plantUnitId')}`, {
        'method': 'POST',
        'contentType': 'application/json',
        'data': self.get('uploadedData')
      });
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'onApproveUploadSucceeded': Ember.on('approveUpload:succeeded', function () {
      this.set('uploadedData', null);
      this.set('showApprove', false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.successful_schedule_approval', {
          'htmlSafe': true
        })
      });
    }),
    'onApproveUploadErrored': Ember.on('approveUpload:errored', function (taskInstance, err) {
      this.set('uploadedData', null);
      this.set('showApprove', false);
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});