define("plantworks/components/work-order/configure/editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,
    'sourceTypes': null,
    'dataTypes': null,
    'timeFormats': null,
    'subComponents': null,
    'selectedComponent': null,
    'propertyMetadataEditor': 'WorkOrder/Configure/PropertyMetadataEditor',
    'propertyMetadataField': 'attributeSetMetadata',
    'currentTab': 'users',

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const sourceTypes = yield this.get('ajax').request('/masterdata/attributeSourceTypes', {
          'method': 'GET'
        });
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        this.setProperties({
          'sourceTypes': sourceTypes,
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('work-order-create');
      const updatePerm = this.get('currentUser').hasPermission('work-order-update');
      const deletePerm = this.get('currentUser').hasPermission('work-order-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'addWorkOrderFormatAttributeSet': (0, _emberConcurrency.task)(function* () {
      const formatAttrSets = yield this.get('model.attributeSets');
      const alreadyUsedIds = [];

      for (let idx = 0; idx < formatAttrSets.length; idx++) {
        const configAttrSet = formatAttrSets.objectAt(idx);
        const attributeSet = yield configAttrSet.get('tenantAttributeSet');
        alreadyUsedIds.push(attributeSet.get('id'));
      }

      const store = this.get('store');
      const allAttributeSets = yield store.findAll('work-order/attribute-set', {
        'reload': true
      });
      const availableAttrSets = allAttributeSets.filter(attributeSet => {
        return !alreadyUsedIds.includes(attributeSet.get('id'));
      }).map(attributeSet => {
        return {
          'attributeSet': attributeSet,
          'selected': false
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_format_add_attribute_set'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Common/AddAttributeSet',
        'componentState': {
          'model': availableAttrSets
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addWorkOrderFormatAttributeSets').perform(availableAttrSets);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addWorkOrderFormatAttributeSets': (0, _emberConcurrency.task)(function* (availableAttributeSets) {
      const toBeAdded = availableAttributeSets.filter(availableAttributeSet => {
        return availableAttributeSet.selected;
      });

      for (let idx = 0; idx < toBeAdded.length; idx++) {
        let formatAttributeSet = toBeAdded[idx]['formatAttributeSet'];

        if (!formatAttributeSet) {
          const toBeAddedAttributeSet = toBeAdded[idx]['attributeSet'];
          formatAttributeSet = this.get('store').createRecord('work-order/format-attribute-set', {
            'tenant': this.get('model.tenant'),
            'tenantAttributeSet': toBeAddedAttributeSet,
            'workOrderFormat': this.get('model')
          });
          toBeAdded[idx]['formatAttributeSet'] = formatAttributeSet;
        }

        if (formatAttributeSet.get('isNew')) {
          yield formatAttributeSet.save();
          this.get('model.attributeSets').addObject(formatAttributeSet);
        }
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_addWorkOrderFormatAttributeSetsErrored': Ember.on('_addWorkOrderFormatAttributeSets:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'saveWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
      yield this.get('model').reload();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveWorkOrderFormatSucceeded': Ember.on('saveWorkOrderFormat:succeeded', function () {
      const attrSet = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', attrSet.get('id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.succesful_format_save', {
          'htmlSafe': true,
          'displayName': attrSet.get('name')
        })
      });
    }),
    'saveWorkOrderFormatErrored': Ember.on('saveWorkOrderFormat:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      const workOrderFormatName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_delete_format'),
          'iconType': 'md',
          'icon': 'edit-attributes'
        },
        'content': this.intl.t('plant_works_webapp_server_server.work_order_feature.delete_format_message', {
          'htmlSafe': true,
          'displayName': workOrderFormatName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteWorkOrderFormat').perform(workOrderFormatName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteWorkOrderFormat': (0, _emberConcurrency.task)(function* (workOrderFormatName, tenantFolderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteWorkOrderFormatSucceeded': Ember.on('_confirmedDeleteWorkOrderFormat:succeeded', function (taskInstance) {
      const workOrderFormatName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.succesful_format_delete', {
          'htmlSafe': true,
          'displayName': workOrderFormatName
        })
      });
    }),
    '_confirmedDeleteWorkOrderFormatErrored': Ember.on('_confirmedDeleteWorkOrderFormat:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addDataSet': function () {
      this.get('addWorkOrderFormatAttributeSet').perform();
    }
  });

  _exports.default = _default;
});