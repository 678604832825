define("plantworks/components/board/configure/input-data-map-sub-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'boardDataDisplayChoices': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const boardDataDisplayChoices = yield this.get('ajax').request('/masterdata/boardDataDisplayChoices', {
          'method': 'GET'
        });
        this.set('boardDataDisplayChoices', boardDataDisplayChoices);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});