define("plantworks/components/report/devenv/response-formatter", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'formatterDataTransformTop': null,
    'formatterDataTransformBottom': null,
    'type': null,
    'currentFormatter': null,

    init() {
      this._super(...arguments);

      let formatterDataTransformTop = `async _responseDataTransformer(processedData) {`;

      if (this.get('type') === 'email') {
        formatterDataTransformTop = `const exceljs = require('exceljs');
async _responseDataTransformer(processedData) {`;
      }

      this.set('formatterDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([formatterDataTransformTop])
      }));
      let formatterDataTransformBottom = `//  return outputData;
}`;

      if (this.get('type') === 'email') {
        formatterDataTransformBottom = `/* return {
*	subject: 'Plant.Works Report - ${this.get('model.name')}',
*	text: 'Please find the report(s) attached to this mail',
*	attachments: [{
*		'fileName': '${this.get('model.name')}.xlsx',
*		'content': <base64 String>
*		'type': 'xlsx'
*	}, {
*		'fileName': '${this.get('model.name')}.pdf',
*		'content': <base64 String>
*		'type': 'pdf'
*	}]
* };
*/`;
      }

      if (this.get('type') === 'sms') {
        formatterDataTransformBottom = `/* return 'text message';
* };
*/`;
      }

      if (this.get('type') === 'telegram') {
        formatterDataTransformBottom = `/* return 'text message';
* };
*/`;
      }

      this.set('formatterDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([formatterDataTransformBottom])
      }));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('_setupFormatter').perform();
    },

    'cancelResponseFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      yield this.get('currentFormatter').rollback();
    }).enqueue(),
    'saveResponseFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      yield this.get('currentFormatter').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveResponseFormatterSucceeded': Ember.on('saveResponseFormatter:succeeded', function () {
      const report = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_save', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    'saveResponseFormatterErrored': Ember.on('saveResponseFormatter:errored', function (taskInstance, err) {
      const responseFormatter = this.get('currentFormatter');
      responseFormatter.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveResponseFormatter': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentFormatter')) return;
      this.set('currentFormatter.publishStatus', publish);
      yield this.get('currentFormatter').save();
    }).keepLatest(),
    'publishAndSaveResponseFormatterSucceeded': Ember.on('publishAndSaveResponseFormatter:succeeded', function () {
      const report = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.report_feature.succesful_report_save', {
          'htmlSafe': true,
          'displayName': report.get('name')
        })
      });
    }),
    'publishAndSaveResponseFormatterErrored': Ember.on('publishAndSaveResponseFormatter:errored', function (taskInstance, err) {
      const report = this.get('model');
      report.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'editResponseFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      if (!this.get('model')) return;
      const currentFormatter = this.get('currentFormatter'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const formatters = yield this.get('model.responseFormatters');
      const newFormatter = this.get('store').createRecord(`report/report-response-formatter`, {
        'tenant': tenant,
        'tenantReport': this.get('model')
      });
      newFormatter.set('responseType', currentFormatter.get('responseType'));
      newFormatter.set('formatterCode', currentFormatter.get('formatterCode'));
      formatters.addObject(newFormatter);
      this.set('currentFormatter', newFormatter);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,responseFormatters.[]}', function () {
      this.get('_setupFormatter').perform();
    }),
    '_setupFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      let formatters = yield this.get('model.responseFormatters');
      if (!formatters) return;
      formatters = formatters.filterBy('responseType', this.get('type'));
      let currentFormatter = null;

      if (formatters.length) {
        currentFormatter = formatters.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentFormatter = this.get('store').createRecord(`report/report-response-formatter`, {
          'tenant': tenant,
          'tenantReport': this.get('model'),
          'responseType': this.get('type')
        });
        formatters.addObject(currentFormatter);
      }

      this.set('currentFormatter', currentFormatter);
    }).keepLatest()
  });

  _exports.default = _default;
});