define("plantworks/helpers/get-name-by-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getNameById([id, machines]) {
    const ele = id.split(':')[id.split(':').length - 1];
    const nameArr = machines.find(x => x.id === ele);
    let name = nameArr.name ? nameArr.name : '';
    return name;
  }

  var _default = Ember.Helper.helper(getNameById);

  _exports.default = _default;
});