define("plantworks/components/board/devenv/response-formatter", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'formatterDataTransformTop': null,
    'formatterDataTransformBottom': null,
    'currentFormatter': null,

    init() {
      this._super(...arguments);

      this.set('formatterDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`async _responseDataTransformer(processedData) {`])
      }));
      this.set('formatterDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`//  return outputData;
}`])
      }));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('_setupFormatter').perform();
    },

    'cancelResponseFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      yield this.get('currentFormatter').rollback();
    }).enqueue(),
    'saveResponseFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      yield this.get('currentFormatter').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveResponseFormatterSucceeded': Ember.on('saveResponseFormatter:succeeded', function () {
      const panel = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.board_feature.succesful_panel_save', {
          'htmlSafe': true,
          'displayName': panel.get('name')
        })
      });
    }),
    'saveResponseFormatterErrored': Ember.on('saveResponseFormatter:errored', function (taskInstance, err) {
      const responseFormatter = this.get('currentFormatter');
      responseFormatter.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveResponseFormatter': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentFormatter')) return;
      this.set('currentFormatter.publishStatus', publish);
      yield this.get('currentFormatter').save();
    }).keepLatest(),
    'publishAndSaveResponseFormatterSucceeded': Ember.on('publishAndSaveResponseFormatter:succeeded', function () {
      const panel = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.board_feature.succesful_panel_save', {
          'htmlSafe': true,
          'displayName': panel.get('name')
        })
      });
    }),
    'publishAndSaveResponseFormatterErrored': Ember.on('publishAndSaveResponseFormatter:errored', function (taskInstance, err) {
      const panel = this.get('model');
      panel.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'editResponseFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentFormatter')) return;
      if (!this.get('model')) return;
      const currentFormatter = this.get('currentFormatter'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const formatters = yield this.get('model.responseFormatters');
      const newFormatter = this.get('store').createRecord(`board/panel-response-formatter`, {
        'tenant': tenant,
        'tenantHistoricalDashboard': this.get('model')
      });
      newFormatter.set('responseType', currentFormatter.get('responseType'));
      newFormatter.set('formatterCode', currentFormatter.get('formatterCode'));
      formatters.addObject(newFormatter);
      this.set('currentFormatter', newFormatter);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,responseFormatters.[]}', function () {
      this.get('_setupFormatter').perform();
    }),
    '_setupFormatter': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      const formatters = yield this.get('model.responseFormatters');
      if (!formatters) return;
      let currentFormatter = null;

      if (formatters.length) {
        currentFormatter = formatters.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentFormatter = this.get('store').createRecord(`board/panel-response-formatter`, {
          'tenant': tenant,
          'tenantHistoricalDashboard': this.get('model')
        });
        formatters.addObject(currentFormatter);
      }

      this.set('currentFormatter', currentFormatter);
    }).keepLatest()
  });

  _exports.default = _default;
});