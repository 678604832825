define("plantworks/components/manufacturing/schedule/plant-unit-downtime-uploader", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});