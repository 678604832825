define("plantworks/models/manufacturing/plant-unit", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'plantUnits'
    }),
    'plant': _emberData.default.belongsTo('manufacturing/plant', {
      'async': true,
      'inverse': 'units'
    }),
    'parent': _emberData.default.belongsTo('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'units'
    }),
    'units': _emberData.default.hasMany('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'parent'
    }),
    'drivers': _emberData.default.hasMany('manufacturing/plant-unit-driver', {
      'async': true,
      'inverse': 'tenantPlantUnit'
    }),
    'machines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'tenantPlantUnit'
    }),
    'stations': _emberData.default.hasMany('manufacturing/plant-unit-station', {
      'async': true,
      'inverse': 'tenantPlantUnit'
    }),
    'lines': _emberData.default.hasMany('manufacturing/plant-unit-line', {
      'async': true,
      'inverse': 'tenantPlantUnit'
    }),
    'schedules': _emberData.default.hasMany('manufacturing/plant-unit-schedule', {
      'async': true,
      'inverse': 'tenantPlantUnit'
    }),
    'extensions': _emberData.default.hasMany('manufacturing/plant-unit-schedule-extension', {
      'async': true,
      'inverse': 'tenantPlantUnit'
    }),
    'path': Ember.computed('name', 'parent.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const parent = yield this.get('parent');

      if (!(parent && parent.get('id'))) {
        const plant = yield this.get('plant');
        return `${plant.get('name')} > ${this.get('name')}`;
      }

      const parentPath = this.get('parent.path');
      return `${parentPath} > ${this.get('name')}`;
    }).keepLatest()
  });

  _exports.default = _default;
});