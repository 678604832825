define("plantworks/components/manufacturing/watch/coke-alarm-downtime-remark-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'downtimeReasons': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      yield this.get('_retrieveDowntimeReasons').perform();
    }).on('init').keepLatest(),
    '_retrieveDowntimeReasons': (0, _emberConcurrency.task)(function* () {
      if (!this.get('machine')) return;
      let type = yield this.get('model.type');
      const downtimeReasonList = type === 'downtime' ? yield this.get('machine.downtimeList') : yield this.get('machine.speedDeviationList');
      if (!downtimeReasonList) return;
      let culpritMachine = yield this.get('culpritMachine');
      if (!this.get('model.reasons').length) return;
      let alarmCode = type === 'downtime' ? yield this.get('model.reasons').objectAt(0).get('reasonCode') : 0;
      let reasonOptions;
      if (type === 'downtime') reasonOptions = yield this.get('ajax').request(`/emd/retrieveCokeAlarmDowntimeRemarks/${downtimeReasonList.get('id')}/${culpritMachine.replace(/ /g, '-')}/${alarmCode}`, {
        'method': 'GET'
      });else reasonOptions = this.get('operatorBasedDowntimesReasons').filter(reason => {
        return reason.machine == culpritMachine;
      });
      reasonOptions = reasonOptions.map(e => {
        return e.value;
      });
      this.get('model.reasons').objectAt(0).set('reasonOptions', reasonOptions);
    }).keepLatest(),
    'editReason': (0, _emberConcurrency.task)(function* (reason) {
      reason.set('isEditing', true);
    }).enqueue(),
    'cancelReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.rollback();
      reason.set('isEditing', false);
    }).enqueue(),
    'saveReason': (0, _emberConcurrency.task)(function* (reason) {
      if (reason.metadata.remark && reason.metadata.remark != '') {
        yield reason.save();
        yield reason.reload();
        let downtimes = yield this.get('workOrderObj').get('actualMachineDowntimes'); //.actualMachineDowntimes

        let count = 0;
        let unaccForThisWo = 0;

        for (let i = 0; i < downtimes.length; i++) {
          let downtime = downtimes.objectAt(i);
          unaccForThisWo += yield downtime.get('unaccountedDurationForCalculations');
          let downtimeReasons = yield downtime.get('reasons');

          for (let j = 0; j < downtimeReasons.length; j++) {
            if (!downtimeReasons.objectAt(j).get('metadata.remark') || downtimeReasons.objectAt(j).get('metadata.remark') == '') {
              count++;
              break;
            }
          }
        }

        this.get('workOrderObj').set('nonEnteredRemarkCount', count);
        this.get('workOrderObj').set('unaccountedDuration', unaccForThisWo);
        reason.set('isEditing', false);
      } else {
        this.get('notification').display({
          'type': 'error',
          'error': 'Invalid Input'
        });
        return;
      }

      return;
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveReasonSucceeded': Ember.on('saveReason:succeeded', function () {
      // this.get('callParentTask').perform();
      this.get('parentTask').perform();
    }),
    'saveReasonErrored': Ember.on('saveReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});