define("plantworks/templates/components/manufacturing/schedule/plant-unit-downtime-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Li2wMVvN",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[5,\"plant-works-card\",[],[[\"@class\"],[\"m-0 flex\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"p-0 layout-column layout-align-start-stretch\"]],{\"statements\":[[0,\"\\n\\n\\t\\t\"],[5,\"manufacturing/schedule/plant-unit-downtime-uploader/planned-downtime\",[],[[\"@model\",\"@controller-action\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\\n\\t\\t\"],[5,\"manufacturing/schedule/plant-unit-downtime-uploader/scheduled-downtime\",[],[[\"@model\",\"@controller-action\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\\n\\t\\t\"],[5,\"manufacturing/schedule/plant-unit-downtime-uploader/maintenace-uploader\",[],[[\"@model\",\"@controller-action\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\\n\\t\\t\"],[7,\"strong\",true],[8],[1,[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.bulk_uploader_notes\"],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"ul\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[1,[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.bulk_uploader_desc1\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[1,[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.bulk_uploader_desc2\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"li\",true],[8],[1,[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.bulk_uploader_desc3\"],null],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/schedule/plant-unit-downtime-uploader.hbs"
    }
  });

  _exports.default = _default;
});