define("plantworks/adapters/application", ["exports", "ember-data", "ember-cli-uuid/mixins/adapters/uuid", "ember-ajax/mixins/ajax-support"], function (_exports, _emberData, _uuid, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_uuid.default, _ajaxSupport.default);

  _exports.default = _default;
});