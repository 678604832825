define("plantworks/components/plant-works-password", ["exports", "pworks-dsl/components/plant-works-password"], function (_exports, _plantWorksPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksPassword.default;
    }
  });
});