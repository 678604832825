define("plantworks/components/operator-form/configure/operator-form-editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'sourceTypes': null,
    'dataTypes': null,
    'timeFormats': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'operator-form-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('operator-form-create');
      const updatePerm = this.get('currentUser').hasPermission('operator-form-update');
      const deletePerm = this.get('currentUser').hasPermission('operator-form-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'cancelOperatorForm': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').rollback();
    }).enqueue(),
    'saveOperatorForm': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveOperatorFormSucceeded': Ember.on('saveOperatorForm:succeeded', function () {
      const unit = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.operator_form_feature.succesful_operator_form_save', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    'saveOperatorFormErrored': Ember.on('saveOperatorForm:errored', function (taskInstance, err) {
      const unit = this.get('model');
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteOperatorForm': (0, _emberConcurrency.task)(function* () {
      const operatorFormName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.operator_form_feature.label_delete_operator_form'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.operator_form_feature.delete_operator_form_message', {
          'htmlSafe': true,
          'displayName': operatorFormName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteOperatorForm').perform(operatorFormName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteOperatorForm': (0, _emberConcurrency.task)(function* (operatorFormName, folderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteOperatorFormSucceeded': Ember.on('_confirmedDeleteOperatorForm:succeeded', function (taskInstance) {
      const operatorFormName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.operator_form_feature.succesful_operator_form_delete', {
          'htmlSafe': true,
          'displayName': operatorFormName
        })
      });
    }),
    '_confirmedDeleteOperatorFormErrored': Ember.on('_confirmedDeleteOperatorForm:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addOperatorFormAttributeSet': (0, _emberConcurrency.task)(function* () {
      const operatorFormAttrSets = yield this.get('model.attributeSets');
      const alreadyUsedIds = [];

      for (let idx = 0; idx < operatorFormAttrSets.length; idx++) {
        const operatorFormAttrSet = operatorFormAttrSets.objectAt(idx);
        const attributeSet = yield operatorFormAttrSet.get('tenantAttributeSet');
        alreadyUsedIds.push(attributeSet.get('id'));
      }

      const store = this.get('store');
      const allAttributeSets = yield store.findAll('operator-form/attribute-set', {
        'reload': true
      });
      const availableAttrSets = allAttributeSets.filter(attributeSet => {
        return !alreadyUsedIds.includes(attributeSet.get('id'));
      }).map(attributeSet => {
        return {
          'attributeSet': attributeSet,
          'selected': false
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_add_data_set'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Common/AddAttributeSet',
        'componentState': {
          'model': availableAttrSets
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addOperatorFormAttributeSets').perform(availableAttrSets);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addOperatorFormAttributeSets': (0, _emberConcurrency.task)(function* (availableAttributeSets) {
      const toBeAdded = availableAttributeSets.filter(availableAttributeSet => {
        return availableAttributeSet.selected;
      });

      for (let idx = 0; idx < toBeAdded.length; idx++) {
        let operatorFormAttributeSet = toBeAdded[idx]['operatorFormAttributeSet'];

        if (!operatorFormAttributeSet) {
          const toBeAddedAttributeSet = toBeAdded[idx]['attributeSet'];
          operatorFormAttributeSet = this.get('store').createRecord('operator-form/operator-form-attribute-set', {
            'tenant': this.get('model.tenant'),
            'tenantAttributeSet': toBeAddedAttributeSet,
            'tenantOperatorForm': this.get('model')
          });
          toBeAdded[idx]['operatorFormAttributeSet'] = operatorFormAttributeSet;
        }

        if (operatorFormAttributeSet.get('isNew')) {
          yield operatorFormAttributeSet.save();
          this.get('model.attributeSets').addObject(operatorFormAttributeSet);
        }
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_addOperatorFormAttributeSetsErrored': Ember.on('_addOperatorFormAttributeSets:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addDataSet': function () {
      this.get('addOperatorFormAttributeSet').perform();
    }
  });

  _exports.default = _default;
});