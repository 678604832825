define("plantworks/components/work-order/work-order-app/uploader", ["exports", "plantworks/framework/base-component", "@uppy/core", "@uppy/dashboard", "@uppy/xhr-upload", "ember-concurrency"], function (_exports, _baseComponent, _core, _dashboard, _xhrUpload, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    uppy: null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    didInsertElement() {
      this._super(...arguments);

      this.initializeUppy();
    },

    didRender() {
      this._super(...arguments);

      if (this.get('uppy') && this.get('uppy').getPlugin('Dashboard') && this.get('uppy').getPlugin('XHRUpload')) this.get('uppy').getPlugin('XHRUpload').opts.endpoint = `/work-order/upload-work-order/${this.get('selectedNode.id')}`;
    },

    'initializeUppy': function () {
      const self = this;
      const uppy = (0, _core.default)({
        id: 'workOrderUppy',
        debug: true,
        autoProceed: false,
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ['.xlsx', '.xls']
        }
      }).use(_dashboard.default, {
        trigger: '#upload-button',
        inline: false,
        showProgressDetails: true,
        note: null,
        //change later
        closeModalOnClickOutside: true
      }).use(_xhrUpload.default, {
        endpoint: `/work-order/upload-work-order/${this.get('selectedNode.id')}`,
        method: 'post',
        timeout: 120000
      });
      uppy.on('upload-success', (file, response) => {
        if (response.body.rows[0].column) {
          this.get('notification').display({
            'type': 'error',
            'error': this.intl.t('plant_works_webapp_server_server.work_order_feature.error_file_upload')
          });
        } else {
          self.setViewerData(response);
        }

        const dashboard = uppy.getPlugin('Dashboard');

        if (dashboard.isModalOpen()) {
          dashboard.closeModal();
        }

        uppy.reset();
      });
      uppy.on('upload-error', (file, error, response) => {
        this.get('notification').display({
          'type': 'error',
          'error': error
        });
        uppy.reset();
      });
      uppy.on('dashboard:modal-closed', () => {
        uppy.reset();
      });
      self.set('uppy', uppy);
    },
    'setViewerData': function (data) {
      this.invokeAction('controller-action', 'setUploadedData', data.body);
    },
    'downloadWOExcel': (0, _emberConcurrency.task)(function* () {
      const blob = yield this.makeRequest(this.get('selectedNode.id'));
      let buffer = yield blob.arrayBuffer();
      saveAs(new Blob([buffer]), `${this.get('selectedNode.text')}.xlsx`);
    }).keepLatest(),

    makeRequest(woId) {
      return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/work-order/download-work-order/${woId}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        xhr.responseType = 'blob';

        xhr.onload = function (e) {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
        };

        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };

        xhr.send();
      });
    }

  });

  _exports.default = _default;
});