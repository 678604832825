define("plantworks/templates/components/work-order/work-order-app/format-data-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AN3qhtMp",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-card\",[],[[\"@class\"],[\"m-0 flex\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"m-0 p-0\"]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"work-order/work-order-app/uploader\",[],[[\"@selectedNode\",\"@model\",\"@controller-action\"],[[22,\"selectedNode\"],[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\\t\\t\\t\"],[5,\"work-order/work-order-app/viewer\",[],[[\"@selectedNode\",\"@model\",\"@uploadedData\",\"@controller-action\"],[[22,\"selectedNode\"],[22,\"model\"],[22,\"uploadedData\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/work-order/work-order-app/format-data-viewer.hbs"
    }
  });

  _exports.default = _default;
});