define("plantworks/models/manufacturing/plant-unit-line-event", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitLine': _emberData.default.belongsTo('manufacturing/plant-unit-line', {
      'async': true,
      'inverse': 'events'
    }),
    'tenantPlantUnitStation': _emberData.default.belongsTo('manufacturing/plant-unit-station', {
      'async': true,
      'inverse': 'tenantPlantUnitLineEvents'
    }),
    'eventType': _emberData.default.belongsTo('masterdata/event-type', {
      'async': true,
      'inverse': null
    }),
    'eventTimestamp': _emberData.default.attr('date', {
      defaultValue() {
        let timestamp = new Date();
        timestamp.setMilliseconds(0);
        return timestamp;
      }

    }),
    'eventMetadata': (0, _attributes.fragment)('manufacturing/fragments/entity-event-metadata')
  });

  _exports.default = _default;
});