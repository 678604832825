define("plantworks/models/manufacturing/plant-unit-machine-downtime-reason", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes", "ember-concurrency"], function (_exports, _baseModel, _emberData, _attributes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'reasonCode': _emberData.default.attr('string', {
      'defaultValue': 'UNKNOWN'
    }),
    'reasonDescription': _emberData.default.attr('string', {
      'defaultValue': 'UNKNOWN REASON'
    }),
    'reasonDurationInMin': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'reasonFilterList': null,
    'allReasons': null,
    'reasonSubClass': Ember.computed('reasonCode', function () {
      let subclass = this.get('reasonCode');

      if (subclass) {
        subclass = subclass.split('!')[0].toString();
        subclass = subclass.toUpperCase();
      } else {
        subclass = '';
      }

      return subclass;
    }),
    'filterReason': Ember.observer('reasonSubClass', function () {
      this.set('reasonFilterList', []);
      this.set('reasonDescription', '');
      const reasonList = this.get('allReasons');
      let filterReasonSelection = [];

      if (reasonList && reasonList.length) {
        reasonList.forEach(res => {
          if (this.get('reasonSubClass') == res.subClass) filterReasonSelection.push(res);
        });
        this.set('reasonFilterList', filterReasonSelection);
      } else {
        this.set('reasonFilterList', []);
      }
    }),
    'maxReasonDuration': Ember.computed('reasonDurationInMin', 'tenantPlantUnitMachineDowntime.unaccountedDuration', function () {
      return this.get('_computedMaxReasonDuration').perform();
    }),
    'reasonOptions': _emberData.default.attr('string', {
      readOnly: true
    }),
    'isEditing': _emberData.default.attr('boolean', {
      'defaultValue': false,
      'readOnly': true
    }),
    'metadata': (0, _attributes.fragment)('manufacturing/fragments/downtime-reason-metadata'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitMachineDowntime': _emberData.default.belongsTo('manufacturing/plant-unit-machine-downtime', {
      'async': true,
      'inverse': 'reasons'
    }),
    'onReasonDurationChanged': Ember.observer('reasonDurationInMin', function () {
      this.get('_trimDuration').perform();
    }),
    '_computedMaxReasonDuration': (0, _emberConcurrency.task)(function* () {
      const machineDowntime = yield this.get('tenantPlantUnitMachineDowntime');
      const durationUnaccountedTime = yield machineDowntime.get('unaccountedDuration');
      return Number(this.get('reasonDurationInMin')) + durationUnaccountedTime;
    }).enqueue(),
    '_trimDuration': (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(250);
      const reasonDuration = Number(this.get('reasonDurationInMin'));
      const maxDuration = yield this.get('maxReasonDuration');
      if (reasonDuration <= maxDuration) return;
      this.set('reasonDurationInMin', maxDuration);
    }).keepLatest()
  });

  _exports.default = _default;
});