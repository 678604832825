define("plantworks/components/alert/devenv/property-metadata-viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'tagName': '',
    'allowedEntityTypes': null,
    'chosenEntityType': null,
    'chosenEntity': null,
    'chosenDataPoint': null,
    'metadata': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      // Metadata Level stuff...
      const allMetadata = yield this.get('Alert.attributeSetMetadata');
      let metadata = allMetadata.filterBy('parameterId', this.get('InputDataPoint.id')).objectAt(0);

      if (!metadata) {
        metadata = allMetadata.createFragment({
          'parameterId': this.get('InputDataPoint.id'),
          'constituentType': null,
          'constituentId': null,
          'externalTag': null
        });
      }

      this.set('metadata', metadata);
      let allObservedEntities = yield this.get('Alert.inputConstituents');
      const allowedEntityTypes = [];
      Object.keys(allObservedEntities).forEach(entityType => {
        allowedEntityTypes.push(entityType);
      });
      this.set('allowedEntityTypes', allowedEntityTypes);

      if (!metadata.get('constituentType')) {
        metadata.set('constituentId', null);
      }

      if (!metadata.get('constituentId')) {
        metadata.set('externalTag', null);
        return;
      }

      let chosenEntityType = null;
      let chosenEntity = null;
      Object.keys(allObservedEntities).forEach(entityType => {
        const constituentEntities = allObservedEntities[entityType];
        const constituentEntity = constituentEntities.filterBy('actualEntity.id', metadata.get('constituentId')).objectAt(0);

        if (constituentEntity) {
          chosenEntityType = entityType;
          chosenEntity = constituentEntity;
        }
      });
      this.set('chosenEntityType', chosenEntityType);
      if (!chosenEntityType) return;
      yield (0, _emberConcurrency.timeout)(100);
      this.set('chosenEntity', chosenEntity);
      if (!chosenEntity) return;
      yield (0, _emberConcurrency.timeout)(100); // Metadata Observed Entity Datapoint Level stuff...

      if (!metadata.get('externalTag')) return;
      let allEntityDataPoints = yield chosenEntity.get('actualEntity.dataPoints');
      let chosenDataPoint = allEntityDataPoints.filterBy('internalTag', metadata.get('externalTag')).objectAt(0);
      this.set('chosenDataPoint', chosenDataPoint);
    }).on('init').drop(),
    'deletePropertyMetadata': (0, _emberConcurrency.task)(function* () {
      yield this.set('chosenEntityType', null);
    }).enqueue(),
    'onChosenEntityTypeChanged': Ember.observer('chosenEntityType', function () {
      const currChosenConstituentType = this.get('chosenEntityType');
      const currMetaConstituentType = this.get('metadata.constituentType');
      const hasChanged = currChosenConstituentType !== currMetaConstituentType;
      if (!hasChanged) return;
      if (!this.get('chosenEntityType')) this.set('metadata.constituentType', null);else this.set('metadata.constituentType', this.get('chosenEntityType'));
      this.set('chosenEntity', null);
    }),
    'onChosenEntityChanged': Ember.observer('chosenEntity', function () {
      const currChosenConstituent = this.get('chosenEntity') && this.get('chosenEntity.actualEntity') ? this.get('chosenEntity.actualEntity.id') : null;
      const currMetaConstituent = this.get('metadata.constituentId');
      const hasChanged = currChosenConstituent !== currMetaConstituent;
      if (!hasChanged) return;
      if (!this.get('chosenEntity')) this.set('metadata.constituentId', null);else this.set('metadata.constituentId', this.get('chosenEntity.actualEntity.id'));
      this.set('chosenDataPoint', null);
    }),
    'onChosenDataPointChanged': Ember.observer('chosenDataPoint', function () {
      if (!this.get('chosenDataPoint')) this.set('metadata.externalTag', null);else this.set('metadata.externalTag', this.get('chosenDataPoint.internalTag'));
    })
  });

  _exports.default = _default;
});