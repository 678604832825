define("plantworks/components/common/single-attribute-set/list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', this.get('requiredPermissions.minPerm'));
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const parentModel = yield this.get('model.parent');
      const createPerm = this.get('currentUser').hasPermission(this.get('requiredPermissions.createPerm')) && parentModel && !!parentModel.get('name');
      const updatePerm = this.get('currentUser').hasPermission(this.get('requiredPermissions.updatePerm')) && parentModel && !!parentModel.get('name');
      const deletePerm = this.get('currentUser').hasPermission(this.get('requiredPermissions.deletePerm')) && parentModel && !!parentModel.get('name');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
    }).keepLatest(),
    'addAttributeSet': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newAttributeSet = this.get('store').createRecord(`${this.get('modelModule')}/attribute-set`, {
        'tenant': tenant,
        'parent': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.common.new_attribute_set_name', {
        'now': window.moment().valueOf()
      });
      newAttributeSet.set('name', displayName);
      newAttributeSet.set('description', displayName);
      const siblingAttributeSets = yield this.get('model.attributeSets');
      siblingAttributeSets.addObject(newAttributeSet);
      this.get('editAttributeSet').perform(newAttributeSet);
    }).enqueue(),
    'editAttributeSet': (0, _emberConcurrency.task)(function* (attributeSet) {
      yield attributeSet.set('isEditing', true);
    }).enqueue(),
    'cancelAttributeSet': (0, _emberConcurrency.task)(function* (attributeSet) {
      attributeSet.set('isEditing', false);
      yield attributeSet.rollback();
    }).enqueue(),
    'saveAttributeSet': (0, _emberConcurrency.task)(function* (attributeSet) {
      attributeSet.set('isEditing', false);
      yield attributeSet.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveAttributeSetSucceeded': Ember.on('saveAttributeSet:succeeded', function (taskInstance) {
      const attributeSet = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_attribute_set_save', {
          'htmlSafe': true,
          'displayName': attributeSet.get('name')
        })
      });
    }),
    'saveAttributeSetErrored': Ember.on('saveAttributeSet:errored', function (taskInstance, err) {
      const attributeSet = taskInstance.args[0];
      attributeSet.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteAttributeSet': (0, _emberConcurrency.task)(function* (attributeSet) {
      const attributeSetName = yield attributeSet.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_delete_attribute_set'),
          'iconType': 'md',
          'icon': 'edit-attributes'
        },
        'content': this.intl.t('plant_works_webapp_server_server.common.delete_attribute_set_message', {
          'htmlSafe': true,
          'displayName': attributeSetName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteAttributeSet').perform(attributeSet);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteAttributeSet': (0, _emberConcurrency.task)(function* (attributeSet) {
      yield attributeSet.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteAttributeSetSucceeded': Ember.on('_confirmedDeleteAttributeSet:succeeded', function (taskInstance) {
      const attributeSet = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_attribute_set_delete', {
          'htmlSafe': true,
          'displayName': attributeSet.get('name')
        })
      });
    }),
    '_confirmedDeleteAttributeSetErrored': Ember.on('_confirmedDeleteAttributeSet:errored', function (taskInstance, err) {
      const attributeSet = taskInstance.args[0];
      attributeSet.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedAttributeSet': function (selectedAttributeSet) {
      if (selectedAttributeSet.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedAttributeSet.get('id'), false, false);
    }
  });

  _exports.default = _default;
});