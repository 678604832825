define("plantworks/templates/components/profile/delete-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UMRBg4DW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-button\",[],[[\"@class\",\"@warn\",\"@raised\",\"@onClick\",\"@bubbles\"],[\"flex\",true,false,[28,\"perform\",[[24,[\"deleteAccount\"]]],null],false]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"mdi-icon\",[],[[\"@icon\",\"@class\"],[\"account-off\",\"m-0 p-0 mr-2\"]]],[0,\"\\n\\t\\t\"],[1,[28,\"t\",[\"modal.default_delete_text\"],null],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/profile/delete-component.hbs"
    }
  });

  _exports.default = _default;
});