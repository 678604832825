define("plantworks/models/devenv/node", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'nodeType': _emberData.default.attr('string', {
      'defaultValue': 'leaf'
    }),
    'route': _emberData.default.attr('string'),
    'dataUrl': _emberData.default.attr('string'),
    'devenvRoute': Ember.computed('route', function () {
      return `devenv.${this.get('route')}`;
    }),
    'i18n_name': Ember.computed('i18n_tag', function () {
      return this.intl.t(`${this.get('i18n_tag')}.title`);
    }),
    'i18n_desc': Ember.computed('i18n_tag', function () {
      return this.intl.t(`${this.get('i18n_tag')}.description`);
    }),
    'i18n_tag': Ember.computed('route', 'intl.locale', function () {
      if (!this.get('route')) return '';
      return `plant_works_webapp_server_server.devenv_feature.${this.get('route').replace(/ /g, '_').replace(/-/g, '_').toLowerCase()}`;
    })
  });

  _exports.default = _default;
});