define("plantworks/controllers/settings/account/features", ["exports", "plantworks/framework/base-controller", "ember-concurrency"], function (_exports, _baseController, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'breadcrumbStack': null,
    'selectedFeature': null,
    'editable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-features-read');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    'onPermissionChanges': Ember.on('init', Ember.observer('permissions', function () {
      this.onUserDataUpdated();
    })),

    onUserDataUpdated() {
      const updatePerm = this.get('currentUser').hasPermission('settings-account-features-update');
      this.set('editable', updatePerm);
    },

    setSelectedFeature(featureModel) {
      if (!featureModel) {
        this.set('selectedFeature', null);
        this.set('breadcrumbStack', null);
        return;
      }

      featureModel.reload().then(reloadedModel => {
        this.set('selectedFeature', reloadedModel);
        this.get('setBreadcrumbHierarchy').perform();
      }).catch(err => {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      });
    },

    'setBreadcrumbHierarchy': (0, _emberConcurrency.task)(function* () {
      let currentFeature = this.get('selectedFeature');
      const breadcrumbHierarchy = [];

      while (currentFeature) {
        if (currentFeature.get('name')) breadcrumbHierarchy.unshift(currentFeature);
        currentFeature = yield currentFeature.get('parent');
      }

      this.set('breadcrumbStack', breadcrumbHierarchy);
    }).keepLatest()
  });

  _exports.default = _default;
});