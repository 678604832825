define("plantworks/models/alert/alert-processor", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'processedDataTransformCode': _emberData.default.attr('string'),
    'onDataCode': _emberData.default.attr('string'),
    'onEventCode': _emberData.default.attr('string'),
    'publishStatus': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantAlert': _emberData.default.belongsTo('alert/alert', {
      'async': true,
      'inverse': 'processors'
    })
  });

  _exports.default = _default;
});