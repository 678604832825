define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/basics-sub-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'operatorListPropertyName': 'operatorList',
    'operatorListFilterPropertyName': 'operatorListFilters',
    'operatorListDisplayName': null,
    'downtimeListPropertyName': 'downtimeList',
    'downtimeListFilterPropertyName': 'downtimeListFilters',
    'setuptimeListFilterPropertyName': 'setuptimeListFilters',
    'idletimeListFilterPropertyName': 'idletimeListFilters',
    'downtimeDisplayName': null,
    'speedDeviationListDisplayName': null,
    'speedDeviationListPropertyName': 'speedDeviationList',
    'speedDeviationListFilterPropertyName': 'speedDeviationsListFilters',

    init() {
      this._super(...arguments);

      this.set('operatorListDisplayName', this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_operator_list'));
      this.set('downtimeListDisplayName', this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_downtime_list'));
      this.set('speedDeviationListDisplayName', this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_speed_deviation_list'));
    }

  });

  _exports.default = _default;
});