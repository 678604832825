define("plantworks/components/manufacturing/watch/station-observed-line-add-supervisor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({});

  _exports.default = _default;
});