define("plantworks/components/common/single-attribute-set/function-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'viewMode': 'configure',
    'addAttributeSetFunction': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newAttributeSetFunction = this.get('store').createRecord(`${this.get('modelModule')}/attribute-set-function`, {
        'tenant': tenant,
        'attributeSet': this.get('model'),
        'type': this.get('funcType') || 'observer'
      });
      const displayName = this.intl.t(`plant_works_webapp_server_server.common.new_attribute_set_${this.get('funcType') || 'observer'}func_name`, {
        'now': window.moment().valueOf()
      });
      newAttributeSetFunction.set('name', displayName);
      newAttributeSetFunction.set('description', displayName);
      const siblingFunctions = yield this.get('model.functions');
      siblingFunctions.addObject(newAttributeSetFunction);
      this.get('editAttributeSetFunction').perform(newAttributeSetFunction);
    }).enqueue(),
    'editAttributeSetFunction': (0, _emberConcurrency.task)(function* (func) {
      yield func.set('isEditing', true);
    }).enqueue(),
    'cancelAttributeSetFunction': (0, _emberConcurrency.task)(function* (func) {
      func.set('isEditing', false);
      yield func.rollback();
    }).enqueue(),
    'saveAttributeSetFunction': (0, _emberConcurrency.task)(function* (func) {
      func.set('isEditing', false);
      yield func.save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveAttributeSetFunctionSucceeded': Ember.on('saveAttributeSetFunction:succeeded', function (taskInstance) {
      const func = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t(`plant_works_webapp_server_server.common.succesful_attribute_set_${func.get('type')}func_save`, {
          'htmlSafe': true,
          'displayName': func.get('name')
        })
      });
    }),
    'saveAttributeSetFunctionErrored': Ember.on('saveAttributeSetFunction:errored', function (taskInstance, err) {
      const func = taskInstance.args[0];
      func.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteAttributeSetFunction': (0, _emberConcurrency.task)(function* (func) {
      const funcName = yield func.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t(`plant_works_webapp_server_server.common.label_delete_attribute_set_${func.get('type')}func`),
          'iconType': 'mdi',
          'icon': 'sprout'
        },
        'content': this.intl.t(`plant_works_webapp_server_server.common.delete_attribute_set_${func.get('type')}func_message`, {
          'htmlSafe': true,
          'displayName': funcName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteAttributeSetFunction').perform(func, funcName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteAttributeSetFunction': (0, _emberConcurrency.task)(function* (func, funcName) {
      // eslint-disable-line no-unused-vars
      yield func.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteAttributeSetFunctionSucceeded': Ember.on('_confirmedDeleteAttributeSetFunction:succeeded', function (taskInstance) {
      const func = taskInstance.args[0];
      const funcName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t(`plant_works_webapp_server_server.common.succesful_attribute_set_${func.get('type')}func_delete`, {
          'htmlSafe': true,
          'displayName': funcName
        })
      });
    }),
    '_confirmedDeleteAttributeSetFunctionErrored': Ember.on('_confirmedDeleteAttributeSetFunction:errored', function (taskInstance, err) {
      const func = taskInstance.args[0];
      func.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});