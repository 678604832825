define("plantworks/models/settings/account/webhooks/tenant-webhook", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModel, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'url': _emberData.default.attr('string'),
    'events': (0, _attributes.array)('string'),
    'headers': (0, _attributes.fragmentArray)('settings/account/webhooks/fragments/tenant-webhook-request-header'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'tenantWebhooks'
    })
  });

  _exports.default = _default;
});