define("plantworks/models/historical-dashboard/historical-dashboard", ["exports", "plantworks/framework/base-model-multiple-attribute-sets", "ember-data", "ember-data-model-fragments/attributes", "ember-concurrency"], function (_exports, _baseModelMultipleAttributeSets, _emberData, _attributes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModelMultipleAttributeSets.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'dataPersistencePeriod': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'enableSelectorComponent': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'attributeSetMetadata': (0, _attributes.fragmentArray)('historical-dashboard/fragments/historical-dashboard-attribute-set-metadata'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'historicalDashboards'
    }),
    'folder': _emberData.default.belongsTo('historical-dashboard/folder', {
      'async': true,
      'inverse': 'historicalDashboards'
    }),
    'attributeSets': _emberData.default.hasMany('historical-dashboard/historical-dashboard-attribute-set', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'dataPointAggregations': _emberData.default.hasMany('historical-dashboard/historical-dashboard-aggregate', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'constituents': _emberData.default.hasMany('historical-dashboard/historical-dashboard-constituent', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'processors': _emberData.default.hasMany('historical-dashboard/historical-dashboard-processor', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'watchers': _emberData.default.hasMany('historical-dashboard/historical-dashboard-watcher', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'requestFormatters': _emberData.default.hasMany('historical-dashboard/historical-dashboard-request-formatter', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'executors': _emberData.default.hasMany('historical-dashboard/historical-dashboard-executor', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'responseFormatters': _emberData.default.hasMany('historical-dashboard/historical-dashboard-response-formatter', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'inputTemplates': _emberData.default.hasMany('historical-dashboard/historical-dashboard-input-template', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'resultTemplates': _emberData.default.hasMany('historical-dashboard/historical-dashboard-result-template', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'inputDataPoints': Ember.computed('dataPoints.@each.{name,internalTag,units,source,datatype}', function () {
      return this.get('_inputDataPoints').perform();
    }),
    'inputConstituents': Ember.computed('constituents.@each.{tenantHistoricalDashboardConstituentId,tenantHistoricalDashboardConstituentType}', function () {
      return this.get('_observedEntities').perform();
    }),
    'path': Ember.computed('name', 'folder.path', function () {
      return this.get('_computePath').perform();
    }),
    '_computePath': (0, _emberConcurrency.task)(function* () {
      const folder = yield this.get('folder');
      const parentPath = yield folder.get('path');
      return `${parentPath} > ${this.get('name')}`;
    }).keepLatest(),
    '_inputDataPoints': (0, _emberConcurrency.task)(function* () {
      const allDataPoints = yield this.get('dataPoints');
      return allDataPoints.filterBy('source', 'input');
    }).enqueue(),
    'subcomponents': _emberData.default.hasMany('historical-dashboard/historical-dashboard-subcomponent', {
      'async': true,
      'inverse': 'tenantHistoricalDashboard'
    }),
    'currentSubcomponents': Ember.computed('subcomponents.[]', function () {
      return this.get('_currentSubcomponents').perform();
    }),
    'publishedSubcomponents': Ember.computed('subcomponents.[]', function () {
      return this.get('_publishedSubcomponents').perform();
    }),
    '_currentSubcomponents': (0, _emberConcurrency.task)(function* () {
      let allSubcomponents = yield this.get('subcomponents');
      allSubcomponents = allSubcomponents.sortBy('createdAt');
      const subcomponentHash = {};
      allSubcomponents.forEach(subcomponent => {
        subcomponentHash[subcomponent.get('componentId')] = subcomponent;
      });
      const currentSubcomponents = Object.keys(subcomponentHash).map(componentId => {
        return subcomponentHash[componentId];
      });
      return currentSubcomponents;
    }).enqueue(),
    '_publishedSubcomponents': (0, _emberConcurrency.task)(function* () {
      let allSubcomponents = yield this.get('subcomponents');
      allSubcomponents = allSubcomponents.filterBy('publishStatus', true).sortBy('createdAt');
      const subcomponentHash = {};
      allSubcomponents.forEach(subcomponent => {
        subcomponentHash[subcomponent.get('componentId')] = subcomponent;
      });
      const currentSubcomponents = Object.keys(subcomponentHash).map(componentId => {
        return subcomponentHash[componentId];
      });
      return currentSubcomponents;
    }).enqueue(),
    '_observedEntities': (0, _emberConcurrency.task)(function* () {
      const constituents = yield this.get('constituents');
      const observedEntities = {};
      observedEntities['machine'] = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      observedEntities['line'] = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });

      for (let idx = 0; idx < constituents.length; idx++) {
        const observedEntity = constituents.objectAt(idx);
        let actualEntity = null;

        switch (observedEntity.get('tenantHistoricalDashboardConstituentType')) {
          case 'machine':
            actualEntity = yield this.get('store').findRecord('manufacturing/plant-unit-machine', observedEntity.get('tenantHistoricalDashboardConstituentId'));
            observedEntities['machine'].addObject(Ember.Object.create({
              'type': 'machine',
              'constituent': observedEntity,
              'actualEntity': actualEntity
            }));
            break;

          case 'line':
            actualEntity = yield this.get('store').findRecord('manufacturing/plant-unit-line', observedEntity.get('tenantHistoricalDashboardConstituentId'));
            observedEntities['line'].addObject(Ember.Object.create({
              'type': 'line',
              'constituent': observedEntity,
              'actualEntity': actualEntity
            }));
            break;
        }
      }

      return observedEntities;
    }).enqueue()
  });

  _exports.default = _default;
});