define("plantworks/initializers/manufacturing/add-manufacturing-plant-unit-line-to-emd-configuration", ["exports", "ember-data", "plantworks/models/emd/configuration", "plantworks/models/manufacturing/plant-unit-line", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _configuration, _plantUnitLine, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _configuration.default.reopen({
      'tenantPlantUnitSupervisorLines': _emberData.default.hasMany('manufacturing/plant-unit-line', {
        'async': true,
        'inverse': 'supervisorList'
      })
    });

    _plantUnitLine.default.reopen({
      'supervisorList': _emberData.default.belongsTo('emd/configuration', {
        'async': true,
        'inverse': 'tenantPlantUnitSupervisorLines'
      }),
      'supervisorListFilters': (0, _attributes.fragmentArray)('manufacturing/fragments/supervisor-list-filters')
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});