define("plantworks/templates/configure/manufacturing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHZss2uw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"page-title\",[[28,\"t\",[\"plant_works_webapp_server_server.configure_feature.manufacturing.title\"],null]],null],false],[0,\"\\n\\t\"],[5,\"manufacturing/configure/explorer\",[],[[\"@model\",\"@controller-action\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/configure/manufacturing.hbs"
    }
  });

  _exports.default = _default;
});