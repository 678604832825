define("plantworks/initializers/fragment-serializer", ["exports", "plantworks/serializers/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('serializer:-fragment', _fragment.default);
  }

  var _default = {
    'name': 'fragment-serializer',
    'initialize': initialize
  };
  _exports.default = _default;
});