define("plantworks/models/manufacturing/plant-unit-line-constituent", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenantPlantUnitLineConstituentId': _emberData.default.attr('string'),
    'constituentType': _emberData.default.attr('string'),
    'step': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitLine': _emberData.default.belongsTo('manufacturing/plant-unit-line', {
      'async': true,
      'inverse': 'constituents'
    }),
    'entities': _emberData.default.hasMany('manufacturing/plant-unit-line-block-entity', {
      'async': true,
      'inverse': 'block'
    })
  });

  _exports.default = _default;
});