define("plantworks/models/operator-form/operator-form-attribute-set", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'evaluationOrder': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantAttributeSet': _emberData.default.belongsTo('operator-form/attribute-set', {
      'async': true,
      'inverse': 'operatorForms'
    }),
    'tenantOperatorForm': _emberData.default.belongsTo('operator-form/operator-form', {
      'async': true,
      'inverse': 'attributeSets'
    }),
    'attributeSetName': Ember.computed('tenantAttributeSet.name', function () {
      return this.get('_attrSetName').perform();
    }),
    'preFuncs': Ember.computed('tenantAttributeSet.functions.@each.{name,type,executionOrder,observedTags}', function () {
      return this.get('_filterFuncs').perform('pre');
    }),
    'dataPoints': Ember.computed('tenantAttributeSet.properties.@each.{name,internalTag,units,source,datatype}', function () {
      return this.get('_filterProps').perform();
    }),
    'observerFuncs': Ember.computed('tenantAttributeSet.functions.@each.{name,type,executionOrder,observedTags}', function () {
      return this.get('_filterFuncs').perform('observer');
    }),
    'postFuncs': Ember.computed('tenantAttributeSet.functions.@each.{name,type,executionOrder,observedTags}', function () {
      return this.get('_filterFuncs').perform('post');
    }),
    '_attrSetName': (0, _emberConcurrency.task)(function* () {
      const attrSet = yield this.get('tenantAttributeSet');
      return attrSet.get('name');
    }).enqueue(),
    '_filterFuncs': (0, _emberConcurrency.task)(function* (funcType) {
      const attrSetFuncs = yield this.get('tenantAttributeSet.functions');
      return attrSetFuncs.filterBy('type', funcType).toArray();
    }).enqueue(),
    '_filterProps': (0, _emberConcurrency.task)(function* () {
      const attrSetProps = yield this.get('tenantAttributeSet.properties');
      return attrSetProps.sortBy('internalTag').toArray();
    }).enqueue()
  });

  _exports.default = _default;
});