define("plantworks/routes/settings", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('settings/node');
        return;
      }

      const settingsData = this.get('store').peekAll('settings/node');
      if (settingsData.get('length')) return settingsData;
      return this.get('store').findAll('settings/node');
    },

    onUserDataUpdated() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('settings/node');
      }

      const isActive = this.get('router').get('currentRouteName') && this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksUserId) {
        this.transitionTo('index');
        return;
      }

      this.get('_refreshTenantFeatureSettings').perform();
    },

    '_refreshTenantFeatureSettings': (0, _emberConcurrency.task)(function* () {
      let tenantFeatureData = yield this.get('store').findAll('settings/node');
      this.get('controller').set('model', tenantFeatureData);
    }).keepLatest()
  });

  _exports.default = _default;
});