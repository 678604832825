define("plantworks/templates/components/manufacturing/watch/plant-unit-machine-operator-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kcn7E7nx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"operatorListDataPoints\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"text-center text-uppercase\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"await\",[[24,[\"tenantPlantUnitMachine\",\"currentOperator\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"tenantPlantUnitMachine\",\"currentOperator\"]]],null],[24,[\"showInDropdownTag\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[5,\"plant-works-button\",[],[[\"@iconButton\",\"@raised\",\"@accent\",\"@disabled\",\"@title\",\"@onClick\",\"@bubbles\"],[true,false,true,[22,\"isLoading\"],\"Edit Operator\",[28,\"perform\",[[24,[\"changeOperator\"]]],null],false]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"plant-works-icon\",[],[[\"@icon\"],[\"edit\"]]],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/watch/plant-unit-machine-operator-display.hbs"
    }
  });

  _exports.default = _default;
});