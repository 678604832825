define("plantworks/components/manufacturing/watch/plant-unit-line-supervisor-display", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'supervisorList': null,
    'uniqueParameterId': null,
    'supervisorListDataPoints': null,
    'isLoading': false,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const tenantPlantUnitLine = yield this.get('model.tenantPlantUnitLine');
      const supervisorListConfiguration = yield tenantPlantUnitLine.get('supervisorList');

      if (supervisorListConfiguration) {
        const supervisorListDataPoints = yield supervisorListConfiguration.get('dataPoints');
        const supervisorListMetadata = yield supervisorListConfiguration.get('attributeSetMetadata');
        let uniqueParameterId = null;

        for (let idx = 0; idx < supervisorListMetadata.get('length'); idx++) {
          const attrSetMetadata = supervisorListMetadata.objectAt(idx);

          if (attrSetMetadata.get('isUniqueId')) {
            uniqueParameterId = attrSetMetadata.get('parameterId');
            break;
          }
        }

        this.set('uniqueParameterId', uniqueParameterId);
        this.set('supervisorListDataPoints', supervisorListDataPoints);
        let showInDropdownId = uniqueParameterId;

        for (let idx = 0; idx < supervisorListMetadata.get('length'); idx++) {
          const attrSetMetadata = supervisorListMetadata.objectAt(idx);

          if (attrSetMetadata.get('showInDropdown')) {
            showInDropdownId = attrSetMetadata.get('parameterId');
            break;
          }
        }

        this.set('showInDropdownId', showInDropdownId);
        let showInDropdownTag = 'masterdata_id';

        if (showInDropdownId !== uniqueParameterId) {
          // get the name and internal tag of the showInDropdownId
          const showInDropdownProp = yield this.get('store').findRecord('emd/attribute-set-property', showInDropdownId);
          showInDropdownTag = showInDropdownProp.internalTag;
        }

        this.set('showInDropdownTag', showInDropdownTag);
        const supervisorList = yield this.get('ajax').request(`/manufacturing/plant-unit-line-supervisors/${tenantPlantUnitLine.get('id')}`);
        this.set('supervisorList', supervisorList);
      }
    }).on('init').keepLatest(),
    'onModelChanged': Ember.observer('model.tenantPlantUnitLine', function () {
      this.get('updateSupervisors').perform();
    }),
    'updateSupervisors': (0, _emberConcurrency.task)(function* () {
      this.set('isLoading', true);

      try {
        const tenantPlantUnitLine = yield this.get('model.tenantPlantUnitLine');
        const supervisorListConfiguration = yield tenantPlantUnitLine.get('supervisorList');

        if (supervisorListConfiguration) {
          const supervisorListDataPoints = yield supervisorListConfiguration.get('dataPoints');
          const supervisorListMetadata = yield supervisorListConfiguration.get('attributeSetMetadata');
          let uniqueParameterId = null;

          for (let idx = 0; idx < supervisorListMetadata.get('length'); idx++) {
            const attrSetMetadata = supervisorListMetadata.objectAt(idx);

            if (attrSetMetadata.get('isUniqueId')) {
              uniqueParameterId = attrSetMetadata.get('parameterId');
              break;
            }
          }

          this.set('uniqueParameterId', uniqueParameterId);
          this.set('supervisorListDataPoints', supervisorListDataPoints);
          const supervisorList = yield this.get('ajax').request(`/manufacturing/plant-unit-line-supervisors/${tenantPlantUnitLine.get('id')}`);
          this.set('supervisorList', supervisorList);
        }

        this.set('isLoading', false);
      } catch (err) {
        this.set('isLoading', false);
      }
    }).drop(),
    'changeSupervisor': (0, _emberConcurrency.task)(function* () {
      // reset the current supervisor - required to handle supervisor change event for the same line
      const tenantPlantUnitLine = yield this.get('model.tenantPlantUnitLine');
      this.set('currentSupervisor', (yield tenantPlantUnitLine.get('currentSupervisor')));
      let previousEventValue = yield tenantPlantUnitLine.get('currentSupervisor');
      const modalState = {
        'supervisorListDataPoints': this.get('supervisorListDataPoints'),
        'uniqueParameterId': this.get('uniqueParameterId'),
        'supervisorList': this.get('supervisorList'),
        'selectedMasterDataId': previousEventValue ? previousEventValue[this.get('showInDropdownTag')] : null,
        'showInDropdownTag': this.get('showInDropdownTag'),
        'showInDropdownId': this.get('showInDropdownId')
      };
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_assign_supervisor_line'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex-25',
        'componentName': 'Manufacturing/Watch/StationObservedLineAddSupervisor',
        'componentState': {
          'model': modalState
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_updateSupervisor').perform(modalState);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_updateSupervisor': (0, _emberConcurrency.task)(function* (modalState) {
      if (!modalState || !modalState.selectedMasterDataId) return;
      let selectedSupervisor = this.get('supervisorList').filterBy(this.get('showInDropdownTag'), modalState.selectedMasterDataId);
      if (!selectedSupervisor || !selectedSupervisor.get('length')) return;
      selectedSupervisor = selectedSupervisor.objectAt(0);
      const newEventValue = JSON.stringify(selectedSupervisor);
      let previousEventValue = yield this.get('model.currentSupervisor');
      previousEventValue = JSON.stringify(previousEventValue);
      let supervisorChangeEventType = null;
      let retry = 3;

      while (retry > 0 && supervisorChangeEventType === null) {
        const allEventTypes = yield this.get('store').findAll('masterdata/event-type');
        supervisorChangeEventType = allEventTypes.filterBy('tag', 'supervisor_change');
        retry--;
      }

      if (!supervisorChangeEventType.get('length')) {
        this.get('notification').display({
          'type': 'error',
          'error': 'No event data found'
        });
        return;
      }

      supervisorChangeEventType = supervisorChangeEventType.objectAt(0);
      const model = this.get('model');
      const tenantPlantUnitLine = yield model.get('tenantPlantUnitLine');
      const tenantPlantUnitStation = yield model.get('tenantPlantUnitStation');
      const supervisorChangeEvent = this.get('store').createRecord('manufacturing/plant-unit-line-event', {
        'tenant': this.get('model.tenant'),
        'tenantPlantUnitStation': tenantPlantUnitStation,
        'tenantPlantUnitLine': tenantPlantUnitLine,
        'eventType': supervisorChangeEventType
      });
      supervisorChangeEvent.set('eventMetadata', {
        'value': newEventValue,
        'previousValue': previousEventValue
      });
      yield supervisorChangeEvent.save(); // reset the current supervisor to the selected supervisor - once event is recoreded properly

      this.set('currentSupervisor', selectedSupervisor);
    }).drop()
  });

  _exports.default = _default;
});