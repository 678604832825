define("plantworks/framework/base-model", ["exports", "ember-data", "ember-moment/computeds/moment", "ember-debug-logger", "ember-moment/computeds/format", "ember-moment/computeds/locale"], function (_exports, _emberData, _moment2, _emberDebugLogger, _format, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    'intl': Ember.inject.service('intl'),
    'moment': Ember.inject.service('moment'),
    'debug': (0, _emberDebugLogger.default)(),
    'createdAt': _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    'updatedAt': _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    'formattedCreatedAt': (0, _format.default)((0, _locale.default)((0, _moment2.default)('createdAt'), 'moment.locale'), 'DD/MMM/YYYY hh:mm A'),
    'formattedUpdatedAt': (0, _format.default)((0, _locale.default)((0, _moment2.default)('updatedAt'), 'moment.locale'), 'DD/MMM/YYYY hh:mm A')
  });

  _exports.default = _default;
});