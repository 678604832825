define("plantworks/models/server-administration/feature-permission", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'feature': _emberData.default.belongsTo('server-administration/feature', {
      'async': true,
      'inverse': 'permissions'
    }),
    'i18n_name': Ember.on('init', Ember.observer('i18n_tag', function () {
      this.get('_computeI18nName').perform();
    })),
    'i18n_desc': Ember.on('init', Ember.observer('i18n_tag', function () {
      this.get('_computeI18nDesc').perform();
    })),
    'i18n_tag': Ember.computed('name', 'feature', 'intl.locale', function () {
      return this.get('_computeI18nTag').perform();
    }),
    '_computeI18nName': (0, _emberConcurrency.task)(function* () {
      const tag = yield this.get('i18n_tag');
      const i18nName = this.intl.t(`${tag}.name`);
      this.set('displayName', i18nName);
    }).keepLatest(),
    '_computeI18nDesc': (0, _emberConcurrency.task)(function* () {
      const tag = yield this.get('i18n_tag');
      const i18nDesc = this.intl.t(`${tag}.description`);
      this.set('displayDesc', i18nDesc);
    }).keepLatest(),
    '_computeI18nTag': (0, _emberConcurrency.task)(function* () {
      const parentChain = [];
      parentChain.push('permission');
      parentChain.push(`${Ember.String.underscore(this.get('name') || '').toLowerCase()}`);
      const parentModule = yield this.get('feature');

      if (parentModule) {
        const parentI18nTag = yield parentModule.get('i18n_tag');
        parentChain.unshift(parentI18nTag);
      }

      return parentChain.join('.');
    }).keepLatest()
  });

  _exports.default = _default;
});