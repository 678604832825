define("plantworks/components/dashboard/main-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'dashboardCategories': null,
    'selectedDashboardCategory': null,
    'selectedFeature': null,
    'router': Ember.inject.service('router'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_setupDashboardCategories').perform();
    }).on('willInsertElement').keepLatest(),
    'onModelChanged': Ember.observer('model', 'model.@each.dashboardCategory', function () {
      this.get('_setupDashboardCategories').perform();
    }),

    onUserDataUpdated() {
      this.get('_setupDashboardCategories').perform();
    },

    '_setupDashboardCategories': (0, _emberConcurrency.task)(function* () {
      let featureData = yield this.get('store').findAll('dashboard/feature');
      this.set('model', featureData);

      if (this.get('dashboardCategories')) {
        this.get('dashboardCategories').clear();
      } else {
        this.set('dashboardCategories', Ember.ArrayProxy.create({
          'content': Ember.A([])
        }));
      }

      if (!this.get('model')) {
        return;
      }

      const model = yield this.get('model');
      const dashCats = new Set();
      model.forEach(dashboardFeature => {
        dashCats.add(dashboardFeature.get('dashboardCategory'));
      });
      const dashCategories = [...dashCats];
      let filteredDashCategories = dashCategories;

      if ((this.get('currentUser').userData.designation === 'Visitor' || !this.get('currentUser').userData.designation) && dashCategories.includes('administration')) {
        filteredDashCategories = dashCategories.filter(dashCategory => dashCategory !== 'administration');
      }

      this.set('dashboardCategories', Ember.ArrayProxy.create({
        'content': Ember.A(filteredDashCategories.map(dashCat => {
          return Ember.ObjectProxy.create({
            'content': Ember.Object.create({
              'name': dashCat,
              'show': false
            })
          });
        }))
      }));
      console.log('Done');
    }).keepLatest(),
    'showCategoryComponents': (0, _emberConcurrency.task)(function* (selectedCategory) {
      const model = yield this.get('model');
      yield this.set('selectedDashboardCategory', selectedCategory.get('name'));
      model.forEach(dashboardFeature => {
        if (dashboardFeature.get('dashboardCategory') === selectedCategory.get('name')) {
          dashboardFeature.set('active', true);
        } else {
          dashboardFeature.set('active', false);
        }
      });
      this.get('currentUser').trigger('dashboardCategoryUpdated');
      this.get('router').transitionTo('dashboard');
    }).enqueue()
  });

  _exports.default = _default;
});