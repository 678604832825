define("plantworks/models/masterdata/event-type", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tag': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string')
  });

  _exports.default = _default;
});