define("plantworks/components/event-alert/configure/event-alert-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'event-alert-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('event-alert-create');
      const updatePerm = this.get('currentUser').hasPermission('event-alert-update');
      const deletePerm = this.get('currentUser').hasPermission('event-alert-delete');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
      yield;
    }).keepLatest(),
    'addEventAlert': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newEventAlert = this.get('store').createRecord('event-alert/event-alert', {
        'tenant': tenant,
        'folder': this.get('model')
      });
      yield newEventAlert.get('escalationLevels').createFragment({
        'enabled': true
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.event_alert_feature.new_event_alert_name', {
        'now': window.moment().valueOf()
      });
      newEventAlert.set('name', displayName);
      newEventAlert.set('description', displayName);
      const siblingEventAlerts = yield this.get('model.eventAlerts');
      siblingEventAlerts.addObject(newEventAlert);
      this.get('editEventAlert').perform(newEventAlert);
    }).enqueue(),
    'editEventAlert': (0, _emberConcurrency.task)(function* (eventAlert) {
      yield eventAlert.set('isEditing', true);
    }).enqueue(),
    'cancelEventAlert': (0, _emberConcurrency.task)(function* (eventAlert) {
      eventAlert.set('isEditing', false);
      yield eventAlert.rollback();
    }).enqueue(),
    'saveEventAlert': (0, _emberConcurrency.task)(function* (eventAlert) {
      eventAlert.set('isEditing', false);
      yield eventAlert.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveEventAlertSucceeded': Ember.on('saveEventAlert:succeeded', function (taskInstance) {
      const eventAlert = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_event_alert_save', {
          'htmlSafe': true,
          'displayName': eventAlert.get('name')
        })
      });
    }),
    'saveEventAlertErrored': Ember.on('saveEventAlert:errored', function (taskInstance, err) {
      const eventAlert = taskInstance.args[0];
      eventAlert.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteEventAlert': (0, _emberConcurrency.task)(function* (eventAlert) {
      const eventAlertName = yield eventAlert.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.event_alert_feature.label_delete_event_alert'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.event_alert_feature.delete_event_alert_message', {
          'htmlSafe': true,
          'displayName': eventAlertName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteEventAlert').perform(eventAlert);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteEventAlert': (0, _emberConcurrency.task)(function* (eventAlert) {
      yield eventAlert.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteEventAlertSucceeded': Ember.on('_confirmedDeleteEventAlert:succeeded', function (taskInstance) {
      const eventAlert = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_event_alert_delete', {
          'htmlSafe': true,
          'displayName': eventAlert.get('name')
        })
      });
    }),
    '_confirmedDeleteEventAlertErrored': Ember.on('_confirmedDeleteEventAlert:errored', function (taskInstance, err) {
      const eventAlert = taskInstance.args[0];
      eventAlert.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedEventAlert': function (selectedEventAlert) {
      if (selectedEventAlert.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedEventAlert.get('id'), false, false);
    }
  });

  _exports.default = _default;
});