define("plantworks/models/report/report-constituent", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenantReportConstituentId': _emberData.default.attr('string'),
    'tenantReportConstituentType': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantReport': _emberData.default.belongsTo('report/report', {
      'async': true,
      'inverse': 'constituents'
    })
  });

  _exports.default = _default;
});