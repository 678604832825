define("plantworks/templates/components/manufacturing/configure/plant-unit-machine-editor/property-metadata-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HLNVBZE8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isTimestamp\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_timestamp\"],null],[24,[\"metadata\",\"isTimestamp\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isTimestamp\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_cannot_be_timestamp\"],null],true,false,null]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_speed_deviation_parameter\"],null],[28,\"not\",[[24,[\"isBoolean\"]]],null],[24,[\"metadata\",\"isSpeedDeviationFlag\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isSpeedDeviationFlag\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_perform_calcstat_parameter\"],null],[28,\"not\",[[24,[\"isNumber\"]]],null],[24,[\"metadata\",\"performCalcstat\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"performCalcstat\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_global\"],null],[24,[\"metadata\",\"isGlobal\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"metadata\",\"isGlobal\"]]],null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/configure/plant-unit-machine-editor/property-metadata-editor.hbs"
    }
  });

  _exports.default = _default;
});