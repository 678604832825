define("plantworks/controllers/settings", ["exports", "plantworks/framework/base-controller"], function (_exports, _baseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'selectedNode': null,
    'lockedOpen': true,
    'sidenavOpen': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-access');
    },

    setSelectedNode(nodeModel) {
      if (!nodeModel) {
        this.set('selectedNode', null);
        return;
      }

      if (nodeModel.get('id') === this.get('selectedNode.id')) return;
      this.set('selectedNode', nodeModel);
    },

    'onSelectedNodeChanged': Ember.observer('selectedNode', function () {
      if (!this.get('selectedNode')) return;
      this.get('router').transitionTo(this.get('selectedNode.settingsRoute'));
    })
  });

  _exports.default = _default;
});