define("plantworks/components/common/plant-works-custom-subcomponent/main", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HTMLBars
  } = window.Ember;

  var _default = _baseComponent.default.extend({
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        if (!this.get('selectedSubcomponent')) return;
        const selectedSubcomponentTemplate = yield this.get('selectedSubcomponent.template');
        if (selectedSubcomponentTemplate === '' || selectedSubcomponentTemplate.trim() === '') return;
        this.set('subcomponentLayout', HTMLBars.compile(selectedSubcomponentTemplate.trim()));
      } catch (err) {
        this.debug(`PlantWorksCustomSubcomponent:onInit: `, err);
      }
    }).on('init').keepLatest()
  });

  _exports.default = _default;
});