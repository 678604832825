define("plantworks/models/dashboard/feature", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'moduleType': _emberData.default.attr('string'),
    'dashboardCategory': _emberData.default.attr('string'),
    'route': _emberData.default.attr('string'),
    'routeParams': _emberData.default.attr('string', {
      'defaultValue': null
    }),
    'iconType': _emberData.default.attr('string'),
    'iconPath': _emberData.default.attr('string'),
    'active': false,
    'i18n_name': Ember.computed('i18n_tag', function () {
      return this.intl.exists(`${this.get('i18n_tag')}.title`) ? this.intl.t(`${this.get('i18n_tag')}.title`) : this.get('name');
    }),
    'i18n_desc': Ember.computed('i18n_tag', function () {
      return this.intl.exists(`${this.get('i18n_tag')}.description`) ? this.intl.t(`${this.get('i18n_tag')}.description`) : this.get('name');
    }),
    'i18n_tag': Ember.computed('name', 'moduleType', 'intl.locale', function () {
      return `plant_works_webapp_server_server.${this.get('name').replace(/ /g, '_').toLowerCase()}_${this.get('moduleType').replace(/ /g, '_').toLowerCase()}`;
    })
  });

  _exports.default = _default;
});