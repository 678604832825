define("plantworks/components/locale-selector", ["exports", "plantworks/framework/base-component", "plantworks/config/environment", "ember-local-storage"], function (_exports, _baseComponent, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'selectedLocale': null,
    'allowedLocales': null,
    'settings': (0, _emberLocalStorage.storageFor)('settings'),
    'languageMap': _environment.default.plantworks.languageMap,

    init() {
      this._super(...arguments);

      const selectedLocaleCode = this.get('intl').locale[0];
      const selectedLocale = {
        'name': this.get('languageMap')[selectedLocaleCode] || selectedLocaleCode,
        'code': selectedLocaleCode
      };
      this.set('selectedLocale', selectedLocale); // returns active locale in array format

      const locales = [];
      this.get('intl').locales.forEach(locale => {
        locales.push({
          'name': this.get('languageMap')[locale],
          'code': locale
        });
      });
      this.set('allowedLocales', locales); // returns available locales
    },

    changeSelectedLocale(newLocale) {
      this.set('selectedLocale', newLocale);
    },

    'onSelectedLocaleChange': Ember.observer('selectedLocale', function () {
      const settings = this.get('settings');
      settings.set('preferredLocale', this.get('selectedLocale').code);
      this.get('intl').setLocale(this.get('selectedLocale').code);
    })
  });

  _exports.default = _default;
});