define("plantworks/router", ["exports", "plantworks/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('dashboard');
    this.route('outgoing');
    this.route('profile');
    this.route('board', function () {
      this.route('watch', {
        'path': '/watch/:id'
      });
    });
    this.route('configure', function () {
      this.route('board', {
        'path': '/board/:type/:id'
      });
      this.route('emd', {
        'path': '/emd/:type/:id'
      });
      this.route('historical-dashboard', {
        'path': '/historical-dashboard/:type/:id'
      });
      this.route('manufacturing', {
        'path': '/manufacturing/:type/:id'
      });
      this.route('operator-form', {
        'path': '/operator-form/:type/:id'
      });
      this.route('report', {
        'path': '/report/:type/:id'
      });
      this.route('work-order', {
        'path': '/work-order/:type/:id'
      });
      this.route('alert', {
        'path': '/alert/:type/:id'
      });
      this.route('event-alert', {
        'path': '/event-alert/:type/:id'
      });
    });
    this.route('devenv', function () {
      this.route('board', {
        'path': '/board/:type/:id'
      });
      this.route('emd', {
        'path': '/emd/:type/:id'
      });
      this.route('historical-dashboard', {
        'path': '/historical-dashboard/:type/:id'
      });
      this.route('manufacturing', {
        'path': '/manufacturing/:type/:id'
      });
      this.route('operator-form', {
        'path': '/operator-form/:type/:id'
      });
      this.route('report', {
        'path': '/report/:type/:id'
      });
      this.route('work-order', {
        'path': '/work-order/:type/:id'
      });
      this.route('alert', {
        'path': '/alert/:type/:id'
      });
      this.route('event-alert', {
        'path': '/event-alert/:type/:id'
      });
    });
    this.route('emd');
    this.route('historical-dashboard', function () {
      this.route('execute', {
        'path': '/execute/:id'
      });
    });
    this.route('manufacturing', function () {
      this.route('watch', {
        'path': '/station/:id'
      });
    });
    this.route('pug', function () {
      this.route('group-manager');
      this.route('user-manager');
    });
    this.route('report', function () {
      this.route('execute', {
        'path': '/execute/:id'
      });
    });
    this.route('schedule', function () {
      this.route('manufacturing', {
        'path': '/manufacturing/:type/:id'
      });
    });
    this.route('settings', function () {
      this.route('account', function () {
        this.route('basics');
        this.route('features');
        this.route('work-orders');
        this.route('webhooks');
      });
    });
    this.route('work-order-app');
  });
  var _default = Router;
  _exports.default = _default;
});