define("plantworks/services/password-strength", ["exports", "ember-cli-password-strength/services/password-strength"], function (_exports, _passwordStrength) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _passwordStrength.default;
    }
  });
});