define("plantworks/initializers/pug/group-manager/add-groups-to-feature-permission", ["exports", "ember-data", "plantworks/models/server-administration/feature-permission"], function (_exports, _emberData, _featurePermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _featurePermission.default.reopen({
      'tenantGroupPermissions': _emberData.default.hasMany('pug/group-manager/tenant-group-permission', {
        'async': true,
        'inverse': 'featurePermission'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});