define("plantworks/components/manufacturing/configure/plant-unit-station-editor/list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'addPlantUnitStation': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitStation = this.get('store').createRecord('manufacturing/plant-unit-station', {
        'tenant': tenant,
        'tenantPlantUnit': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_unit_station_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnitStation.set('name', displayName);
      newPlantUnitStation.set('description', displayName);
      const siblingUnitStations = yield this.get('model.stations');
      siblingUnitStations.addObject(newPlantUnitStation);
      this.get('editPlantUnitStation').perform(newPlantUnitStation);
    }).enqueue(),
    'editPlantUnitStation': (0, _emberConcurrency.task)(function* (unitStation) {
      yield unitStation.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitStation': (0, _emberConcurrency.task)(function* (unitStation) {
      unitStation.set('isEditing', false);
      yield unitStation.rollback();
    }).enqueue(),
    'savePlantUnitStation': (0, _emberConcurrency.task)(function* (unitStation) {
      unitStation.set('isEditing', false);
      yield unitStation.save();
      yield unitStation.reload();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitStationSucceeded': Ember.on('savePlantUnitStation:succeeded', function (taskInstance) {
      const unitStation = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_station_save', {
          'htmlSafe': true,
          'displayName': unitStation.get('name')
        })
      });
    }),
    'savePlantUnitStationErrored': Ember.on('savePlantUnitStation:errored', function (taskInstance, err) {
      const unitStation = taskInstance.args[0];
      unitStation.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitStation': (0, _emberConcurrency.task)(function* (unitStation) {
      const unitStationName = unitStation.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_unit_station'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_unit_station_message', {
          'htmlSafe': true,
          'displayName': unitStationName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitStation').perform(unitStation, unitStationName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitStation': (0, _emberConcurrency.task)(function* (unitStation, unitStationName) {
      // eslint-disable-line no-unused-vars
      yield unitStation.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitStationSucceeded': Ember.on('_confirmedDeletePlantUnitStation:succeeded', function (taskInstance) {
      const unitStationName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_station_delete', {
          'htmlSafe': true,
          'displayName': unitStationName
        })
      });
    }),
    '_confirmedDeletePlantUnitStationErrored': Ember.on('_confirmedDeletePlantUnitStation:errored', function (taskInstance, err) {
      const unitStation = taskInstance.args[0];
      unitStation.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});