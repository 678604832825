define("plantworks/components/manufacturing/configure/plant-unit-line-editor/attached-form-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'addAttachedForm': (0, _emberConcurrency.task)(function* () {
      const plantUnitLine = this.get('model');
      const plantUnitLineAttachedForms = yield plantUnitLine.get('attachedForms');
      const alreadyAddedForms = [];

      for (let idx = 0; idx < plantUnitLineAttachedForms.length; idx++) {
        const plantUnitLineAttachedForm = plantUnitLineAttachedForms.objectAt(idx);
        const operatorForm = yield plantUnitLineAttachedForm.get('tenantOperatorForm');
        alreadyAddedForms.push(operatorForm.get('id'));
      }

      const allPossibleForms = yield this.get('store').findAll('operator-form/operator-form');
      const allOperatorForms = [];

      for (let idx = 0; idx < allPossibleForms.length; idx++) {
        const form = allPossibleForms.objectAt(idx);
        if (form.get('isNew')) continue;
        allOperatorForms.push(form);
      }

      const availableForms = allOperatorForms.filter(form => {
        return !alreadyAddedForms.includes(form.get('id'));
      });
      const self = this;
      const chooseModalModel = availableForms.map(availableForm => {
        return {
          'selected': false,
          'form': availableForm
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_line_add_form'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/AttachForm',
        'componentState': {
          'model': chooseModalModel
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('addForms').perform(chooseModalModel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deleteAttachedForm': (0, _emberConcurrency.task)(function* (attachedForm) {
      yield attachedForm.destroyRecord();
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addForms': (0, _emberConcurrency.task)(function* (chooseModalModel) {
      const plantUnitLine = this.get('model');
      const tenant = yield plantUnitLine.get('tenant');
      const chosenForms = chooseModalModel.filter(chosenForm => {
        return chosenForm.selected;
      });

      for (let idx = 0; idx < chosenForms.length; idx++) {
        const chosenForm = chosenForms[idx];
        let attachedForm = chosenForm.attachedForm;

        if (attachedForm) {
          if (attachedForm.get('isDirty')) {
            yield attachedForm.save();
          }

          continue;
        }

        chosenForm.attachedForm = this.get('store').createRecord('manufacturing/plant-unit-line-attached-form', {
          'tenant': tenant,
          'tenantPlantUnitLine': plantUnitLine,
          'tenantOperatorForm': chosenForm.form
        });
        attachedForm = chosenForm.attachedForm;
        plantUnitLine.get('attachedForms').addObject(attachedForm);
        chosenForm.form.get('linkedMachines').addObject(attachedForm);
        yield attachedForm.save();
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});