define("plantworks/components/settings/account/features/tree-component", ["exports", "plantworks/framework/base-component", "plantworks/config/environment", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _environment, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-features-read');
    },

    didInsertElement() {
      const self = this;

      this._super(...arguments);

      let getTreeUrl;
      if (!(0, _jquery.default)('div#settings-account-feature-manager-tree-container').length) return;

      if (_environment.default.IS_CORBER) {
        getTreeUrl = `https://${_environment.default.plantworks.domain}/server-administration/features/tree`;
      } else {
        getTreeUrl = '/server-administration/features/tree';
      }

      const moduTree = this.$('div#settings-account-feature-manager-tree-container').jstree({
        'core': {
          'check_callback': function (operation) {
            if (operation === 'move_node') return false;
            if (operation === 'rename_node') return false;
            if (operation === 'delete_node') return false;
            return true;
          },
          'multiple': false,
          'themes': {
            'name': 'default',
            'icons': false,
            'dots': false,
            'responsive': true
          },
          'data': {
            'url': getTreeUrl,
            'dataType': 'json',
            'data': function (node) {
              return {
                'id': node.id
              };
            },
            'success': function (nodeData) {
              const treedData = {};
              nodeData.forEach(singleNodeData => {
                treedData[singleNodeData.id] = {
                  'parent': singleNodeData.parent,
                  'text': singleNodeData.text,
                  'i18nText': ''
                };
              });
              Object.keys(treedData).forEach(treedNodeId => {
                let currentNode = treedData[treedNodeId];
                let i18n_tag = [`${Ember.String.underscore(currentNode.text).toLowerCase()}_${currentNode.parent === '#' ? 'server' : 'feature'}`];

                while (currentNode.parent !== '#') {
                  currentNode = treedData[currentNode.parent];
                  i18n_tag.unshift(`${Ember.String.underscore(currentNode.text).toLowerCase()}_${currentNode.parent === '#' ? 'server' : 'feature'}`);
                }

                i18n_tag.push('title');
                treedData[treedNodeId]['i18nText'] = self.intl.exists(i18n_tag.join('.')) ? self.intl.t(i18n_tag.join('.')) : treedData[treedNodeId]['text'];
              });
              nodeData.forEach(singleNodeData => {
                singleNodeData['text'] = treedData[singleNodeData.id]['i18nText'];
              });
            }
          }
        },
        'plugins': ['sort', 'unique']
      });
      moduTree.on('ready.jstree', () => {
        const rootNodeId = this.$('div#settings-account-feature-manager-tree-container > ul > li:first-child').attr('id');
        this.$('div#settings-account-feature-manager-tree-container').jstree('open_node', rootNodeId);
        this.$('div#settings-account-feature-manager-tree-container').jstree('activate_node', rootNodeId, false, false);
      });
      moduTree.on('activate_node.jstree', (event, data) => {
        this.get('onActivateNode').perform(data.node);
      });
    },

    willDestroyElement() {
      this.invokeAction('controller-action', 'setSelectedFeature', null);
      this.$('div#settings-account-feature-manager-tree-container').jstree('destroy', true);

      this._super(...arguments);
    },

    'onActivateNode': (0, _emberConcurrency.task)(function* (treeNode) {
      try {
        let serverFeature = yield this.get('selectedFeature');
        if (serverFeature && serverFeature.get('id') === treeNode.id) return;
        const store = this.get('store');
        serverFeature = store.peekRecord('server-administration/feature', treeNode.id);

        if (!serverFeature) {
          serverFeature = yield store.findRecord('server-administration/feature', treeNode.id);
        }

        this.$('div#settings-account-feature-manager-tree-container').jstree('open_node', treeNode.id);
        this.invokeAction('controller-action', 'setSelectedFeature', serverFeature);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).keepLatest(),
    'onSelectedFeatureChanged': Ember.observer('selectedFeature', function () {
      if (!this.get('selectedFeature')) return;
      if (this.$('div#settings-account-feature-manager-tree-container').jstree('get_selected')[0] === this.get('selectedFeature.id')) return;
      this.$('div#settings-account-feature-manager-tree-container').jstree('activate_node', this.get('selectedFeature.id'), false, false);
      this.$('div#settings-account-feature-manager-tree-container').jstree('open_node', this.get('selectedFeature.id'));
    })
  });

  _exports.default = _default;
});