define("plantworks/components/common/selection-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentTab': null,
    'startDate': null,
    'endDate': null,
    'maxDate': null,
    'minDate': null,
    'currentDateType': null,
    'prevStateOptions': null,
    'prevStateFilters': null,
    'disableSelectionCancel': true,
    'disableSelectionApply': true,
    'disableFilterCancel': true,
    'disableFilterApply': true,
    'disableFilterClear': true,
    'disableTabs': true,
    'loading': true,

    init() {
      this._super(...arguments);
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      this.set('currentTab', 'selection');
      const user = this.get('currentUser').userData;
      let d = new Date();
      d.setSeconds(0);
      d.setMinutes(0);
      d.setHours(0);
      this.set('maxDate', d);
      this.set('currentDateType', 'today');
      const schedules = yield this.get('ajax').request(`/manufacturing/user-schedules/${user.user_id}`, {
        'method': 'GET'
      });
      this.set('schedules', schedules);
      const currentDate = window.moment(d).startOf('day');
      this.set('currentDate', currentDate);
      this.set('shiftOptions', []);
      this.set('shift', null);
      this.set('startDate', currentDate.clone().format('YYYY-MM-DD'));
      this.set('endDate', currentDate.clone().format('YYYY-MM-DD'));
      this.set('trendAggregationOptions', ['One Hour', 'Shift', 'Day']);
      const options = {};
      options.trendAggregation = 'One Hour';
      options.entityAggregation = 'line';
      this.set('prevStateAggregation', JSON.parse(JSON.stringify(options)));
      this.set('trendAggregation', 'One Hour');
      this.set('entityAggregation', 'line');
      yield this.get('saveAggregation').perform(); // plant

      this.set('plants', []);
      this.set('chosenPlant', null); // unit

      this.set('units', []);
      this.set('chosenUnit', null); // line

      this.set('lines', []);
      this.set('chosenLine', null); // process

      this.set('processes', []);
      this.set('chosenProcess', null); // line

      this.set('lines', []);
      this.set('chosenLine', null); // machine

      this.set('machines', []);
      this.set('chosenMachine', null); // workorder

      this.set('workOrders', []);
      this.set('chosenWorkOrder', null); // sku

      this.set('skuList', []);
      this.set('chosenSKU', null); // supervisors

      this.set('supervisors', []);
      this.set('chosenSupervisor', null); // operators

      this.set('operators', []);
      this.set('chosenOperator', null);
      this.set('noOfPlants', 0);
      this.set('noOfUnits', 0);
      this.set('noOfLines', 0);
      yield this.get('saveOptions').perform();
    }).on('init').keepLatest(),
    'changeTab': (0, _emberConcurrency.task)(function* (tab) {
      yield;
      this.set('currentTab', tab);
    }).keepLatest(),
    'changeType': (0, _emberConcurrency.task)(function* (type) {
      yield;
      this.set('disableSelectionCancel', false);
      this.set('disableSelectionApply', false);
      this.set('currentDateType', type);

      if (type === 'today') {
        this.set('startDate', this.get('currentDate').clone().format('YYYY-MM-DD'));
        this.set('endDate', this.get('currentDate').clone().format('YYYY-MM-DD'));
      } else if (type === 'yesterday') {
        this.set('startDate', this.get('currentDate').clone().subtract(1, 'day').format('YYYY-MM-DD'));
        this.set('endDate', this.get('currentDate').clone().subtract(1, 'day').format('YYYY-MM-DD'));
      }
    }).keepLatest(),
    'changeAggregation': (0, _emberConcurrency.task)(function* (type) {
      yield;
      this.set('entityAggregation', type);

      if (this.get('prevStateAggregation').entityAggregation != this.get('entityAggregation') || this.get('prevStateAggregation').trendAggregation != this.get('trendAggregation')) {
        this.set('disableAggregationCancel', false);
        this.set('disableAggregationApply', false);
      } else {
        this.set('disableAggregationCancel', true);
        this.set('disableAggregationApply', true);
      }
    }).keepLatest(),
    'saveAggregation': (0, _emberConcurrency.task)(function* () {
      const options = {};
      options.trendAggregation = this.get('trendAggregation');
      options.entityAggregation = this.get('entityAggregation');
      this.set('prevStateAggregation', JSON.parse(JSON.stringify(options)));
      yield this.invokeAction('controller-action', 'changeAggregations', JSON.parse(JSON.stringify(options)));
      this.set('disableAggregationCancel', true);
      this.set('disableAggregationApply', true);
    }).keepLatest(),
    'cancelAggregation': (0, _emberConcurrency.task)(function* () {
      yield;
      this.set('trendAggregation', this.get('prevStateAggregation.trendAggregation'));
      this.set('entityAggregation', this.get('prevStateAggregation.entityAggregation'));
      this.set('disableAggregationCancel', true);
      this.set('disableAggregationApply', true);
    }).keepLatest(),
    'saveOptions': (0, _emberConcurrency.task)(function* () {
      this.set('disableTabs', true);
      this.set('loading', true);
      const options = {};
      options.startDate = this.get('startDate');
      options.endDate = this.get('endDate');
      options.shift = this.get('shift');
      options.userId = this.get('currentUser.userData').user_id;
      let filtersData = yield this.get('ajax').post('/manufacturing/selection-filters-details', {
        'dataType': 'json',
        'data': {
          'data': JSON.stringify(options),
          'userId': options.userId
        }
      });
      options.shift = JSON.stringify(this.get('shift'));
      options.unitIntervals = JSON.stringify(filtersData.unitIntervals);
      this.set('filtersData', filtersData);

      if (this.get('filtersData').filterCombinations.length) {
        yield this.get('clearSelection').perform();
        this.set('noOfPlants', this.get('plants').length);
        this.set('noOfUnits', this.get('units').length);
        this.set('noOfLines', this.get('lines').length);
        yield this.get('saveFilters').perform();
        yield this.invokeAction('controller-action', `submit${this.get('entityType')}Data`, JSON.parse(JSON.stringify(options)));
        this.set('disableTabs', false);
      } else {
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('general.no_data_on_this_period_message')
        });
      }

      options.shift = this.get('shift');
      options.currentDateType = this.get('currentDateType');
      options.shiftOptions = this.get('shiftOptions');
      this.set('prevStateOptions', JSON.parse(JSON.stringify(options)));
      this.set('loading', false);
      this.set('disableSelectionCancel', true);
      this.set('disableSelectionApply', true);
    }).keepLatest(),
    'cancelOptions': (0, _emberConcurrency.task)(function* () {
      yield;
      this.set('startDate', this.get('prevStateOptions.startDate'));
      this.set('endDate', this.get('prevStateOptions.endDate'));
      this.set('currentDateType', this.get('prevStateOptions.currentDateType'));
      this.set('shift', this.get('prevStateOptions.shift'));
      this.set('shiftOptions', this.get('prevStateOptions.shiftOptions'));
      this.set('disableSelectionCancel', true);
      this.set('disableSelectionApply', true);
    }).keepLatest(),
    'saveFilters': (0, _emberConcurrency.task)(function* () {
      const options = {};
      const filtersData = this.get('filtersData');
      const filtersList = filtersData.filtersList;

      for (let m = 0; m < filtersList.length; m++) {
        const filter = filtersList[m];
        const keys = this.getPathKeys(filter);
        options[keys.list] = this.get(keys.list);
        options[keys.selected] = this.get(keys.selected);
      }

      if (this.get('clearPerformed')) this.set('path', []);
      options.path = this.get('path');
      options.disableFilterClear = this.get('disableFilterClear');
      this.set('clearPerformed', false);
      yield this.get('setCurrentFilters').perform();
      this.set('prevStateFilters', JSON.parse(JSON.stringify(options)));
      this.set('disableFilterCancel', true);
      this.set('disableFilterApply', true);
    }).keepLatest(),
    'setCurrentFilters': (0, _emberConcurrency.task)(function* () {
      const filtersData = this.get('filtersData');
      const filtersList = filtersData.filtersList;
      const options = {};

      for (let m = 0; m < filtersList.length; m++) {
        const filter = filtersList[m];
        const keys = this.getPathKeys(filter);
        let chosenArr;
        const allChosen = this.get(keys.selected).map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (!allChosen) chosenArr = this.get(keys.selected).map(e => {
          return e.name;
        });else chosenArr = this.get(keys.list).map(e => {
          return e.name;
        }).filter(l => l !== 'All');
        options[keys.list] = chosenArr;
      }

      yield this.invokeAction('controller-action', 'changeFilters', JSON.parse(JSON.stringify(options)));
    }).keepLatest(),
    'cancelFilters': (0, _emberConcurrency.task)(function* () {
      yield;
      const filtersData = this.get('filtersData');
      const filtersList = filtersData.filtersList;

      for (let m = 0; m < filtersList.length; m++) {
        const filter = filtersList[m];
        const keys = this.getPathKeys(filter);
        this.set(keys.list, this.get('prevStateFilters')[keys.list]);
        this.set(keys.selected, this.get('prevStateFilters')[keys.selected]);
      }

      this.set('path', this.get('prevStateFilters').path);
      this.set('disableFilterCancel', true);
      this.set('disableFilterApply', true);
      this.set('disableFilterClear', this.get('prevStateFilters.disableFilterClear'));
    }).keepLatest(),
    'clearSelection': (0, _emberConcurrency.task)(function* () {
      this.set('clearPerformed', true);
      const filtersData = this.get('filtersData');
      const filterCombinations = filtersData.filterCombinations;
      const filtersList = filtersData.filtersList;

      for (let m = 0; m < filtersList.length; m++) {
        const filter = filtersList[m];
        const keys = this.getPathKeys(filter);
        let list = this.getUniqueArr(filterCombinations, filter);
        list = this.sortAndAdd(list);
        if (list.length) this.set(keys.selected, [list[0]]);
        this.set(keys.list, list);
        yield this.get('disableOptions').perform(this.get(keys.selected), keys.list, true);
      }

      this.set('disableFilterCancel', false);
      this.set('disableFilterApply', false);
      this.set('disableFilterClear', true);
    }).keepLatest(),
    'setSelection': (0, _emberConcurrency.task)(function* (changedProp) {
      const filtersData = this.get('filtersData');
      const filterCombinations = filtersData.filterCombinations;
      const filtersList = filtersData.filtersList;
      const filterKeys = this.getPathKeys(changedProp);
      let aggArray = [];
      const allSelected = this.get(filterKeys.selected).map(e => {
        return e.name;
      }).indexOf('All') >= 0;
      if (!allSelected) aggArray = this.get(filterKeys.selected).map(e => {
        return e.name;
      });else aggArray = this.get(filterKeys.list).map(e => {
        return e.name;
      });
      const options = this.getFilteredArr(aggArray, filterCombinations, changedProp);

      for (let m = 0; m < filtersList.length; m++) {
        const filter = filtersList[m];

        if (changedProp !== filter) {
          const keys = this.getPathKeys(filter);
          let list = options;
          const path = this.get('path');

          if (path.indexOf(filter) < 0 && path.length) {
            for (let m = 0; m < path.length; m++) {
              if (path[m] !== changedProp) {
                const pathKeys = this.getPathKeys(path[m]);
                let compArr;
                const allChosen = this.get(pathKeys.selected).map(e => {
                  return e.name;
                }).indexOf('All') >= 0;
                if (!allChosen) compArr = this.get(pathKeys.selected).map(e => {
                  return e.name;
                });else compArr = this.get(pathKeys.list).map(e => {
                  return e.name;
                });
                list = this.getFilteredArr(compArr, list, pathKeys.filterKey);
              }
            }
          }

          list = this.getUniqueArr(list, filter);
          list = this.sortAndAdd(list);
          this.set(keys.list, list);
          const checkSubArr = this.checkSubArray(this.get(keys.selected), list);
          if (!checkSubArr) this.set(keys.selected, [this.get(keys.list)[0]]);
          const allSelected = this.get(keys.selected) && this.get(keys.selected).map(e => {
            return e.name;
          }).indexOf('All') >= 0;
          if (allSelected) this.set(keys.selected, [{
            'name': 'All'
          }]);
          yield this.get('disableOptions').perform(this.get(keys.selected), keys.list, allSelected);
        }
      }

      this.set('disableFilterCancel', false);
      this.set('disableFilterApply', false);
      this.set('disableFilterClear', false);
    }).enqueue(),
    'disableOptions': (0, _emberConcurrency.task)(function* (arr, optionsProperty, allSelected) {
      yield;
      const indexArr = [];
      arr.forEach(idx => {
        if (this.get(optionsProperty).map(e => {
          return e.name;
        }).indexOf(idx.name) >= 0) {
          indexArr.push(this.get(optionsProperty).map(e => {
            return e.name;
          }).indexOf(idx.name));
        }
      });
      this.get(optionsProperty).forEach((obj, idx) => {
        if (allSelected) {
          window.Ember.set(this.get(optionsProperty).objectAt(idx), 'disabled', true);
        } else {
          if (indexArr.indexOf(idx) >= 0) window.Ember.set(this.get(optionsProperty).objectAt(idx), 'disabled', true);else window.Ember.set(this.get(optionsProperty).objectAt(idx), 'disabled', false);
        }
      });
    }).enqueue(),
    'setProperty': (0, _emberConcurrency.task)(function* (property, selectedList, changedProperty) {
      const uniqueArr = [];
      selectedList.forEach(idx => {
        if (uniqueArr.map(e => {
          return e.name;
        }).indexOf(idx.name) < 0) {
          uniqueArr.push({
            'name': idx.name
          });
        }
      });
      const arr = this.get(property);
      let subArray = false;
      if (uniqueArr.length == arr.length) subArray = this.checkSubArray(uniqueArr, arr);
      const path = this.get('path');

      if (path.indexOf(changedProperty) < 0) {
        path.push(changedProperty);
        this.set('path', path);
      }

      if (!subArray) {
        this.set(property, uniqueArr);

        if (this.get(property) && this.get(property).length) {
          yield this.get('setSelection').perform(changedProperty);
        }
      }
    }).enqueue(),
    'onDateChanged': Ember.observer('startDate', 'endDate', function () {
      this.set('disableSelectionCancel', false);
      this.set('disableSelectionApply', false);
      this.set('minDate', new Date(this.get('startDate')));

      if (this.get('currentDateType') === 'custom' && window.moment(this.get('endDate')) < window.moment(this.get('startDate'))) {
        this.get('notification').display({
          'type': 'error',
          'error': this.intl.t('general.start_date_greater_than_end_date_error_message')
        });
        this.set('endDate', this.get('startDate'));
      }

      if (this.get('startDate') && this.get('endDate') && this.get('schedules')) {
        const filteredSchedules = this.get('schedules').filter(schedule => {
          if (window.moment(schedule.start_date) >= window.moment(this.get('startDate')) || window.moment(schedule.end_date) <= window.moment(this.get('endDate')) || window.moment(schedule.start_date) <= window.moment(this.get('startDate')) && window.moment(schedule.end_date) >= window.moment(this.get('startDate'))) {
            return true;
          }
        });
        const shifts = [];
        filteredSchedules.forEach(schedule => {
          if (shifts.map(e => {
            return e;
          }).indexOf(schedule.description) < 0) {
            shifts.push(schedule.description);
          }
        });
        this.set('shiftOptions', shifts);
        if (shifts.length) this.set('shift', [shifts[0]]);
      }
    }),
    'onShiftChange': Ember.observer('shift', function () {
      if (this.get('shift') && this.get('shift').length) {
        this.set('disableSelectionCancel', false);
        this.set('disableSelectionApply', false);
      }
    }),
    'onAggregationChange': Ember.observer('trendAggregation', function () {
      if (this.get('prevStateAggregation').entityAggregation != this.get('entityAggregation') || this.get('prevStateAggregation').trendAggregation != this.get('trendAggregation')) {
        this.set('disableAggregationCancel', false);
        this.set('disableAggregationApply', false);
      } else {
        this.set('disableAggregationCancel', true);
        this.set('disableAggregationApply', true);
      }
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    actions: {
      'changeLine': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('lines').length > 1 && value.length === this.get('lines').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'lines', allSelected);
        this.get('setProperty').perform('chosenLine', arr, 'line');
      },
      'changeMachine': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('machines').length > 1 && value.length === this.get('machines').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'machines', allSelected);
        this.get('setProperty').perform('chosenMachine', arr, 'machine');
      },
      'changeOperator': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('operators').length > 1 && value.length === this.get('operators').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'operators', allSelected);
        this.get('setProperty').perform('chosenOperators', arr, 'operator');
      },
      'changePlant': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('plants').length > 1 && value.length === this.get('plants').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'plants', allSelected);
        this.get('setProperty').perform('chosenPlants', arr, 'plant');
      },
      'changeProcess': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('processes').length > 1 && value.length === this.get('processes').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'processes', allSelected);
        this.get('setProperty').perform('chosenProcess', arr, 'process');
      },
      'changeSKU': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('skuList').length > 1 && value.length === this.get('skuList').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'skuList', allSelected);
        this.get('setProperty').perform('chosenSKU', arr, 'sku');
      },
      'changeSupervisor': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('supervisors').length > 1 && value.length === this.get('supervisors').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'supervisors', allSelected);
        this.get('setProperty').perform('chosenSupervisor', arr, 'supervisor');
      },
      'changeUnit': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('units').length > 1 && value.length === this.get('units').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'units', allSelected);
        this.get('setProperty').perform('chosenUnit', arr, 'unit');
      },
      'changeWorkOrder': function (value) {
        let allSelected = value && value.map(e => {
          return e.name;
        }).indexOf('All') >= 0;
        if (allSelected) value = [{
          'name': 'All'
        }];else {
          if (this.get('workOrders').length > 1 && value.length === this.get('workOrders').length - 1) {
            value = [{
              'name': 'All'
            }];
            allSelected = true;
          }
        }
        const arr = value;
        this.get('disableOptions').perform(arr, 'workOrders', allSelected);
        this.get('setProperty').perform('chosenWorkOrder', arr, 'workOrder');
      }
    },
    'getPathKeys': function (path) {
      if (path === 'plant') return {
        'list': 'plants',
        'selected': 'chosenPlant',
        'filterKey': 'plant'
      };else if (path === 'unit') return {
        'list': 'units',
        'selected': 'chosenUnit',
        'filterKey': 'unit'
      };else if (path === 'line') return {
        'list': 'lines',
        'selected': 'chosenLine',
        'filterKey': 'line'
      };else if (path === 'process') return {
        'list': 'processes',
        'selected': 'chosenProcess',
        'filterKey': 'process'
      };else if (path === 'machine') return {
        'list': 'machines',
        'selected': 'chosenMachine',
        'filterKey': 'machine'
      };else if (path === 'workOrder') return {
        'list': 'workOrders',
        'selected': 'chosenWorkOrder',
        'filterKey': 'workOrder'
      };else if (path === 'sku') return {
        'list': 'skuList',
        'selected': 'chosenSKU',
        'filterKey': 'sku'
      };else if (path === 'supervisor') return {
        'list': 'supervisors',
        'selected': 'chosenSupervisor',
        'filterKey': 'supervisor'
      };else if (path === 'operator') return {
        'list': 'operators',
        'selected': 'chosenOperator',
        'filterKey': 'operator'
      };else return true;
    },
    'getUniqueArr': function (arr, key) {
      const availableArr = [];
      arr.forEach(idx => {
        if (availableArr.map(e => {
          return e.name;
        }).indexOf(idx[key]) < 0) availableArr.push({
          'name': idx[key]
        });
      });
      return availableArr;
    },
    'sortAndAdd': function (arr) {
      arr = arr.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      if (arr.length > 1) arr.unshift({
        'name': 'All'
      });
      return arr;
    },
    'getFilteredArr': function (comparingArr, arr, key) {
      let availableArr = [];
      availableArr = arr.filter(e => {
        return comparingArr.indexOf(e[key]) >= 0;
      });
      return availableArr;
    },
    'checkSubArray': function (subArr, arr) {
      for (let i = 0; i < subArr.length; i++) {
        const idx = subArr[i];
        if (arr.map(e => {
          return e.name;
        }).indexOf(idx.name) < 0) return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});