define("plantworks/models/settings/account/basics/tenant-location", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'line1': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'line2': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'line3': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'area': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'city': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'state': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'country': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'postalCode': _emberData.default.attr('string', {
      'defaultValue': ''
    }),
    'latitude': _emberData.default.attr('number', {
      'defaultValue': 0
    }),
    'longitude': _emberData.default.attr('number', {
      'defaultValue': 0
    }),
    'timezoneId': _emberData.default.attr('string', {
      'defaultValue': 'asia_kolkata'
    }),
    'timezoneName': _emberData.default.attr('string', {
      'defaultValue': 'ist'
    }),
    'isPrimary': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'tenantLocations'
    }),
    'i18n_timezone_id': Ember.computed('timezoneId', 'intl.locale', function () {
      return this.intl.t(`timezone.id.${this.get('timezoneId')}`);
    }),
    'i18n_timezone_name': Ember.computed('timezoneName', 'intl.locale', function () {
      return this.intl.t(`timezone.name.${this.get('timezoneName')}`);
    })
  });

  _exports.default = _default;
});