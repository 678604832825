define("plantworks/components/manufacturing/watch/downtime-reason-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'downtimeReasons': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      yield this.get('_retrieveDowntimeReasons').perform();
    }).on('init').keepLatest(),
    '_retrieveDowntimeReasons': (0, _emberConcurrency.task)(function* () {
      if (!this.get('machine')) return;
      const downtimeReasonList = yield this.get('machine.downtimeList');
      if (!downtimeReasonList) return;
      const downtimeReasons = yield this.get('ajax').request(`/emd/retrieveDowntimeReasons/${downtimeReasonList.get('id')}/${this.get('machine.id')}/downtime`, {
        'method': 'GET'
      });
      this.set('downtimeReasons', downtimeReasons.map(downtimeReason => {
        return {
          'reasonCode': downtimeReason['masterdata_id'],
          'reasonDescription': downtimeReason['displayValue'] ? downtimeReason['displayValue'] : downtimeReason['masterdata_id']
        };
      }));
      this.set('downtimeReasonValues', downtimeReasons.map(downtimeReason => {
        return downtimeReason['displayValue'] ? downtimeReason['displayValue'] : downtimeReason['masterdata_id'];
      }));
    }).keepLatest(),
    'addReason': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('machine.tenant');
      const newReason = this.get('store').createRecord('manufacturing/plant-unit-machine-downtime-reason', {
        'tenant': tenant,
        'tenantPlantUnitMachineDowntime': this.get('model'),
        'metadata': {}
      });
      this.get('model.reasons').addObject(newReason);
    }).enqueue(),
    'cancelReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.rollback();
    }).enqueue(),
    'saveReason': (0, _emberConcurrency.task)(function* (reason) {
      if (reason.reasonDurationInMin > 0 && reason.reasonCode != 'UNKNOWN') {
        yield reason.save();
      } else {
        this.get('notification').display({
          'type': 'error',
          'error': 'Invalid Input'
        });
        return;
      }
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveReasonSucceeded': Ember.on('saveReason:succeeded', function () {
      //this.get('callParentTask').perform();
      this.get('parentTask').perform();
    }),
    'saveReasonErrored': Ember.on('saveReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteSucceeded': Ember.on('deleteReason:succeeded', function () {
      this.get('parentTask').perform();
    }),
    'deleteReasonErrored': Ember.on('deleteReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onReasonDescChanged': Ember.observer('model.reasons.@each.reasonDescription', function () {
      this.get('model.reasons').forEach(reason => {
        if (!this.get('downtimeReasons')) return;
        const downtimeReason = this.get('downtimeReasons').filter(downtimeReason => {
          return downtimeReason['reasonDescription'] === reason.get('reasonDescription');
        })[0];
        if (!downtimeReason) return;
        reason.set('reasonCode', downtimeReason['reasonCode']);
      });
    })
  });

  _exports.default = _default;
});