define("plantworks/initializers/manufacturing/add-manufacturing-plant-unit-machine-to-emd-configuration", ["exports", "ember-data", "plantworks/models/emd/configuration", "plantworks/models/manufacturing/plant-unit-machine", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _configuration, _plantUnitMachine, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _configuration.default.reopen({
      'tenantPlantUnitOperatorMachines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
        'async': true,
        'inverse': 'operatorList'
      }),
      'tenantPlantUnitDowntimeMachines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
        'async': true,
        'inverse': 'downtimeList'
      }),
      'tenantPlantUnitSpeedDeviationMachines': _emberData.default.hasMany('manufacturing/plant-unit-machine', {
        'async': true,
        'inverse': 'speedDeviationList'
      })
    });

    _plantUnitMachine.default.reopen({
      'operatorList': _emberData.default.belongsTo('emd/configuration', {
        'async': true,
        'inverse': 'tenantPlantUnitOperatorMachines'
      }),
      'downtimeList': _emberData.default.belongsTo('emd/configuration', {
        'async': true,
        'inverse': 'tenantPlantUnitDowntimeMachines'
      }),
      'speedDeviationList': _emberData.default.belongsTo('emd/configuration', {
        'async': true,
        'inverse': 'tenantPlantUnitSpeedDeviationMachines'
      }),
      'operatorListFilters': (0, _attributes.fragmentArray)('manufacturing/fragments/operator-list-filters'),
      'downtimeListFilters': (0, _attributes.fragmentArray)('manufacturing/fragments/downtime-list-filters'),
      'setuptimeListFilters': (0, _attributes.fragmentArray)('manufacturing/fragments/setuptime-list-filters'),
      'idletimeListFilters': (0, _attributes.fragmentArray)('manufacturing/fragments/idletime-list-filters'),
      'speedDeviationsListFilters': (0, _attributes.fragmentArray)('manufacturing/fragments/speed-deviations-list-filters')
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});