define("plantworks/components/outgoing/realtime-custom-display", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch', 'bg-white'],
    'categorizedDataSetPropertyView': false,
    'selectedDataSetSourceType': 'static',
    'onBeforeRenderTaskDone': false,
    'onAfterRenderTaskDone': false,

    init() {
      this._super(...arguments);

      this._setupFrontEndHooks();
    },

    'onDidRender': (0, _emberConcurrency.task)(function* () {
      if (this.get('onAfterRenderTaskDone')) return;

      while (!this.get('onBeforeRenderTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('componentAfterRenderTask').perform();
      (0, _emberLifeline.runTask)(this, 'executeCustomOnDataFunction', 250);
    }).on('didRender').enqueue(),

    _setupFrontEndHooks() {
      // Step 1: Sanity checks
      let componentBeforeRenderCode = this.get('processor.componentBeforeRenderCode');
      if (!componentBeforeRenderCode) componentBeforeRenderCode = '';else componentBeforeRenderCode = componentBeforeRenderCode.trim();
      let componentAfterRenderCode = this.get('processor.componentAfterRenderCode');
      if (!componentAfterRenderCode) componentAfterRenderCode = '';else componentAfterRenderCode = componentAfterRenderCode.trim();
      let componentOnDataCode = this.get('processor.componentOnDataCode');
      if (!componentOnDataCode) componentOnDataCode = '';else componentOnDataCode = componentOnDataCode.trim();
      let componentBeforeDestroyCode = this.get('processor.componentBeforeDestroyCode');
      if (!componentBeforeDestroyCode) componentBeforeDestroyCode = '';else componentBeforeDestroyCode = componentBeforeDestroyCode.trim(); // Step 2: Set defaults

      if (componentBeforeRenderCode === '') componentBeforeRenderCode = `this.debug('Executing custom componentBeforeRenderCode');\nyield;`;
      if (componentAfterRenderCode === '') componentAfterRenderCode = `this.debug('Executing custom componentAfterRenderCode');\nyield;`;
      if (componentOnDataCode === '') componentOnDataCode = `this.debug('Executing custom componentOnDataCode');\nyield;`;
      if (componentBeforeDestroyCode === '') componentBeforeDestroyCode = `this.debug('Executing custom componentBeforeDestroyCode');\nyield;`; // Step 3: Create functions using GeneratorFunction

      const GeneratorFunction = Object.getPrototypeOf(function* () {}).constructor;
      const componentBeforeRenderFunc = new GeneratorFunction(`
try {
	${componentBeforeRenderCode}

	this.set('onBeforeRenderTaskDone', true);
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentAfterRenderFunc = new GeneratorFunction(`
try {
	${componentAfterRenderCode}

	this.set('onAfterRenderTaskDone', true);
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentBeforeDestroyFunc = new GeneratorFunction(`
try {
	${componentBeforeDestroyCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`);
      const componentOnDataFunc = new GeneratorFunction(`
try {
	${componentOnDataCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
		`); // Step 4: Set the new functions as properties of this object

      Ember.defineProperty(this, 'state', undefined, Ember.Object.create(this.get('processor.frontendComponentState') || {}));
      Ember.defineProperty(this, 'onWillInsertElement', (0, _emberConcurrency.task)(componentBeforeRenderFunc).on('willInsertElement').enqueue());
      Ember.defineProperty(this, 'componentAfterRenderTask', (0, _emberConcurrency.task)(componentAfterRenderFunc).enqueue());
      Ember.defineProperty(this, 'onWillDestroyElement', (0, _emberConcurrency.task)(componentBeforeDestroyFunc).on('willDestroyElement').enqueue());
      Ember.defineProperty(this, 'onDataFunc', (0, _emberConcurrency.task)(componentOnDataFunc).enqueue()); // Repeating above steps for observers

      const Function = Object.getPrototypeOf(function () {}).constructor;
      const allObservers = this.get('processor.componentObservers');
      allObservers.forEach(componentObserver => {
        if (!componentObserver.get('name') || !componentObserver.get('observedProperty') || !componentObserver.get('functionCode')) return;
        const templateObservedProperty = componentObserver.get('observedProperty');
        let componentObserverCode = componentObserver.get('functionCode');
        componentObserverCode = `
try {
	${componentObserverCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
			`;
        const componentObserverFunction = new Function(componentObserverCode);
        Ember.addObserver(this, templateObservedProperty, this, componentObserverFunction);
      }); // Repeating above steps for tasks

      const allTasks = this.get('processor.componentTasks');
      allTasks.forEach(componentTask => {
        if (!componentTask.get('name') || !componentTask.get('functionCode')) return;
        let componentTaskCode = componentTask.get('functionCode');
        let componentTaskArgs = componentTask.get('arguments');
        if (componentTaskArgs) componentTaskArgs = componentTaskArgs.split(',').map(arg => {
          return arg.trim();
        });else componentTaskArgs = [];
        componentTaskCode = `
try {
	${componentTaskCode}
}
catch(err) {
	this.get('notification').display({
		'type': 'error',
		'error': err
	});

	throw err;
}
			`;
        const componentTaskGeneratorFunction = new GeneratorFunction(...componentTaskArgs, componentTaskCode);
        Ember.defineProperty(this, componentTask.get('name'), (0, _emberConcurrency.task)(componentTaskGeneratorFunction).keepLatest());
      });
    },

    'onDataChanged': Ember.observer('arrayModel.@each.currentValue', function () {
      (0, _emberLifeline.debounceTask)(this, 'executeCustomOnDataFunction', 1000);
    }),
    'preOnDataFunc': (0, _emberConcurrency.task)(function* () {
      while (!this.get('onAfterRenderTaskDone')) yield (0, _emberConcurrency.timeout)(500);

      yield this.get('onDataFunc').perform();
    }).enqueue(),

    executeCustomOnDataFunction() {
      Promise.resolve().then(() => {
        return this.get('preOnDataFunc').perform();
      }).catch(err => {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      });
    }

  });

  _exports.default = _default;
});