define("plantworks/components/manufacturing/configure/plant-unit-machine-editor/hardware-sub-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-row', 'layout-align-center-stretch', 'layout-wrap'],
    'possibleHardwareProtocols': null,
    'possibleCommunicationProtocols': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('_setHardwareProtocol').perform();
        yield this.get('_setCommunicationProtocol').perform();
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'editPlantUnitMachineMachine': (0, _emberConcurrency.task)(function* () {
      const currentMachine = this.get('model.machine');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_add_machine'),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitMachineEditor/AddMachine',
        'componentState': {
          'model': this.get('model')
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {}
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {
            if (!currentMachine) {
              this.set('model.machine', null);
              return;
            }

            if (currentMachine === this.get('model.machine')) return;
            this.set('model.machine', currentMachine);
          }
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'editPlantUnitMachinePlc': (0, _emberConcurrency.task)(function* () {
      const currentPlc = this.get('model.plc');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine_add_plc'),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitMachineEditor/AddPlc',
        'componentState': {
          'model': this.get('model')
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            if (currentPlc && currentPlc.get('id') === this.get('model.plc.id')) return;
            this.get('_setHardwareProtocol').perform();
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {
            if (!currentPlc) {
              this.set('model.plc', null);
              return;
            }

            if (currentPlc === this.get('model.plc')) return;
            this.set('model.plc', currentPlc);
          }
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'editPlantUnitMachineDriver': (0, _emberConcurrency.task)(function* () {
      const currentDriver = yield this.get('model.tenantPlantUnitDriver');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_driver'),
          'iconType': 'mdi',
          'icon': 'boom-gate-up'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitMachineEditor/AddDriver',
        'componentState': {
          'model': this.get('model')
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            if (currentDriver && currentDriver.get('id') === this.get('model.tenantPlantUnitDriver.id')) return;
            this.get('_setHardwareProtocol').perform();
            this.get('_setCommunicationProtocol').perform();
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {
            if (currentDriver && currentDriver.get('id') === this.get('model.tenantPlantUnitDriver.id')) return;
            this.set('model.tenantPlantUnitDriver', currentDriver);
          }
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_setHardwareProtocol': (0, _emberConcurrency.task)(function* () {
      const machinePlc = yield this.get('model.plc');
      const machineDriver = yield this.get('model.tenantPlantUnitDriver');

      if (!(machinePlc && machinePlc.get('id'))) {
        this.set('possibleHardwareProtocols', null);
        this.set('model.hardwareProtocol', null);
        return;
      }

      if (!(machineDriver && machineDriver.get('id'))) {
        this.set('possibleHardwareProtocols', null);
        this.set('model.hardwareProtocol', null);
        return;
      }

      const gatewayHardwareProtocols = yield machineDriver.get('gateway.hardwareProtocols');
      const allowedHardwareProtocols = [];

      for (let idx = 0; idx < gatewayHardwareProtocols.length; idx++) {
        const gatewayHardwareProtocol = gatewayHardwareProtocols.objectAt(idx);
        const allowedHardwareProtocol = yield gatewayHardwareProtocol.get('protocol');
        allowedHardwareProtocols.push(allowedHardwareProtocol);
      }

      const plcHardwareProtocols = yield machinePlc.get('hardwareProtocols');
      const supportedHardwareProtocols = [];

      for (let idx = 0; idx < plcHardwareProtocols.length; idx++) {
        const plcHardwareProtocol = plcHardwareProtocols.objectAt(idx);
        const supportedHardwareProtocol = yield plcHardwareProtocol.get('protocol');
        supportedHardwareProtocols.push(supportedHardwareProtocol);
      }

      const possibleHardwareProtocols = allowedHardwareProtocols.filter(allowedHardwareProtocol => {
        const thisProtoAllowed = supportedHardwareProtocols.filter(supportedHardwareProtocol => {
          return supportedHardwareProtocol.get('id') === allowedHardwareProtocol.get('id');
        });
        return !!thisProtoAllowed.length;
      });
      this.set('possibleHardwareProtocols', possibleHardwareProtocols);
      if (!this.get('model.hardwareProtocol')) return;
      const machineHwProtocolId = yield this.get('model.hardwareProtocol.id');
      const isHwProtocolAllowed = possibleHardwareProtocols.filter(possibleHardwareProtocol => {
        return possibleHardwareProtocol.get('id') === machineHwProtocolId;
      });
      if (isHwProtocolAllowed.length) return;

      if (possibleHardwareProtocols.length === 1) {
        this.set('model.hardwareProtocol', possibleHardwareProtocols[0]);
        return;
      }

      this.set('model.hardwareProtocol', null);
    }).keepLatest(),
    '_setCommunicationProtocol': (0, _emberConcurrency.task)(function* () {
      const machineDriver = yield this.get('model.tenantPlantUnitDriver');

      if (!(machineDriver && machineDriver.get('id'))) {
        this.set('possibleCommunicationProtocols', null);
        this.set('model.communicationProtocol', null);
        return;
      }

      const gatewayCommProtocols = yield machineDriver.get('gateway.communicationProtocols');
      const allowedCommProtocols = [];

      for (let idx = 0; idx < gatewayCommProtocols.length; idx++) {
        const gatewayCommProtocol = gatewayCommProtocols.objectAt(idx);
        const allowedCommProtocol = yield gatewayCommProtocol.get('protocol');
        allowedCommProtocols.push(allowedCommProtocol);
      }

      this.set('possibleCommunicationProtocols', allowedCommProtocols);
      if (!this.get('model.communicationProtocol')) return;
      const machineCommProtocolId = yield this.get('model.communicationProtocol.id');
      const isCommProtocolAllowed = allowedCommProtocols.filter(allowedCommProtocol => {
        return allowedCommProtocol.get('id') === machineCommProtocolId;
      });
      if (isCommProtocolAllowed.length) return;

      if (allowedCommProtocols.length === 1) {
        this.set('model.communicationProtocol', allowedCommProtocols[0]);
        return;
      }

      this.set('model.communicationProtocol', null);
    }).keepLatest()
  });

  _exports.default = _default;
});