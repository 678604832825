define("plantworks/components/historical-dashboard/configure/data-point-aggregations", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0'],
    'viewMode': 'configure',
    'dataTypes': null,
    'timeFormats': null,
    'aggregateTypes': null,
    'aggregationPeriodChoices': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        const aggregateTypes = yield this.get('ajax').request('/masterdata/boardAggregationTypeChoices', {
          'method': 'GET'
        });
        const aggregationPeriodChoices = yield this.get('ajax').request('/masterdata/boardAggregationPeriodChoices', {
          'method': 'GET'
        });
        this.setProperties({
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes,
          'aggregateTypes': aggregateTypes,
          'aggregationPeriodChoices': aggregationPeriodChoices
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addDataPointAggregate': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const historicalDashboard = yield this.get('model');
      const newDataPointAggregate = this.get('store').createRecord('historical-dashboard/historical-dashboard-aggregate', {
        'tenant': tenant,
        'tenantHistoricalDashboard': historicalDashboard
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.common.new_data_point_aggregate_name', {
        'now': window.moment().valueOf()
      });
      newDataPointAggregate.set('name', displayName);
      newDataPointAggregate.set('description', displayName);
      newDataPointAggregate.set('internalTag', Ember.String.underscore(displayName).toLocaleUpperCase());
      const siblingAggregates = yield this.get('model.dataPointAggregations');
      siblingAggregates.addObject(newDataPointAggregate);
      this.get('editDataPointAggregate').perform(newDataPointAggregate);
    }).enqueue(),
    'editDataPointAggregate': (0, _emberConcurrency.task)(function* (aggregate) {
      yield aggregate.set('isEditing', true);
    }).enqueue(),
    'cancelDataPointAggregate': (0, _emberConcurrency.task)(function* (aggregate) {
      aggregate.set('isEditing', false);
      yield aggregate.rollback();
    }).enqueue(),
    'saveDataPointAggregate': (0, _emberConcurrency.task)(function* (aggregate) {
      aggregate.set('isEditing', false);
      yield aggregate.save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveDataPointAggregateSucceeded': Ember.on('saveDataPointAggregate:succeeded', function (taskInstance) {
      const aggregate = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_data_point_aggregate_save', {
          'htmlSafe': true,
          'displayName': aggregate.get('name')
        })
      });
    }),
    'saveDataPointAggregateErrored': Ember.on('saveDataPointAggregate:errored', function (taskInstance, err) {
      const aggregate = taskInstance.args[0];
      aggregate.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteDataPointAggregate': (0, _emberConcurrency.task)(function* (aggregate) {
      const aggrName = yield aggregate.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_delete_data_point_aggregate'),
          'iconType': 'mdi',
          'icon': 'sprout'
        },
        'content': this.intl.t('plant_works_webapp_server_server.common.delete_data_point_aggregate_message', {
          'htmlSafe': true,
          'displayName': aggrName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteDataPointAggregate').perform(aggregate, aggrName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteDataPointAggregate': (0, _emberConcurrency.task)(function* (aggregate, propertyName) {
      // eslint-disable-line no-unused-vars
      yield aggregate.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteDataPointAggregateSucceeded': Ember.on('_confirmedDeleteDataPointAggregate:succeeded', function (taskInstance) {
      const propertyName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.common.succesful_data_point_aggregate_delete', {
          'htmlSafe': true,
          'displayName': propertyName
        })
      });
    }),
    '_confirmedDeleteDataPointAggregateErrored': Ember.on('_confirmedDeleteDataPointAggregate:errored', function (taskInstance, err) {
      const aggregate = taskInstance.args[0];
      aggregate.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});