define("plantworks/controllers/emd", ["exports", "plantworks/framework/base-controller"], function (_exports, _baseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'selectedNode': null,
    'selectedNodeType': null,
    'parsedData': null,
    'lockedOpen': true,
    'sidenavOpen': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'emd-read');
    },

    setSelectedNode(nodeModel) {
      if (!nodeModel) {
        this.set('selectedNode', null);
        return;
      }

      if (nodeModel.id === this.get('selectedNode.id')) return;
      this.set('selectedNode', nodeModel);
    },

    setSelectedNodeType(treeNode) {
      if (!treeNode) {
        this.set('selectedNodeType', null);
      }

      if (treeNode.data.type.replace('/', '-') == this.get('selectedNodeType')) return;
      this.set('selectedNodeType', treeNode.data.type.replace('/', '-'));
    },

    setParsedData(rows) {
      this.set('parsedData', rows);
    }

  });

  _exports.default = _default;
});