define("plantworks/templates/board/watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KNdIXuWZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"board/watch/realtime-data-display\",[],[[\"@class\",\"@model\",\"@controller-action\"],[\"flex layout-column layout-align-start-stretch\",[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/board/watch.hbs"
    }
  });

  _exports.default = _default;
});