define("plantworks/models/pug/group-manager/tenant-group-permission", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'tenantGroupPermissions'
    }),
    'tenantGroup': _emberData.default.belongsTo('pug/group-manager/tenant-group', {
      'async': true,
      'inverse': 'permissions'
    }),
    'feature': _emberData.default.belongsTo('server-administration/feature', {
      'async': true,
      'inverse': 'tenantGroupPermissions'
    }),
    'featurePermission': _emberData.default.belongsTo('server-administration/feature-permission', {
      'async': true,
      'inverse': 'tenantGroupPermissions'
    })
  });

  _exports.default = _default;
});