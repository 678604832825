define("plantworks/components/common/consolidated-attribute-set/property-viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'pb-2', 'layout-column', 'layout-align-start-stretch'],
    'categorizedDataSetPropertyView': false,
    'selectedDataSetSourceType': 'static',
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const sourceTypes = yield this.get('ajax').request('/masterdata/attributeSourceTypes', {
          'method': 'GET'
        });
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        this.setProperties({
          'sourceTypes': sourceTypes,
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'toggleAttributeSetProperty': (0, _emberConcurrency.task)(function* (property) {
      yield property.set('show', !property.get('show'));
    }).enqueue()
  });

  _exports.default = _default;
});