define("plantworks/initializers/work-order/add-wof-to-tenant-user", ["exports", "ember-data", "plantworks/models/pug/user-manager/tenant-user"], function (_exports, _emberData, _tenantUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _tenantUser.default.reopen({
      'tenantWofs': _emberData.default.hasMany('work-order/wof-watcher', {
        'async': true,
        'inverse': 'tenantUser'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});