define("plantworks/templates/components/manufacturing/devenv/plant-unit-line-editor/property-metadata-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AtTkXiMT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isTimestamp\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_is_timestamp\"],null],true,[24,[\"metadata\",\"isTimestamp\"]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_cannot_be_timestamp\"],null],true,false,null]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"plant-works-checkbox\",[],[[\"@class\",\"@label\",\"@disabled\",\"@value\",\"@onChange\"],[\"flex\",[28,\"t\",[\"general.label_global\"],null],true,[24,[\"metadata\",\"isGlobal\"]],null]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/manufacturing/devenv/plant-unit-line-editor/property-metadata-viewer.hbs"
    }
  });

  _exports.default = _default;
});