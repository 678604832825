define("plantworks/templates/components/operator-form/execute/request-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L15KDHzb",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-card\",[],[[\"@class\"],[\"m-0 flex bg-white\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"Header\"]],[],[[\"@class\"],[\"flex\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"inputComponentModel\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"operator-form/execute/input-component-viewer\",[],[[\"@class\",\"@layout\",\"@inputComponentModel\",\"@isExecuting\",\"@displayResult\",\"@resultData\",\"@operatorForm\",\"@station\",\"@machine\",\"@currentWorkOrder\",\"@line\",\"@controller-action\"],[\"flex\",[22,\"inputComponentLayout\"],[22,\"inputComponentModel\"],[22,\"isExecuting\"],[22,\"displayResult\"],[22,\"resultData\"],[22,\"model\"],[22,\"station\"],[22,\"machine\"],[22,\"currentWorkOrder\"],[22,\"line\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"flex\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"resultComponentModel\"]],[24,[\"displayResult\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"operator-form/execute/result-component-viewer\",[],[[\"@class\",\"@layout\",\"@resultComponentModel\",\"@isExecuting\",\"@displayResult\",\"@resultData\",\"@operatorForm\",\"@controller-action\"],[\"flex\",[22,\"resultComponentLayout\"],[22,\"resultComponentModel\"],[22,\"isExecuting\"],[22,\"displayResult\"],[22,\"resultData\"],[22,\"model\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/operator-form/execute/request-wrapper.hbs"
    }
  });

  _exports.default = _default;
});