define("plantworks/components/common/consolidated-attribute-set/list-manager", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-row', 'layout-align-start-center'],
    'allowModifications': true,
    'saveDataSetMapping': (0, _emberConcurrency.task)(function* (dataSet) {
      yield dataSet.save();
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'cancelDataSetMapping': (0, _emberConcurrency.task)(function* (dataSet) {
      yield dataSet.rollback();
    }).enqueue(),
    'deleteDataSetMapping': (0, _emberConcurrency.task)(function* (dataSet) {
      yield dataSet.destroyRecord();
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});