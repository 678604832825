define("plantworks/components/manufacturing/watch/approved-downtime-reason-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'downtimeReasons': null,
    'subClass': null,
    'subClassSelection': null,
    'reasonEntryEnabled': false,
    'onInit': (0, _emberConcurrency.task)(function* () {
      yield this.get('_retrieveDowntimeReasons').perform();
    }).on('init').keepLatest(),
    '_retrieveDowntimeReasons': (0, _emberConcurrency.task)(function* () {
      if (!this.get('machine')) return;
      let type = yield this.get('type');
      const downtimeReasonList = yield this.get('machine.downtimeList');
      if (!downtimeReasonList) return;
      let unitName = yield this.get('unit.name');
      let lineName = yield this.get('line.tenantPlantUnitLine.name');
      unitName = unitName.replace(/ /g, '_').toUpperCase();
      lineName = lineName.replace(/ /g, '_').toUpperCase();
      let downtimeReasons = yield this.get('ajax').request(`/emd/retrieveDowntimeReasons/${downtimeReasonList.get('id')}/${this.get('machine.id')}/${type}`, {
        'method': 'GET'
      });
      let subclass = [];
      downtimeReasons.forEach(sc => {
        if (sc.PLANT_UNIT == unitName && sc.PLANT_UNIT_LINE == lineName && sc.REASON !== 'Minor Stoppage') subclass.push(sc.SUBCLASS);
      });
      subclass = [...new Set(subclass)];
      this.set('subClassSelection', subclass);
      downtimeReasons = downtimeReasons.filter(downtimeReason => {
        if (downtimeReason['PLANT_UNIT'] == unitName && downtimeReason['PLANT_UNIT_LINE'] == lineName && downtimeReason['REASON'] != 'Minor Stoppage') {
          return true;
        } else {
          false;
        }
      });
      this.set('downtimeReasons', downtimeReasons.map(downtimeReason => {
        return {
          'reasonCode': downtimeReason['masterdata_id'],
          'reasonDescription': downtimeReason['REASON'],
          'subClass': downtimeReason['SUBCLASS']
        };
      }));
      this.set('reasonEntryEnabled', true); // this.set('downtimeReasonValues', downtimeReasons.map((downtimeReason) => {
      // 	return downtimeReason['displayValue'] ? downtimeReason['displayValue'] : downtimeReason['masterdata_id'];
      // }));
    }).keepLatest(),
    'addReason': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('machine.tenant');
      const newReason = this.get('store').createRecord('manufacturing/plant-unit-machine-approved-downtime-reason', {
        'tenant': tenant,
        'tenantPlantUnitMachineApprovedDowntime': this.get('model'),
        'allReasons': this.get('downtimeReasons')
      });
      this.get('model.reasons').addObject(newReason);
    }).enqueue(),
    'cancelReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.rollback();
    }).enqueue(),
    'saveReason': (0, _emberConcurrency.task)(function* (reason) {
      if (typeof reason.reasonDescription === 'object') {
        let reasonObj = reason.reasonDescription;
        reason.reasonCode = reasonObj.reasonCode;
        reason.reasonDescription = reasonObj.reasonDescription;
      }

      if (reason.reasonDurationInMin > 0 && reason.reasonCode != '' && reason.reasonDescription != '') {
        yield reason.save();
      } else {
        this.get('notification').display({
          'type': 'error',
          'error': 'Invalid Input'
        });
        return;
      }
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveReasonSucceeded': Ember.on('saveReason:succeeded', function () {
      //this.get('callParentTask').perform();
      this.get('parentTask').perform();
    }),
    'saveReasonErrored': Ember.on('saveReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteReason': (0, _emberConcurrency.task)(function* (reason) {
      yield reason.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteSucceeded': Ember.on('deleteReason:succeeded', function () {
      this.get('parentTask').perform();
    }),
    'deleteReasonErrored': Ember.on('deleteReason:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onReasonDescChanged': Ember.observer('model.reasons.@each.reasonDescription', function () {
      this.get('model.reasons').forEach(reason => {
        if (!this.get('downtimeReasons')) return;
        const downtimeReason = this.get('downtimeReasons').filter(downtimeReason => {
          return downtimeReason['reasonDescription'] === reason.get('reasonDescription');
        })[0];
        if (!downtimeReason) return;
        reason.set('reasonCode', downtimeReason['reasonCode']);
      });
    })
  });

  _exports.default = _default;
});