define("plantworks/components/report/devenv/request-formatter-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    selectedRequestFormatterType: null,

    init() {
      this._super(...arguments);
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const requestFormatterTypes = yield this.get('ajax').request('/masterdata/report-request-formatter-types', {
          'method': 'GET'
        });
        this.setProperties({
          'requestFormatterTypes': requestFormatterTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'selectRequestFormatterType': (0, _emberConcurrency.task)(function* (selectionType) {
      this.set('selectedRequestFormatterType', selectionType);
      yield;
    }),
    'resetRequestFormatterType': function () {
      this.set('selectedRequestFormatterType', null);
    }
  });

  _exports.default = _default;
});