define("plantworks/components/common/dev-env/template-observer-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'addComponentObserver': (0, _emberConcurrency.task)(function* () {
      const allObservers = this.get('model');
      yield allObservers.createFragment({
        'name': 'New Observer',
        'observedProperty': '',
        'functionCode': ''
      });
    }).drop(),
    'deleteComponentObserver': (0, _emberConcurrency.task)(function* (componentObserver) {
      const allObservers = this.get('model');
      yield allObservers.removeFragment(componentObserver);
    }).drop(),
    'showComponentObserver': (0, _emberConcurrency.task)(function* (componentObserver) {
      yield componentObserver.set('show', true);
    }).drop(),
    'hideComponentObserver': (0, _emberConcurrency.task)(function* (componentObserver) {
      yield componentObserver.set('show', false);
    }).drop()
  });

  _exports.default = _default;
});