define("plantworks/models/manufacturing/plant-unit-machine-schedule", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'description': _emberData.default.attr('string'),
    'type': _emberData.default.attr('string'),
    'startDate': _emberData.default.attr('date'),
    'endDate': _emberData.default.attr('date'),
    'startTime': _emberData.default.attr('string'),
    'endTime': _emberData.default.attr('string'),
    'i18n_type': Ember.computed('type', function () {
      return this.intl.t(`plant_works_webapp_server_server.manufacturing_feature.label_${this.get('type')}`);
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true
    }),
    'tenantPlantUnitMachine': _emberData.default.belongsTo('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'schedules'
    })
  });

  _exports.default = _default;
});