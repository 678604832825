define("plantworks/components/manufacturing/configure/plant-unit-line-editor/upstream-sub-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency", "ember-cli-uuid"], function (_exports, _baseComponent, _emberConcurrency, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'p-0', 'layout-column', 'layout-align-start-stretch'],
    'categorizedEntityView': 'multi-table',
    'selectedTabCategory': 'machine',
    'blockTypes': null,

    init() {
      this._super(...arguments);

      this.set('blockTypes', ['block_some', 'block_any']);
    },

    'addObservedEntity': (0, _emberConcurrency.task)(function* (entityTypeLabel) {
      const line = this.get('model');
      const allInputConstituents = yield line.get('inputConstituents');
      const allEntities = [];
      const alreadyAddedEntities = [];
      Object.keys(allInputConstituents).forEach(entityType => {
        if (entityType !== 'block') {
          allInputConstituents[entityType].forEach(entity => {
            alreadyAddedEntities.push(entity.get('actualEntity.id'));
          });
        } else {
          allInputConstituents[entityType].forEach(entity => {
            entity.get('blockEntities').forEach(blockEntity => {
              alreadyAddedEntities.push(blockEntity.get('actualEntity.id'));
            });
          });
        }
      });
      const plants = yield this.get('store').findAll('manufacturing/plant', {
        'reload': true
      });

      for (let idx = 0; idx < plants.length; idx++) {
        const plant = plants.objectAt(idx);
        const plantUnits = yield plant.get('units');

        for (let jdx = 0; jdx < plantUnits.length; jdx++) {
          const unit = plantUnits.objectAt(jdx);
          const unitEntities = yield unit.get(`${entityTypeLabel}s`);

          for (let kdx = 0; kdx < unitEntities.length; kdx++) {
            const entity = unitEntities.objectAt(kdx);
            if (entity.get('id') === line.get('id')) continue;
            if (entity.get('isNew')) continue;
            allEntities.push(entity);
          }
        }
      }

      const availableEntities = allEntities.filter(entity => {
        return !alreadyAddedEntities.includes(entity.get('id'));
      });
      const self = this;
      let count = alreadyAddedEntities.length;
      const chooseModalModel = availableEntities.map(availableEntity => {
        count++;
        return {
          'selected': false,
          'entity': availableEntity,
          'step': count
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t(`plant_works_webapp_server_server.manufacturing_feature.label_line_add_${entityTypeLabel}`),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitLineEditor/AddConstituents',
        'componentState': {
          'model': chooseModalModel
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('addObservedEntities').perform(chooseModalModel, entityTypeLabel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deleteObservedEntity': (0, _emberConcurrency.task)(function* (observedEntity) {
      yield observedEntity.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteConstituentSucceeded': Ember.on('deleteObservedEntity:succeeded', function (taskInstance) {
      const constituent = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_constituent_delete', {
          'htmlSafe': true,
          'displayName': constituent.get('constituentType')
        })
      });
    }),
    'deleteConstituentErrored': Ember.on('deleteObservedEntity:errored', function (taskInstance, err) {
      const constituent = taskInstance.args[0];
      constituent.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addObservedEntities': (0, _emberConcurrency.task)(function* (chooseModalModel, entityType) {
      const line = this.get('model');
      const tenant = yield line.get('tenant');
      const chosenEntities = chooseModalModel.filter(chosenEntity => {
        return chosenEntity.selected && chosenEntity.step;
      });

      for (let idx = 0; idx < chosenEntities.length; idx++) {
        const chosenEntity = chosenEntities[idx];
        let observedEntity = chosenEntity.observedEntity;

        if (observedEntity) {
          if (observedEntity.get('isDirty')) {
            yield observedEntity.save();
          }

          continue;
        }

        chosenEntity.observedEntity = this.get('store').createRecord('manufacturing/plant-unit-line-constituent', {
          'tenant': tenant,
          'tenantPlantUnitLine': line,
          'constituentType': entityType,
          'step': chosenEntity.step,
          'tenantPlantUnitLineConstituentId': chosenEntity.entity.get('id')
        });
        observedEntity = chosenEntity.observedEntity;
        line.get('constituents').addObject(observedEntity);
        yield observedEntity.save();
      }
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveConstituentSucceeded': Ember.on('addObservedEntities:succeeded', function (taskInstance) {
      const constituent = taskInstance.args[0][0].observedEntity;
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_constituent_save', {
          'htmlSafe': true,
          'displayName': constituent.get('constituentType')
        })
      });
    }),
    'saveConstituentErrored': Ember.on('addObservedEntities:errored', function (taskInstance, err) {
      let constituent;
      taskInstance.args[0].forEach(arg => {
        if (arg.observedEntity) constituent = arg.observedEntity;
      });
      constituent.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'createBlock': (0, _emberConcurrency.task)(function* () {
      const line = this.get('model');
      const tenant = yield line.get('tenant');
      const newBlock = this.get('store').createRecord('manufacturing/plant-unit-line-constituent', {
        'tenant': tenant,
        'tenantPlantUnitLine': line,
        'constituentType': 'block_some',
        'step': '',
        'tenantPlantUnitLineConstituentId': (0, _emberCliUuid.uuid)()
      });
      newBlock.set('isEditing', true);
    }).drop()
  });

  _exports.default = _default;
});