define("plantworks/components/tenant-selector", ["exports", "plantworks/framework/base-component", "ember-computed-style", "plantworks/config/environment", "ember-concurrency"], function (_exports, _baseComponent, _emberComputedStyle, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    attributeBindings: ['style'],
    'allowedTenants': null,
    'selectedTenant': null,
    'style': (0, _emberComputedStyle.default)('display'),
    'display': Ember.computed('allowedTenants', function () {
      return {
        'display': this.get('allowedTenants') && this.get('allowedTenants.length') > 1 ? 'block' : 'none'
      };
    }),

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('currentUser').on('userDataUpdated', this, 'updateAllowedTenants');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated');
    },

    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.updateAllowedTenants();
    }).on('willInsertElement').keepLatest(),

    changeSelectedTenant(newSubDomain) {
      this.set('selectedTenant', newSubDomain);
    },

    'onSelectedTenantChange': Ember.observer('selectedTenant', function () {
      this.get('_changeSubdomain').perform();
    }),

    updateAllowedTenants() {
      if (!(this.get('currentUser') && this.get('currentUser').get('userData') && this.get('currentUser').get('userData').get('otherTenants'))) return;
      let otherTenants = this.get('currentUser').get('userData').get('otherTenants');
      this.set('allowedTenants', otherTenants);
      this.set('selectedTenant', otherTenants.filter(allowedTenant => {
        return allowedTenant.tenant_id === this.get('currentUser').get('userData').get('tenant_id');
      })[0]);
    },

    '_changeSubdomain': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedTenant')) return;
      let currentSubDomain = (window.location.hostname || '').replace(_environment.default.plantworks.domain, '');
      if (currentSubDomain[currentSubDomain.length - 1] === '.') currentSubDomain = currentSubDomain.slice(0, -1);
      const newSubDomain = this.get('selectedTenant.sub_domain');

      if (!newSubDomain || newSubDomain === '' || newSubDomain === currentSubDomain || newSubDomain === 'www' && currentSubDomain === 'localhost') {
        return;
      }

      if (currentSubDomain === '' || currentSubDomain === window.location.hostname) {
        window.location.hostname = `${newSubDomain}.${window.location.hostname}`;
        return;
      }

      if (_environment.default.IS_CORBER) {
        window.PlantWorksApp.newSubDomain = newSubDomain;
        window.location.hostname = "".concat(newSubDomain, ".");
      } else {
        let newHref = window.location.href.replace(currentSubDomain, newSubDomain);

        if (window.location.pathname == '/') {
          newHref = newHref.slice(0, -1);
        } else {
          newHref = newHref.replace(window.location.pathname, '');
        }

        window.location.href = newHref;
      }

      yield;
    }).keepLatest()
  });

  _exports.default = _default;
});