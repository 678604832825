define("plantworks/models/pug/group-manager/tenant-group", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'displayName': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'defaultForNewUser': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'tenantGroups'
    }),
    'parent': _emberData.default.belongsTo('pug/group-manager/tenant-group', {
      'async': true,
      'inverse': 'groups'
    }),
    'groups': _emberData.default.hasMany('pug/group-manager/tenant-group', {
      'async': true,
      'inverse': 'parent'
    }),
    'tenantUserGroups': _emberData.default.hasMany('pug/group-manager/tenant-user-group', {
      'async': true,
      'inverse': 'tenantGroup'
    }),
    'permissions': _emberData.default.hasMany('pug/group-manager/tenant-group-permission', {
      'async': true,
      'inverse': 'tenantGroup'
    }),
    'isProtected': Ember.computed('defaultForNewUser', 'groups.@each.isProtected', function () {
      let isDefaultForNewUser = this.get('defaultForNewUser');
      if (isDefaultForNewUser) return isDefaultForNewUser;
      this.get('groups').forEach(subGroup => {
        isDefaultForNewUser = isDefaultForNewUser || subGroup.get('isProtected');
      });
      return isDefaultForNewUser;
    }),
    'onDisplayNameChanged': Ember.on('didLoad', Ember.observer('displayName', function () {
      if (!this.get('isNew')) return;
      this.set('name', this.get('displayName').dasherize().toLowerCase());
    }))
  });

  _exports.default = _default;
});