define("plantworks/services/ajax", ["exports", "ember-ajax/services/ajax", "plantworks/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    [_environment.default.ajaxExportKey]: Ember.computed('env.plantworks.domain', function () {
      let currentSubDomain = window.location.hostname.replace(_environment.default.plantworks.domain, '');
      if (currentSubDomain[currentSubDomain.length - 1] === '.') currentSubDomain = currentSubDomain.slice(0, -1);

      if (currentSubDomain === window.location.hostname) {
        currentSubDomain = '';
      }

      let domainFields = ['//'];

      if (_environment.default.IS_CORBER) {
        domainFields = ['https://'];

        if (window.PlantWorksApp.newSubDomain) {
          domainFields.push(`${window.PlantWorksApp.newSubDomain}.`);
        }
      }

      if (currentSubDomain !== '') domainFields.push(`${currentSubDomain}.`);
      domainFields.push(_environment.default.plantworks.domain);

      if (window.location.port !== '' && (window.location.protocol === 'http:' && window.location.port !== '80' || window.location.protocol === 'https:' && window.location.port !== '443')) {
        domainFields.push(`:${window.location.port}`);
      }

      return domainFields.join('');
    })
  });

  _exports.default = _default;
});