define("plantworks/helpers/plant-works-power-select-true-string-if-present", ["exports", "pworks-dsl/helpers/plant-works-power-select-true-string-if-present"], function (_exports, _plantWorksPowerSelectTrueStringIfPresent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectTrueStringIfPresent.default;
    }
  });
  Object.defineProperty(_exports, "emberPowerSelectTrueStringIfPresent", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectTrueStringIfPresent.emberPowerSelectTrueStringIfPresent;
    }
  });
});