define("plantworks/components/manufacturing/configure/plant-editor", ["exports", "plantworks/framework/base-component", "jquery", "plantworks/config/environment", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,
    'allLocations': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'manufacturing-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    'onModelOrLocationChange': Ember.observer('model', 'model.tenantLocation', function () {
      this.get('_setLocationStaticUrl').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('manufacturing-create');
      const updatePerm = this.get('currentUser').hasPermission('manufacturing-update');
      const deletePerm = this.get('currentUser').hasPermission('manufacturing-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('model.tenant');
      let tenantLocations = this.get('store').peekAll('settings/account/basics/tenant-location');
      this.set('allLocations', tenantLocations);
      this.get('_setLocationStaticUrl').perform();
    }).drop().on('willInsertElement'),
    'savePlant': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantSucceeded': Ember.on('savePlant:succeeded', function () {
      const plant = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', plant.get('id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_save', {
          'htmlSafe': true,
          'displayName': plant.get('name')
        })
      });
    }),
    'savePlantErrored': Ember.on('savePlant:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelPlant': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deletePlant': (0, _emberConcurrency.task)(function* () {
      const plantName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_message', {
          'htmlSafe': true,
          'displayName': plantName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlant').perform(plantName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlant': (0, _emberConcurrency.task)(function* (plantName, tenantFolderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantSucceeded': Ember.on('_confirmedDeletePlant:succeeded', function (taskInstance) {
      const plantName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_delete', {
          'htmlSafe': true,
          'displayName': plantName
        })
      });
    }),
    '_confirmedDeletePlantErrored': Ember.on('_confirmedDeletePlant:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addPlantUnit': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnit = this.get('store').createRecord('manufacturing/plant-unit', {
        'tenant': tenant,
        'plant': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_unit_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnit.set('name', displayName);
      newPlantUnit.set('description', displayName);
      const siblingUnits = yield this.get('model.units');
      siblingUnits.addObject(newPlantUnit);
      this.get('editPlantUnit').perform(newPlantUnit);
    }).enqueue(),
    'editPlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      yield unit.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      unit.set('isEditing', false);
      yield unit.rollback();
    }).enqueue(),
    'savePlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      unit.set('isEditing', false);
      yield unit.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitSucceeded': Ember.on('savePlantUnit:succeeded', function (taskInstance) {
      const unit = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_save', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    'savePlantUnitErrored': Ember.on('savePlantUnit:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      const unitName = yield unit.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_unit'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_unit_message', {
          'htmlSafe': true,
          'displayName': unitName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnit').perform(unit);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnit': (0, _emberConcurrency.task)(function* (unit) {
      yield unit.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitSucceeded': Ember.on('_confirmedDeletePlantUnit:succeeded', function (taskInstance) {
      const unit = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_delete', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    '_confirmedDeletePlantUnitErrored': Ember.on('_confirmedDeletePlantUnit:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedPlantUnit': function (selectedPlantUnit) {
      if (selectedPlantUnit.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedPlantUnit.get('id'), false, false);
    },
    '_setLocationStaticUrl': (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1500);
      const plant = this.get('model');
      if (!plant) return;
      const location = yield this.get('model.tenantLocation');
      if (!location) return;
      const staticLocationDisplayDiv = document.getElementById('manufacturing-plant-location-display');
      if (!staticLocationDisplayDiv) return;
      const mapWidth = staticLocationDisplayDiv.clientWidth;
      const mapHeight = staticLocationDisplayDiv.clientHeight;
      const mapParameters = {
        'lat': 0,
        'lng': 0,
        'key': _environment.default['ember-google-maps']['key']
      };
      mapParameters.lat = location.get('latitude');
      mapParameters.lng = location.get('longitude');
      location.set('staticUrl', `//maps.googleapis.com/maps/api/staticmap?center=${mapParameters.lat},${mapParameters.lng}&size=${mapWidth}x${mapHeight}&maptype=roadmap&markers=color:blue%7Clabel:S%7C${mapParameters.lat},${mapParameters.lng}&key=${mapParameters.key}`);
    }).keepLatest()
  });

  _exports.default = _default;
});