define("plantworks/components/report/configure/upstream-sub-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'p-0', 'layout-column', 'layout-align-start-stretch'],
    'categorizedEntityView': 'multi-table',
    'selectedTabCategory': 'machine',
    'addObservedEntity': (0, _emberConcurrency.task)(function* (entityTypeLabel) {
      const report = this.get('model');
      const reportconstituents = yield report.get('constituents');
      const allEntities = [];
      const alreadyAddedEntities = [];

      for (let idx = 0; idx < reportconstituents.length; idx++) {
        const reportConstituent = reportconstituents.objectAt(idx);
        if (reportConstituent.get('tenantReportConstituentType') !== entityTypeLabel) continue;
        alreadyAddedEntities.push(reportConstituent.get('tenantReportConstituentId'));
      }

      if (entityTypeLabel !== 'report') {
        const plants = yield this.get('store').findAll('manufacturing/plant', {
          'reload': true
        });

        for (let idx = 0; idx < plants.length; idx++) {
          const plant = plants.objectAt(idx);
          const plantUnits = yield plant.get('units');

          for (let jdx = 0; jdx < plantUnits.length; jdx++) {
            const unit = plantUnits.objectAt(jdx);
            const unitEntities = yield unit.get(`${entityTypeLabel}s`);

            for (let kdx = 0; kdx < unitEntities.length; kdx++) {
              const entity = unitEntities.objectAt(kdx);
              if (entity.get('isNew')) continue;
              allEntities.push(entity);
            }
          }
        }
      } else {
        const reports = yield this.get('store').findAll('report/report', {
          'reload': true
        });

        for (let idx = 0; idx < reports.length; idx++) {
          const report = reports.objectAt(idx);
          if (report.get('isNew')) continue;
          if (report.get('id') === this.get('model.id')) continue;
          allEntities.push(report);
        }
      }

      const availableEntities = allEntities.filter(entity => {
        return !alreadyAddedEntities.includes(entity.get('id'));
      });
      const self = this;
      const chooseModalModel = availableEntities.map(availableEntity => {
        return {
          'selected': false,
          'entity': availableEntity
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t(`plant_works_webapp_server_server.report_feature.label_report_add_${entityTypeLabel}`),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Report/Configure/AddEntity',
        'componentState': {
          'model': chooseModalModel
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('addObservedEntities').perform(chooseModalModel, entityTypeLabel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deleteObservedEntity': (0, _emberConcurrency.task)(function* (observedEntity) {
      yield observedEntity.destroyRecord();
    }).enqueue().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'addObservedEntities': (0, _emberConcurrency.task)(function* (chooseModalModel, entityType) {
      const report = this.get('model');
      const tenant = yield report.get('tenant');
      const chosenEntities = chooseModalModel.filter(chosenEntity => {
        return chosenEntity.selected;
      });

      for (let idx = 0; idx < chosenEntities.length; idx++) {
        const chosenEntity = chosenEntities[idx];
        let observedEntity = chosenEntity.observedEntity;

        if (observedEntity) {
          if (observedEntity.get('isDirty')) {
            yield observedEntity.save();
          }

          continue;
        }

        chosenEntity.observedEntity = this.get('store').createRecord('report/report-constituent', {
          'tenant': tenant,
          'tenantReport': report,
          'tenantReportConstituentType': entityType,
          'tenantReportConstituentId': chosenEntity.entity.get('id')
        });
        observedEntity = chosenEntity.observedEntity;
        report.get('constituents').addObject(observedEntity);
        yield observedEntity.save();
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});