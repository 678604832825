define("plantworks/components/dashboard/notification-area", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'iconType': 'mdi',
    'icon': 'view-dashboard',
    'routeName': 'dashboard',
    'userFeatures': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    didInsertElement() {
      if (!this.get('currentUser').isLoggedIn()) return;
      this.get('_refreshDashboardFeatures').perform();
    },

    onUserDataUpdated() {
      if (!this.get('currentUser').isLoggedIn()) return;
      this.get('_refreshDashboardFeatures').perform();
    },

    'displayText': Ember.computed('intl.locale', 'userFeatures.[]', function () {
      const features = this.get('userFeatures');
      if (!features.get('length')) return '';

      if (features.get('length') === 1) {
        const onlyFeature = features.objectAt(0);
        return onlyFeature.get('i18n_name');
      }

      return this.intl.t('plant_works_webapp_server_server.dashboard_feature.title');
    }),
    'onDashBoardFeatureChange': Ember.observer('userFeatures.[]', function () {
      const features = this.get('userFeatures');
      if (!features.get('length')) return;

      if (features.get('length') === 1) {
        const onlyFeature = features.objectAt(0);
        this.set('iconType', onlyFeature.get('iconType'));
        this.set('icon', onlyFeature.get('iconPath'));
        this.set('routeName', onlyFeature.get('route'));
        return;
      }

      this.set('iconType', 'mdi');
      this.set('icon', 'view-dashboard');
      this.set('routeName', 'dashboard');
    }),
    '_refreshDashboardFeatures': (0, _emberConcurrency.task)(function* () {
      yield this.get('store').unloadAll('dashboard/feature');
      const features = yield this.get('store').findAll('dashboard/feature', {
        'reload': true
      });
      this.set('userFeatures', features);
    }).enqueue()
  });

  _exports.default = _default;
});