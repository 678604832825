define("plantworks/components/pug/group-manager/sub-group-editor-component", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'editable': false,
    'observerInitialized': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'group-manager-read');
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('group-manager-update');
      this.set('editable', updatePerm);
    }),
    '_initializeObservers': (0, _emberConcurrency.task)(function* () {
      const newGroup = this.get('store').createRecord('pug/group-manager/tenant-group', {
        'tenant': this.get('model'),
        'parent': this.get('selectedGroup')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.new_subgroup_name', {
        'now': window.moment().valueOf()
      });
      newGroup.set('displayName', displayName);
      newGroup.set('description', displayName);
      const siblingGroups = yield this.get('selectedGroup.groups');
      const tenantGroups = yield this.get('model.tenantGroups');
      siblingGroups.addObject(newGroup);
      tenantGroups.addObject(newGroup);
      siblingGroups.removeObject(newGroup);
      tenantGroups.removeObject(newGroup);
      newGroup.deleteRecord();
    }).drop(),
    'changeDefaultForNewUser': (0, _emberConcurrency.task)(function* (subGroup) {
      const loadedGroups = this.get('store').peekAll('pug/group-manager/tenant-group');
      let oldDefaultGroup = null;
      loadedGroups.forEach(tenantGroup => {
        if (tenantGroup.get('id') === subGroup.get('id')) return;
        if (!tenantGroup.get('defaultForNewUser')) return;
        oldDefaultGroup = tenantGroup;
        oldDefaultGroup.set('defaultForNewUser', false);
      });
      if (oldDefaultGroup) yield oldDefaultGroup.save();
      subGroup.set('defaultForNewUser', true);
      yield subGroup.save();
      if (oldDefaultGroup) yield oldDefaultGroup.reload();
    }).keepLatest().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'changeDefaultForNewUserSucceeded': Ember.on('changeDefaultForNewUser:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.succesful_save', {
          'htmlSafe': true,
          'displayName': taskInstance.args[0].get('displayName')
        })
      });
    }),
    'changeDefaultForNewUserErrored': Ember.on('changeDefaultForNewUser:errored', function (taskInstance, err) {
      taskInstance.args[0].rollback();
      taskInstance.args[0].reload();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addGroup': (0, _emberConcurrency.task)(function* () {
      if (!this.get('observerInitialized')) {
        yield this.get('_initializeObservers').perform();
        this.set('observerInitialized', true);
      }

      const newGroup = this.get('store').createRecord('pug/group-manager/tenant-group', {
        'tenant': this.get('model'),
        'parent': this.get('selectedGroup')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.new_subgroup_name', {
        'now': window.moment().valueOf()
      });
      newGroup.set('displayName', displayName);
      newGroup.set('description', displayName);
      const siblingGroups = yield this.get('selectedGroup.groups');
      siblingGroups.addObject(newGroup);
      const tenantGroups = yield this.get('model.tenantGroups');
      tenantGroups.addObject(newGroup);
    }).drop(),
    'saveGroup': (0, _emberConcurrency.task)(function* (subGroup) {
      yield subGroup.save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveGroupSucceeded': Ember.on('saveGroup:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.succesful_save', {
          'htmlSafe': true,
          'displayName': taskInstance.args[0].get('displayName')
        })
      });
    }),
    'saveGroupErrored': Ember.on('saveGroup:errored', function (taskInstance, err) {
      const subGroup = taskInstance.args[0];
      subGroup.rollback();
      if (!subGroup.get('isNew')) subGroup.reload();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteGroup': (0, _emberConcurrency.task)(function* (subGroup) {
      if (!this.get('observerInitialized')) {
        yield this.get('_initializeObservers').perform();
        this.set('observerInitialized', true);
      }

      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.label_delete_group'),
          'iconType': 'md',
          'icon': 'group'
        },
        'content': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.delete_group_message', {
          'htmlSafe': true,
          'displayName': subGroup.get('displayName')
        }),
        'confirmButton': {
          'text': this.intl.t('modal.default_delete_text'),
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteGroup').perform(subGroup);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteGroup': (0, _emberConcurrency.task)(function* (subGroup) {
      const parentGroup = yield subGroup.get('parent');
      const groupSiblings = yield parentGroup.get('groups');
      groupSiblings.removeObject(subGroup);
      const tenantGroups = yield this.get('model.tenantGroups');
      tenantGroups.removeObject(subGroup);
      if (subGroup.get('isNew')) subGroup.deleteRecord();else yield subGroup.destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteGroupSucceeded': Ember.on('_confirmedDeleteGroup:succeeded', function (taskInstance) {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.pug_feature.group_manager_feature.succesful_delete', {
          'htmlSafe': true,
          'displayName': taskInstance.args[0].get('displayName')
        })
      });
    }),
    '_confirmedDeleteGroupErrored': Ember.on('_confirmedDeleteGroup:errored', function (taskInstance, err) {
      const subGroup = taskInstance.args[0];
      subGroup.rollback();
      subGroup.reload();
      const parentGroup = subGroup.get('parent');
      const groupSiblings = parentGroup.get('groups');
      groupSiblings.addObject(subGroup);
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});