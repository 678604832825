define("plantworks/components/alert/devenv/response-formatter-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    selectedResponseFormatterType: null,

    init() {
      this._super(...arguments);
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const responseFormatterTypes = yield this.get('ajax').request('/masterdata/alert-response-formatter-types', {
          'method': 'GET'
        });
        this.setProperties({
          'responseFormatterTypes': responseFormatterTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'selectResponseFormatterType': (0, _emberConcurrency.task)(function* (selectionType) {
      this.set('selectedResponseFormatterType', selectionType);
      yield;
    }),
    'resetResponseFormatterType': function () {
      this.set('selectedResponseFormatterType', null);
    }
  });

  _exports.default = _default;
});