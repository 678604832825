define("plantworks/models/manufacturing/plant-unit-station-observed-machine", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitStation': _emberData.default.belongsTo('manufacturing/plant-unit-station', {
      'async': true,
      'inverse': 'observedMachines'
    }),
    'tenantPlantUnitMachine': _emberData.default.belongsTo('manufacturing/plant-unit-machine', {
      'async': true,
      'inverse': 'linkedStations'
    }),
    'templates': _emberData.default.hasMany('manufacturing/plant-unit-station-observed-machine-template', {
      'async': true,
      'inverse': 'tenantPlantUnitStationObservedMachine'
    })
  });

  _exports.default = _default;
});