define("plantworks/routes/work-order-app", ["exports", "plantworks/framework/base-route", "ember-concurrency"], function (_exports, _baseRoute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    init() {
      this._super(...arguments);

      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    model(param) {
      return param;
    },

    onUserDataUpdated() {
      if (!window.plantworksUserId) {
        this.get('store').unloadAll('work-order/work-order-format');
      }

      const isActive = this.get('router').get('currentRouteName') && this.get('router').get('currentRouteName').includes(this.get('fullRouteName'));
      if (!isActive) return;

      if (!window.plantworksUserId) {
        this.transitionTo('index');
        return;
      }

      this.get('_refreshTenantFeatureWoApp').perform();
    },

    '_refreshTenantFeatureWoApp': (0, _emberConcurrency.task)(function* () {
      let tenantFeatureWoApp = yield this.get('store').findAll('work-order/node');
      this.get('controller').set('model', tenantFeatureWoApp);
    }).keepLatest()
  });

  _exports.default = _default;
});