define("plantworks/initializers/pug/group-manager/add-tenant-group-perm-to-feature", ["exports", "ember-data", "plantworks/models/server-administration/feature"], function (_exports, _emberData, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _feature.default.reopen({
      'tenantGroupPermissions': _emberData.default.hasMany('pug/group-manager/tenant-group-permission', {
        'async': true,
        'inverse': 'feature'
      })
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});