define("plantworks/components/emd/uploader/file-preview", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'emd-create');
    },

    'uploadParsedData': (0, _emberConcurrency.task)(function* () {
      const approvedData = this.get('parsedData');
      if (!approvedData || !approvedData.length) yield null;else yield this.get('ajax').request('/emd/commitData/' + this.get('model.id'), {
        'method': 'POST',
        'data': {
          'rows': approvedData.filter(row => {
            return row.approved;
          })
        },
        'contentType': 'application/json; charset=utf-8'
      });
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'uploadParsedDataSucceeded': Ember.on('uploadParsedData:succeeded', function () {
      this.invokeAction('controller-action', 'setParsedData', null);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.emd_feature.succesful_emd_file_upload', {
          'htmlSafe': true,
          'displayName': this.get('model.name')
        })
      });
    }),
    'uploadParsedDataErrored': Ember.on('uploadParsedData:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelParsedData': (0, _emberConcurrency.task)(function* () {
      this.invokeAction('controller-action', 'setParsedData', null);
      yield null;
    }).drop()
  });

  _exports.default = _default;
});