define("plantworks/components/plant-works-power-select-multiple", ["exports", "pworks-dsl/components/plant-works-power-select-multiple"], function (_exports, _plantWorksPowerSelectMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectMultiple.default;
    }
  });
});