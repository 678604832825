define("plantworks/components/profile/basics-manager", ["exports", "plantworks/framework/base-component", "jquery", "plantworks/config/environment", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    '_profileImageElem': null,
    'isCorber': _environment.default.IS_CORBER,
    'availableApps': null,
    'selectedApp': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('_getAvailableApps').perform();
    },

    '_getAvailableApps': (0, _emberConcurrency.task)(function* () {
      try {
        const apps = yield this.get('ajax').request('/profile/default-app-option-list', {
          'method': 'GET'
        });
        let validFeatures = [];
        Object.entries(apps).forEach(([key, value]) => {
          const property = {
            'groupName': null,
            'featureId': null,
            'route': null,
            'options': []
          };
          property['groupName'] = key;
          property['featureId'] = value.feature_id;
          property['route'] = value.route ? value.route : undefined;
          value.attributes.forEach(val => {
            property['options'].push(val);
          });
          validFeatures.push(property);
        });
        this.set('availableApps', validFeatures);
      } catch (error) {
        this.get('notification').display({
          'type': 'error',
          'error': error
        });
      }
    }),
    'onSelectedAppChange': Ember.observer('selectedApp', function () {
      const choosenFeature = this.get('availableApps').filter(item => {
        if (item.options.filter(element => {
          if (element.id === this.get('selectedApp.id')) {
            return element;
          }

          return null;
        }).length > 0) {
          return item;
        }

        return null;
      });
      this.set('model.defaultApplicationParameters', this.get('selectedApp'));
      this.set('model.defaultApplicationParameters.route', choosenFeature[0].route ? choosenFeature[0].route : undefined);
      this.set('model.defaultApplication', {
        'name': choosenFeature[0].groupName,
        'id': choosenFeature[0].featureId
      });
    }),
    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      try {
        this.set('_profileImageElem', (0, _jquery.default)('div#profile-basic-information-image'));
        let getImageUrl, croppieDimensions, subDomain;

        if (_environment.default.IS_CORBER) {
          subDomain = this.get('currentUser').getSubDomain();
          if (subDomain != null && subDomain != undefined) getImageUrl = `https://${subDomain}.${_environment.default.plantworks.domain}/profile/get-image?_random=`;else getImageUrl = `https://${_environment.default.plantworks.domain}/profile/get-image?_random=`;
        } else {
          getImageUrl = '/profile/get-image?_random=';
        }

        const profileImageElem = this.get('_profileImageElem');
        if (_environment.default.IS_CORBER) croppieDimensions = profileImageElem.width() < profileImageElem.height() ? profileImageElem.height() : profileImageElem.width();else croppieDimensions = profileImageElem.width() < profileImageElem.height() ? profileImageElem.width() : profileImageElem.height();
        profileImageElem.croppie({
          'boundary': {
            'width': croppieDimensions,
            'height': croppieDimensions
          },
          'viewport': {
            'width': croppieDimensions,
            'height': croppieDimensions,
            'type': 'circle'
          },
          'showZoomer': true,
          'useCanvas': true,
          'update': this.get('_processCroppieUpdate').bind(this)
        });
        yield profileImageElem.croppie('bind', {
          'url': getImageUrl + window.moment().valueOf(),
          'orientation': 1
        }); // Add an event handler for catching dropped images

        document.getElementById('profile-basic-information-image').addEventListener('drop', this._processDroppedImage.bind(this));
        document.getElementsByClassName('cr-boundary')[0].setAttribute('contenteditable', true);
        document.getElementsByClassName('cr-slider-wrap')[0].setAttribute('contenteditable', false);
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      } finally {
        this.set('_enableCroppieUpdates', true);
      }
    }).drop().on('didInsertElement'),
    'onWillDestroyElement': (0, _emberConcurrency.task)(function* () {
      document.getElementById('profile-basic-information-image').removeEventListener('drop', this._processDroppedImage.bind(this));
      yield this.get('_profileImageElem').croppie('destroy');
    }).drop().on('willDestroyElement'),

    _processDroppedImage(event) {
      event.stopPropagation();
      event.preventDefault();
      const imageFile = event.dataTransfer.files[0];
      if (!imageFile.type.match('image.*')) return;
      const imageReader = new FileReader();
      const profileImageElem = this.get('_profileImageElem');

      imageReader.onload = imageData => {
        profileImageElem.croppie('bind', {
          'url': imageData.target.result,
          'orientation': 1
        });
      };

      imageReader.readAsDataURL(imageFile);
    },

    _processCroppieUpdate() {
      if (!this.get('_enableCroppieUpdates')) return;

      if (this.get('_profileImageUploadTimeout')) {
        this.cancelTask(this.get('_profileImageUploadTimeout'));
        this.set('_profileImageUploadTimeout', null);
      }

      this.set('_profileImageUploadTimeout', this.runTask(() => {
        this.get('_uploadProfileImage').perform();
      }, 10000));
    },

    '_uploadProfileImage': (0, _emberConcurrency.task)(function* () {
      try {
        this.set('_enableCroppieUpdates', false);
        let getImageUrl;
        let uploadImageUrl;

        if (_environment.default.IS_CORBER) {
          getImageUrl = `https://${_environment.default.plantworks.domain}/profile/get-image?_random=`;
          uploadImageUrl = `https://${_environment.default.plantworks.domain}/profile/upload-image`;
        } else {
          getImageUrl = '/profile/get-image?_random=';
          uploadImageUrl = '/profile/upload-image';
        }

        const profileImageElem = this.get('_profileImageElem');
        const metadata = profileImageElem.croppie('get');
        const imageData = yield profileImageElem.croppie('result', {
          'type': 'base64',
          'circle': true
        });
        yield this.get('ajax').post(uploadImageUrl, {
          'dataType': 'json',
          'data': {
            'image': imageData,
            'metadata': metadata
          }
        });
        window.PlantWorksApp.trigger('userChanged');
        yield profileImageElem.croppie('bind', {
          'url': getImageUrl + window.moment().valueOf(),
          'orientation': 1
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      } finally {
        this.set('_enableCroppieUpdates', true);
        this.set('_profileImageUploadTimeout', null);
      }
    }).keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'save': (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('model').save();
        this.get('notification').display({
          'type': 'success',
          'message': this.intl.t('plant_works_webapp_server_server.profile_feature.main_component.succesful_save')
        });
        window.PlantWorksApp.trigger('userChanged');
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'cancel': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop()
  });

  _exports.default = _default;
});