define("plantworks/components/settings/account/webhooks/webhooks", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'ajax': Ember.inject.service('ajax'),
    'classNames': ['w-100'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    'createable': false,
    'editable': false,
    'deleteable': false,
    'eventsMeta': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-webhooks-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_webhookEventsData').perform();
      this.get('_updateUserPermissions').perform();
    }),
    '_webhookEventsData': (0, _emberConcurrency.task)(function* () {
      let eventsData = yield this.get('store').findAll('masterdata/event-type');
      eventsData = eventsData.filter(e => {
        return e.get('tag') !== 'scheduled_report';
      });
      eventsData = eventsData.map(e => {
        return e.get('tag');
      });
      this.set('eventsMeta', eventsData);
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('settings-account-webhooks-create');
      const updatePerm = this.get('currentUser').hasPermission('settings-account-webhooks-update');
      const deletePerm = this.get('currentUser').hasPermission('settings-account-webhooks-delete');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
    }).keepLatest(),
    'addWebhook': (0, _emberConcurrency.task)(function* () {
      const newWebhook = this.get('store').createRecord('settings/account/webhooks/tenant-webhook', {
        'tenant': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.webhooks.new_webhook_name', {
        'now': window.moment().valueOf()
      });
      newWebhook.set('name', displayName);
      newWebhook.set('description', displayName);
      newWebhook.set('url', displayName);
      this.get('editWebhook').perform(newWebhook);
    }).enqueue(),
    'editWebhook': (0, _emberConcurrency.task)(function* (webhook) {
      yield webhook.set('isEditing', true);
    }).enqueue(),
    'cancelWebhook': (0, _emberConcurrency.task)(function* (webhook) {
      webhook.set('isEditing', false);
      yield webhook.rollback();
    }).enqueue(),
    'saveWebhook': (0, _emberConcurrency.task)(function* (webhook) {
      webhook.set('isEditing', false);
      yield webhook.save();
      yield webhook.reload();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveWebhookSucceeded': Ember.on('saveWebhook:succeeded', function (taskInstance) {
      const webhook = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.webhooks.succesful_webhook_save', {
          'htmlSafe': true,
          'displayName': webhook.get('name')
        })
      });
    }),
    'saveWebhookErrored': Ember.on('saveWebhook:errored', function (taskInstance, err) {
      const webhook = taskInstance.args[0];
      webhook.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteWebhook': (0, _emberConcurrency.task)(function* (webhook) {
      const webhookName = webhook.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.webhooks.label_delete_webhook'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.webhooks.delete_webhook_message', {
          'htmlSafe': true,
          'displayName': webhookName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteWebhook').perform(webhook, webhookName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteWebhook': (0, _emberConcurrency.task)(function* (webhook, webhookName) {
      // eslint-disable-line no-unused-vars
      yield webhook.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteWebhookSucceeded': Ember.on('_confirmedDeleteWebhook:succeeded', function (taskInstance) {
      const webhookName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.webhooks.succesful_webhook_delete', {
          'htmlSafe': true,
          'displayName': webhookName
        })
      });
    }),
    '_confirmedDeleteWebhookErrored': Ember.on('_confirmedDeleteWebhook:errored', function (taskInstance, err) {
      const webhook = taskInstance.args[0];
      webhook.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'testWebhook': (0, _emberConcurrency.task)(function* (webhook) {
      try {
        const axiosData = {};
        axiosData['url'] = webhook.get('url');

        if (webhook.get('headers').length) {
          const headers = {};
          webhook.get('headers').forEach(header => {
            headers[header.name] = header.value;
          });
          axiosData['headers'] = headers;
        }

        const testResult = yield this.get('ajax').request('/settings/account/webhooks/test-webhook', {
          'method': 'POST',
          'data': {
            'requestData': JSON.stringify(axiosData)
          }
        });
        this.get('notification').display({
          'type': 'success',
          'message': testResult.body
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    })
  });

  _exports.default = _default;
});