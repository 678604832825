define("plantworks/components/manufacturing/configure/plant-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'manufacturing-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const parentModel = yield this.get('model.parent');
      const createPerm = this.get('currentUser').hasPermission('manufacturing-create') && parentModel && !!parentModel.get('name');
      const updatePerm = this.get('currentUser').hasPermission('manufacturing-update') && parentModel && !!parentModel.get('name');
      const deletePerm = this.get('currentUser').hasPermission('manufacturing-delete') && parentModel && !!parentModel.get('name');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
    }).keepLatest(),
    'addPlant': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlant = this.get('store').createRecord('manufacturing/plant', {
        'tenant': tenant,
        'parent': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_name', {
        'now': window.moment().valueOf()
      });
      newPlant.set('name', displayName);
      newPlant.set('description', displayName);
      const siblingPlants = yield this.get('model.plants');
      siblingPlants.addObject(newPlant);
      this.get('editPlant').perform(newPlant);
    }).enqueue(),
    'editPlant': (0, _emberConcurrency.task)(function* (plant) {
      yield plant.set('isEditing', true);
    }).enqueue(),
    'cancelPlant': (0, _emberConcurrency.task)(function* (plant) {
      plant.set('isEditing', false);
      yield plant.rollback();
    }).enqueue(),
    'savePlant': (0, _emberConcurrency.task)(function* (plant) {
      plant.set('isEditing', false);
      yield plant.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantSucceeded': Ember.on('savePlant:succeeded', function (taskInstance) {
      const plant = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_save', {
          'htmlSafe': true,
          'displayName': plant.get('name')
        })
      });
    }),
    'savePlantErrored': Ember.on('savePlant:errored', function (taskInstance, err) {
      const plant = taskInstance.args[0];
      plant.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlant': (0, _emberConcurrency.task)(function* (plant) {
      const plantName = yield plant.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_message', {
          'htmlSafe': true,
          'displayName': plantName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlant').perform(plant);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlant': (0, _emberConcurrency.task)(function* (plant) {
      yield plant.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantSucceeded': Ember.on('_confirmedDeletePlant:succeeded', function (taskInstance) {
      const plant = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_delete', {
          'htmlSafe': true,
          'displayName': plant.get('name')
        })
      });
    }),
    '_confirmedDeletePlantErrored': Ember.on('_confirmedDeletePlant:errored', function (taskInstance, err) {
      const plant = taskInstance.args[0];
      plant.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedPlant': function (selectedPlant) {
      if (selectedPlant.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedPlant.get('id'), false, false);
    }
  });

  _exports.default = _default;
});