define("plantworks/models/manufacturing/plant", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'plants'
    }),
    'tenantLocation': _emberData.default.belongsTo('settings/account/basics/tenant-location', {
      'async': true,
      'inverse': 'plants'
    }),
    'folder': _emberData.default.belongsTo('manufacturing/folder', {
      'async': true,
      'inverse': 'plants'
    }),
    'units': _emberData.default.hasMany('manufacturing/plant-unit', {
      'async': true,
      'inverse': 'plant'
    }),
    'schedules': _emberData.default.hasMany('manufacturing/plant-schedule', {
      'async': true,
      'inverse': 'plant'
    })
  });

  _exports.default = _default;
});