define("plantworks/components/common/consolidated-attribute-set/observerfunc-viewer", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'toggleAttributeSetFunction': (0, _emberConcurrency.task)(function* (postFunc) {
      yield postFunc.set('show', !postFunc.get('show'));
    }).enqueue()
  });

  _exports.default = _default;
});