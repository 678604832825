define("plantworks/components/emd/configure/editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'propertyMetadataEditor': 'Emd/Configure/PropertyMetadataEditor',
    'propertyMetadataField': 'attributeSetMetadata',
    'currentTab': 'users',
    'createable': false,
    'editable': false,
    'deleteable': false,
    'sourceTypes': null,
    'dataTypes': null,
    'timeFormats': null,
    'subComponents': null,
    'selectedComponent': null,
    'operations': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'emd-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const operations = ['Merge', 'Overwrite'];
        this.set('operations', operations);
        const sourceTypes = yield this.get('ajax').request('/masterdata/attributeSourceTypes', {
          'method': 'GET'
        });
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        this.setProperties({
          'sourceTypes': sourceTypes,
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes
        });
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').drop().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('emd-create');
      const updatePerm = this.get('currentUser').hasPermission('emd-update');
      const deletePerm = this.get('currentUser').hasPermission('emd-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'addEmdConfigurationAttributeSet': (0, _emberConcurrency.task)(function* () {
      const configurationAttrSets = yield this.get('model.attributeSets');
      const alreadyUsedIds = [];

      for (let idx = 0; idx < configurationAttrSets.length; idx++) {
        const configAttrSet = configurationAttrSets.objectAt(idx);
        const attributeSet = yield configAttrSet.get('tenantAttributeSet');
        alreadyUsedIds.push(attributeSet.get('id'));
      }

      const store = this.get('store');
      const allAttributeSets = yield store.findAll('emd/attribute-set', {
        'reload': true
      });
      const availableAttrSets = allAttributeSets.filter(attributeSet => {
        return !alreadyUsedIds.includes(attributeSet.get('id'));
      }).map(attributeSet => {
        return {
          'attributeSet': attributeSet,
          'selected': false
        };
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.common.label_add_data_set'),
          'iconType': 'md',
          'icon': 'edit_attributes'
        },
        'dialogClass': 'flex',
        'componentName': 'Common/AddAttributeSet',
        'componentState': {
          'model': availableAttrSets
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            this.get('_addEmdConfigurationAttributeSets').perform(availableAttrSets);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_addEmdConfigurationAttributeSets': (0, _emberConcurrency.task)(function* (availableAttributeSets) {
      const toBeAdded = availableAttributeSets.filter(availableAttributeSet => {
        return availableAttributeSet.selected;
      });

      for (let idx = 0; idx < toBeAdded.length; idx++) {
        let configurationAttributeSet = toBeAdded[idx]['configurationAttributeSet'];

        if (!configurationAttributeSet) {
          const toBeAddedAttributeSet = toBeAdded[idx]['attributeSet'];
          configurationAttributeSet = this.get('store').createRecord('emd/configuration-attribute-set', {
            'tenant': this.get('model.tenant'),
            'tenantAttributeSet': toBeAddedAttributeSet,
            'tenantEmdConfiguration': this.get('model')
          });
          toBeAdded[idx]['configurationAttributeSet'] = configurationAttributeSet;
        }

        if (configurationAttributeSet.get('isNew')) {
          yield configurationAttributeSet.save();
          this.get('model.attributeSets').addObject(configurationAttributeSet);
        }
      }
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_addEmdConfigurationAttributeSetsErrored': Ember.on('_addEmdConfigurationAttributeSets:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'saveEmdConfiguration': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveEmdConfigurationSucceeded': Ember.on('saveEmdConfiguration:succeeded', function () {
      const attrSet = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', attrSet.get('id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.emd_feature.succesful_emd_configuration_save', {
          'htmlSafe': true,
          'displayName': attrSet.get('name')
        })
      });
    }),
    'saveEmdConfigurationErrored': Ember.on('saveEmdConfiguration:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancelEmdConfiguration': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteEmdConfiguration': (0, _emberConcurrency.task)(function* () {
      const emdConfigurationName = yield this.get('model.name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.emd_feature.label_delete_emd_configuration'),
          'iconType': 'md',
          'icon': 'edit-attributes'
        },
        'content': this.intl.t('plant_works_webapp_server_server.emd_feature.delete_emd_configuration_message', {
          'htmlSafe': true,
          'displayName': emdConfigurationName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteEmdConfiguration').perform(emdConfigurationName, this.get('model.folder.id'));
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteEmdConfiguration': (0, _emberConcurrency.task)(function* (emdConfigurationName, tenantFolderId) {
      // eslint-disable-line no-unused-vars
      yield this.get('model').destroyRecord();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteEmdConfigurationSucceeded': Ember.on('_confirmedDeleteEmdConfiguration:succeeded', function (taskInstance) {
      const emdConfigurationName = taskInstance.args[0];
      const folderId = taskInstance.args[1];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', folderId, false, false);
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', folderId, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.emd_feature.succesful_emd_configuration_delete', {
          'htmlSafe': true,
          'displayName': emdConfigurationName
        })
      });
    }),
    '_confirmedDeleteEmdConfigurationErrored': Ember.on('_confirmedDeleteEmdConfiguration:errored', function (taskInstance, err) {
      this.get('model').rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'addDataSet': function () {
      this.get('addEmdConfigurationAttributeSet').perform();
    }
  });

  _exports.default = _default;
});