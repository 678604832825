define("plantworks/components/manufacturing/watch/marico-work-order-list", ["exports", "plantworks/framework/base-component", "ember-concurrency", "moment"], function (_exports, _baseComponent, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'workOrder': null,
    'workOrderSets': null,
    'currentWorkOrder': null,
    'realtimeData': Ember.inject.service('realtime-data'),
    'subscribedLineId': null,
    'woStartTime': null,
    'woLoaded': null,
    'workOrderTab': 'current',
    'disableWorkOrderCancelation': null,
    'disableWorkOrderStart': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
        yield null;
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }

      this._resubscribeRealtimeData();
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    didInsertElement() {
      this._super(...arguments);

      this.set('showUpcoming', false);
      this.get('refreshOperationData').perform();
    },

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');
      this.get('realtimeData.streamer').write({
        'action': 'unsubscribe',
        'id': this.get('selectedNode.observedEntityId'),
        'type': 'line.event'
      });

      this._super(...arguments);
    },

    '_selectedNodeChangeReactor': Ember.observer('selectedNode', function () {
      this.set('woLoaded', false);
      this.set('workOrder', null);
      this.get('refreshOperationData').perform();

      this._resubscribeRealtimeData();
    }),
    'refreshOperationData': (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1000);
      let workOrderSets = yield this.get('ajax').request(`/manufacturing/plant-unit-line-work-orders/${this.get('selectedNode.observedEntityId')}`, {
        'method': 'GET'
      });
      let sortProp = 'PLANNED START TIME';
      let index = workOrderSets[0].head.indexOf(sortProp);
      if (index != -1) workOrderSets[0].rows = workOrderSets[0].rows.sort((a, b) => {
        let aDate = new Date(a.data[index]);
        let bDate = new Date(b.data[index]);
        return aDate - bDate;
      });
      workOrderSets[0].rows.forEach(element => {
        element.data.forEach((entry, idx) => {
          if (window.moment(entry, _moment.default.ISO_8601, true).isValid() && isNaN(entry)) {
            element.data[idx] = window.moment(entry).format('DD/MM/YYYY HH:mm');
          }
        });
      });

      if (window.moment(workOrderSets[0].woStartTime, _moment.default.ISO_8601, true).isValid()) {
        this.set('woStartTime', window.moment(workOrderSets[0].woStartTime).format('DD/MM/YYYY HH:mm'));
      }

      this.set('internalTag', workOrderSets[0].head); // converting to ember types

      workOrderSets = Ember.ArrayProxy.create({
        'content': Ember.A(workOrderSets.map(workOrderSet => {
          workOrderSet.head = Ember.ArrayProxy.create({
            'content': Ember.A(workOrderSet.head)
          });
          workOrderSet.rows = Ember.ArrayProxy.create({
            'content': Ember.A(workOrderSet.rows.map(row => {
              row = Ember.Object.create(row);
              row.set('showLineConfig', false);
              row.set('head', workOrderSet.head);
              row.set('tenantWorkOrderFormatId', workOrderSet.tenantWorkOrderFormatId);
              return row;
            }))
          });
          return Ember.Object.create(workOrderSet);
        }))
      });
      this.set('workOrderSets', workOrderSets);
      yield this.get('setCurrentWorkOrder').perform();
    }),
    'refreshConstituentMachines': (0, _emberConcurrency.task)(function* () {
      const plantUnitLine = yield this.get('store').findRecord('manufacturing/plant-unit-line', this.get('selectedNode.observedEntityId'));
      const selectedLineConstituents = yield plantUnitLine.get('inputConstituents');

      if (selectedLineConstituents['machines']) {
        for (let idx = 0; idx < selectedLineConstituents['machines'].length; idx++) {
          const constituentMachine = selectedLineConstituents['machines'][idx];
          yield constituentMachine.reload();
        }
      }
    }).drop(),
    'setCurrentWorkOrder': (0, _emberConcurrency.task)(function* () {
      const woNotRunningStatuses = new Set(['discontinued', 'not_started', 'canceled', 'completed']);
      const workOrderSets = this.get('workOrderSets');
      if (!workOrderSets || !workOrderSets.get('length')) return;
      let currentWorkOrder = null;

      for (let idx = 0; idx < workOrderSets.get('length'); idx++) {
        const workOrderSet = workOrderSets.objectAt(idx);
        const workOrders = workOrderSet.get('rows');
        let breakFlag = false;

        for (let idy = 0; idy < workOrders.get('length'); idy++) {
          const workOrderRow = workOrders.objectAt(idy);

          if (workOrderRow.status == 'operator_change' || workOrderRow.status == 'supervisor_change' || workOrderRow.status == 'shift_change' || workOrderRow.status == 'in_progress' || workOrderRow.displayStatus == 'logical_day_change') {
            workOrderRow.set('displayStatus', 'in_progress');
          }

          if (!woNotRunningStatuses.has(workOrderRow.get('status'))) {
            currentWorkOrder = workOrderRow;
            breakFlag = true;
            break;
          }
        }

        if (breakFlag) break;
      }

      if (this.get('current')) this.invokeAction('controller-action', 'setCurrentWorkOrder', currentWorkOrder);
      yield this.set('currentWorkOrder', currentWorkOrder);
      yield this.set('workOrder', currentWorkOrder);
      yield this.set('woLoaded', true);
    }),
    'changeStatus': (0, _emberConcurrency.task)(function* (newStatus, workOrder) {
      const statusData = Ember.Object.create();
      statusData.set('status', newStatus);
      statusData.set('updatedAt', (0, _moment.default)().format());
      let modalData = null;
      modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_perform_operation'),
          'iconType': 'mdi',
          'icon': 'file-upload'
        },
        'content': this.intl.t('plant_works_webapp_server_server.work_order_feature.perform_operation_message', {
          'htmlSafe': true,
          'displayName': newStatus
        }),
        'confirmButton': {
          'text': 'Change Status',
          'iconType': 'md',
          'icon': 'save',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('confirmChangeStatus').perform(statusData, workOrder);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }),
    'changeStatusStart': (0, _emberConcurrency.task)(function* (newStatus, workOrder) {
      const statusData = Ember.Object.create();
      statusData.set('status', newStatus);
      statusData.set('updatedAt', (0, _moment.default)().format());
      this.get('confirmChangeStatus').perform(statusData, workOrder);
    }),
    'confirmChangeStatus': (0, _emberConcurrency.task)(function* (statusData, workOrder) {
      const requestData = {
        'workOrderId': workOrder.get('work_order_id'),
        'status': statusData.get('status'),
        'updatedAt': statusData.get('updatedAt'),
        'tenantPlantUnitStationObservedLineId': this.get('selectedNode.id')
      };
      yield this.get('ajax').request(`/work-order/change-work-order-status/${workOrder.get('tenantWorkOrderFormatId')}`, {
        'method': 'POST',
        'dataType': 'json',
        'data': requestData
      });
      workOrder.set('status', statusData.get('status'));
      yield this.get('setCurrentWorkOrder').perform();
    }).keepLatest().evented(),
    'onChangeStatusErrored': Ember.on('confirmChangeStatus:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'actions': {
      'controller-action': function (action, data) {
        if (this[action] && typeof this[action] === 'function') {
          this[action](data);
          return;
        }

        this.invokeAction('controller-action', action, data);
      },
      'controller-action-2': function (action, data, data2) {
        if (this[action] && typeof this[action] === 'function') {
          this[action](data, data2);
          return;
        }
      }
    },

    _resubscribeRealtimeData() {
      if (this.get('subscribedLineId')) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': this.get('subscribedLineId'),
          'type': 'line.event'
        });
      }

      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'id': this.get('selectedNode.observedEntityId'),
        'type': 'line.event'
      });
      this.set('subscribedLineId', this.get('selectedNode.observedEntityId'));
      this.get('refreshOperationData').perform();
    },

    _processRealtimeData(data) {
      try {
        this.debug(`manufacturing::work-order-operations::work-order-list:_processRealtimeData::data: `, data);
        if (!data || !data.tag) return;

        if (data.tag === 'work_order_status_change') {
          this.get('refreshOperationData').perform();
          this.get('refreshConstituentMachines').perform();
        }
      } catch (err) {
        this.debug(`_processRealtimeData::Error: `, err);
      }
    }

  });

  _exports.default = _default;
});