define("plantworks/templates/components/pug/user-manager/user-panels-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dR/FJUxB",
    "block": "{\"symbols\":[\"Table\",\"Body\",\"panel\",\"Row\"],\"statements\":[[4,\"if\",[[24,[\"hasPermission\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"plant-works-table\",[],[[\"@sortDir\",\"@selectable\"],[\"asc\",true]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"Body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"features\",\"panels\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[28,\"sort-by\",[[23,1,[\"sortDesc\"]],[24,[\"model\",\"features\",\"panels\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[6,[23,2,[\"Row\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,[23,4,[\"Cell\"]],[],[[\"@checkbox\"],[true]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[5,\"plant-works-checkbox\",[],[[\"@value\",\"@onChange\"],[[23,3,[\"newAccess\"]],[28,\"perform\",[[24,[\"togglePanelAccess\"]],[23,3,[]]],null]]]],[0,\"\\n\\t\\t\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[6,[23,4,[\"Cell\"]],[],[[],[]],{\"statements\":[[1,[23,3,[\"i18n_path\"]],false]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[4]}],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"t\",[\"plant_works_webapp_server_server.pug_feature.user_manager_feature.no_panels_available\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"]],\"parameters\":[2]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/pug/user-manager/user-panels-access.hbs"
    }
  });

  _exports.default = _default;
});