define("plantworks/templates/components/outgoing/realtime-data-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1MDrPt5u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"hasPermission\"]],[28,\"or\",[[24,[\"_realtimeDataDisplayArrayModel\"]],[24,[\"_realtimeDataDisplayObjectModel\"]]],null]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"entitySpecificLayout\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"outgoing/realtime-custom-display\",[],[[\"@entity\",\"@model\",\"@layout\",\"@processor\",\"@objectModel\",\"@arrayModel\",\"@sourceTypes\",\"@dataTypes\",\"@timeFormats\",\"@controller-action\"],[[22,\"selectedNode\"],[22,\"dataModel\"],[22,\"entitySpecificLayout\"],[22,\"entitySpecificHooks\"],[22,\"_realtimeDataDisplayObjectModel\"],[22,\"_realtimeDataDisplayArrayModel\"],[22,\"sourceTypes\"],[22,\"dataTypes\"],[22,\"timeFormats\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[5,\"outgoing/realtime-standard-display\",[],[[\"@entity\",\"@model\",\"@objectModel\",\"@arrayModel\",\"@sourceTypes\",\"@dataTypes\",\"@timeFormats\",\"@controller-action\"],[[22,\"selectedNode\"],[22,\"dataModel\"],[22,\"_realtimeDataDisplayObjectModel\"],[22,\"_realtimeDataDisplayArrayModel\"],[22,\"sourceTypes\"],[22,\"dataTypes\"],[22,\"timeFormats\"],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/outgoing/realtime-data-display.hbs"
    }
  });

  _exports.default = _default;
});