define("plantworks/models/emd/attribute-set-function", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'type': _emberData.default.attr('string', {
      'defaultValue': 'observer'
    }),
    'executionOrder': _emberData.default.attr('number', {
      'defaultValue': 1
    }),
    'code': _emberData.default.attr('string'),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'emdAttributeSetFunctions'
    }),
    'attributeSet': _emberData.default.belongsTo('emd/attribute-set', {
      'async': true,
      'inverse': 'functions'
    }),
    'observedProperties': _emberData.default.hasMany('emd/attribute-set-function-observed-property', {
      'async': true,
      'inverse': 'attributeSetFunction'
    }),
    'observedTags': Ember.computed('observedProperties.@each.linkedInternalTag', function () {
      return this.get('_getObservedTags').perform();
    }),
    '_getObservedTags': (0, _emberConcurrency.task)(function* () {
      if (this.get('type') !== 'observer') return '';
      const obsProps = yield this.get('observedProperties');
      const internalTags = [];

      for (let idx = 0; idx < obsProps.length; idx++) {
        const obsProp = obsProps.objectAt(idx);
        const obsPropTag = yield obsProp.get('linkedInternalTag');
        internalTags.push(obsPropTag);
      }

      internalTags.sort();
      return internalTags.join(', ');
    }).keepLatest()
  });

  _exports.default = _default;
});