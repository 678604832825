define("plantworks/components/outgoing/realtime-data-display", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HTMLBars
  } = window.Ember;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch', 'bg-white'],
    'realtimeData': Ember.inject.service('realtime-data'),
    '_currentSelectedNode': null,
    '_realtimeDataDisplayArrayModel': null,
    '_realtimeDataDisplayObjectModel': null,
    'dataModel': null,
    'displayMode': null,
    'entitySpecificHooks': null,
    'entitySpecificLayout': null,
    'devSelectedLayout': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'realtime-data-access');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const sourceTypes = yield this.get('ajax').request('/masterdata/attributeSourceTypes', {
          'method': 'GET'
        });
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        this.setProperties({
          'sourceTypes': sourceTypes,
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes
        });
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberLifeline.runTask)(this, () => {
        this.get('_setupRealtimeDataDisplayModel').perform();
      }, 150);
    },

    'onSelectedNodeChanged': Ember.observer('selectedNode', function () {
      if (this.get('_currentSelectedNode') && (this.get('_currentSelectedNode')['data']['type'] === 'machine' || this.get('_currentSelectedNode')['data']['type'] === 'line')) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'type': this.get('_currentSelectedNode')['data']['type'],
          'id': this.get('_currentSelectedNode.id')
        });
        this.set('dataModel', null);
        this.set('_realtimeDataDisplayArrayModel', null);
        this.set('_realtimeDataDisplayObjectModel', null);
        this.set('entitySpecificHooks', null);
        this.set('entitySpecificLayout', null);
        this.set('devSelectedLayout', null);
      }

      this.set('_currentSelectedNode', this.get('selectedNode'));
      if (this.get('selectedNode')['data']['type'] !== 'machine' && this.get('selectedNode')['data']['type'] !== 'line') return;
      this.get('_setupRealtimeDataDisplayModel').perform();
    }),
    '_setupRealtimeDataDisplayModel': (0, _emberConcurrency.task)(function* () {
      let dataModel = null;
      this.set('dataModel', null);
      this.set('_realtimeDataDisplayArrayModel', null);
      this.set('_realtimeDataDisplayObjectModel', null);
      this.set('entitySpecificHooks', null);
      this.set('entitySpecificLayout', null);
      this.set('devSelectedLayout', null);
      if (!this.get('selectedNode')) return;
      if (!this.get('selectedNode')['data']) return;

      if (this.get('selectedNode')['data']['type'] === 'machine') {
        dataModel = yield this.get('store').findRecord('manufacturing/plant-unit-machine', this.get('selectedNode.id'));
      }

      if (this.get('selectedNode')['data']['type'] === 'line') {
        dataModel = yield this.get('store').findRecord('manufacturing/plant-unit-line', this.get('selectedNode.id'));
      }

      if (!dataModel) return;
      const realtimeDataModel = [];
      const dataPoints = yield dataModel.get('dataPoints');
      dataPoints.forEach(dataPoint => {
        realtimeDataModel.push({
          'id': dataPoint.get('id'),
          'name': dataPoint.get('name'),
          'internalTag': dataPoint.get('internalTag'),
          'units': dataPoint.get('units'),
          'source': dataPoint.get('source'),
          'dataType': dataPoint.get('datatype'),
          'currentValue': null
        });
      });

      if (this.get('selectedNode')['data']['type'] === 'machine' || this.get('selectedNode')['data']['type'] === 'line') {
        ['availability', 'performance', 'quality', 'oee'].forEach(oeeSpecialTag => {
          realtimeDataModel.push({
            'id': `${dataModel.get('id')}-${oeeSpecialTag}`,
            'name': oeeSpecialTag.toUpperCase(),
            'internalTag': oeeSpecialTag.toUpperCase(),
            'units': null,
            'source': 'generated',
            'dataType': 'number',
            'currentValue': null
          });
        });
      }

      const _realtimeDataDisplayArrayModel = [];
      const _realtimeDataDisplayObjectModel = {};
      realtimeDataModel.forEach(realtimeDataPoint => {
        const emberRealtimeDataPoint = Ember.Object.create(realtimeDataPoint);

        _realtimeDataDisplayArrayModel.push(emberRealtimeDataPoint);

        _realtimeDataDisplayObjectModel[realtimeDataPoint.internalTag] = emberRealtimeDataPoint;
      });
      this.set('_realtimeDataDisplayObjectModel', Ember.Object.create(_realtimeDataDisplayObjectModel));
      this.set('_realtimeDataDisplayArrayModel', Ember.ArrayProxy.create({
        'content': Ember.A(_realtimeDataDisplayArrayModel)
      }));
      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'type': this.get('selectedNode')['data']['type'],
        'id': this.get('selectedNode.id')
      });
      this.set('dataModel', dataModel);
      yield this.get('_setupEntitySpecificLayout').perform();
    }).enqueue(),
    'onTemplateChanged': Ember.observer('dataModel.templates.[]', function () {
      this.get('_setupEntitySpecificLayout').perform();
    }),
    '_setupEntitySpecificLayout': (0, _emberConcurrency.task)(function* () {
      try {
        this.set('entitySpecificHooks', null);
        this.set('entitySpecificLayout', null);
        this.set('devSelectedLayout', null);
        const dataModel = this.get('dataModel');
        if (!dataModel) return;
        const currentTemplates = yield dataModel.get('templates');
        if (!currentTemplates.length) return;
        const currentPublishedTemplate = currentTemplates.filterBy('publishStatus', true).sortBy('createdAt').get('lastObject');
        const latestTemplate = currentTemplates.sortBy('createdAt').get('lastObject');
        const currentDisplayMode = this.get('displayMode') || 'production';

        if (currentDisplayMode === 'production' && !!currentPublishedTemplate) {
          let displayTemplate = currentPublishedTemplate;
          if (!displayTemplate.get('template')) return;
          if (displayTemplate.get('template').trim() === '') return;
          this.set('entitySpecificHooks', displayTemplate);
          this.set('entitySpecificLayout', HTMLBars.compile(displayTemplate.get('template').trim()));
          return;
        }

        if (latestTemplate) {
          let displayTemplate = latestTemplate;

          if (this.get('devSelectedLayout')) {
            displayTemplate = currentTemplates.filterBy('id', this.get('devSelectedLayout')).objectAt(0);
          }

          if (!displayTemplate.get('template')) return;
          if (displayTemplate.get('template').trim() === '') return;
          this.set('entitySpecificHooks', displayTemplate);
          this.set('entitySpecificLayout', HTMLBars.compile(displayTemplate.get('template').trim()));
          return;
        }
      } catch (err) {
        this.debug(`_setupEntitySpecificLayout: `, err);
      }
    }).enqueue(),

    _resubscribeRealtimeData() {
      if (!this.get('_currentSelectedNode')) return;
      if (this.get('_currentSelectedNode')['data']['type'] !== 'machine' && this.get('_currentSelectedNode')['data']['type'] !== 'line') return;
      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'type': this.get('_currentSelectedNode')['data']['type'],
        'id': this.get('_currentSelectedNode.id')
      });
      this.get('_setupRealtimeDataDisplayModel').perform();
    },

    _processRealtimeData(data) {
      try {
        this.debug(`outgoing::realtime-data-display::_processRealtimeData::data: `, data);
        if (!data) return;
        const realtimeDataObjectModel = this.get('_realtimeDataDisplayObjectModel');
        Object.keys(data).forEach(internalTag => {
          realtimeDataObjectModel.set(`${internalTag}.currentValue`, data[internalTag]);
        });
        const realtimeDataArrayModel = this.get('_realtimeDataDisplayArrayModel');

        for (let idx = 0; idx < realtimeDataArrayModel.length; idx++) {
          const thisDataPoint = realtimeDataArrayModel.objectAt(idx);
          const internalTag = thisDataPoint.get('internalTag');
          if (data[internalTag] === undefined) continue;
          thisDataPoint.set('currentValue', typeof data[internalTag] == 'number' ? Math.round(data[internalTag] * 100) / 100 : data[internalTag]);
        }
      } catch (err) {
        this.debug(`_processRealtimeArrayData::Error: `, err);
      }
    }

  });

  _exports.default = _default;
});