define("plantworks/components/historical-dashboard/devenv/request-executor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'executorDataTransformTop': null,
    'executorDataTransformBottom': null,
    'currentExecutor': null,

    init() {
      this._super(...arguments);

      this.set('executorDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`async _executorDataTransformer(formattedData) {`])
      }));
      this.set('executorDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`//  return processedData;
}`])
      }));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('_setupExecutor').perform();
    },

    'cancelExecutor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentExecutor')) return;
      yield this.get('currentExecutor').rollback();
    }).enqueue(),
    'saveExecutor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentExecutor')) return;
      yield this.get('currentExecutor').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveExecutorSucceeded': Ember.on('saveExecutor:succeeded', function () {
      const historicalDashboard = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.succesful_historical_dashboard_save', {
          'htmlSafe': true,
          'displayName': historicalDashboard.get('name')
        })
      });
    }),
    'saveExecutorErrored': Ember.on('saveExecutor:errored', function (taskInstance, err) {
      const executor = this.get('currentExecutor');
      executor.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveExecutor': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentExecutor')) return;
      this.set('currentExecutor.publishStatus', publish);
      yield this.get('currentExecutor').save();
    }).keepLatest(),
    'publishAndSaveExecutorSucceeded': Ember.on('publishAndSaveExecutor:succeeded', function () {
      const historicalDashboard = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.succesful_historical_dashboard_save', {
          'htmlSafe': true,
          'displayName': historicalDashboard.get('name')
        })
      });
    }),
    'publishAndSaveExecutorErrored': Ember.on('publishAndSaveExecutor:errored', function (taskInstance, err) {
      const historicalDashboard = this.get('model');
      historicalDashboard.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'editExecutor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentExecutor')) return;
      if (!this.get('model')) return;
      const currentExecutor = this.get('currentExecutor'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const executors = yield this.get('model.executors');
      const newExecutor = this.get('store').createRecord(`historical-dashboard/historical-dashboard-executor`, {
        'tenant': tenant,
        'tenantHistoricalDashboard': this.get('model')
      });
      newExecutor.set('executorCode', currentExecutor.get('executorCode'));
      executors.addObject(newExecutor);
      this.set('currentExecutor', newExecutor);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,executors.[]}', function () {
      this.get('_setupExecutor').perform();
    }),
    '_setupExecutor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      const executors = yield this.get('model.executors');
      if (!executors) return;
      let currentExecutor = null;

      if (executors.length) {
        currentExecutor = executors.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentExecutor = this.get('store').createRecord(`historical-dashboard/historical-dashboard-executor`, {
          'tenant': tenant,
          'tenantHistoricalDashboard': this.get('model')
        });
        executors.addObject(currentExecutor);
      }

      this.set('currentExecutor', currentExecutor);
    }).keepLatest()
  });

  _exports.default = _default;
});