define("plantworks/models/emd/attribute-set-property", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'name': _emberData.default.attr('string'),
    'description': _emberData.default.attr('string'),
    'internalTag': _emberData.default.attr('string'),
    'evaluationExpression': _emberData.default.attr('string'),
    'units': _emberData.default.attr('string'),
    'source': _emberData.default.attr('string', {
      'defaultValue': 'static'
    }),
    'datatype': _emberData.default.attr('string', {
      'defaultValue': 'number'
    }),
    'timestampFormat': _emberData.default.attr('string', {
      'defaultValue': 'not_a_timestamp'
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': 'emdAttributeSetProperties'
    }),
    'attributeSet': _emberData.default.belongsTo('emd/attribute-set', {
      'async': true,
      'inverse': 'properties'
    }),
    'observingFunctions': _emberData.default.hasMany('emd/attribute-set-function-observed-property', {
      'async': true,
      'inverse': 'attributeSetProperty'
    }),
    'theValue': Ember.computed('evaluationExpression', 'source', function () {
      if (this.get('source') !== 'static') return '';
      return this.get('evaluationExpression') || '';
    }),
    'expression': Ember.computed('evaluationExpression', 'source', function () {
      if (this.get('source') !== 'computed') return '';
      return this.get('evaluationExpression') || '';
    }),
    'onDatatypeChanged': Ember.on('didLoad', Ember.observer('datatype', function () {
      if (this.get('isDeleted') || this.get('isEmpty') || this.get('isError') || this.get('isLoading')) return;

      if (this.get('datatype') !== 'date') {
        this.set('timestampFormat', 'not_a_timestamp');
        return;
      }

      if (this.get('timestampFormat') !== 'not_a_timestamp') return;
      this.set('timestampFormat', 'unix_epoch_with_milliseconds');
    }))
  });

  _exports.default = _default;
});