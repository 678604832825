define("plantworks/controllers/pug", ["exports", "plantworks/framework/base-controller"], function (_exports, _baseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'canViewGroupAdministrator': false,
    'canViewUserAdministrator': false,
    'hasSubModulePermissions': Ember.computed.or('canViewGroupAdministrator', 'canViewUserAdministrator'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'pug-read');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    changeSubFeature(subFeature) {
      this.get('router').transitionTo(`pug.${subFeature}`);
    },

    'onPermissionChanged': Ember.on('init', Ember.observer('permissions', function () {
      this.onUserDataUpdated();
    })),

    onUserDataUpdated() {
      const currentUser = this.get('currentUser');
      this.set('canViewGroupAdministrator', currentUser.hasPermission('group-manager-read'));
      this.set('canViewUserAdministrator', currentUser.hasPermission('user-manager-read'));
    }

  });

  _exports.default = _default;
});