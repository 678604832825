define("plantworks/models/manufacturing/plant-unit-station-observed-line", ["exports", "plantworks/framework/base-model", "ember-data", "ember-concurrency"], function (_exports, _baseModel, _emberData, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'workOrderOperationsEnabled': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitStation': _emberData.default.belongsTo('manufacturing/plant-unit-station', {
      'async': true,
      'inverse': 'observedLines'
    }),
    'tenantPlantUnitLine': _emberData.default.belongsTo('manufacturing/plant-unit-line', {
      'async': true,
      'inverse': 'linkedStations'
    }),
    'templates': _emberData.default.hasMany('manufacturing/plant-unit-station-observed-line-template', {
      'async': true,
      'inverse': 'tenantPlantUnitStationObservedLine'
    }),
    'currentSupervisor': Ember.computed('tenantPlantUnitLine.events.[]', function () {
      return this.get('_getLatestSupervisor').perform();
    }),
    '_getLatestSupervisor': (0, _emberConcurrency.task)(function* () {
      const tenantPlantUnitLine = yield this.get('tenantPlantUnitLine');
      const allEvents = yield tenantPlantUnitLine.get('events');
      let supervisorEvents = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });

      for (let idx = 0; idx < allEvents.get('length'); idx++) {
        const event = allEvents.objectAt(idx);
        const eventType = yield event.get('eventType');
        if (eventType.get('tag') === 'supervisor_change' && event.get('tenantPlantUnitStation.id') === this.get('tenantPlantUnitStation.id')) supervisorEvents.addObject(event);
      }

      supervisorEvents = supervisorEvents.sortBy('createdAt');

      if (!supervisorEvents.get('length')) {
        return {};
      }

      const latestEvent = supervisorEvents.get('lastObject');
      return JSON.parse(latestEvent.get('eventMetadata.value'));
    }).keepLatest()
  });

  _exports.default = _default;
});