define("plantworks/components/board/watch/panel-list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDevMode': null,
    'allDevModes': null,
    'dashboardFeatures': null,
    'dashboardList': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'board-watch');
      const dashboardFeatures = this.get('store').peekAll('dashboard/feature');
      this.set('dashboardFeatures', dashboardFeatures);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_setupDashboardList').perform();
    }).on('didInsertElement').keepLatest(),
    'onDashboardFeaturesChanged': Ember.observer('dashboardFeatures.[]', function () {
      this.get('_setupDashboardList').perform();
    }),
    '_setupDashboardList': (0, _emberConcurrency.task)(function* () {
      yield;
      if (!this.get('dashboardFeatures.length')) return;

      if (this.get('dashboardList')) {
        this.get('dashboardList').clear();
      } else {
        this.set('dashboardList', Ember.ArrayProxy.create({
          'content': Ember.A([])
        }));
      }

      this.get('dashboardFeatures').forEach(dashboardFeature => {
        if (dashboardFeature.get('route') !== 'board.watch') return;
        this.get('dashboardList').addObject(dashboardFeature);
      });
    }).keepLatest()
  });

  _exports.default = _default;
});