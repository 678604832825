define("plantworks/components/manufacturing/schedule/plant-unit-machine-scheduled-downtime-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'currentDate': null,

    init() {
      this._super(...arguments);

      let d = new Date();
      d.setSeconds(0);
      d.setMinutes(0);
      d.setHours(0);
      this.set('currentDate', d);
    },

    'addSchedule': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitMachineSchedule = this.get('store').createRecord('manufacturing/plant-unit-machine-schedule', {
        'tenant': tenant,
        'plant': this.get('model')
      });
      const displayDescription = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_scheduled_downtime_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnitMachineSchedule.set('description', displayDescription);
      newPlantUnitMachineSchedule.set('type', this.get('allowedTypes')[0]);
      newPlantUnitMachineSchedule.set('startDate', new Date());
      newPlantUnitMachineSchedule.set('endDate', new Date());
      newPlantUnitMachineSchedule.set('startTime', '13:00');
      newPlantUnitMachineSchedule.set('endTime', '15:00');
      const siblingSchedules = yield this.get('model.schedules');
      siblingSchedules.addObject(newPlantUnitMachineSchedule);
      this.get('editPlantUnitMachineSchedule').perform(newPlantUnitMachineSchedule);
    }).enqueue(),
    'editPlantUnitMachineSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      yield schedule.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitMachineSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      schedule.set('isEditing', false);
      yield schedule.rollback();
    }).enqueue(),
    'savePlantUnitMachineSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      schedule.set('isEditing', false);
      yield schedule.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitMachineScheduleSucceeded': Ember.on('savePlantUnitMachineSchedule:succeeded', function (taskInstance) {
      const schedule = taskInstance.args[0];
      (0, _jquery.default)('div#schedule-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_schedule_save', {
          'htmlSafe': true,
          'displayName': schedule.get('type')
        })
      });
    }),
    'savePlantUnitMachineScheduleErrored': Ember.on('savePlantUnitMachineSchedule:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitMachineSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      const scheduleName = yield schedule.get('i18n_type');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_schedule'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_schedule_message', {
          'htmlSafe': true,
          'displayName': scheduleName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitMachineSchedule').perform(schedule);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitMachineSchedule': (0, _emberConcurrency.task)(function* (schedule) {
      yield schedule.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitMachineScheduleSucceeded': Ember.on('_confirmedDeletePlantUnitMachineSchedule:succeeded', function (taskInstance) {
      const schedule = taskInstance.args[0];
      (0, _jquery.default)('div#schedule-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_schedule_delete', {
          'htmlSafe': true,
          'displayName': schedule.get('i18n_type')
        })
      });
    }),
    '_confirmedDeletePlantUnitMachineScheduleErrored': Ember.on('_confirmedDeletePlantUnitMachineSchedule:errored', function (taskInstance, err) {
      const schedule = taskInstance.args[0];
      schedule.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'onStartDateChange': (0, _emberConcurrency.task)(function* (schedule) {
      const dateStr = schedule.get('startDate');
      yield schedule.set('startDate', new Date(dateStr));
    }).enqueue(),
    'onEndDateChange': (0, _emberConcurrency.task)(function* (schedule) {
      const dateStr = schedule.get('endDate');
      yield schedule.set('endDate', new Date(dateStr));
    }).enqueue()
  });

  _exports.default = _default;
});