define("plantworks/components/manufacturing/watch/zydus-work-order-admin-form", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'moment': Ember.inject.service('moment'),
    'displayLineSelector': false,
    'userAssigned': true,
    'selectedLine': null,
    'selectedLineNode': null,
    'realtimeData': Ember.inject.service('realtime-data'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'zydus-work-order-admin-form-update');
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    destroy() {
      this._super(...arguments);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_toggleLineSelectors').perform();
    }).on('didInsertElement').keepLatest(),
    'onObservedLinesChanged': Ember.observer('model.observedLines.[]', function () {
      this.get('_toggleLineSelectors').perform();
    }),
    'onSelectedLineChanged': Ember.observer('selectedLine', function () {
      this.get('_setSelectedLineNode').perform();
    }),
    '_toggleLineSelectors': (0, _emberConcurrency.task)(function* () {
      const observedLines = yield this.get('model.observedLines');

      if (observedLines.get('length') === 0) {
        this.set('selectedLine', null);
        this.set('displayLineSelector', false);
        return;
      }

      if (observedLines.length >= 1) {
        const lineArray = [];

        for (let i = 0; i < observedLines.length; i++) {
          const line = observedLines.objectAt(i);
          yield line.get('tenantPlantUnitLine').reload();
          let plantUnitLineWatchers = yield line.get('tenantPlantUnitLine.watchers');

          for (let j = 0; j < plantUnitLineWatchers.length; j++) {
            const user = plantUnitLineWatchers.objectAt(j);
            const userId = yield user.get('tenantUser');

            if (this.get('currentUser').getUser().user_id === userId.get('user').get('id')) {
              lineArray.push(line);
            }
          }
        }

        const lines = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        lineArray.forEach(line => {
          lines.addObject(line);
        });
        this.set('userAssigned', lines.length > 0);
        this.set('assignedLines', lines);
        this.set('selectedLine', lines.objectAt(0));
        this.set('displayLineSelector', lines.length > 1);
        return;
      }
    }).keepLatest(),
    '_setSelectedLineNode': (0, _emberConcurrency.task)(function* () {
      const currentlySelectedLineNode = this.get('selectedLineNode');
      this.set('selectedLineNode', {
        'id': this.get('selectedLine.id'),
        'observedEntityId': this.get('selectedLine.tenantPlantUnitLine.id'),
        'data': {
          'type': 'line'
        }
      });
      yield null;
    }).keepLatest()
  });

  _exports.default = _default;
});