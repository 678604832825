define("plantworks/helpers/plant-works-power-select-is-group", ["exports", "pworks-dsl/helpers/plant-works-power-select-is-group"], function (_exports, _plantWorksPowerSelectIsGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectIsGroup.default;
    }
  });
  Object.defineProperty(_exports, "emberPowerSelectIsGroup", {
    enumerable: true,
    get: function () {
      return _plantWorksPowerSelectIsGroup.emberPowerSelectIsGroup;
    }
  });
});