define("plantworks/components/historical-dashboard/configure/historical-dashboard-list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'historical-dashboard-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('historical-dashboard-create');
      const updatePerm = this.get('currentUser').hasPermission('historical-dashboard-update');
      const deletePerm = this.get('currentUser').hasPermission('historical-dashboard-delete');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
      yield;
    }).keepLatest(),
    'addHistoricalDashboard': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newHistoricalDashboard = this.get('store').createRecord('historical-dashboard/historical-dashboard', {
        'tenant': tenant,
        'folder': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.new_historical_dashboard_name', {
        'now': window.moment().valueOf()
      });
      newHistoricalDashboard.set('name', displayName);
      newHistoricalDashboard.set('description', displayName);
      const siblingHistoricalDashboards = yield this.get('model.historicalDashboards');
      siblingHistoricalDashboards.addObject(newHistoricalDashboard);
      this.get('editHistoricalDashboard').perform(newHistoricalDashboard);
    }).enqueue(),
    'editHistoricalDashboard': (0, _emberConcurrency.task)(function* (historicalDashboard) {
      yield historicalDashboard.set('isEditing', true);
    }).enqueue(),
    'cancelHistoricalDashboard': (0, _emberConcurrency.task)(function* (historicalDashboard) {
      historicalDashboard.set('isEditing', false);
      yield historicalDashboard.rollback();
    }).enqueue(),
    'saveHistoricalDashboard': (0, _emberConcurrency.task)(function* (historicalDashboard) {
      historicalDashboard.set('isEditing', false);
      yield historicalDashboard.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveHistoricalDashboardSucceeded': Ember.on('saveHistoricalDashboard:succeeded', function (taskInstance) {
      const historicalDashboard = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.succesful_historical_dashboard_save', {
          'htmlSafe': true,
          'displayName': historicalDashboard.get('name')
        })
      });
    }),
    'saveHistoricalDashboardErrored': Ember.on('saveHistoricalDashboard:errored', function (taskInstance, err) {
      const historicalDashboard = taskInstance.args[0];
      historicalDashboard.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteHistoricalDashboard': (0, _emberConcurrency.task)(function* (historicalDashboard) {
      const historicalDashboardName = yield historicalDashboard.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.label_delete_historical_dashboard'),
          'iconType': 'mdi',
          'icon': 'pac-man'
        },
        'content': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.delete_historical_dashboard_message', {
          'htmlSafe': true,
          'displayName': historicalDashboardName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteHistoricalDashboard').perform(historicalDashboard);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteHistoricalDashboard': (0, _emberConcurrency.task)(function* (historicalDashboard) {
      yield historicalDashboard.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteHistoricalDashboardSucceeded': Ember.on('_confirmedDeleteHistoricalDashboard:succeeded', function (taskInstance) {
      const historicalDashboard = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.historical_dashboard_feature.succesful_historical_dashboard_delete', {
          'htmlSafe': true,
          'displayName': historicalDashboard.get('name')
        })
      });
    }),
    '_confirmedDeleteHistoricalDashboardErrored': Ember.on('_confirmedDeleteHistoricalDashboard:errored', function (taskInstance, err) {
      const historicalDashboard = taskInstance.args[0];
      historicalDashboard.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedHistoricalDashboard': function (selectedHistoricalDashboard) {
      if (selectedHistoricalDashboard.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedHistoricalDashboard.get('id'), false, false);
    }
  });

  _exports.default = _default;
});