define("plantworks/components/common/single-attribute-set/property-editor", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'bg-grey'],
    'viewMode': 'configure'
  });

  _exports.default = _default;
});