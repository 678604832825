define("plantworks/models/emd/fragments/configuration-attribute-set-metadata", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    'parameterId': _emberData.default.attr('string'),
    'isUniqueId': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'isNull': _emberData.default.attr('boolean', {
      'defaultValue': false
    }),
    'showInDropdown': _emberData.default.attr('boolean', {
      'defaultValue': false
    })
  });

  _exports.default = _default;
});