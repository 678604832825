define("plantworks/models/pug/user-manager/user", ["exports", "plantworks/framework/base-model", "ember-data"], function (_exports, _baseModel, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    'firstName': _emberData.default.attr('string'),
    'middleNames': _emberData.default.attr('string'),
    'lastName': _emberData.default.attr('string'),
    'nickname': _emberData.default.attr('string'),
    'email': _emberData.default.attr('string'),
    'password': _emberData.default.attr('string'),
    'profileImage': _emberData.default.attr('string'),
    'profileImageMetadata': _emberData.default.attr(),
    'contacts': _emberData.default.hasMany('pug/user-manager/user-contact', {
      'async': true,
      'inverse': 'user'
    }),
    'tenantUsers': _emberData.default.hasMany('pug/user-manager/tenant-user', {
      'async': true,
      'inverse': 'user'
    }),
    'fullName': Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    'saveDisabled': Ember.computed('email', function () {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.get('email'));
    })
  });

  _exports.default = _default;
});