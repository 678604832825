define("plantworks/components/board/configure/display-period-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'tagName': '',
    'metadata': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      const allMetadata = yield this.get('Panel.attributeSetMetadata');
      let metadata = allMetadata.filterBy('parameterId', this.get('InputDataPoint.id')).objectAt(0);

      if (!metadata) {
        metadata = allMetadata.createFragment({
          'parameterId': this.get('InputDataPoint.id'),
          'constituentType': null,
          'constituentId': null,
          'externalTag': null
        });
      }

      this.set('metadata', metadata);
    }).on('init').drop()
  });

  _exports.default = _default;
});