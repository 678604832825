define("plantworks/components/manufacturing/configure/plant-unit-station-editor/add-watchers", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'possibleTenantUsers': null,
    'onWillInsertElement': (0, _emberConcurrency.task)(function* () {
      const selectedStationId = this.get('state.station.id');
      const possibleTenantUsers = yield this.get('ajax').request(`/manufacturing/possibleStationTenantUsersList?station=${selectedStationId}`);
      this.set('possibleTenantUsers', Ember.ArrayProxy.create({
        'content': Ember.A(possibleTenantUsers.map(possibleTenantUser => {
          return Ember.ObjectProxy.create({
            'content': Ember.Object.create(possibleTenantUser)
          });
        }))
      }));
    }).on('willInsertElement'),
    'toggleTenantUser': (0, _emberConcurrency.task)(function* (tenantUser) {
      const alreadyInModel = yield this.get('state.model').filterBy('id', tenantUser.get('id')).objectAt(0);
      if (alreadyInModel) this.get('state.model').removeObject(tenantUser);else this.get('state.model').addObject(tenantUser);
    }).enqueue()
  });

  _exports.default = _default;
});