define("plantworks/components/manufacturing/configure/plant-unit-editor", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'sourceTypes': null,
    'dataTypes': null,
    'timeFormats': null,
    'subComponents': null,
    'selectedComponent': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'manufacturing-read');
      this.set('subComponents', [{
        'iconType': 'md',
        'icon': 'ac-unit',
        'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_sub_unit'),
        'component': 'Manufacturing/Configure/PlantSubUnitList'
      }, {
        'iconType': 'mdi',
        'icon': 'boom-gate-up',
        'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_driver'),
        'component': 'Manufacturing/Configure/PlantUnitDriverEditor/List'
      }, {
        'iconType': 'mdi',
        'icon': 'screw-machine-round-top',
        'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_machine'),
        'component': 'Manufacturing/Configure/PlantUnitMachineEditor/List'
      }, {
        'iconType': 'mdi',
        'icon': 'seat-legroom-extra',
        'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_line'),
        'component': 'Manufacturing/Configure/PlantUnitLineEditor/List'
      }, {
        'iconType': 'mdi',
        'icon': 'seat-legroom-extra',
        'title': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_station'),
        'component': 'Manufacturing/Configure/PlantUnitStationEditor/List'
      }]);
      this.set('selectedComponent', this.get('subComponents')[0]);
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const createPerm = this.get('currentUser').hasPermission('manufacturing-create');
      const updatePerm = this.get('currentUser').hasPermission('manufacturing-update');
      const deletePerm = this.get('currentUser').hasPermission('manufacturing-delete');
      yield this.set('createable', createPerm);
      yield this.set('editable', updatePerm);
      yield this.set('deleteable', deletePerm);
    }).keepLatest(),
    'cancelPlantUnit': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').rollback();
    }).enqueue(),
    'savePlantUnit': (0, _emberConcurrency.task)(function* () {
      this.set('model.isEditing', false);
      yield this.get('model').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitSucceeded': Ember.on('savePlantUnit:succeeded', function () {
      const unit = this.get('model');
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_sub_unit_save', {
          'htmlSafe': true,
          'displayName': unit.get('name')
        })
      });
    }),
    'savePlantUnitErrored': Ember.on('savePlantUnit:errored', function (taskInstance, err) {
      const unit = this.get('model');
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnit': (0, _emberConcurrency.task)(function* () {
      const unit = this.get('model');
      const unitName = unit.get('name');
      const shouldNavigateTree = unit.get('id') === this.get('model.id');
      let unitParent = yield unit.get('parent.id');
      if (!unitParent) unitParent = yield unit.get('plant.id');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_sub_unit'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_sub_unit_message', {
          'htmlSafe': true,
          'displayName': unitName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnit').perform(unit, unitName, unitParent, shouldNavigateTree);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnit': (0, _emberConcurrency.task)(function* (unit, unitName, unitParent, shouldNavigateTree) {
      // eslint-disable-line no-unused-vars
      yield unit.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitSucceeded': Ember.on('_confirmedDeletePlantUnit:succeeded', function (taskInstance) {
      const unitName = taskInstance.args[1];
      const parentNode = taskInstance.args[2];
      const shouldNavigateTree = taskInstance.args[3];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', parentNode, false, false);
      if (shouldNavigateTree) (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', parentNode, false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_sub_unit_delete', {
          'htmlSafe': true,
          'displayName': unitName
        })
      });
    }),
    '_confirmedDeletePlantUnitErrored': Ember.on('_confirmedDeletePlantUnit:errored', function (taskInstance, err) {
      const unit = taskInstance.args[0];
      unit.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});