define("plantworks/components/work-order/configure/list", ["exports", "plantworks/framework/base-component", "jquery", "ember-concurrency"], function (_exports, _baseComponent, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'createable': false,
    'editable': false,
    'deleteable': false,

    init() {
      this._super(...arguments);

      this.set('permissions', 'work-order-read');
    },

    'onPermissionOrModelChange': Ember.observer('hasPermission', 'model', function () {
      this.get('_updateUserPermissions').perform();
    }),
    '_updateUserPermissions': (0, _emberConcurrency.task)(function* () {
      const parentModel = yield this.get('model.parent');
      const createPerm = this.get('currentUser').hasPermission('work-order-create') && parentModel && !!parentModel.get('name');
      const updatePerm = this.get('currentUser').hasPermission('work-order-update') && parentModel && !!parentModel.get('name');
      const deletePerm = this.get('currentUser').hasPermission('work-order-delete') && parentModel && !!parentModel.get('name');
      this.set('createable', createPerm);
      this.set('editable', updatePerm);
      this.set('deleteable', deletePerm);
    }).keepLatest(),
    'addWorkOrderFormat': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newWorkOrderFormat = this.get('store').createRecord('work-order/work-order-format', {
        'tenant': tenant,
        'parent': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.work_order_feature.new_format_name', {
        'now': window.moment().valueOf()
      });
      newWorkOrderFormat.set('name', displayName);
      newWorkOrderFormat.set('description', displayName);
      const siblingWorkOrderFormats = yield this.get('model.workOrderFormats');
      siblingWorkOrderFormats.addObject(newWorkOrderFormat);
      this.get('editWorkOrderFormat').perform(newWorkOrderFormat);
    }).enqueue(),
    'editWorkOrderFormat': (0, _emberConcurrency.task)(function* (format) {
      yield format.set('isEditing', true);
    }).enqueue(),
    'cancelWorkOrderFormat': (0, _emberConcurrency.task)(function* (format) {
      format.set('isEditing', false);
      yield format.rollback();
    }).enqueue(),
    'saveWorkOrderFormat': (0, _emberConcurrency.task)(function* (format) {
      format.set('isEditing', false);
      yield format.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveWorkOrderFormatSucceeded': Ember.on('saveWorkOrderFormat:succeeded', function (taskInstance) {
      const format = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.succesful_format_save', {
          'htmlSafe': true,
          'displayName': format.get('name')
        })
      });
    }),
    'saveWorkOrderFormatErrored': Ember.on('saveWorkOrderFormat:errored', function (taskInstance, err) {
      const format = taskInstance.args[0];
      format.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deleteWorkOrderFormat': (0, _emberConcurrency.task)(function* (format) {
      const formatName = yield format.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.work_order_feature.label_delete_format'),
          'iconType': 'mdi',
          'icon': 'file-upload'
        },
        'content': this.intl.t('plant_works_webapp_server_server.work_order_feature.delete_format_message', {
          'htmlSafe': true,
          'displayName': formatName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeleteWorkOrderFormat').perform(format);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeleteWorkOrderFormat': (0, _emberConcurrency.task)(function* (format) {
      yield format.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeleteWorkOrderFormatSucceeded': Ember.on('_confirmedDeleteWorkOrderFormat:succeeded', function (taskInstance) {
      const format = taskInstance.args[0];
      (0, _jquery.default)('div#configs-tree-container').jstree('refresh_node', this.get('model.id'), false, false);
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.work_order_feature.succesful_work_order_format_delete', {
          'htmlSafe': true,
          'displayName': format.get('name')
        })
      });
    }),
    '_confirmedDeleteWorkOrderFormatErrored': Ember.on('_confirmedDeleteWorkOrderFormat:errored', function (taskInstance, err) {
      const format = taskInstance.args[0];
      format.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'setSelectedWorkOrderFormat': function (selectedWorkOrderFormat) {
      if (selectedWorkOrderFormat.get('isEditing')) return;
      (0, _jquery.default)('div#configs-tree-container').jstree('activate_node', selectedWorkOrderFormat.get('id'), false, false);
    }
  });

  _exports.default = _default;
});