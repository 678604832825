define("plantworks/components/settings/account/webhooks/webhook-editor/editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'ajax': Ember.inject.service('ajax'),
    'classNames': ['w-100'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    'createable': false,
    'editable': false,
    'deleteable': false,
    'eventsMeta': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-webhooks-read');
    },

    'addHeader': (0, _emberConcurrency.task)(function* () {
      const newHeader = yield this.get('model').get('headers').createFragment({
        'name': null,
        'value': null
      });
      newHeader.set('isEditing', true);
    }).drop(),
    'deleteHeader': (0, _emberConcurrency.task)(function* (header) {
      yield this.get('model').get('headers').removeFragment(header);
    }).enqueue(),
    'editHeader': (0, _emberConcurrency.task)(function* (header) {
      yield header.set('isEditing', true);
    }).drop(),
    'cancelHeader': (0, _emberConcurrency.task)(function* (header) {
      header.rollbackAttributes();
      yield header.set('isEditing', false);
    }).drop(),
    'saveHeader': (0, _emberConcurrency.task)(function* (header) {
      yield header.set('isEditing', false);
    }).drop()
  });

  _exports.default = _default;
});