define("plantworks/components/pug/user-manager/reset-password", ["exports", "plantworks/framework/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);

      this.set('permissions', 'user-manager-update');
    },

    'onGeneratePasswordChange': Ember.observer('state.generateRandomPassword', function () {
      this.set('state.newPassword', '');
    })
  });

  _exports.default = _default;
});