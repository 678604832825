define("plantworks/controllers/pug/group-manager", ["exports", "plantworks/framework/base-controller", "ember-concurrency"], function (_exports, _baseController, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseController.default.extend({
    'breadcrumbStack': null,
    'selectedGroup': null,
    'canViewGroupAdministrator': false,
    'canViewUserAdministrator': false,
    'editable': false,
    'hasSubModulePermissions': Ember.computed.or('canViewGroupAdministrator', 'canViewUserAdministrator'),

    init() {
      this._super(...arguments);

      this.set('permissions', 'group-manager-read');
      this.get('currentUser').on('userDataUpdated', this, 'onUserDataUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onUserDataUpdated');

      this._super(...arguments);
    },

    'onPermissionChanges': Ember.on('init', Ember.observer('permissions', function () {
      this.onUserDataUpdated();
    })),

    onUserDataUpdated() {
      const currentUser = this.get('currentUser');
      const updatePerm = currentUser.hasPermission('group-manager-update');
      this.set('editable', updatePerm);
      this.set('canViewGroupAdministrator', currentUser.hasPermission('group-manager-read'));
      this.set('canViewUserAdministrator', currentUser.hasPermission('user-manager-read'));
    },

    setSelectedGroup(groupModel) {
      if (!groupModel) {
        this.set('selectedGroup', null);
        this.set('breadcrumbStack', null);
        return;
      }

      if (groupModel.get('id') === this.get('selectedGroup.id')) return;
      groupModel.reload().then(reloadedModel => {
        this.set('selectedGroup', reloadedModel);
        this.get('setBreadcrumbHierarchy').perform();
      }).catch(err => {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      });
    },

    'setBreadcrumbHierarchy': (0, _emberConcurrency.task)(function* () {
      let currentGroup = this.get('selectedGroup');
      const breadcrumbHierarchy = [];

      while (currentGroup) {
        if (currentGroup.get('displayName')) breadcrumbHierarchy.unshift(currentGroup);
        currentGroup = yield currentGroup.get('parent');
      }

      this.set('breadcrumbStack', breadcrumbHierarchy);
    }).keepLatest()
  });

  _exports.default = _default;
});