define("plantworks/components/event-alert/devenv/event-alert-backend-environment", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['flex', 'layout-column', 'layout-align-start-stretch'],
    'selectedEvaluationStep': null,
    'evaluationSteps': null,
    'processedDataTransformTop': null,
    'processedDataTransformBottom': null,
    'onEventTop': null,
    'onEventBottom': null,
    'currentProcessor': null,

    init() {
      this._super(...arguments);

      this.set('evaluationSteps', Ember.ArrayProxy.create({
        'content': Ember.A([{
          'name': 'plant_works_webapp_server_server.event_alert_feature.label_processed_data_transform'
        }, {
          'name': 'plant_works_webapp_server_server.event_alert_feature.label_on_event'
        }])
      }));
      this.set('processedDataTransformTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [eventData] - event data.
  * @returns  {undefined} Nothing.
  *
  * @summary  Post-processing of event data that has already been evaluated. Done in-place.
  */
`, `async _processedDataTransformer(eventData) {`])
      }));
      this.set('processedDataTransformBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    return null;
}`])
      }));
      this.set('onEventTop', Ember.ArrayProxy.create({
        'content': Ember.A([`/**
  * @async
  * @function
  *
  * @param    {object} [eventData] - Event data.
  * @returns  {object} Notification Data.
  *
  * @summary  On Station Event hook to publish notifications.
  */
`, `async _onEvent(eventData) {`])
      }));
      this.set('onEventBottom', Ember.ArrayProxy.create({
        'content': Ember.A([`    // return null;
}`])
      }));
      this.set('selectedEvaluationStep', this.get('evaluationSteps').objectAt(0));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('_setupProcessor').perform();
      (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
    },

    'cancelEventAlertProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentProcessor')) return;
      yield this.get('currentProcessor').rollback();
    }).enqueue(),
    'saveEventAlertProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentProcessor')) return;
      yield this.get('currentProcessor').save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveEventAlertProcessorSucceeded': Ember.on('saveEventAlertProcessor:succeeded', function () {
      const eventAlert = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_event_alert_save', {
          'htmlSafe': true,
          'displayName': eventAlert.get('name')
        })
      });
    }),
    'saveEventAlertProcessorErrored': Ember.on('saveEventAlertProcessor:errored', function (taskInstance, err) {
      const eventAlertProcessor = this.get('currentProcessor');
      eventAlertProcessor.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'publishAndSaveEventAlertProcessor': (0, _emberConcurrency.task)(function* (publish) {
      if (!this.get('currentProcessor')) return;
      this.set('currentProcessor.publishStatus', publish);
      yield this.get('currentProcessor').save();
    }).keepLatest(),
    'publishAndSaveEventAlertProcessorSucceeded': Ember.on('publishAndSaveEventAlertProcessor:succeeded', function () {
      const eventAlert = this.get('model');
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.event_alert_feature.succesful_event_alert_save', {
          'htmlSafe': true,
          'displayName': eventAlert.get('name')
        })
      });
    }),
    'publishAndSaveEventAlertProcessorErrored': Ember.on('publishAndSaveEventAlertProcessor:errored', function (taskInstance, err) {
      const eventAlert = this.get('model');
      eventAlert.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'editEventAlertProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('currentProcessor')) return;
      if (!this.get('model')) return;
      const currentProcessor = this.get('currentProcessor'); // Editing a published field Should always spawn a new record. The previous published record is to be considered read-only

      const tenant = yield this.get('model.tenant');
      const processors = yield this.get('model.processors');
      const newProcessor = this.get('store').createRecord(`event-alert/event-alert-processor`, {
        'tenant': tenant,
        'tenantEventAlert': this.get('model')
      });
      newProcessor.set('publishStatus', false);
      newProcessor.set('processedDataTransformCode', currentProcessor.get('processedDataTransformCode'));
      newProcessor.set('onEventCode', currentProcessor.get('onEventCode'));
      processors.addObject(newProcessor);
      this.set('currentProcessor', newProcessor);
    }).keepLatest(),
    'onModelChanged': Ember.observer('model.{id,name,processors.[]}', function () {
      this.get('_setupProcessor').perform();
    }),
    'onActiveTabChanged': Ember.observer('selectedEvaluationStep', function () {
      if (!this.get('selectedEvaluationStep')) return;
      const currTabIdx = this.get('evaluationSteps').indexOf(this.get('selectedEvaluationStep'));

      if (currTabIdx !== 0) {
        (0, _emberLifeline.runTask)(this, this._fillCodeEditor, 150);
        return;
      }
    }),
    '_setupProcessor': (0, _emberConcurrency.task)(function* () {
      if (!this.get('model')) return;
      const processors = yield this.get('model.processors');
      if (!processors) return;
      let currentProcessor = null;

      if (processors.length) {
        currentProcessor = processors.sortBy('createdAt').get('lastObject');
      } else {
        const tenant = yield this.get('model.tenant');
        currentProcessor = this.get('store').createRecord(`event-alert/event-alert-processor`, {
          'tenant': tenant,
          'tenantEventAlert': this.get('model')
        });
        processors.addObject(currentProcessor);
      }

      this.set('currentProcessor', currentProcessor);
    }).keepLatest(),
    '_fillCodeEditor': function () {
      const containerDiv = this.$('div.event-alert-backend-editor-container');
      const panelDivs = this.$('div.event-alert-backend-editor-container div.panel');
      const codeEditorDiv = this.$('div.CodeMirror div.CodeMirror-scroll');
      const containerDivHeight = containerDiv.height();
      let panelDivTotalHeight = 0;

      for (let panelIdx = 0; panelIdx < panelDivs.length; panelIdx++) {
        const thisPanel = panelDivs[panelIdx];
        panelDivTotalHeight += this.$(thisPanel).outerHeight();
      }

      const topPanelDivs = this.$('div.event-alert-backend-editor-container div.panel.top');
      if (topPanelDivs.length > 1) panelDivTotalHeight += topPanelDivs.length - 1; // Add border height

      codeEditorDiv.css('min-height', containerDivHeight - panelDivTotalHeight > 300 ? containerDivHeight - panelDivTotalHeight : 300);
    }
  });

  _exports.default = _default;
});