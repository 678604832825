define("plantworks/components/settings/account/work-orders", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'classNames': ['w-100'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    'editable': false,
    'metadata': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'settings-account-basics-read');
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      const updatePerm = this.get('currentUser').hasPermission('settings-account-basics-update');
      this.set('editable', updatePerm);
    }),
    'save': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').save();
    }).drop().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'saveSucceeded': Ember.on('save:succeeded', function () {
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.settings_feature.account_feature.work-orders.succesful_save')
      });
    }),
    'saveErrored': Ember.on('save:errored', function (taskInstance, err) {
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'cancel': (0, _emberConcurrency.task)(function* () {
      yield this.get('model').rollback();
    }).drop()
  });

  _exports.default = _default;
});