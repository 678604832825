define("plantworks/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute(function () {
      return true;
    }), this.toRoute(function () {
      return true;
    }), this.use('crossFade', {
      'duration': 500
    }));
    this.transition(this.fromModel(function () {
      return true;
    }), this.toModel(function () {
      return true;
    }), this.use('crossFade', {
      'duration': 500
    }));
  }
});