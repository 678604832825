define("plantworks/components/board/watch/realtime-data-display", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HTMLBars
  } = window.Ember;

  var _default = _baseComponent.default.extend({
    'realtimeData': Ember.inject.service('realtime-data'),
    '_currentSelectedDashboard': null,
    '_realtimeDataDisplayArrayModel': null,
    '_realtimeDataDisplayObjectModel': null,
    '_periodicRequestDataModel': null,
    'displayMode': null,
    'isLoading': null,
    'entitySpecificProcessor': null,
    'entitySpecificLayout': null,
    'devSelectedLayout': null,

    init() {
      this._super(...arguments);

      this.set('permissions', 'board-watch');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      try {
        const sourceTypes = yield this.get('ajax').request('/masterdata/attributeSourceTypes', {
          'method': 'GET'
        });
        const dataTypes = yield this.get('ajax').request('/masterdata/attributeDataTypes', {
          'method': 'GET'
        });
        const timestampFormatTypes = yield this.get('ajax').request('/masterdata/timestampFormatTypes', {
          'method': 'GET'
        });
        this.setProperties({
          'sourceTypes': sourceTypes,
          'dataTypes': dataTypes,
          'timeFormats': timestampFormatTypes
        });
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-error', this, '_unsubscribeRealtimeData');
        this.get('realtimeData').on('websocket-close', this, '_unsubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberLifeline.runTask)(this, () => {
        this.set('_currentSelectedDashboard', this.get('model'));
        this.get('_setupRealtimeDataDisplayModel').perform();
      }, 150);
    },

    'onSelectedDashboardChanged': Ember.observer('model', function () {
      this.get('realtimeData.streamer').write({
        'action': 'unsubscribe',
        'type': 'panel',
        'id': this.get('_currentSelectedDashboard.id')
      });
      this.set('_currentSelectedDashboard', this.get('model'));
      this.get('_setupRealtimeDataDisplayModel').perform();
    }),
    '_setupRealtimeDataDisplayModel': (0, _emberConcurrency.task)(function* () {
      this.set('_realtimeDataDisplayArrayModel', null);
      this.set('_realtimeDataDisplayObjectModel', null);
      this.set('entitySpecificProcessor', null);
      this.set('entitySpecificLayout', null);
      this.set('devSelectedLayout', null);
      if (!this.get('_currentSelectedDashboard')) return;
      const realtimeDataModel = [];
      const dataPoints = yield this.get('_currentSelectedDashboard.dataPoints');
      dataPoints.forEach(dataPoint => {
        realtimeDataModel.push({
          'id': dataPoint.get('id'),
          'name': dataPoint.get('name'),
          'internalTag': dataPoint.get('internalTag'),
          'units': dataPoint.get('units'),
          'source': dataPoint.get('source'),
          'dataType': dataPoint.get('datatype'),
          'currentValue': null
        });
      });
      const _realtimeDataDisplayArrayModel = [];
      const _realtimeDataDisplayObjectModel = {};
      realtimeDataModel.forEach(realtimeDataPoint => {
        const emberRealtimeDataPoint = Ember.Object.create(realtimeDataPoint);

        _realtimeDataDisplayArrayModel.push(emberRealtimeDataPoint);

        _realtimeDataDisplayObjectModel[realtimeDataPoint.internalTag] = emberRealtimeDataPoint;
      });
      this.set('_realtimeDataDisplayObjectModel', Ember.Object.create(_realtimeDataDisplayObjectModel));
      this.set('_realtimeDataDisplayArrayModel', Ember.ArrayProxy.create({
        'content': Ember.A(_realtimeDataDisplayArrayModel)
      }));
      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'type': 'panel',
        'id': this.get('_currentSelectedDashboard.id')
      });
      yield this.get('_setupEntitySpecificLayout').perform();
    }).enqueue(),
    'onTemplateChanged': Ember.observer('model.templates.@each.template', function () {
      this.get('_setupEntitySpecificLayout').perform();
    }),
    'onRequestFormatterChanged': Ember.observer('model.requestFormatters.[]', function () {
      this.get('_setupPeriodicDataRequest').perform();
    }),
    'onRequestExecutorChanged': Ember.observer('model.executors.[]', function () {
      this.get('_setupPeriodicDataRequest').perform();
    }),
    'onResponseFormatterChanged': Ember.observer('model.responseFormatters.[]', function () {
      this.get('_setupPeriodicDataRequest').perform();
    }),
    '_setupEntitySpecificLayout': (0, _emberConcurrency.task)(function* () {
      try {
        this.set('entitySpecificProcessor', null);
        this.set('entitySpecificLayout', null);
        this.set('devSelectedLayout', null);
        let subcomponents = null;
        let subcomponentMap = Ember.Object.create({});
        if (!this.get('model')) return;
        const currentTemplates = yield this.get('model.templates');
        if (!currentTemplates.length) return;
        const currentPublishedTemplate = currentTemplates.filterBy('publishStatus', true).sortBy('createdAt').get('lastObject');
        const latestTemplate = currentTemplates.sortBy('createdAt').get('lastObject');
        const currentDisplayMode = this.get('displayMode') || 'production';

        if (currentDisplayMode === 'production' && !!currentPublishedTemplate) {
          let displayTemplate = currentPublishedTemplate;
          if (!displayTemplate.get('template')) return;
          if (displayTemplate.get('template').trim() === '') return;
          subcomponents = yield this.get('model.publishedSubcomponents');
          subcomponents.forEach(subcomponent => {
            if (subcomponent.get('name')) subcomponentMap.set(subcomponent.get('name'), subcomponent);
          });
          this.set('entitySpecificSubcomponents', subcomponentMap);
          this.set('entitySpecificProcessor', displayTemplate);
          this.set('entitySpecificLayout', HTMLBars.compile(displayTemplate.get('template').trim()));
          yield this.get('_setupPeriodicDataRequest').perform();
          return;
        }

        if (latestTemplate) {
          let displayTemplate = latestTemplate;

          if (this.get('devSelectedLayout')) {
            displayTemplate = currentTemplates.filterBy('id', this.get('devSelectedLayout')).objectAt(0);
          }

          if (!displayTemplate.get('template')) return;
          if (displayTemplate.get('template').trim() === '') return;
          subcomponents = yield this.get('model.currentSubcomponents');
          subcomponents.forEach(subcomponent => {
            if (subcomponent.get('name')) subcomponentMap.set(subcomponent.get('name'), subcomponent);
          });
          this.set('entitySpecificSubcomponents', subcomponentMap);
          this.set('entitySpecificProcessor', displayTemplate);
          this.set('entitySpecificLayout', HTMLBars.compile(displayTemplate.get('template').trim()));
          yield this.get('_setupPeriodicDataRequest').perform();
          return;
        }
      } catch (err) {
        this.debug(`_setupEntitySpecificLayout: `, err);
      }
    }).enqueue(),
    '_setupPeriodicDataRequest': (0, _emberConcurrency.task)(function* () {
      if (!this.get('entitySpecificLayout')) (0, _emberLifeline.cancelPoll)(this, 'DATA_REQUEST_POLL'); // Step 1: ensure this panel has atleast one request formatter, executor and response formatter

      const currentDisplayMode = this.get('displayMode') || 'production';
      const tenantPanel = this.get('model');
      let requestFormatter = yield tenantPanel.get('requestFormatters');
      let requestExecutor = yield tenantPanel.get('executors');
      let responseFormatter = yield tenantPanel.get('responseFormatters');

      if (currentDisplayMode === 'production') {
        requestFormatter = requestFormatter.filterBy('publishStatus', true);
        requestExecutor = requestExecutor.filterBy('publishStatus', true);
        responseFormatter = responseFormatter.filterBy('publishStatus', true);
      }

      if (!requestFormatter.get('length') || !requestExecutor.get('length') || !responseFormatter.get('length')) (0, _emberLifeline.cancelPoll)(this, 'DATA_REQUEST_POLL'); // Step 2: setup custom data request loop

      this.set('isLoading', true);
      (0, _emberLifeline.pollTask)(this, 'requestCustomData', 'DATA_REQUEST_POLL');
    }),

    requestCustomData(next) {
      this.get('_getCustomRequestData').perform(next);
    },

    '_getCustomRequestData': (0, _emberConcurrency.task)(function* (next) {
      const inputData = {
        'panelId': this.get('model.id'),
        'panelMode': this.get('displayMode') || 'production'
      };
      const resultData = yield this.get('ajax').post('/board/execute', {
        'dataType': 'json',
        'data': inputData
      });
      this.set('isLoading', false);
      this.set('_periodicRequestDataModel', resultData);
      (0, _emberLifeline.runTask)(this, next, this.get('model.dataRequestPeriod') * 1000);
    }).keepLatest(),

    _resubscribeRealtimeData() {
      if (!this.get('_currentSelectedDashboard')) return;
      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'type': 'panel',
        'id': this.get('_currentSelectedDashboard.id')
      });
      this.get('_setupPeriodicDataRequest').perform();
    },

    _unsubscribeRealtimeData() {
      (0, _emberLifeline.cancelPoll)(this, 'DATA_REQUEST_POLL');
    },

    _processRealtimeData(data) {
      try {
        this.debug(`board::watch::realtime-data-display::_processRealtimeData::data: `, data);
        if (!data) return;
        const realtimeDataObjectModel = this.get('_realtimeDataDisplayObjectModel');
        Object.keys(data).forEach(internalTag => {
          realtimeDataObjectModel.set(`${internalTag}.currentValue`, data[internalTag]);
        });
        const realtimeDataArrayModel = this.get('_realtimeDataDisplayArrayModel');

        for (let idx = 0; idx < realtimeDataArrayModel.length; idx++) {
          const thisDataPoint = realtimeDataArrayModel.objectAt(idx);
          const internalTag = thisDataPoint.get('internalTag');
          if (data[internalTag] === undefined) continue;
          thisDataPoint.set('currentValue', data[internalTag]);
        }
      } catch (err) {
        this.debug(`_processRealtimeArrayData::Error: `, err);
      }
    }

  });

  _exports.default = _default;
});