define("plantworks/components/profile/notification-area", ["exports", "plantworks/framework/base-component", "plantworks/config/environment"], function (_exports, _baseComponent, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    displayName: '',
    displayImage: '',

    init() {
      this._super(...arguments);

      this.set('permissions', 'registered');
      this.get('currentUser').on('userDataUpdated', this, 'onProfileUpdated');
    },

    destroy() {
      this.get('currentUser').off('userDataUpdated', this, 'onProfileUpdated');

      this._super(...arguments);
    },

    'onHasPermissionChange': Ember.observer('hasPermission', function () {
      let getImageUrl, subDomain;

      if (_environment.default.IS_CORBER) {
        subDomain = this.get('currentUser').getSubDomain();
        if (subDomain != null && subDomain != undefined) getImageUrl = `https://${subDomain}.${_environment.default.plantworks.domain}/profile/get-image?_random=${window.moment().valueOf()}`;else getImageUrl = `https://${_environment.default.plantworks.domain}/profile/get-image?_random=${window.moment().valueOf()}`;
      } else {
        getImageUrl = `/profile/get-image?_random=${window.moment().valueOf()}`;
      }

      if (!this.get('hasPermission')) {
        this.set('displayName', '');
        this.set('displayImage', '');
        return;
      }

      const userDetails = this.get('currentUser').getUser();
      this.set('displayName', userDetails ? userDetails['name'] : '');
      this.set('displayImage', userDetails ? getImageUrl : '');
    }),

    onProfileUpdated() {
      let getImageUrl, subDomain;

      if (_environment.default.IS_CORBER) {
        subDomain = this.get('currentUser').getSubDomain();
        if (subDomain != null && subDomain != undefined) getImageUrl = `https://${subDomain}.${_environment.default.plantworks.domain}/profile/get-image?_random=${window.moment().valueOf()}`;else getImageUrl = `https://${_environment.default.plantworks.domain}/profile/get-image?_random=${window.moment().valueOf()}`;
      } else {
        getImageUrl = `/profile/get-image?_random=${window.moment().valueOf()}`;
      }

      if (!this.get('hasPermission')) {
        this.set('displayName', '');
        this.set('displayImage', '');
        return;
      }

      const userDetails = this.get('currentUser').getUser();

      if (!userDetails) {
        this.set('displayName', '');
        this.set('displayImage', '');
        return;
      }

      this.set('displayName', userDetails ? userDetails['name'] : '');
      this.set('displayImage', userDetails ? getImageUrl : '');
    }

  });

  _exports.default = _default;
});