define("plantworks/templates/components/report/devenv/report-executor-environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N6JqJ2tA",
    "block": "{\"symbols\":[\"Card\",\"Tabs\",\"devMode\",\"idx\"],\"statements\":[[5,\"plant-works-card\",[],[[\"@class\"],[\"m-0 flex\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Content\"]],[],[[\"@class\"],[\"flex m-0 p-0 layout-column layout-align-start-stretch\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"plant-works-tabs\",[],[[\"@borderBottom\",\"@stretch\",\"@selected\",\"@onChange\"],[true,true,[22,\"currentDevMode\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"currentDevMode\"]]],null]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"allDevModes\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[6,[23,2,[\"Tab\"]],[],[[\"@value\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"t\",[[23,3,[\"name\"]]],null],false],[0,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[28,\"lte\",[[23,4,[]],[28,\"sub\",[[24,[\"allDevModes\",\"length\"]],2],null]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[6,[23,2,[\"Tab\"]],[],[[\"@disabled\"],[true]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"long-arrow-alt-right\",\"2x\"]]],[0,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3,4]},null],[0,\"\\t\\t\"]],\"parameters\":[2]}],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"report-executor-editor-subcomponent-container\"],[10,\"class\",\"flex layout-column layout-align-start-stretch bg-blue-grey lighten-2\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[24,[\"currentDevMode\",\"component\"]]],[[\"model\",\"controller-action\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"controller-action\"],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "plantworks/templates/components/report/devenv/report-executor-environment.hbs"
    }
  });

  _exports.default = _default;
});