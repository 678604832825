define("plantworks/components/manufacturing/configure/plant-unit-driver-editor/list", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['w-100'],
    'onInit': (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      let gatewayTypes = yield store.findAll('masterdata/gateway-master', {
        'reload': true
      });
      this.set('gatewayTypes', gatewayTypes);
    }).on('init').drop(),
    'addPlantUnitDriver': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const newPlantUnitDriver = this.get('store').createRecord('manufacturing/plant-unit-driver', {
        'tenant': tenant,
        'tenantPlantUnit': this.get('model')
      });
      const displayName = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.new_plant_unit_driver_name', {
        'now': window.moment().valueOf()
      });
      newPlantUnitDriver.set('name', displayName);
      newPlantUnitDriver.set('description', displayName);
      const siblingUnitDrivers = yield this.get('model.drivers');
      siblingUnitDrivers.addObject(newPlantUnitDriver);
      this.get('editPlantUnitDriver').perform(newPlantUnitDriver);
    }).enqueue(),
    'editPlantUnitDriver': (0, _emberConcurrency.task)(function* (unitDriver) {
      yield unitDriver.set('isEditing', true);
    }).enqueue(),
    'cancelPlantUnitDriver': (0, _emberConcurrency.task)(function* (unitDriver) {
      unitDriver.set('isEditing', false);
      yield unitDriver.rollback();
    }).enqueue(),
    'savePlantUnitDriver': (0, _emberConcurrency.task)(function* (unitDriver) {
      unitDriver.set('isEditing', false);
      yield unitDriver.save();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'savePlantUnitDriverSucceeded': Ember.on('savePlantUnitDriver:succeeded', function (taskInstance) {
      const unitDriver = taskInstance.args[0];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_driver_save', {
          'htmlSafe': true,
          'displayName': unitDriver.get('name')
        })
      });
    }),
    'savePlantUnitDriverErrored': Ember.on('savePlantUnitDriver:errored', function (taskInstance, err) {
      const unitDriver = taskInstance.args[0];
      unitDriver.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    }),
    'deletePlantUnitDriver': (0, _emberConcurrency.task)(function* (unitDriver) {
      const unitDriverName = unitDriver.get('name');
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_unit_driver'),
          'iconType': 'md',
          'icon': 'ac-unit'
        },
        'content': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.delete_plant_unit_driver_message', {
          'htmlSafe': true,
          'displayName': unitDriverName
        }),
        'confirmButton': {
          'text': 'Delete',
          'iconType': 'md',
          'icon': 'delete',
          'warn': true,
          'raised': true,
          'callback': () => {
            this.get('_confirmedDeletePlantUnitDriver').perform(unitDriver, unitDriverName);
          }
        },
        'cancelButton': {
          'text': 'Cancel',
          'iconType': 'md',
          'icon': 'close',
          'primary': true,
          'raised': true
        }
      };

      if (unitDriver.iotDeleteDisabled) {
        modalData.confirmButton.disabled = true;
        modalData.content = this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_delete_plant_unit_driver_alert', {
          'htmlSafe': true,
          'displayName': unitDriverName
        });
      }

      yield this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    '_confirmedDeletePlantUnitDriver': (0, _emberConcurrency.task)(function* (unitDriver, unitDriverName) {
      // eslint-disable-line no-unused-vars
      yield unitDriver.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    '_confirmedDeletePlantUnitDriverSucceeded': Ember.on('_confirmedDeletePlantUnitDriver:succeeded', function (taskInstance) {
      const unitDriverName = taskInstance.args[1];
      this.get('notification').display({
        'type': 'success',
        'message': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.succesful_plant_unit_driver_delete', {
          'htmlSafe': true,
          'displayName': unitDriverName
        })
      });
    }),
    '_confirmedDeletePlantUnitDriverErrored': Ember.on('_confirmedDeletePlantUnitDriver:errored', function (taskInstance, err) {
      const unitDriver = taskInstance.args[0];
      unitDriver.rollback();
      this.get('notification').display({
        'type': 'error',
        'error': err
      });
    })
  });

  _exports.default = _default;
});