define("plantworks/components/manufacturing/configure/plant-unit-line-editor/input-data-map-sub-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['p-0', 'layout-column', 'layout-align-start-stretch'],
    'allEntityData': null,
    'onInit': (0, _emberConcurrency.task)(function* () {
      let allObservedEntities = yield this.get('model.inputConstituents');
      this.createAllEntityData(allObservedEntities);
    }).on('init').drop(),
    'createAllEntityData': function (allObservedEntities) {
      const self = this;
      let allEntityData = Ember.Object.create({
        'machine': Ember.ArrayProxy.create({
          'content': Ember.A([])
        }),
        'line': Ember.ArrayProxy.create({
          'content': Ember.A([])
        })
      });
      Object.keys(allObservedEntities).forEach(entityType => {
        if (entityType !== 'block') {
          allEntityData[entityType] = allObservedEntities[entityType];
        } else {
          let allBlocks = allObservedEntities[entityType];
          allBlocks.forEach(block => {
            block.get('blockEntities').forEach(entity => {
              allEntityData[entity.get('entityType')].addObject(entity);
            });
          });
        }
      });
      self.set('allEntityData', allEntityData);
    }
  });

  _exports.default = _default;
});