define("plantworks/models/manufacturing/plant-unit-line-work-order-format", ["exports", "plantworks/framework/base-model", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _baseModel, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseModel.default.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'changeTracker': {
      'trackHasMany': true,
      'auto': true,
      'enableIsDirty': true
    },
    'tenant': _emberData.default.belongsTo('settings/account/basics/tenant', {
      'async': true,
      'inverse': null
    }),
    'tenantPlantUnitLine': _emberData.default.belongsTo('manufacturing/plant-unit-line', {
      'async': true,
      'inverse': 'workOrderFormats'
    }),
    'tenantWorkOrderFormat': _emberData.default.belongsTo('work-order/work-order-format', {
      'async': true,
      'inverse': 'lines'
    }),
    'filters': (0, _attributes.fragmentArray)('manufacturing/fragments/work-order-filters')
  });

  _exports.default = _default;
});