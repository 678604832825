define("plantworks/routes/application", ["exports", "plantworks/framework/base-route", "debug", "ember-local-storage"], function (_exports, _baseRoute, _debug, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    'intl': Ember.inject.service('intl'),

    init() {
      this._super(...arguments);

      if (window.developmentMode) _debug.default.enable('*');else _debug.default.disable();
    },

    settings: (0, _emberLocalStorage.storageFor)('settings'),

    beforeModel() {
      let settings = this.get('settings');

      if (settings.get('preferredLocale')) {
        this.get('intl').setLocale([settings.get('preferredLocale')]);
      } else {
        this.get('intl').setLocale(['en-US']);
      }
    },

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    'actions': {
      'controller-action': function (action, data) {
        this.get('controller').send('controller-action', action, data);
      }
    }
  });

  _exports.default = _default;
});