define("plantworks/components/manufacturing/watch/marico-work-order-form", ["exports", "plantworks/framework/base-component", "ember-lifeline", "ember-concurrency", "ember-moment/computeds/moment"], function (_exports, _baseComponent, _emberLifeline, _emberConcurrency, _moment2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    'moment': Ember.inject.service('moment'),
    'reasonReload': false,
    'displayLineSelector': false,
    'userAssigned': true,
    'selectedLine': null,
    'selectedUnit': null,
    'selectedLineNode': null,
    'observedLineMachines': null,
    'selectedLineMachine': null,
    'selectedLineMachineNode': null,
    'selectedObservedMachine': null,
    'currentMachineTab': 'downtime',
    'actualMachineDowntimes': null,
    'editSupervisor': null,
    'noInternetConnectivity': null,
    'noMachineConnectivity': null,
    'machineDowntimes': null,
    'machineSpeedDeviations': null,
    'downtimesLoaded': null,
    'realtimeData': Ember.inject.service('realtime-data'),
    'flag': null,
    'disableWorkOrderCancelation': false,
    'workOrderOperationTask': (0, _emberConcurrency.task)(function* () {
      yield this.get('_updateCurrentEndTimeForDowntimes').perform();
    }),

    init() {
      this._super(...arguments);

      (0, _emberLifeline.pollTask)(this, '_updateCurrentEndTime', 'CURRENT_TIME_POLL');
    },

    'onInit': (0, _emberConcurrency.task)(function* () {
      const tenant = yield this.get('model.tenant');
      const settins = yield tenant.get('settings');
      const unitId = yield this.get('model.-change-tracker.tenantPlantUnit'); // get all the shifts from units

      const selectedUnit = yield this.get('store').findRecord('manufacturing/plant-unit', unitId);
      this.set('selectedUnit', selectedUnit);
      if (settins && settins.get('disableWorkOrderOperationsCancelation')) this.set('disableWorkOrderCancelation', true);

      try {
        this.get('realtimeData').on('websocket-open', this, '_resubscribeRealtimeData');
        this.get('realtimeData').on('websocket-error', this, '_unsubscribeRealtimeData');
        this.get('realtimeData').on('websocket-close', this, '_unsubscribeRealtimeData');
        this.get('realtimeData').on('websocket-data::streaming-data', this, '_processRealtimeData');
        yield null;
      } catch (err) {
        this.get('notification').display({
          'type': 'error',
          'error': err
        });
      }
    }).on('init').keepLatest().retryable(window.PlantWorksApp.get('backoffPolicy')),

    willDestroyElement() {
      (0, _emberLifeline.cancelPoll)(this, 'CURRENT_TIME_POLL');

      this._super(...arguments);
    },

    destroy() {
      this.get('realtimeData').off('websocket-data::streaming-data', this, '_processRealtimeData');
      this.get('realtimeData').off('websocket-open', this, '_resubscribeRealtimeData');
      const currentlySelectedLineNode = this.get('selectedLineNode');

      if (currentlySelectedLineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedLineNode['observedEntityId'],
          'type': 'line.event'
        });
      }

      const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

      if (currentlySelectedMachineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedMachineNode['observedEntityId'],
          'type': 'machine.event'
        });
      }

      this._super(...arguments);
    },

    _updateCurrentEndTime(next) {
      this.get('_updateCurrentEndTimeForDowntimes').perform(next);
    },

    'onDidInsertElement': (0, _emberConcurrency.task)(function* () {
      yield this.get('_toggleLineSelectors').perform();
    }).on('didInsertElement').keepLatest(),
    'onObservedLinesChanged': Ember.observer('model.observedLines.[]', function () {
      this.get('_toggleLineSelectors').perform();
    }),
    'onSelectedLineChanged': Ember.observer('selectedLine', function () {
      this.get('_displayLineMachines').perform();
      this.get('_setSelectedLineNode').perform();
      this.get('_refreshSupervisor').perform();
      this.set('flag', true);
      this.set('shiftData', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
    }),
    'onSelectedLineMachineChanged': Ember.observer('selectedLineMachine', function () {
      this.set('downtimesLoaded', false);
      this.set('currentMachineTab', 'downtime');
      this.get('_setSelectedLineMachineNode').perform();
      this.get('_setSelectedObservedMachine').perform();
      this.get('_refreshOperator').perform(); // if(!this.get('selectedLineMachine'))
      // 	return;

      this.set('flag', true);
      this.set('shiftData', Ember.ArrayProxy.create({
        'content': Ember.A([])
      }));
      this.get('_updateCurrentEndTimeForDowntimes').perform();
    }),
    'toggleDurationReasons': (0, _emberConcurrency.task)(function* (downtime) {
      yield downtime.set('isEditing', !downtime.get('isEditing'));
    }).enqueue(),
    '_toggleLineSelectors': (0, _emberConcurrency.task)(function* () {
      const observedLines = yield this.get('model.observedLines');

      if (observedLines.get('length') === 0) {
        this.set('selectedLine', null);
        this.set('displayLineSelector', false);
        return;
      }

      if (observedLines.length >= 1) {
        this.set('downtimesLoaded', false);
        const lineArray = [];

        for (let i = 0; i < observedLines.length; i++) {
          const line = observedLines.objectAt(i);
          yield line.get('tenantPlantUnitLine').reload();
          let plantUnitLineWatchers = yield line.get('tenantPlantUnitLine.watchers');

          for (let j = 0; j < plantUnitLineWatchers.length; j++) {
            const user = plantUnitLineWatchers.objectAt(j);
            const userId = yield user.get('tenantUser');

            if (this.get('currentUser').getUser().user_id === userId.get('user').get('id')) {
              lineArray.push(line);
            }
          }
        }

        const lines = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        lineArray.forEach(line => {
          lines.addObject(line);
        });
        this.set('userAssigned', lines.length > 0);
        this.set('assignedLines', lines);
        this.set('selectedLine', lines.objectAt(0));
        this.set('editSupervisor', true);
        this.set('displayLineSelector', lines.length > 1);
        return;
      }
    }).keepLatest(),
    '_displayLineMachines': (0, _emberConcurrency.task)(function* () {
      if (!this.get('selectedLine')) {
        this.set('observedLineMachines', null);
        this.set('selectedLineMachine', null);
        return;
      }

      const plantUnitLine = yield this.get('selectedLine.tenantPlantUnitLine');
      const selectedLineConstituents = yield plantUnitLine.get('inputConstituents');
      const allLineMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      let actualLineMachine = selectedLineConstituents['machine'].filterBy('actualEntity.description', 'line').objectAt(0);
      if (actualLineMachine) allLineMachines.addObject(actualLineMachine.get('actualEntity'));
      const stationLineMachines = [];
      const observedMachines = yield this.get('model.observedMachines');

      for (let observedMachineIdx = 0; observedMachineIdx < observedMachines.length; observedMachineIdx++) {
        const observedMachine = observedMachines.objectAt(observedMachineIdx);
        const machine = yield observedMachine.get('tenantPlantUnitMachine');
        stationLineMachines.addObject(machine.get('id'));
      }

      const monitoredLineMachines = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      allLineMachines.forEach(lineMachine => {
        if (!stationLineMachines.includes(lineMachine.get('id'))) return;
        monitoredLineMachines.addObject(lineMachine);
      });

      if (monitoredLineMachines.length < 1) {
        this.set('observedLineMachines', []);
        this.set('selectedLineMachine', null);
        return;
      }

      this.set('observedLineMachines', monitoredLineMachines);
      this.set('selectedLineMachine', monitoredLineMachines.objectAt(0));
    }).keepLatest(),
    '_updateCurrentEndTimeForDowntimes': (0, _emberConcurrency.task)(function* (next) {
      if (!this.get('selectedLineMachine')) return;
      this.set('unaccIdle', true);
      this.set('unaccForThisWorkOrder', true);
      this.set('unaccSpeedDeviations', true);

      if (!this.get('selectedLineMachine') && next) {
        (0, _emberLifeline.runTask)(this, next, 2000);
        return;
      }

      const currentTime = new Date();
      const lineMachine = this.get('selectedLineMachine');
      yield lineMachine.reload();
      yield lineMachine.get('downtimes').reload();
      yield lineMachine.get('setuptimes').reload();
      yield lineMachine.get('idletimes').reload();
      let connDetails = yield this.get('ajax').request(`/manufacturing/plant-unit-machine-connection-details/${this.get('selectedLineMachine.id')}`, {
        'method': 'GET'
      });

      if (connDetails) {
        let date;

        if (connDetails.end_time) {
          date = moment(connDetails.end_time).format('DD-MMM-YYYY hh:mm A');
        } else {
          date = moment(connDetails.start_time).format('DD-MMM-YYYY hh:mm A');
        }

        this.set('date', date);
        this.set('connectionDetails', connDetails);
      }

      let actualMachineDowntimes = Ember.ArrayProxy.create({
        'content': Ember.A([])
      });
      let totalUnaccTime = 0;
      let totalUnAccIdleTime = 0;
      let totalUnAccSpeedDeviations = 0;
      let totalUnAccDowntimes = 0; // entries for downtimes tab only - not considering speed deviations
      // Loop through all the machines to calculate unaccounted times

      const machines = this.get('observedLineMachines');

      for (let i = 0; i < machines.length; i++) {
        const machine = machines.objectAt(i);
        const machineIdletimes = yield machine.get('idletimes').reload();
        const machineDowntimes = yield machine.get('downtimes').reload();
        const machineSetuptimes = yield machine.get('setuptimes').reload(); // calculate unaccounted time for idle times

        if (machineIdletimes && machineIdletimes.length) {
          for (let idx = 0; idx < machineIdletimes.length; idx++) {
            let thisMachineIdletime = machineIdletimes.objectAt(idx);

            if (this.get('reasonReload')) {
              // reasons model reload
              // get the unsaved reasons, remove them from the existing reasons model, reload the reasons model and push the unsaved reasons back in the model
              const newReasons = yield thisMachineIdletime.get('reasons').filter(reason => {
                return reason.get('isDirty');
              });
              newReasons.forEach(reason => {
                thisMachineIdletime.get('reasons').removeObject(reason);
              });
              yield thisMachineIdletime.get('reasons').reload();
              newReasons.forEach(reason => {
                thisMachineIdletime.get('reasons').addObject(reason);
              });
            }

            if (thisMachineIdletime.get('endTime')) {
              yield thisMachineIdletime.set('currentEndTime', null);
            } else {
              yield thisMachineIdletime.set('currentEndTime', currentTime);
            }

            totalUnAccIdleTime += yield thisMachineIdletime.get('unaccountedDurationForCalculations');
          }
        } // filter out the same instances of the setuptime and downtime, loading this only if there's no idletimes


        if (machineDowntimes && machineDowntimes.length && machineIdletimes.length == 0) {
          for (let idx = 0; idx < machineDowntimes.length; idx++) {
            let isSetupTime = false;
            let thisMachineDowntime = machineDowntimes.objectAt(idx);

            if (this.get('reasonReload')) {
              // reasons model reload
              // get the unsaved reasons, remove them from the existing reasons model, reload the reasons model and push the unsaved reasons back in the model
              const newReasons = yield thisMachineDowntime.get('reasons').filter(reason => {
                return reason.get('isDirty');
              });
              newReasons.forEach(reason => {
                thisMachineDowntime.get('reasons').removeObject(reason);
              });
              yield thisMachineDowntime.get('reasons').reload();
              newReasons.forEach(reason => {
                thisMachineDowntime.get('reasons').addObject(reason);
              });
            }

            if (thisMachineDowntime.get('endTime')) {
              yield thisMachineDowntime.set('currentEndTime', null);
            } else {
              yield thisMachineDowntime.set('currentEndTime', currentTime);
            }

            let rejectType = ['downtime', 'disconnected', 'setup_time', 'idle_time', 'no_data'];

            if (rejectType.indexOf(thisMachineDowntime.get('type')) == -1) {
              totalUnaccTime += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
              totalUnAccSpeedDeviations += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
            }

            if (thisMachineDowntime.get('type') != 'downtime') {
              if (thisMachineDowntime.get('tenantPlantUnitMachine.id') == this.get('selectedLineMachine.id')) actualMachineDowntimes.addObject(thisMachineDowntime);
              continue;
            }

            if (machineSetuptimes.length > 0) {
              for (let idx = 0; idx < machineSetuptimes.length; idx++) {
                let thisMachineSetuptime = machineSetuptimes.objectAt(idx);

                if (this.get('reasonReload')) {
                  // reasons model reload
                  // get the unsaved reasons, remove them from the existing reasons model, reload the reasons model and push the unsaved reasons back in the model
                  const newReasons = yield thisMachineSetuptime.get('reasons').filter(reason => {
                    return reason.get('isDirty');
                  });
                  newReasons.forEach(reason => {
                    thisMachineSetuptime.get('reasons').removeObject(reason);
                  });
                  yield thisMachineSetuptime.get('reasons').reload();
                  newReasons.forEach(reason => {
                    thisMachineSetuptime.get('reasons').addObject(reason);
                  });
                }

                let endTime = thisMachineSetuptime.get('endTime');
                let isOpenEnded = false;

                if (endTime) {
                  yield thisMachineSetuptime.set('currentEndTime', null);
                } else {
                  isOpenEnded = true;
                  yield thisMachineSetuptime.set('currentEndTime', currentTime);
                  endTime = thisMachineSetuptime.get('currentEndTime');
                }

                if (moment(endTime).diff(moment(thisMachineSetuptime.get('startTime')).format(), 'seconds') > 60 || isOpenEnded) {
                  if (thisMachineDowntime.get('tenantPlantUnitMachine.id') == thisMachineSetuptime.get('tenantPlantUnitMachine.id') && moment(thisMachineDowntime.get('startTime')).format() == moment(thisMachineSetuptime.get('startTime')).format()) {
                    isSetupTime = true;
                  }
                }
              }
            }

            if (!isSetupTime && thisMachineDowntime.get('tenantPlantUnitMachine.id') == this.get('selectedLineMachine.id')) actualMachineDowntimes.addObject(thisMachineDowntime);

            if (!isSetupTime) {
              totalUnaccTime += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
              totalUnAccDowntimes += yield thisMachineDowntime.get('unaccountedDurationForCalculations');
            }
          }
        } // calculate unaccounted time for setuptimes


        for (let idx = 0; idx < machineSetuptimes.length; idx++) {
          let thisSetuptime = machineSetuptimes.objectAt(idx);

          if (this.get('reasonReload')) {
            // reasons model reload
            // get the unsaved reasons, remove them from the existing reasons model, reload the reasons model and push the unsaved reasons back in the model
            const newReasons = yield thisSetuptime.get('reasons').filter(reason => {
              return reason.get('isDirty');
            });
            newReasons.forEach(reason => {
              thisSetuptime.get('reasons').removeObject(reason);
            });
            yield thisSetuptime.get('reasons').reload();
            newReasons.forEach(reason => {
              thisSetuptime.get('reasons').addObject(reason);
            });
          }

          totalUnaccTime += yield thisSetuptime.get('unaccountedDurationForCalculations');
          totalUnAccDowntimes += yield thisSetuptime.get('unaccountedDurationForCalculations');
        }
      }

      yield this.set('actualMachineDowntimes', actualMachineDowntimes);

      if (actualMachineDowntimes.length) {
        let startInterval = moment(actualMachineDowntimes.objectAt(0).startTime).tz('Asia/Kolkata').subtract(1, 'day');
        let endInterval = moment().tz('Asia/Kolkata');
        yield this.get('_truncateShiftTimes').perform(startInterval, endInterval, actualMachineDowntimes);
      }

      this.set('downtimesLoaded', true);
      this.set('unaccIdle', totalUnAccIdleTime > 10);
      this.set('unaccForThisWorkOrder', totalUnaccTime > 10);
      this.set('speedDeviationsAlert', totalUnAccSpeedDeviations > 0);
      this.set('downtimesAlert', totalUnAccDowntimes > 0 || totalUnAccIdleTime > 0); // reset the reason reload flag

      if (this.get('reasonReload')) this.set('reasonReload', !this.get('reasonReload'));
      if (next) (0, _emberLifeline.runTask)(this, next, 30000);
    }).keepLatest(),
    '_setSelectedLineNode': (0, _emberConcurrency.task)(function* () {
      const currentlySelectedLineNode = this.get('selectedLineNode');

      if (currentlySelectedLineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'unsubscribe',
          'id': currentlySelectedLineNode['observedEntityId'],
          'type': 'line.event'
        });
      }

      this.set('selectedLineNode', {
        'id': this.get('selectedLine.id'),
        'observedEntityId': this.get('selectedLine.tenantPlantUnitLine.id'),
        'data': {
          'type': 'line'
        }
      });
      this.get('realtimeData.streamer').write({
        'action': 'subscribe',
        'id': this.get('selectedLine.tenantPlantUnitLine.id'),
        'type': 'line.event'
      });
      yield null;
    }).keepLatest(),
    '_setSelectedLineMachineNode': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');

      for (let idx = 0; idx < observedMachines.length; idx++) {
        const observedMachine = observedMachines.objectAt(idx);

        if (observedMachine.get('tenantPlantUnitMachine.id') === this.get('selectedLineMachine.id')) {
          const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

          if (currentlySelectedMachineNode) {
            this.get('realtimeData.streamer').write({
              'action': 'unsubscribe',
              'id': currentlySelectedMachineNode['observedEntityId'],
              'type': 'machine.event'
            });
          }

          this.set('selectedLineMachineNode', {
            'id': observedMachine.get('id'),
            'observedEntityId': this.get('selectedLineMachine.id'),
            'data': {
              'type': 'machine'
            }
          });
          this.get('realtimeData.streamer').write({
            'action': 'subscribe',
            'id': this.get('selectedLineMachine.id'),
            'type': 'machine.event'
          });
          break;
        }
      }
    }).keepLatest(),
    '_setSelectedObservedMachine': (0, _emberConcurrency.task)(function* () {
      const observedMachines = yield this.get('model.observedMachines');
      const selectedLineMachine = this.get('selectedLineMachine');
      let selectedObservedMachine = null;
      if (!selectedLineMachine || !observedMachines || !observedMachines.get('length')) return;

      for (let idx = 0; idx < observedMachines.get('length'); idx++) {
        const observedMachine = observedMachines.objectAt(idx);
        const observedPlantUnitMachine = yield observedMachine.get('tenantPlantUnitMachine');

        if (observedPlantUnitMachine.get('id') === selectedLineMachine.get('id')) {
          selectedObservedMachine = observedMachine;
          break;
        }
      }

      this.set('selectedObservedMachine', selectedObservedMachine);
    }).keepLatest(),

    _resubscribeRealtimeData() {
      const currentlySelectedLineNode = this.get('selectedLineNode');

      if (currentlySelectedLineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'subscribe',
          'id': currentlySelectedLineNode['observedEntityId'],
          'type': 'line.event'
        });
      }

      const currentlySelectedMachineNode = this.get('selectedLineMachineNode');

      if (currentlySelectedMachineNode) {
        this.get('realtimeData.streamer').write({
          'action': 'subscribe',
          'id': currentlySelectedMachineNode['observedEntityId'],
          'type': 'machine.event'
        });
      } // set the reason reload flag


      this.set('reasonReload', !this.get('reasonReload'));
      (0, _emberLifeline.pollTask)(this, '_updateCurrentEndTime', 'CURRENT_TIME_POLL');
    },

    _unsubscribeRealtimeData() {
      (0, _emberLifeline.cancelPoll)(this, 'CURRENT_TIME_POLL');
    },

    _processRealtimeData(data) {
      try {
        this.debug(`manufacturing::work-order-operations::_processRealtimeData::data: `, data);
        if (!data || !data.tag) return;

        if (data.tag === 'operator_change') {
          this.get('_refreshOperator').perform();
        }

        if (data.tag === 'supervisor_change') {
          this.get('_refreshSupervisor').perform();
        }
      } catch (err) {
        this.debug(`_processRealtimeArrayData::Error: `, err);
      }
    },

    'updateShifts': (0, _emberConcurrency.task)(function* (shifts) {
      yield shifts.set('isEditing', !shifts.get('isEditing'));
    }).keepLatest(),
    '_truncateShiftTimes': (0, _emberConcurrency.task)(function* (startInterval, endInterval, machineDowntimes) {
      if (this.get('flag') == true) {
        // get the current unit
        let selectedUnit = this.get('selectedUnit');
        let shifts = yield selectedUnit.get('schedules');
        let actualShiftData = Ember.ArrayProxy.create({
          'content': Ember.A([])
        });
        shifts = shifts.filter(schedule => {
          if (schedule.type == 'shift') {
            if (moment(schedule.startDate) >= moment(startInterval) || moment(schedule.endDate) <= moment(endInterval) || moment(schedule.startDate) <= moment(startInterval) && moment(schedule.endDate) >= moment(startInterval)) {
              return true;
            }
          } else {
            return false;
          }
        });
        endInterval = moment(endInterval).add(1, 'day');
        let shiftIntervalArr = [];

        while (moment(endInterval).isSameOrAfter(moment(startInterval))) {
          for (let i = 0; i < shifts.length; i++) {
            let thisShift = shifts[i];
            const shiftObj1 = Ember.Object.extend({});
            let st = thisShift.startTime.split(':');
            let et = thisShift.endTime.split(':');
            let intervalStart, intervalEnd;

            if (moment(endInterval).clone().startOf('day') >= moment(thisShift.startDate).tz('Asia/Kolkata') && moment(endInterval).clone().startOf('day') <= moment(thisShift.endDate).tz('Asia/Kolkata')) {
              intervalStart = endInterval.clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0);

              if (st[0] > et[0]) {
                intervalEnd = endInterval.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0).add(1, 'day');
              } else {
                intervalEnd = endInterval.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0);
              }
            } //create an ember object


            let shiftObj = shiftObj1.create({
              intervalStart: moment(intervalStart).tz('Asia/Kolkata'),
              intervalEnd: moment(intervalEnd).tz('Asia/Kolkata'),
              date: moment(intervalStart).tz('Asia/Kolkata').format('DD/MM/YYYY'),
              shiftName: thisShift.description,
              displayStatus: false,
              timestampValue: moment(intervalStart).valueOf(),
              isEditing: false
            });

            if (moment().tz('Asia/Kolkata').isBetween(moment(intervalStart).tz('Asia/Kolkata'), moment(intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
              shiftObj['displayStatus'] = true;
            }

            shiftIntervalArr.push(shiftObj);
          }

          endInterval = endInterval.clone().tz('Asia/Kolkata').subtract(1, 'day');
        }

        shiftIntervalArr = shiftIntervalArr.sort(function (a, b) {
          return moment(a['timestampValue']).tz('Asia/Kolkata').diff(moment(b['timestampValue']).tz('Asia/Kolkata'));
        });
        shiftIntervalArr = shiftIntervalArr.reverse();

        for (let i = 0; i < shiftIntervalArr.length; i++) {
          let shiftObj = shiftIntervalArr[i];
          let actualMachineDowntimes = Ember.ArrayProxy.create({
            'content': Ember.A([])
          });
          let thisFilterDowntime = machineDowntimes.filter(function (downtime) {
            return moment(downtime.startTime).tz('Asia/Kolkata') >= moment(shiftObj['intervalStart']).tz('Asia/Kolkata') && moment(downtime.endTime).tz('Asia/Kolkata') <= moment(shiftObj['intervalEnd']).tz('Asia/Kolkata');
          });

          if (thisFilterDowntime.length) {
            for (let f = 0; f < thisFilterDowntime.length; f++) {
              actualMachineDowntimes.addObject(thisFilterDowntime[f]);
            }
          }

          shiftObj['actualMachineDowntimes'] = actualMachineDowntimes;

          if (actualMachineDowntimes.length) {
            actualShiftData.addObject(shiftObj);
          }
        }

        this.set('shiftData', actualShiftData);
        this.set('flag', false);
      } else if (this.get('flag') == false) {
        let selectedUnit = this.get('selectedUnit');
        let shifts = yield selectedUnit.get('schedules');
        let actualShiftData = this.get('shiftData');
        shifts = shifts.filter(schedule => {
          if (schedule.type == 'shift') {
            if (moment(schedule.startDate) >= moment(startInterval) || moment(schedule.endDate) <= moment(endInterval) || moment(schedule.startDate) <= moment(startInterval) && moment(schedule.endDate) >= moment(startInterval)) {
              return true;
            }
          } else {
            return false;
          }
        });
        startInterval = moment(endInterval).subtract(1, 'day');
        endInterval = moment(endInterval).add(1, 'day'); // get the first shift obj for shift data

        let latestShiftData = actualShiftData.objectAt(0); // if the latest shift is still ongoing then update the downtimes in it

        if (moment().tz('Asia/Kolkata').isBetween(moment(latestShiftData.intervalStart).tz('Asia/Kolkata'), moment(latestShiftData.intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
          let actualMachineDowntimes = Ember.ArrayProxy.create({
            'content': Ember.A([])
          });
          let thisFilterDowntime = machineDowntimes.filter(function (downtime) {
            return moment(downtime.startTime).tz('Asia/Kolkata') >= moment(latestShiftData['intervalStart']).tz('Asia/Kolkata') && moment(downtime.endTime).tz('Asia/Kolkata') <= moment(latestShiftData['intervalEnd']).tz('Asia/Kolkata');
          });

          if (thisFilterDowntime.length) {
            for (let f = 0; f < thisFilterDowntime.length; f++) {
              actualMachineDowntimes.addObject(thisFilterDowntime[f]);
            }
          } // update actualMachineDowntimes if any


          this.get('shiftData').objectAt(0).set('actualMachineDowntimes', actualMachineDowntimes); // if no downtimes then remove object

          if (!actualMachineDowntimes.length) {
            this.get('shiftData').removeObject(latestShiftData);
          }
        } else if (moment().tz('Asia/Kolkata').isAfter(moment(latestShiftData.intervalEnd).tz('Asia/Kolkata'))) {
          let newShiftData = Ember.ArrayProxy.create({
            'content': Ember.A([])
          }); // get the current running shift

          while (moment(endInterval).isSameOrAfter(moment(startInterval))) {
            for (let i = 0; i < shifts.length; i++) {
              let thisShift = shifts[i];
              const shiftObj1 = Ember.Object.extend({});
              let st = thisShift.startTime.split(':');
              let et = thisShift.endTime.split(':');
              let intervalStart, intervalEnd;

              if (moment(endInterval).clone().startOf('day') >= moment(thisShift.startDate).tz('Asia/Kolkata') && moment(endInterval).clone().startOf('day') <= moment(thisShift.endDate).tz('Asia/Kolkata')) {
                intervalStart = endInterval.clone().tz('Asia/Kolkata').hour(st[0]).minute(st[1]).second(0).millisecond(0);

                if (st[0] > et[0]) {
                  intervalEnd = endInterval.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0).add(1, 'day');
                } else {
                  intervalEnd = endInterval.clone().tz('Asia/Kolkata').hour(et[0]).minute(et[1]).second(0).millisecond(0);
                }
              }

              if (moment().tz('Asia/Kolkata').isBetween(moment(intervalStart).tz('Asia/Kolkata'), moment(intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
                //create an ember object
                let shiftObj = shiftObj1.create({
                  intervalStart: moment(intervalStart).tz('Asia/Kolkata'),
                  intervalEnd: moment(intervalEnd).tz('Asia/Kolkata'),
                  date: moment(intervalStart).tz('Asia/Kolkata').format('DD/MM/YYYY'),
                  shiftName: thisShift.description,
                  displayStatus: true,
                  timestampValue: moment(intervalStart).valueOf(),
                  isEditing: false
                });
                let actualMachineDowntimes = Ember.ArrayProxy.create({
                  'content': Ember.A([])
                });
                let thisFilterDowntime = machineDowntimes.filter(function (downtime) {
                  return moment(downtime.startTime).tz('Asia/Kolkata') >= moment(shiftObj['intervalStart']).tz('Asia/Kolkata') && moment(downtime.endTime).tz('Asia/Kolkata') <= moment(shiftObj['intervalEnd']).tz('Asia/Kolkata');
                });

                if (thisFilterDowntime.length) {
                  for (let f = 0; f < thisFilterDowntime.length; f++) {
                    actualMachineDowntimes.addObject(thisFilterDowntime[f]);
                  }

                  shiftObj['actualMachineDowntimes'] = actualMachineDowntimes;
                  newShiftData.addObject(shiftObj);
                }
              }
            }

            endInterval = endInterval.clone().tz('Asia/Kolkata').subtract(1, 'day');
          } // get the existing state of shift data


          let curentStateData = this.get('shiftData'); // reset the shift data if and only if new shift has downtime

          if (curentStateData.length) {
            curentStateData.forEach(data => {
              if (!moment().tz('Asia/Kolkata').isBetween(moment(data.intervalStart).tz('Asia/Kolkata'), moment(data.intervalEnd).tz('Asia/Kolkata'), undefined, '[]')) {
                data.set('displayStatus', false);
              }

              newShiftData.addObject(data);
            });
            this.set('shiftData', newShiftData);
          }
        }
      }
    }),
    '_refreshOperator': (0, _emberConcurrency.task)(function* () {
      const selectedLineMachine = this.get('selectedLineMachine');
      if (selectedLineMachine) yield selectedLineMachine.reload();
    }).keepLatest(),
    '_refreshSupervisor': (0, _emberConcurrency.task)(function* () {
      const selectedLine = this.get('selectedLine.tenantPlantUnitLine');
      if (selectedLine) yield selectedLine.reload();
    }).keepLatest()
  });

  _exports.default = _default;
});