define("plantworks/components/manufacturing/configure/plant-unit-line-editor/block-editor", ["exports", "plantworks/framework/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    classNames: ['pb-2', 'bg-grey', 'layout-column', 'layout-align-start-stretch'],
    'addBlockEntites': (0, _emberConcurrency.task)(function* () {
      const block = this.get('model');
      const parentLine = yield block.get('constituent.tenantPlantUnitLine');
      const allInputConstituents = yield parentLine.get('inputConstituents');
      const alreadyAddedEntities = [];
      Object.keys(allInputConstituents).forEach(entityType => {
        if (entityType !== 'block') {
          allInputConstituents[entityType].forEach(entity => {
            alreadyAddedEntities.push(entity.get('actualEntity.id'));
          });
        } else {
          allInputConstituents[entityType].forEach(entity => {
            entity.get('blockEntities').forEach(blockEntity => {
              alreadyAddedEntities.push(blockEntity.get('actualEntity.id'));
            });
          });
        }
      });
      const plantUnit = yield block.get('constituent.tenantPlantUnitLine.tenantPlantUnit');
      const plant = yield plantUnit.get('plant');
      const plantUnits = yield plant.get('units');
      const allPlantMachines = [];
      const allPlantLines = [];

      for (let idx = 0; idx < plantUnits.length; idx++) {
        const unit = plantUnits.objectAt(idx);
        const unitMachines = yield unit.get('machines');

        for (let jdx = 0; jdx < unitMachines.length; jdx++) {
          const machine = unitMachines.objectAt(jdx);
          if (machine.get('isNew')) continue;
          allPlantMachines.push(machine);
        }
      }

      for (let idx = 0; idx < plantUnits.length; idx++) {
        const unit = plantUnits.objectAt(idx);
        const unitLines = yield unit.get('lines');

        for (let jdx = 0; jdx < unitLines.length; jdx++) {
          const Line = unitLines.objectAt(jdx);
          if (Line.get('isNew')) continue;
          if (Line.get('id') === parentLine.get('id')) continue;
          allPlantLines.push(Line);
        }
      }

      const availableMachines = allPlantMachines.filter(plantMachine => {
        return !alreadyAddedEntities.includes(plantMachine.get('id'));
      });
      const availableLines = allPlantLines.filter(plantLine => {
        return !alreadyAddedEntities.includes(plantLine.get('id'));
      });
      const self = this;
      const chooseModalModel = availableMachines.map(availableMachine => {
        return {
          'selected': false,
          'entity': availableMachine,
          'type': 'machine'
        };
      });
      availableLines.forEach(line => {
        chooseModalModel.push({
          'selected': false,
          'entity': line,
          'type': 'line'
        });
      });
      const modalData = {
        'title': {
          'text': this.intl.t('plant_works_webapp_server_server.manufacturing_feature.label_plant_unit_driver_gateway_add_machine'),
          'iconType': 'mdi',
          'icon': 'screw-machine-round-top'
        },
        'dialogClass': 'flex',
        'componentName': 'Manufacturing/Configure/PlantUnitLineEditor/AddBlockEntities',
        'componentState': {
          'model': chooseModalModel
        },
        'confirmButton': {
          'text': this.intl.t('modal.default_add_text'),
          'iconType': 'md',
          'icon': 'check',
          'primary': true,
          'raised': true,
          'callback': () => {
            self.get('addEntites').perform(chooseModalModel);
          }
        },
        'cancelButton': {
          'text': this.intl.t('modal.default_cancel_text'),
          'iconType': 'md',
          'icon': 'cancel',
          'warn': true,
          'raised': true,
          'callback': () => {}
        }
      };
      this.invokeAction('controller-action', 'displayModal', modalData);
    }).drop(),
    'deleteEntity': (0, _emberConcurrency.task)(function* (entity) {
      yield entity.destroyRecord();
    }).enqueue().evented().retryable(window.PlantWorksApp.get('backoffPolicy')),
    'deleteEntitySucceeded': Ember.on('deleteEntity:succeeded', function (taskInstance) {
      const deletedEntity = taskInstance.args[0];
      let blockEntities = this.get('model.blockEntities');
      blockEntities = blockEntities.filter(entity => {
        if (entity.get('entityId') === deletedEntity.get('entityId')) return false;else return true;
      });
      this.set('model.blockEntities', blockEntities);
    }),
    'addEntites': (0, _emberConcurrency.task)(function* (chooseModalModel) {
      const blockModel = this.get('model');
      const block = blockModel.get('constituent');
      const tenant = yield block.get('tenant');
      const chosenEntities = chooseModalModel.filter(chosenEntity => {
        return chosenEntity.selected;
      });

      for (let idx = 0; idx < chosenEntities.length; idx++) {
        const chosenEntity = chosenEntities[idx];
        let entity = chosenEntity.entity;
        const newBlockEntity = this.get('store').createRecord('manufacturing/plant-unit-line-block-entity', {
          'tenant': tenant,
          'block': block,
          'entityId': entity.get('id'),
          'entityType': chosenEntity.type,
          'isActive': false
        });
        entity = chosenEntity.entity;
        const blockEntities = blockModel.get('blockEntities');
        let actualEntity = null;
        yield newBlockEntity.save();
        if (newBlockEntity.get('entityType') === 'machine') actualEntity = yield this.get('store').findRecord('manufacturing/plant-unit-machine', newBlockEntity.get('entityId'));else if (newBlockEntity.get('entityType') === 'line') actualEntity = yield this.get('store').findRecord('manufacturing/plant-unit-line', newBlockEntity.get('entityId'));
        newBlockEntity.set('actualEntity', actualEntity);
        blockEntities.addObject(newBlockEntity);
      }
    }).drop().retryable(window.PlantWorksApp.get('backoffPolicy'))
  });

  _exports.default = _default;
});